import { Box } from '@material-ui/core';
import { Lightbox } from 'components';
import React, { useState } from 'react';
import { useStyles } from './profile-picture-post-content.style';

type ProfilePicturePostContentProps = {
    imageUrl: string;
};

export const ProfilePicturePostContent: React.FC<ProfilePicturePostContentProps> = ({ imageUrl }) => {
    const classes = useStyles({ imageUrl })();
    const [lightboxOpen, setLightboxOpen] = useState(false);

    const openLightbox = (): void => {
        setLightboxOpen(true);
    };

    return (
        <Box className={classes.root}>
            <img className={classes.image} src={imageUrl} onClick={openLightbox} />
            <Box className={classes.background} />
            {lightboxOpen && <Lightbox mainSrc={imageUrl} onCloseRequest={(): void => setLightboxOpen(false)} />}
        </Box>
    );
};
