import { Container, ContainerProps, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { mobileSize } from 'config';
import React from 'react';

type PageContentProps = ContainerProps & { disableMobilePadding?: boolean };

const useStyles = makeStyles((theme) => {
    return {
        root: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            marginRight: 0,
            paddingRight: theme.spacing(8),
            [theme.breakpoints.down(mobileSize)]: {
                padding: theme.spacing(0, 2),
            },
        },
        disableMobilePadding: {
            [theme.breakpoints.down(mobileSize)]: {
                padding: 0,
                overflow: 'hidden',
            },
        },
    };
});

export const PageContent: React.FC<PageContentProps> = ({
    maxWidth = 'md',
    className,
    children,
    disableMobilePadding = true,
    ...other
}) => {
    const classes = useStyles();
    return (
        <Container
            maxWidth={maxWidth}
            className={clsx(classes.root, disableMobilePadding && classes.disableMobilePadding, className)}
            {...other}
        >
            {children}
        </Container>
    );
};
