import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => {
    return {
        lightbox: {
            '& *': {
                outline: 'none',
            },
            '& .ril__toolbar': {
                height: 64,
                backgroundColor: 'rgba(0,0,0,0.9)',
            },
            '& .ril__toolbarSide': {
                height: 64,
            },
            '& .ril__toolbarItem': {
                lineHeight: '64px',
            },
        },
    };
});
