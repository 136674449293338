import { Lightbox } from 'components';
import { RatioBox } from 'components/ratio-box';
import React, { useState } from 'react';
import { useStyles } from './cover-photo-post-content.style';

type CoverPhotoPostContentProps = {
    imageUrl: string;
};

export const CoverPhotoPostContent: React.FC<CoverPhotoPostContentProps> = ({ imageUrl }) => {
    const classes = useStyles();
    const [lightboxOpen, setLightboxOpen] = useState(false);

    const openLightbox = (): void => {
        setLightboxOpen(true);
    };

    return (
        <RatioBox>
            <img className={classes.image} src={imageUrl} onClick={openLightbox} />
            {lightboxOpen && <Lightbox mainSrc={imageUrl} onCloseRequest={(): void => setLightboxOpen(false)} />}
        </RatioBox>
    );
};
