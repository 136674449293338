import { Box, Grid, IconButton, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { ReactComponent as CommentIcon } from 'assets/icons/comment.svg';
import { ReactComponent as HeartIcon } from 'assets/icons/heart.svg';
import { ReactComponent as HeartFilledIcon } from 'assets/icons/heart-filled.svg';
import { SharePostDialog } from 'components/post/share-post-dialog';
import { Post, useDislikePostMutation, useLikePostMutation } from 'graphql/generated';
import { mobileSize } from 'layout-config';
import React, { useState } from 'react';
import { useStyles } from './post-actions.style';
import { RepostedUsersDialog } from 'components/post/reposted-users-dialog';

type PostActionsProps = {
    post: Post;
    inputRef: React.RefObject<HTMLInputElement>;
};

export const PostActions: React.FC<PostActionsProps> = ({ post, inputRef }) => {
    const classes = useStyles();
    const { id, isLiked, likeCount, repostCount } = post;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(mobileSize));
    const [like, setLike] = useState({ isLiked, likeCount });

    const [likePost] = useLikePostMutation({
        onCompleted(result) {
            const likeCount = result.likePost.likeCount;
            const isLiked = true;
            setLike({ isLiked, likeCount });
        },
    });

    const [dislikePost] = useDislikePostMutation({
        onCompleted(result) {
            const likeCount = result.dislikePost.likeCount;
            const isLiked = false;
            setLike({ isLiked, likeCount });
        },
    });

    const handleLike = (): void => {
        if (like.isLiked) {
            dislikePost({ variables: { post: { postId: id } } });
        } else {
            likePost({ variables: { post: { postId: id } } });
        }
    };

    const likeCountText = like.likeCount > 1 ? `${like.likeCount} likes` : `${like.likeCount} like`;
    const isRepost = post.meta.__typename === 'RepostMeta';

    return (
        <Box>
            <Grid container>
                <Grid item xs={5}>
                    <Box className={classes.actions}>
                        <IconButton size="small" onClick={handleLike}>
                            {like.isLiked ? <HeartFilledIcon className={classes.likeActive} /> : <HeartIcon />}
                        </IconButton>
                        <IconButton size="small" onClick={() => inputRef.current?.focus()}>
                            <CommentIcon />
                        </IconButton>
                        {!isRepost && <SharePostDialog post={post} />}
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        id={`post-portal-${post.id}`}
                    />
                </Grid>
                <Grid item xs={5}>
                    {!isMobile && (
                        <Box height="100%" display="flex" justifyContent="flex-end" alignItems="center">
                            <Typography className={classes.likeCount}>{likeCountText}</Typography>
                            {repostCount > 0 && <RepostedUsersDialog repostCount={repostCount} postId={id} />}
                        </Box>
                    )}
                </Grid>
            </Grid>
            {isMobile && (
                <Box display="flex">
                    <Typography className={classes.likeCount}>{likeCountText}</Typography>
                    {repostCount > 0 && <RepostedUsersDialog repostCount={repostCount} postId={id} />}
                </Box>
            )}
        </Box>
    );
};
