import { PartnersCategory } from 'enum';
import { Community } from 'graphql/generated';
import produce, { Draft } from 'immer';
import { Reducer } from 'react';
export const COMMUNITY_ID_KEY = '@community/communityId';

// #region State
export type UserCommunitiesState = {
    communities: Community[];
    communitiesLoading: boolean;
    communitiesLoadingError?: string;
    communityId: Community['id'];
    communityRefetchAck: number;
    communityCategories: string[] | null;
};

export const initialCategories = [
    PartnersCategory.Healthcare,
    PartnersCategory.Hotel,
    PartnersCategory.Other,
    PartnersCategory.Restaurant,
    PartnersCategory.Shop,
];

export const INITIAL_USER_COMMUNITIES_STATE: UserCommunitiesState = {
    communities: [],
    communitiesLoading: true,
    communitiesLoadingError: undefined,
    communityId: '',
    communityRefetchAck: Date.now(),
    communityCategories: initialCategories,
};
// #endregion

// #region Sync Actions
type LoadCommunitiesStartedAction = { type: 'LOAD_COMMUNITIES_STARTED' };
type LoadCommunitiesSuccessAction = { type: 'LOAD_COMMUNITIES_SUCCESS'; communities: Community[] };
type LoadCommunitiesFailedAction = { type: 'LOAD_COMMUNITIES_FAILED'; error: string };
type CommunityChangedAction = { type: 'COMMUNITY_CHANGED'; communityId: Community['id'] };
type RefetchCommunitiesAction = { type: 'REFETCH_COMMUNITIES' };
type CommunityCategoriesChangedAction = { type: 'COMMUNITY_CATEGORY_CHANGED'; categories: string[] };

export type UserCommunitiesAction =
    | LoadCommunitiesStartedAction
    | LoadCommunitiesSuccessAction
    | LoadCommunitiesFailedAction
    | RefetchCommunitiesAction
    | CommunityChangedAction
    | CommunityCategoriesChangedAction;

const communitiesLoaded = (draft: Draft<UserCommunitiesState>, action: LoadCommunitiesSuccessAction) => {
    const storedCommunityId = localStorage.getItem(COMMUNITY_ID_KEY);
    const storedCommunityIdIsValid = !!action.communities.find((community) => community.id === storedCommunityId);
    if (storedCommunityId && storedCommunityIdIsValid) {
        draft.communityId = storedCommunityId;
        localStorage.setItem(COMMUNITY_ID_KEY, storedCommunityId);
    } else {
        draft.communityId = action.communities[0].id;
        localStorage.setItem(COMMUNITY_ID_KEY, action.communities[0].id);
    }
    draft.communitiesLoading = false;
    draft.communities = action.communities;
};

const communityChanged = (draft: Draft<UserCommunitiesState>, action: CommunityChangedAction) => {
    draft.communityId = action.communityId;
    localStorage.setItem(COMMUNITY_ID_KEY, action.communityId);
};

export const reducer: Reducer<UserCommunitiesState, UserCommunitiesAction> = produce(
    (draft: Draft<UserCommunitiesState>, action: UserCommunitiesAction): void => {
        switch (action.type) {
            case 'LOAD_COMMUNITIES_STARTED':
                draft.communitiesLoading = true;
                break;
            case 'LOAD_COMMUNITIES_SUCCESS':
                communitiesLoaded(draft, action);
                break;
            case 'LOAD_COMMUNITIES_FAILED':
                draft.communitiesLoading = false;
                draft.communitiesLoadingError = action.error;
                break;
            case 'COMMUNITY_CHANGED':
                communityChanged(draft, action);
                break;
            case 'REFETCH_COMMUNITIES':
                draft.communityRefetchAck = Date.now();
                break;
            case 'COMMUNITY_CATEGORY_CHANGED':
                draft.communityCategories = action.categories;
                break;
            default:
                break;
        }
    },
);
// #endregion
