import { Box, FormHelperText, Grid, IconButton, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { RatioBox } from 'components';
import { imageTypes, videoTypes } from 'config';
import { ErrorMessage, useFormikContext } from 'formik';
import React, { useCallback, useMemo, useRef } from 'react';
import { CroppableImage } from './croppable-image';
import { useStyles } from './gallery-field.style';

const fileStyle: React.CSSProperties = { width: '100%', height: '100%', objectFit: 'cover' };

type GalleryFieldProps = {
    name: string;
    loading: boolean;
};

export const GalleryField: React.FC<GalleryFieldProps> = ({ name, loading }) => {
    const classes = useStyles();
    const { values, setFieldValue } = useFormikContext<any>();
    const inputRef = useRef<HTMLInputElement>(null);

    const openFileSelector = () => inputRef.current?.click();

    const files = useMemo(() => values[name] || [], [values, name]);

    const onSaveCrop = useCallback(
        (croppedFile, index) => {
            const newFiles = [...files];
            newFiles[index] = croppedFile;
            setFieldValue(name, newFiles);
        },
        [files, name, setFieldValue],
    );

    return (
        <Grid container spacing={2}>
            <input
                accept={[...imageTypes, ...videoTypes].join(', ')}
                type="file"
                hidden
                ref={inputRef}
                multiple
                onChange={(e) => {
                    if (e.target.files) {
                        setFieldValue(name, [...files, ...Array.from(e.target.files)]);
                    }
                }}
            />
            <Grid item xs={12}>
                <Typography variant="h5">Gallery</Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
                <RatioBox>
                    <Box onClick={openFileSelector} className={classes.uploader}>
                        <AddIcon />
                    </Box>
                </RatioBox>
            </Grid>
            {files.map((file, i) => (
                <Grid item xs={6} sm={4} md={3} key={i}>
                    <RatioBox>
                        <Box className={classes.imageContainer}>
                            {videoTypes.includes(file.type) ? (
                                <video style={fileStyle} src={URL.createObjectURL(file)} />
                            ) : (
                                <CroppableImage image={file} index={i} onSaveCrop={onSaveCrop} />
                            )}
                            <IconButton
                                onClick={(): void => {
                                    const filteredImages = files.filter((_, index) => index !== i);
                                    setFieldValue(name, filteredImages);
                                }}
                                disabled={loading}
                                disableRipple
                                color="secondary"
                                size="small"
                            >
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </Box>
                    </RatioBox>
                </Grid>
            ))}
            <ErrorMessage
                name={name}
                render={(err) => (
                    <FormHelperText error variant="filled">
                        {err}
                    </FormHelperText>
                )}
            />
        </Grid>
    );
};
