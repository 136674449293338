import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => {
    return {
        root: {
            position: 'relative',
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: theme.spacing(4),
            paddingBottom: theme.spacing(3),
            borderRadius: 20,
            transition: '0.2s',
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(4, 2),
                paddingBottom: theme.spacing(3),
            },
            [theme.breakpoints.down(350)]: {
                paddingBottom: theme.spacing(1),
            },
        },
        favorite: {
            display: 'flex',
            position: 'absolute',
            top: 12,
            right: 12,
            color: '#fff',
        },
        digits: {
            fontSize: 20,
            whiteSpace: 'nowrap',
            color: '#fff',
            [theme.breakpoints.down(460)]: {
                fontSize: 18,
            },
            [theme.breakpoints.down(410)]: {
                fontSize: 16,
            },
            [theme.breakpoints.down(380)]: {
                fontSize: 14,
            },
            [theme.breakpoints.down(350)]: {
                fontSize: 12,
            },
        },
    };
});
