import { Box, Icon, IconButton, IconButtonProps, Popover, PopoverProps } from '@material-ui/core';
import clsx from 'clsx';
import { ActionItem } from 'components/action-item';
import { ActionItemProps } from 'components/action-item/action-item';
import React, { PropsWithChildren, useImperativeHandle, useState } from 'react';
import { useStyles } from './action-menu.style';

export type Actions = (ActionItemProps & {
    hidden?: boolean;
    disableMenuDismiss?: boolean;
})[];

type ActionMenuProps = {
    actions: Actions;
    buttonProps?: IconButtonProps & { activeClassName?: string };
    menuProps?: PopoverProps;
};

export type ActionMenuRef = { closeMenu: () => void };

export const ActionMenu = React.forwardRef<ActionMenuRef, PropsWithChildren<ActionMenuProps>>(
    (
        {
            children,
            actions,
            buttonProps: { className, activeClassName = '', ...buttonProps } = {},
            menuProps: { classes: menuClasses, ...menuProps } = {},
        },
        ref,
    ) => {
        const classes = useStyles();

        const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

        const closeMenu = (): void => setAnchorEl(null);

        useImperativeHandle(ref, () => ({
            closeMenu,
        }));

        return (
            <Box>
                <IconButton
                    size="small"
                    className={clsx(classes.openButton, className, !!anchorEl && activeClassName)}
                    onClick={(e): void => setAnchorEl(e.currentTarget)}
                    {...buttonProps}
                >
                    <Icon fontSize="small">more_horiz</Icon>
                </IconButton>
                <Popover
                    classes={{
                        paper: classes.menu,
                        ...menuClasses,
                    }}
                    elevation={2}
                    anchorEl={anchorEl}
                    onClose={closeMenu}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    {...menuProps}
                    open={!!anchorEl}
                >
                    {children}
                    {actions.map(({ disableMenuDismiss, ...action }, index) =>
                        action.hidden ? null : (
                            <ActionItem
                                key={index}
                                {...action}
                                onClick={(e) => {
                                    action.onClick?.(e);
                                    if (!disableMenuDismiss) {
                                        closeMenu();
                                    }
                                }}
                            />
                        ),
                    )}
                </Popover>
            </Box>
        );
    },
);

ActionMenu.displayName = 'ActionMenu';
