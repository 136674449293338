import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
    return {
        root: {
            justifyContent: 'flex-start',
            textAlign: 'start',
            padding: theme.spacing(1, 2),
            borderRadius: 8,
        },
        divider: {
            margin: theme.spacing(1, 0),
        },
    };
});
