import { Box, Card, Typography } from '@material-ui/core';
import { Post } from 'graphql/generated';
import React, { useRef } from 'react';
import { timeAgo } from 'utils/dateTimeHelper';
import { getPostContent } from '../utils';
import { PostActions } from './post-actions';
import { PostComments } from './post-comments';
import { PostHeader } from './post-header';
import { useStyles } from './post-item.style';

type PostItemProps = {
    post: Post;
};

export const PostItem: React.FC<PostItemProps> = ({ post }) => {
    const classes = useStyles();
    const { id } = post;

    const inputRef = useRef<HTMLInputElement>(null);

    const publishDate = timeAgo(post.createdAt);

    const topComments = post.topComments.edges.map(({ node }) => node);

    return (
        <Card className={classes.root}>
            <PostHeader {...{ post }} />

            <Box overflow="hidden">{getPostContent(post)}</Box>

            <Box className={classes.footer}>
                <Box className={classes.actions}>
                    <PostActions post={post} inputRef={inputRef} />
                </Box>

                <Box className={classes.comments}>
                    <PostComments
                        totalCount={post.comments.totalCount}
                        initialComments={topComments}
                        postId={id}
                        authorId={post.author.id}
                        inputRef={inputRef}
                    />
                </Box>

                <Typography variant="body1" color="textSecondary" className={classes.publishDate}>
                    {publishDate}
                </Typography>
            </Box>
        </Card>
    );
};
