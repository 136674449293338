import { Box, Button, Typography } from '@material-ui/core';
import { EventPaymentDialog } from 'components';
import { Event, EventTranslation } from 'graphql/generated';
import React, { useCallback, useState } from 'react';
import { useStyles } from './payment.style';
import browserHistory from 'utils/browser-utils';

type PaymentProps = {
    id: Event['id'];
    title: EventTranslation['title'];
    price: Event['price'];
    detailed?: boolean;
    purchased: boolean;
};

export const Payment: React.FC<PaymentProps> = ({ id, title, price, detailed, purchased }) => {
    const classes = useStyles();
    const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
    const [booked, setBooked] = useState<boolean>(purchased);

    const hasPrice = price !== undefined && price !== null;
    const isFree = hasPrice && price === 0;

    const openPaymentDialog = useCallback(() => setPaymentDialogOpen(true), []);
    const closePaymentDialog = useCallback(() => setPaymentDialogOpen(false), []);

    if (!hasPrice) {
        return null;
    }

    return (
        <Box mt={2} width="100%">
            {isFree ? (
                <Typography className={classes.text}>Free</Typography>
            ) : (
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography className={classes.text}>{`Price ${price}€`}</Typography>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={detailed ? openPaymentDialog : () => browserHistory.push(`/d/experience/${id}`)}
                        disabled={booked}
                    >
                        {booked ? 'Booked' : 'Buy ticket'}
                    </Button>
                    {detailed && (
                        <EventPaymentDialog
                            open={paymentDialogOpen}
                            onClose={closePaymentDialog}
                            eventId={id}
                            productName={title}
                            setBooked={setBooked}
                            price={price}
                        />
                    )}
                </Box>
            )}
        </Box>
    );
};
