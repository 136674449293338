import { Box, Button, ButtonProps, Divider, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useStyles } from './action-item.style';

export type ActionItemProps = ButtonProps & { primaryText: string; secondaryText?: string; divider?: boolean };

export const ActionItem: React.FC<ActionItemProps> = ({ className, primaryText, secondaryText, divider, ...props }) => {
    const classes = useStyles();

    return (
        <>
            {divider && (
                <Box px={1}>
                    <Divider className={classes.divider} />
                </Box>
            )}
            <Button fullWidth className={clsx(classes.root, className)} {...props}>
                <Box display="flex" flexDirection="column">
                    <Typography>{primaryText}</Typography>
                    {secondaryText && (
                        <Typography variant="body2" color="textSecondary">
                            {secondaryText}
                        </Typography>
                    )}
                </Box>
            </Button>
        </>
    );
};
