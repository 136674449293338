import {
    Box,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Icon,
    IconButton,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { LoadingButton } from 'components';
import { ActionItem } from 'components/action-item';
import { useAuthState } from 'context/auth/store';
import { useFeedDispatch } from 'context/feed/store';
import { Formik } from 'formik';
import { FileInfo, LanguageCode, Post, UpdatePostInput, useUpdatePostMutation } from 'graphql/generated';
import React, { useRef, useState } from 'react';
import { UserHeader } from '../user-header';
import { EditPostDialogForm } from './edit-post-dialog-form';
import { useStyles } from './edit-post-dialog.style';
import { EditPostValidationSchema } from './validation-schema';

type EditPostDialogProps = {
    post: Post;
    closeMenu: () => void;
};

export const EditPostDialog: React.FC<EditPostDialogProps> = ({ post, closeMenu }) => {
    const classes = useStyles();
    const { isManager, user } = useAuthState();
    const dispatch = useFeedDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const [open, setOpen] = useState(false);

    const openDialog = (): void => {
        setOpen(true);
    };
    const closeDialog = (): void => {
        setOpen(false);
        closeMenu();
    };

    const [updatePost, { loading }] = useUpdatePostMutation({
        onCompleted(result) {
            const post = result.updatePost.post as Post;
            dispatch({ type: 'EDIT_POST', post });
            closeDialog();
        },
    });

    const translationsInitialValues =
        isManager && post.authorId === user?.id
            ? Object.values(LanguageCode).map((lang) => {
                  const node = post.translations.edges.find((edge) => edge.node.lang === lang)?.node;
                  return {
                      lang: node?.lang || lang,
                      body: node?.body || '',
                  };
              })
            : post.translations.edges.map(({ node }) => ({ lang: node.lang, body: node.body }));

    const initialValues: UpdatePostInput & { previousFiles: FileInfo[] } = {
        id: post.id,
        communityId: post.communityId,
        translations: translationsInitialValues,
        visibility: post.visibility,
        files: [],
        previousFiles: post.files.edges.map(({ node }) => node),
    };

    const onSubmit = ({
        previousFiles,
        id,
        translations,
        visibility,
        files,
        communityId,
    }: UpdatePostInput & { previousFiles: FileInfo[] }): void => {
        const previousFileIds = previousFiles.map((file) => file.id);
        const post = { id, communityId, translations, visibility, files, previousFileIds };
        updatePost({ variables: { post } });
    };

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const isRepost = post.meta.__typename === 'RepostMeta';
    const isAuthor = post.authorId === user?.id;

    return (
        <>
            <ActionItem primaryText="Edit post" startIcon={<EditOutlinedIcon />} onClick={openDialog} />
            <Dialog classes={{ paper: classes.dialog }} open={open} fullScreen={isMobile}>
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={EditPostValidationSchema}
                    isInitialValid={false}
                >
                    {({ submitForm, setFieldValue, values, errors, touched }): React.ReactElement => (
                        <>
                            <DialogTitle className={classes.dialogTitle} disableTypography>
                                <Box className={classes.dialogHeader}>
                                    <Box width={36} />
                                    <Typography variant="h4">Edit Post</Typography>
                                    <IconButton size="small" disabled={loading} onClick={closeDialog}>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </Box>
                                <Box>
                                    <Divider className={classes.divider} />
                                    <UserHeader />
                                </Box>
                            </DialogTitle>
                            <DialogContent className={classes.dialogContent}>
                                <EditPostDialogForm
                                    {...{ values, setFieldValue, loading, errors, touched, isRepost, isAuthor }}
                                />
                            </DialogContent>
                            <DialogActions className={classes.dialogActions}>
                                {!isRepost && (
                                    <Card className={classes.attachments} variant="outlined">
                                        <Box flexGrow={1}>
                                            <Typography style={{ cursor: 'default' }} variant="h6">
                                                Add to your post
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Tooltip arrow placement="top" title="File">
                                                <IconButton
                                                    disabled={loading}
                                                    onClick={(): void => fileInputRef.current?.click()}
                                                    className={classes.fileButton}
                                                >
                                                    <Icon>attach_file</Icon>
                                                </IconButton>
                                            </Tooltip>
                                            <input
                                                hidden
                                                multiple
                                                type="file"
                                                ref={fileInputRef}
                                                onChange={(e): void => {
                                                    if (e.target.files) {
                                                        setFieldValue('files', [
                                                            ...(values.files || []),
                                                            ...Array.from(e.target.files),
                                                        ]);
                                                        e.target.value = '';
                                                    }
                                                }}
                                            />
                                        </Box>
                                    </Card>
                                )}
                                <LoadingButton
                                    size="large"
                                    color="primary"
                                    style={{ marginLeft: 0 }}
                                    onClick={submitForm}
                                    variant="contained"
                                    fullWidth
                                    loading={loading}
                                    text="Save"
                                    loadingText="Saving"
                                />
                            </DialogActions>
                        </>
                    )}
                </Formik>
            </Dialog>
        </>
    );
};
