import { Tab, Tabs, TabsProps, useTheme } from '@material-ui/core';
import { getIn } from 'formik';
import { LanguageCode } from 'graphql/generated';
import React from 'react';
import { useStyles } from './language-tabs.style';

type LanguageTabsProps = TabsProps & {
    value: number;
    onChange: (value: number) => void;
    errors: any;
    touched: any;
};

const languageNameMap = {
    [LanguageCode.En]: 'English',
    [LanguageCode.Ru]: 'Русский',
    [LanguageCode.Lv]: 'Latviešu',
};

export const LanguageTabs: React.FC<LanguageTabsProps> = ({ value, onChange, errors, touched, ...props }) => {
    const classes = useStyles();
    const theme = useTheme();
    return (
        <Tabs
            textColor="primary"
            indicatorColor="primary"
            value={value}
            onChange={(_, value) => onChange(Number(value))}
            {...props}
        >
            {Object.values(LanguageCode).map((lang: LanguageCode, index) => {
                const error = getIn(errors, `translations[${index}]`, undefined);
                const touchedFields = getIn(touched, `translations[${index}]`, undefined);
                const hasValidationError =
                    error &&
                    Object.keys(error)?.some((val) => !!val) &&
                    touchedFields &&
                    Object.values(touchedFields)?.some((val) => !!val);

                return (
                    <Tab
                        className={classes.tab}
                        style={{
                            color: hasValidationError
                                ? '#f00'
                                : value === index
                                ? theme.palette.primary.main
                                : theme.palette.text.secondary,
                        }}
                        disableRipple
                        key={index}
                        value={index}
                        label={languageNameMap[lang]}
                    />
                );
            })}
        </Tabs>
    );
};
