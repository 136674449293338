import { Box, Tooltip, Typography } from '@material-ui/core';
import { ColorIDAvatar } from 'components';
import { Link } from 'components/link';
import { User } from 'graphql/generated';
import React from 'react';
import { useStyles } from './user-info-card.style';

type UserInfoCardProps = {
    user: User;
};

export const UserInfoCard: React.FC<UserInfoCardProps> = ({ user, children }) => {
    const classes = useStyles();
    return (
        <Tooltip
            interactive
            PopperProps={{ className: classes.root }}
            enterDelay={400}
            placement="top"
            title={
                <Box display="flex">
                    <Box>
                        <Link to={`/d/profile/${user.id}`}>
                            <ColorIDAvatar
                                id={user.id}
                                text={user.fullName}
                                fontSize="lg"
                                src={user.avatar?.url}
                                className={classes.avatar}
                            />
                        </Link>
                    </Box>
                    <Box ml={2} flexGrow={1} display="flex" flexDirection="column" justifyContent="space-evenly">
                        <Link to={`/d/profile/${user.id}`} link style={{ width: 'fit-content' }}>
                            <Typography variant="h4" style={{ fontWeight: 700, fontSize: '1.1rem' }}>
                                {user.fullName}
                            </Typography>
                        </Link>
                        <Typography variant="h5" color="textSecondary" style={{ textTransform: 'capitalize' }}>
                            {user.statusText}
                        </Typography>
                    </Box>
                </Box>
            }
        >
            <Box display="inline-block">{children}</Box>
        </Tooltip>
    );
};
