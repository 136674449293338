import { Spinner } from 'components';
import { Analytics } from 'lib/analytics';
import browserHistory from 'utils/browser-utils';
import React, { lazy, Suspense } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import AuthorizedRoute from './AuthorizedRoute';

const DashboardLayout = lazy(() => import('views/layout/dashboard'));
const AppLayout = lazy(() => import('views/layout/app'));

const Routes: React.FC = () => {
    return (
        <Router history={browserHistory}>
            <Suspense fallback={<Spinner style={{ width: '100vw', height: '100vh' }} />}>
                <Route path="/" component={Analytics} />

                <Switch>
                    <AuthorizedRoute path="/d" component={DashboardLayout} />
                    <Route path="/" component={AppLayout} />
                </Switch>
            </Suspense>
        </Router>
    );
};

export default Routes;
