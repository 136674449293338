import { FormHelperText, InputLabel } from '@material-ui/core';
import clsx from 'clsx';
import { getIn } from 'formik';
import quillEmoji from 'quill-emoji';
import React, { useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.css';
import { useStyles } from './rich-text-editor.style';

const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = quillEmoji;

Quill.register(
    {
        'formats/emoji': EmojiBlot,
        'modules/emoji-shortname': ShortNameEmoji,
        'modules/emoji-toolbar': ToolbarEmoji,
        'modules/emoji-textarea': TextAreaEmoji,
    },
    true,
);

const InsertEmoticonIcon =
    '<svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"></path></svg>';

const modules = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike', 'link'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['emoji'],
        ['clean'],
    ],
    'emoji-toolbar': { buttonIcon: InsertEmoticonIcon },
    'emoji-textarea': false,
    'emoji-shortname': true,
    clipboard: {
        matchVisual: false,
    },
};

const formats = ['bold', 'underline', 'strike', 'italic', 'list', 'link', 'emoji'];

export const RichTextEditor = ({
    field,
    form,
    label,
    minHeight = 300,
    noBorderTop = false,
    theme = 'snow',
    ...props
}) => {
    const classes = useStyles({ minHeight, noBorderTop })();
    const error = getIn(form.errors, field.name, undefined);
    const touched = getIn(form.touched, field.name, undefined);
    const quillRef = useRef<ReactQuill>(null);

    return (
        <div className={clsx(classes.container, !!error && touched && classes.error)}>
            <ReactQuill
                ref={quillRef}
                key={field.name}
                preserveWhitespace
                theme={theme}
                modules={modules}
                formats={formats}
                value={field.value}
                defaultValue={field.value}
                onBlur={() => form.setFieldTouched(field.name, true)}
                onChange={(content, _, __, editor) => {
                    if (!editor.getText().trim() && !content.includes('class="ql-emojiblot"')) {
                        form.setFieldValue(field.name, '');
                    } else {
                        form.setFieldValue(field.name, content);
                    }
                }}
                {...props}
            />
            {label && (
                <InputLabel shrink className={clsx(classes.label, !!error && touched && classes.errorLabel)}>
                    {label}
                </InputLabel>
            )}
            {!!error && touched && (
                <FormHelperText variant="filled" error>
                    {error}
                </FormHelperText>
            )}
        </div>
    );
};
