import { lighten, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
    return {
        root: {
            zIndex: 1300,
            '& .MuiTooltip-tooltip': {
                minWidth: 450,
                color: 'unset',
                boxShadow: theme.shadows[2],
                padding: theme.spacing(2),
                backgroundColor: lighten(theme.palette.background.default, 0.05),
                [theme.breakpoints.down('xs')]: {
                    minWidth: 400,
                },
                [theme.breakpoints.down(350)]: {
                    minWidth: 300,
                },
            },
        },
        avatar: {
            width: 80,
            height: 80,
            border: '1px solid rgba(0,0,0,0.1)',
            [theme.breakpoints.down('xs')]: {
                width: 50,
                height: 50,
            },
        },
    };
});
