import { Box, Dialog, DialogContent, DialogProps, DialogTitle, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementLocale } from '@stripe/stripe-js';
import { useSettings } from 'context/SettingsContext';
import { LanguageCode } from 'graphql/generated';
import React from 'react';
import { useStyles } from './add-payment-method-dialog.style';
import { AddPaymentMethodForm } from './add-payment-method-form';

const stripePromise = loadStripe(
    'pk_test_51Iy8tYLUSzFb3FS9zWCOfGny4DWc45QzkCB4P5zYyzeoIzNJfjywBv5JTEDDQKTb8ZG6tZyaJpJcmekQTHl6qbg4007ZTNH6gp',
);

export const AddPaymentMethodDialog: React.FC<Omit<DialogProps, 'onClose'> & { onClose?: () => void }> = ({
    ...props
}) => {
    const classes = useStyles();
    const { language } = useSettings();

    const locale: StripeElementLocale = ({
        [LanguageCode.En]: 'en',
        [LanguageCode.Ru]: 'ru',
        [LanguageCode.Lv]: 'lv',
    }[language] || 'en') as StripeElementLocale;

    return (
        <Dialog maxWidth="xs" fullWidth {...props}>
            <DialogTitle disableTypography className={classes.header}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box width={36} />
                    <Typography variant="h5">Add new payment method</Typography>
                    <IconButton size="small" onClick={props.onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <Elements options={{ locale }} stripe={stripePromise}>
                    <AddPaymentMethodForm onSuccess={props.onClose} />
                </Elements>
            </DialogContent>
        </Dialog>
    );
};
