import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => {
    return {
        image: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
    };
});
