import { Box, BoxProps, Typography } from '@material-ui/core';
import React from 'react';
import { useStyles } from './summary-item.style';

type SummaryItemProps = Omit<BoxProps, 'title'> & {
    title: string;
    text: string;
};

export const SummaryItem: React.FC<SummaryItemProps> = ({ title, text, ...props }) => {
    const classes = useStyles();
    return (
        <Box {...props}>
            <Typography className={classes.title}>{title}</Typography>
            <Typography className={classes.text}>{text}</Typography>
        </Box>
    );
};
