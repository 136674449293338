import { TextField } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { FieldProps, getIn } from 'formik';
import { TextFieldProps } from 'formik-material-ui';
import { City, useCitiesQuery } from 'graphql/generated';
import React from 'react';

type CityFieldProps = FieldProps & { inputVariant?: TextFieldProps['variant'] };

export const CityField: React.FC<CityFieldProps> = ({ form, field, inputVariant = 'outlined', ...other }) => {
    const error = getIn(form.errors, field.name);
    const touched = getIn(form.touched, field.name);
    const hasError = !!error && touched;

    const { data, loading } = useCitiesQuery({
        fetchPolicy: 'network-only',
    });

    const cities = data?.cities.edges.map(({ node }) => node) || [];

    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option: Pick<City, 'id' | 'name' | '__typename'>) => option.name,
    });

    if (loading) {
        return <TextField {...other} variant={inputVariant} disabled fullWidth />;
    }

    return (
        <Autocomplete
            options={cities}
            filterOptions={filterOptions}
            getOptionLabel={(city): string => city.name}
            defaultValue={cities.find((city) => city.id === field.value)}
            onBlur={(): void => form.setFieldTouched(field.name, true)}
            onChange={(_, city): void => {
                form.setFieldValue(field.name, city?.id);
            }}
            renderInput={(params): React.ReactElement => (
                <TextField
                    {...other}
                    {...params}
                    error={Boolean(hasError)}
                    helperText={hasError ? error : undefined}
                    variant={inputVariant}
                    fullWidth
                />
            )}
        />
    );
};
