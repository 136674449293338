import { createBrowserHistory } from 'history';
import { parse, ParsedQuery } from 'query-string';
import { isEmpty } from './validationHelper';

const history = createBrowserHistory();

history.listen(() => {
    window.scrollTo({ top: 0, left: 0 });
});

export const getSearchParams = (): ParsedQuery<string | number | boolean> => {
    return parse(history.location.search, { parseNumbers: true, parseBooleans: true });
};

export const setSearchParams = (params: { [key: string]: unknown }): void => {
    const search = new URLSearchParams(history.location.search);
    Object.entries(params).forEach(([key, value]) => {
        if (!isEmpty(value)) {
            search.set(key, String(value));
        } else {
            search.delete(key);
        }
    });
    search.sort();
    history.replace(`${history.location.pathname}?${search}`);
};

export const extractOrderByFromQueryString = <T>(): T | undefined => {
    const { orderBy } = getSearchParams();
    if (isEmpty(orderBy)) {
        return undefined;
    }

    return (orderBy as unknown) as T;
};

export const extractPageFromQueryString = (): number | undefined => {
    const { page } = getSearchParams();

    if (isEmpty(page)) {
        return undefined;
    }

    return Number(page);
};

export default history;
