import { LanguageCode } from 'graphql/generated';

export const getTranslationContent = <TranslationType extends { id: string; lang: LanguageCode }>({
    translations,
    requiredFields,
    language,
}: {
    translations: TranslationType[];
    requiredFields: string[];
    language: LanguageCode;
}): TranslationType => {
    const currentLangContent = translations.find(
        (translation) => translation.lang === language && requiredFields.every((field) => !!translation[field]),
    );
    if (currentLangContent) {
        return currentLangContent;
    }
    const filledLangContent = translations.find((translation) => requiredFields.every((field) => !!translation[field]));
    if (filledLangContent) {
        return filledLangContent;
    }
    return translations[0] || {};
};
