import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => {
    return {
        root: {
            '& fieldset': {
                border: 'none',
            },
            width: 175,
        },
    };
});
