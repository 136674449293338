import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => {
    return {
        root: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            width: '100%',
        },
    };
});
