import clsx from 'clsx';
import { RatioBox } from 'components';
import { FileInfo } from 'graphql/generated';
import React, { useState } from 'react';
import { Blurhash } from 'react-blurhash';
import { useStyles } from './blurhash-image.style';

type BlurhashImageProps = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
    image: FileInfo;
    withRatio?: boolean;
    relativePositioning?: boolean;
};

export const BlurhashImage: React.FC<BlurhashImageProps> = ({
    image,
    withRatio,
    relativePositioning,
    className,
    ...props
}) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);

    const onLoad = () => setLoading(false);

    const img = new Image();
    img.src = image.url;
    img.onload = onLoad;

    if (loading && image.blurhash) {
        if (withRatio) {
            return (
                <RatioBox ratio={image.blurhash.height / image.blurhash.width}>
                    <Blurhash
                        hash={image.blurhash.hash}
                        width={'100%'}
                        height={'100%'}
                        resolutionX={32}
                        resolutionY={32}
                        punch={1}
                    />
                </RatioBox>
            );
        } else {
            return (
                <div
                    style={
                        relativePositioning
                            ? undefined
                            : { position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 2 }
                    }
                >
                    <Blurhash
                        hash={image.blurhash.hash}
                        width={'100%'}
                        height={'100%'}
                        resolutionX={32}
                        resolutionY={32}
                        punch={1}
                    />
                </div>
            );
        }
    }

    return (
        <>
            <img className={clsx(classes.image, className)} src={img.src} {...props} />
        </>
    );
};
