import { darken, makeStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import { bottomBarHeight, mobileSize } from 'config';
import React from 'react';

export type Action = {
    icon: React.ReactNode;
    name: string;
    onClick: () => unknown;
};

const useStyles = makeStyles((theme) => {
    return {
        speedDial: {
            position: 'fixed',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
            '& .MuiSpeedDial-fab': {
                boxShadow: '2px 2px 10px 0px rgba(0,0,0,0.4) !important',
            },
            '& .MuiSpeedDialAction-fab': {
                backgroundColor: darken(theme.palette.background.paper, 0.1),
            },
            [theme.breakpoints.down(mobileSize)]: {
                bottom: bottomBarHeight + theme.spacing(2),
            },
        },
    };
});

export const FABGroup: React.FC<{ actions: Action[] }> = ({ actions }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClose = (): void => {
        setOpen(false);
    };

    const handleOpen = (): void => {
        setOpen(true);
    };

    return (
        <SpeedDial
            ariaLabel="SpeedDial example"
            className={classes.speedDial}
            icon={<SpeedDialIcon />}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            direction="up"
        >
            {actions.map((action) => (
                <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    onClick={action.onClick}
                />
            ))}
        </SpeedDial>
    );
};
