import { Box, Icon, MenuItem, Typography } from '@material-ui/core';
import { ColorIDAvatar } from 'components';
import { useAuthState } from 'context/auth/store';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { MemberStatus, PostVisibility } from 'graphql/generated';
import React from 'react';
import { useStyles } from './user-header.style';

export const UserHeader: React.FC = () => {
    const classes = useStyles();
    const { user, isManager } = useAuthState();
    const canPostPublicly = isManager || user?.member?.status === MemberStatus.Approved;

    return (
        <Box display="flex">
            <Box mr={1}>
                <ColorIDAvatar id={user?.id || ''} text={user?.firstName || ''} fontSize="sm" src={user?.avatar?.url} />
            </Box>
            <Box>
                <Typography variant="h6" style={{ lineHeight: 1, opacity: 0.9 }}>
                    {user?.firstName} {user?.lastName}
                </Typography>
                <Field component={TextField} className={classes.visibility} select name="visibility" variant="outlined">
                    {canPostPublicly && (
                        <MenuItem className={classes.menuItem} value={PostVisibility.Public}>
                            <Icon className={classes.icon}>public</Icon>Public
                        </MenuItem>
                    )}
                    {canPostPublicly && (
                        <MenuItem className={classes.menuItem} value={PostVisibility.Members}>
                            <Icon className={classes.icon}>people_alt</Icon>Members
                        </MenuItem>
                    )}
                    <MenuItem className={classes.menuItem} value={PostVisibility.Followers}>
                        <Icon className={classes.icon}>person</Icon>Followers
                    </MenuItem>
                    <MenuItem className={classes.menuItem} value={PostVisibility.Private}>
                        <Icon className={classes.icon}>no_encryption</Icon>Only me
                    </MenuItem>
                </Field>
            </Box>
        </Box>
    );
};
