import { Grid, SvgIcon } from '@material-ui/core';
import { ReactComponent as EmailRemove } from 'assets/community-icons/email-remove.svg';
import { useFeed, useFeedStateSelector } from 'context/feed/store';
import { useLayoutDispatch } from 'context/layout/store';
import { useUserCommunitiesState } from 'context/user-communities/store';
import { PageInfo, Post, useSavedPostsQuery } from 'graphql/generated';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CircleIcon } from '../../circle-icon';
import { EmptyState } from '../../empty-state';
import { PostItem } from '../post-item';
import { PostSkeleton } from '../post-skeleton';

export const BookmarkedPostList: React.FC = () => {
    const { t } = useTranslation();
    const { communityId } = useUserCommunitiesState();
    const layoutDispatch = useLayoutDispatch();
    const [{ page, initialLoadFinished, canLoadMore }, dispatch] = useFeed();
    const posts = useFeedStateSelector((s) => s.posts);

    const { loading } = useSavedPostsQuery({
        fetchPolicy: 'network-only',
        variables: { page, where: { communityId } },
        skip: !communityId,
        onCompleted(result) {
            const posts = result.bookmarkedPosts.edges.map(({ node }) => node) as Post[];
            const pageInfo = result.bookmarkedPosts.pageInfo as PageInfo;
            dispatch({ type: 'PAGINATE_FEED', posts, pageInfo });
            dispatch({ type: 'INITIAL_LOAD_FINISHED' });
        },
    });

    const loadMore = useCallback((): void => {
        dispatch({ type: 'CHANGE_PAGE', page: page + 1 });
    }, [dispatch, page]);

    useEffect(() => {
        if (communityId) {
            dispatch({ type: 'RESET_FEED' });
        }
    }, [communityId]);

    const handleScroll = useCallback((): void => {
        const { scrollHeight, scrollTop } = document.documentElement;
        const { innerHeight } = window;
        if (innerHeight + scrollTop > scrollHeight - 650 && !loading && canLoadMore) {
            loadMore();
        }
    }, [canLoadMore, loadMore, loading]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return (): void => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll, layoutDispatch]);

    const isEmpty = initialLoadFinished && posts.length === 0;

    if (isEmpty) {
        return (
            <EmptyState
                media={<CircleIcon radius={80} icon={<SvgIcon component={EmailRemove} style={{ fontSize: 70 }} />} />}
                primaryText={t('emptyState:post')}
            />
        );
    }

    return (
        <Grid container spacing={3}>
            {posts.map((post: Post) => (
                <Grid item xs={12} key={post.id}>
                    <PostItem post={post} />
                </Grid>
            ))}
            {(!initialLoadFinished || loading || canLoadMore) && (
                <>
                    <Grid item xs={12}>
                        <PostSkeleton />
                    </Grid>
                    <Grid item xs={12}>
                        <PostSkeleton variant="image" />
                    </Grid>
                </>
            )}
        </Grid>
    );
};
