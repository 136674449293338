import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { useStyles } from './contact-dialog.style';

type AboutDialogProps = {
    openContact: boolean;
    setOpenContact: (boolean) => void;
};

export const ContactDialog: React.FC<AboutDialogProps> = ({ openContact, setOpenContact }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = () => {
        setOpenContact(false);
    };

    return (
        <Dialog
            onClose={handleClose}
            open={openContact}
            fullScreen={isMobile}
            maxWidth={false}
            classes={{ paper: classes.dialog }}
        >
            <DialogTitle style={{ display: 'flex', justifyContent: 'flex-end', padding: 16 }}>
                <IconButton size="small" onClick={() => setOpenContact(false)}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Typography style={{ lineHeight: 1, marginBottom: '8px' }} variant="h3">
                    Contact us
                </Typography>

                <Box className={classes.dialogDescription}>
                    For any questions please contact <a href="mailto:jelena@thelobby.lv">jelena@thelobby.lv</a> or&nbsp;
                    <a href="tel:+37126575953">+37126575953</a>
                </Box>
            </DialogContent>
        </Dialog>
    );
};
