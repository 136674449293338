import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => {
    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1 auto',
        },
        main: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            paddingBottom: theme.spacing(3),
            paddingTop: theme.spacing(3),
        },
    };
});
