export enum QueryParamKeys {
    Title = 'title',
    CategoryId = 'categoryId',
    CompanyId = 'companyId',
    RegionId = 'regionId',
    Age = 'age',
    Experience = 'experience',
    Salarymin = 'salarymin',
    Salarymax = 'salarymax',
    OrderBy = 'orderby',
    Page = 'page',
}

export enum PartnersCategory {
    Healthcare = 'HEALTHCARE',
    Hotel = 'HOTEL',
    Other = 'OTHER',
    Restaurant = 'RESTAURANT',
    Shop = 'SHOP',
}

export enum SocialLinkTypes {
    Website = 'website',
    Instagram = 'instagram',
    Facebook = 'facebook',
    Twitter = 'twitter',
    Linkedin = 'linkedin',
}

export const Theme = {
    Dark: 'dark',
    Light: 'light',
};

export const ViewType = {
    List: 1,
    Grid: 2,
};

export const UserNetworkStatus = {
    Online: 1,
    Invisible: 2,
};

export const ApolloNetworkStatus = {
    /**
     * The query has never been run before and the query is now currently running. A query will still
     * have this network status even if a partial data result was returned from the cache, but a
     * query was dispatched anyway.
     */
    Loading: 1,
    /**
     * If `setVariables` was called and a query was fired because of that then the network status
     * will be `setVariables` until the result of that query comes back.
     */
    SetVariables: 2,
    /**
     * Indicates that `fetchMore` was called on this query and that the query created is currently in
     * flight.
     */
    FetchMore: 3,
    /**
     * Similar to the `setVariables` network status. It means that `refetch` was called on a query
     * and the refetch request is currently in flight.
     */
    Refetch: 4,
    /**
     * Indicates that a polling query is currently in flight. So for example if you are polling a
     * query every 10 seconds then the network status will switch to `poll` every 10 seconds whenever
     * a poll request has been sent but not resolved.
     */
    Poll: 6,
    /**
     * No request is in flight for this query, and no errors happened. Everything is OK.
     */
    Ready: 7,
    /**
     * No request is in flight for this query, but one or more errors were detected.
     */
    Error: 8,
};
