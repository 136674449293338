import logoWhite from 'assets/thelobby_logo.svg';
import logoDark from 'assets/thelobby_logo_dark.svg';

declare global {
    interface Window {
        _env_: any;
    }
}

const env = {
    ...process.env,
    ...window._env_,
};

export const VERSION = env.REACT_APP_VERSION;
export const API_URL = env.REACT_APP_API_URL;
export const GA_PROPERTY = env.REACT_APP_GA;
export const APP_ENV = env.APP_ENV;

export const imageTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/webp', 'image/gif'];
export const videoTypes = ['video/mp4'];
export const imageExtensions = ['gif', 'jpeg', 'jpg', 'png', 'webp', 'tiff'];
export const videoExtensions = ['mov', 'mp4'];
export const defaultAvatar = 'https://s3.mediusoft.com/assets/the/blank_avatar.png';

export { logoWhite, logoDark };
export * from './layout-config';
