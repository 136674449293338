import { PaymentMethod } from 'graphql/generated';
import produce, { Draft } from 'immer';
import { Reducer } from 'react';

// #region State
export type PaymentMethodsState = {
    paymentMethods: PaymentMethod[];
    paymentMethodsLoading: boolean;
    paymentMethodsLoadingError: string | undefined;
};

export const INITIAL_PAYMENT_METHODS_STATE: PaymentMethodsState = {
    paymentMethods: [],
    paymentMethodsLoading: true,
    paymentMethodsLoadingError: undefined,
};
// #endregion

// #region Sync Actions
type LoadPaymentMethodsSuccessAction = { type: 'LOAD_PAYMENT_METHODS_SUCCESS'; paymentMethods: PaymentMethod[] };
type LoadPaymentMethodsFailedAction = { type: 'LOAD_PAYMENT_METHODS_FAILED'; error: string };
type PaymentMethodAddedAction = { type: 'PAYMENT_METHOD_ADDED'; paymentMethod: PaymentMethod };
type PaymentMethodDeletedAction = { type: 'PAYMENT_METHOD_DELETED'; id: PaymentMethod['id'] };

export type PaymentMethodsAction =
    | LoadPaymentMethodsSuccessAction
    | LoadPaymentMethodsFailedAction
    | PaymentMethodAddedAction
    | PaymentMethodDeletedAction;

const paymentMethodsLoaded = (draft: Draft<PaymentMethodsState>, action: LoadPaymentMethodsSuccessAction) => {
    draft.paymentMethods = action.paymentMethods;
    draft.paymentMethodsLoading = false;
};

const paymentMethodAdded = (draft: Draft<PaymentMethodsState>, action: PaymentMethodAddedAction) => {
    if (action.paymentMethod.isDefault) {
        draft.paymentMethods = draft.paymentMethods.map((paymentMethod) => ({ ...paymentMethod, isDefault: false }));
    }
    draft.paymentMethods = [...draft.paymentMethods, action.paymentMethod];
};

const paymentMethodDeleted = (draft: Draft<PaymentMethodsState>, action: PaymentMethodDeletedAction) => {
    draft.paymentMethods = draft.paymentMethods.filter((paymentMethod) => paymentMethod.id !== action.id);
};

export const reducer: Reducer<PaymentMethodsState, PaymentMethodsAction> = produce(
    (draft: Draft<PaymentMethodsState>, action: PaymentMethodsAction): void => {
        switch (action.type) {
            case 'LOAD_PAYMENT_METHODS_SUCCESS':
                paymentMethodsLoaded(draft, action);
                break;
            case 'LOAD_PAYMENT_METHODS_FAILED':
                draft.paymentMethodsLoading = false;
                draft.paymentMethodsLoadingError = action.error;
                break;
            case 'PAYMENT_METHOD_ADDED':
                paymentMethodAdded(draft, action);
                break;
            case 'PAYMENT_METHOD_DELETED':
                paymentMethodDeleted(draft, action);
                break;
            default:
                break;
        }
    },
);
// #endregion
