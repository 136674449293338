import { Typography } from '@material-ui/core';
import { Event, Post } from 'graphql/generated';
import React from 'react';
import {
    CoverPhotoPostContent,
    DefaultPostContent,
    EventPostContent,
    ExperiencePostContent,
    ProfilePicturePostContent,
    RepostPostContent,
} from './post-item/post-content';

export const getActionText = (post: Post): React.ReactElement | null => {
    const metaType = post.meta.__typename;
    switch (metaType) {
        case 'CoverPhotoChangeMeta':
            return (
                <Typography variant="body1" color="textSecondary" style={{ lineHeight: 1 }}>
                    updated cover photo.
                </Typography>
            );
        case 'ProfilePictureChangeMeta':
            return (
                <Typography variant="body1" color="textSecondary" style={{ lineHeight: 1 }}>
                    updated profile picture.
                </Typography>
            );
        case 'EventMeta':
            return (
                <Typography variant="body1" color="textSecondary" style={{ lineHeight: 1 }}>
                    created an event.
                </Typography>
            );
        case 'ExperienceMeta':
            return (
                <Typography variant="body1" color="textSecondary" style={{ lineHeight: 1 }}>
                    created an experience.
                </Typography>
            );
        case 'RepostMeta':
            return (
                <Typography variant="body1" color="textSecondary" style={{ lineHeight: 1 }}>
                    shared a post.
                </Typography>
            );
        default:
            return null;
    }
};

export const getPostContent = (post: Post, readonly?: boolean): React.ReactElement | null => {
    const metaType = post.meta.__typename;
    switch (metaType) {
        case 'CoverPhotoChangeMeta':
            const coverPhotoUrl = (post.meta as any).coverPhotoUrl;
            return <CoverPhotoPostContent imageUrl={coverPhotoUrl} />;
        case 'ProfilePictureChangeMeta':
            const profilePictureUrl = (post.meta as any).profilePictureUrl;
            return <ProfilePicturePostContent imageUrl={profilePictureUrl} />;
        case 'EventMeta':
            const event: Event = (post.meta as any).event;
            return <EventPostContent event={event} />;
        case 'ExperienceMeta':
            const experience: Event = (post.meta as any).experience;
            return <ExperiencePostContent experience={experience} />;
        case 'DefaultPostMeta':
            return <DefaultPostContent post={post} readonly={readonly} />;
        case 'RepostMeta':
            const repost = (post.meta as any).post;
            return <RepostPostContent post={post} repost={repost} />;
        default:
            return null;
    }
};
