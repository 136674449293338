import React from 'react';
import { KeyboardDateTimePicker, KeyboardDateTimePickerProps } from '@material-ui/pickers';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Field, FieldProps, getIn } from 'formik';

export type DateTimePickerFieldProps = Omit<KeyboardDateTimePickerProps, 'value' | 'onChange'>;

export const DateTimePickerField: React.FC<DateTimePickerFieldProps> = ({ name, ...other }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Field name={name}>
            {({
                field: { name: fieldName, value },
                form: { setFieldValue, setFieldTouched, errors, touched },
            }: FieldProps): React.ReactNode => {
                const currentError = getIn(touched, fieldName) && getIn(errors, fieldName);
                return (
                    <KeyboardDateTimePicker
                        autoOk
                        ampm={false}
                        name={fieldName}
                        value={value}
                        format="dd.MM.yyyy HH:mm"
                        variant={isMobile ? 'dialog' : 'inline'}
                        inputVariant="outlined"
                        fullWidth
                        helperText={currentError}
                        error={Boolean(currentError)}
                        onBlur={() => setFieldTouched(fieldName, true)}
                        // if you are using custom validation schema you probably want to pass `true` as third argument
                        onChange={(date) => {
                            if (date === null) {
                                setFieldValue(fieldName, null, true);
                            } else {
                                setFieldValue(fieldName, date, true);
                            }
                        }}
                        {...other}
                    />
                );
            }}
        </Field>
    );
};
