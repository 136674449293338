import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'enum';

export const useStyles = makeStyles((theme) => {
    const isDarkMode = theme.palette.type === Theme.Dark;
    return {
        visibility: {
            marginTop: theme.spacing(0.75),
            '& fieldset': {
                display: 'none',
            },
            '& .MuiInputBase-root': {
                borderRadius: theme.spacing(1),
                backgroundColor: isDarkMode ? 'rgb(58,59,60)' : 'rgb(240,242,245)',
            },
            '& .MuiSelect-root': {
                display: 'flex',
                fontSize: 12,
                fontWeight: 600,
                padding: theme.spacing(0.5, 1),
                paddingRight: theme.spacing(3),
            },
            '& .MuiSelect-icon': {
                fontSize: '1.2rem',
                top: 'calc(50% - 9px)',
                right: '2px',
            },
        },
        menuItem: {
            padding: theme.spacing(1),
        },
        icon: {
            fontSize: '1rem',
            marginRight: theme.spacing(0.75),
        },
    };
});
