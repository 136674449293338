import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
    return {
        menu: {
            padding: theme.spacing(0.75),
            width: 350,
        },
        openButton: {
            height: 'fit-content',
            transition: '.2s opacity',
            color: theme.palette.text.secondary,
        },
    };
});
