import { Box, Fab as MUIFab, FabProps, useMediaQuery, useTheme } from '@material-ui/core';
import { mobileSize } from 'layout-config';
import React from 'react';
import { useStyles } from './fab.style';

export const FAB: React.FC<Omit<FabProps, 'title'> & { title?: React.ReactNode }> = ({
    children,
    variant,
    title = null,
    ...props
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(mobileSize));
    return (
        <MUIFab
            color="primary"
            className={classes.fab}
            {...props}
            variant={isMobile ? variant : !!title ? 'extended' : variant}
        >
            {children}
            {title && !isMobile && <Box ml={1}>{title}</Box>}
        </MUIFab>
    );
};
