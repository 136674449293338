import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import { useFormikContext, getIn } from 'formik';

type ClearButtonProps = {
    width?: number | string;
    right?: number | string;
    height?: number | string;
    name?: string;
};

export const ClearButton: React.FC<ClearButtonProps> = ({ width = 5, right = 25, height = 5, name }) => {
    const { setFieldValue, values, setFieldTouched } = useFormikContext();

    const showClearButton = !!getIn(values, name as string);

    const onReset = (name): void => {
        setFieldTouched(name, true);
        setFieldValue(name, undefined, true);
    };

    return (
        <>
            {showClearButton && (
                <IconButton
                    onClick={() => {
                        onReset(name);
                    }}
                    style={{ position: 'absolute', right, width, height }}
                >
                    <CloseIcon style={{ fontSize: 15 }} />
                </IconButton>
            )}
        </>
    );
};
