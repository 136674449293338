import {
    Box,
    Dialog,
    DialogContent,
    DialogProps,
    DialogTitle,
    IconButton,
    Slide,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';
import { Experience } from 'data/experiences';
import React from 'react';
import { useStyles } from './experience-detail-dialog.style';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

type ExperienceDetailDialogProps = DialogProps & {
    experience: Experience;
};

export const ExperienceDetailDialog: React.FC<ExperienceDetailDialogProps> = ({ experience, ...props }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            fullScreen={isMobile}
            maxWidth={false}
            classes={{ paper: classes.dialog }}
            TransitionComponent={Transition}
            {...props}
        >
            <DialogTitle style={{ display: 'flex', justifyContent: 'flex-end', padding: 16 }}>
                <IconButton size="small" onClick={(e) => props.onClose?.(e, 'backdropClick')}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                {experience.title && (
                    <Typography style={{ lineHeight: 1 }} variant="h3">
                        {experience.title}
                    </Typography>
                )}
                <Box
                    className={classes.dialogDescription}
                    dangerouslySetInnerHTML={{ __html: experience.longDescription }}
                />
            </DialogContent>
        </Dialog>
    );
};
