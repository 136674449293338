// import { MaritalStatus } from 'generated/graphql';
import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(' '),
    lastName: Yup.string().required(' '),
    email: Yup.string().email().required(' '),
    phoneNumber: Yup.string().required(' '),
    dateOfBirth: Yup.date().typeError(' ').required(' '),
    cityOfResidence: Yup.string().required(' '),
    countryOfResidenceId: Yup.string().required(' '),
});
