import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Field, FieldProps, getIn } from 'formik';
import React from 'react';

export interface DatePickerFieldProps extends Omit<KeyboardDatePickerProps, 'value' | 'onChange'> {
    normalize?: (value: MaterialUiPickersDate) => MaterialUiPickersDate;
    showError?: boolean;
}

export const DatePickerField: React.FC<DatePickerFieldProps> = ({
    name,
    showError = true,
    normalize,
    inputVariant = 'outlined',
    ...other
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Field name={name}>
            {({
                field: { name: fieldName, value: fieldValue },
                form: { setFieldValue, setFieldTouched, errors, touched },
            }: FieldProps): React.ReactNode => {
                const currentError = getIn(touched, fieldName) && getIn(errors, fieldName);
                return (
                    <KeyboardDatePicker
                        autoOk
                        name={fieldName}
                        value={fieldValue}
                        format="dd.MM.yyyy"
                        variant={isMobile ? 'dialog' : 'inline'}
                        inputVariant={inputVariant}
                        fullWidth
                        helperText={showError ? currentError : null}
                        error={Boolean(currentError)}
                        // if you are using custom validation schema you probably want to pass `true` as third argument
                        onBlur={() => setFieldTouched(fieldName, true)}
                        // if you are using custom validation schema you probably want to pass `true` as third argument
                        onChange={(date) => {
                            if (date === null) {
                                setFieldValue(fieldName, '', true);
                            } else {
                                const value = normalize ? normalize(date) : date.toISODate();
                                setFieldValue(fieldName, value, true);
                            }
                        }}
                        {...other}
                    />
                );
            }}
        </Field>
    );
};
