import { makeStyles } from '@material-ui/core/styles';
import { mobileSize } from 'config';

export const useStyles = makeStyles((theme) => {
    return {
        root: {
            overflow: 'hidden',
            pointerEvents: 'none',
            [theme.breakpoints.down(mobileSize)]: {
                backgroundColor: theme.palette.background.default,
            },
        },
    };
});
