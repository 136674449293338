import { Button, Card } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useAuthState } from 'context/auth/store';
import { useFeedDispatch } from 'context/feed/store';
import React from 'react';
import { useStyles } from './post-compose.style';

export const PostCompose: React.FC = () => {
    const classes = useStyles();
    const dispatch = useFeedDispatch();
    const { user } = useAuthState();

    const openCreatePostDialog = () => dispatch({ type: 'OPEN_CREATE_POST_DIALOG' });

    return (
        <Card className={classes.root}>
            <Button
                classes={{ root: classes.button, label: classes.buttonLabel }}
                onClick={openCreatePostDialog}
                fullWidth
                variant="contained"
                disableRipple
                startIcon={<AddIcon />}
            >
                What&apos;s on your mind, {user?.firstName}?
            </Button>
        </Card>
    );
};
