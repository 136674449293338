import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'enum';

export const useStyles = ({ hasActionPermissions }: { hasActionPermissions: boolean }) =>
    makeStyles((theme) => {
        const isDarkMode = theme.palette.type === Theme.Dark;
        return {
            root: {
                display: 'flex',
                flexDirection: 'column',
                width: 'fit-content',
                marginBottom: theme.spacing(2),
                '&:hover': {
                    '& .comment-actions-button': {
                        opacity: 1,
                    },
                },
                '& .comment-actions-button-active': {
                    opacity: 1,
                },
            },
            avatar: {
                cursor: 'pointer',
                width: 40,
                height: 40,
            },
            commentContainer: {
                position: 'relative',
                overflow: 'visible',
                marginLeft: theme.spacing(1),
                padding: theme.spacing(1, 1.5),
                backgroundColor: isDarkMode ? 'rgb(30,30,30)' : 'rgb(245,245,245)',
                display: 'flex',
                flexDirection: 'column',
            },
            authorName: {
                width: 'fit-content',
                cursor: 'pointer',
                marginRight: theme.spacing(4),
                fontSize: '0.8125rem',
                lineHeight: '16px',
                fontWeight: 600,
                '&:hover': {
                    textDecoration: 'underline',
                },
            },
            commentText: {
                wordBreak: 'break-word',
                fontSize: '0.9375rem',
                lineHeight: '20px',
                fontWeight: 400,
            },
            publishDate: {
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                paddingRight: hasActionPermissions ? 44 : 0,
                '& .MuiTypography-root': {
                    fontSize: 11,
                },
            },
        };
    });
