import { makeStyles } from '@material-ui/core/styles';
import { mobileSize } from 'layout-config';

export const useStyles = ({ imageUrl }: { imageUrl: string }) =>
    makeStyles((theme) => {
        return {
            root: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                padding: theme.spacing(6, 0),
                overflow: 'hidden',
                [theme.breakpoints.down(mobileSize)]: {
                    padding: theme.spacing(3, 0),
                },
            },
            background: {
                position: 'absolute',
                top: -10,
                left: -10,
                bottom: -10,
                right: -10,
                backgroundImage: `url(${imageUrl})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                filter: 'blur(8px)',
            },
            image: {
                width: 'auto',
                height: 'auto',
                maxHeight: 350,
                maxWidth: 350,
                zIndex: 1,
                borderRadius: '50%',
                objectFit: 'cover',
                cursor: 'pointer',
                border: `3px solid ${theme.palette.background.paper}`,
                transition: '.3s',
                '&:hover': {
                    filter: 'brightness(70%)',
                },
                [theme.breakpoints.down(mobileSize)]: {
                    maxHeight: 250,
                    maxWidth: 250,
                },
            },
        };
    });
