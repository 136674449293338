import { MutationFunctionOptions } from '@apollo/react-common';
import { Box, Card, Typography } from '@material-ui/core';
import { ColorIDAvatar } from 'components';
import { Link } from 'components/link';
import { UserInfoCard } from 'components/user-info-card';
import { useAuthState } from 'context/auth/store';
import { ExecutionResult } from 'graphql';
import { DeletePostCommentMutation, Exact, PostComment } from 'graphql/generated';
import React from 'react';
import { timeAgo } from 'utils/dateTimeHelper';
import { PostCommentItemActions } from './post-comment-item-actions';
import { useStyles } from './post-comment-item.style';

type PostCommentItemProps = {
    comment: PostComment;
    deleteComment: (
        options?:
            | MutationFunctionOptions<
                  DeletePostCommentMutation,
                  Exact<{
                      id: string;
                  }>
              >
            | undefined,
    ) => Promise<ExecutionResult<DeletePostCommentMutation>>;
    authorId: string;
};

export const PostCommentItem: React.FC<PostCommentItemProps> = ({ comment, deleteComment, authorId }) => {
    const { user, isManager } = useAuthState();

    const publishDate = timeAgo(comment.createdAt);
    const author = comment.author;

    const hasActionPermissions = isManager || authorId === user?.id || comment.author.id === user?.id;
    const classes = useStyles({ hasActionPermissions })();

    return (
        <Box display="flex" className={classes.root}>
            <Box display="flex">
                <UserInfoCard user={author}>
                    <Link to={`/d/profile/${comment.author.id}`}>
                        <ColorIDAvatar
                            id={author.id}
                            text={author.fullName}
                            fontSize="sm"
                            className={classes.avatar}
                            src={author.avatar?.url}
                        />
                    </Link>
                </UserInfoCard>
                <Card elevation={0} className={classes.commentContainer}>
                    <UserInfoCard user={author}>
                        <Link to={`/d/profile/${comment.author.id}`}>
                            <Typography className={classes.authorName} variant="h6">
                                {author.fullName}
                            </Typography>
                        </Link>
                    </UserInfoCard>
                    <Box mt={0.5}>
                        <Typography className={classes.commentText}>{comment.text}</Typography>
                    </Box>
                </Card>
                {hasActionPermissions && (
                    <Box ml={1} display="flex" alignItems="center">
                        <PostCommentItemActions id={comment.id} deleteComment={deleteComment} />
                    </Box>
                )}
            </Box>
            <Box className={classes.publishDate}>
                <Typography color="textSecondary">{publishDate}</Typography>
            </Box>
        </Box>
    );
};
