import { TextField } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { FieldProps, getIn } from 'formik';
import { TextFieldProps } from 'formik-material-ui';
import { Country, useCountriesQuery } from 'graphql/generated';
import React from 'react';

type CountryFieldProps = FieldProps & { inputVariant?: TextFieldProps['variant'] };

export const CountryField: React.FC<CountryFieldProps> = ({ form, field, inputVariant = 'outlined', ...other }) => {
    const currentError = getIn(form.errors, field.name) && getIn(form.touched, field.name);

    const { data, loading } = useCountriesQuery({
        fetchPolicy: 'network-only',
    });

    const countries = data?.countries.edges.map(({ node }) => node) || [];

    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option: Country) => option.name,
    });

    if (loading) {
        return <TextField {...other} variant={inputVariant} disabled fullWidth />;
    }

    return (
        <Autocomplete
            options={countries}
            filterOptions={filterOptions}
            getOptionLabel={(country): string => country.name}
            defaultValue={countries.find((country) => country.id === field.value)}
            onBlur={(): void => form.setFieldTouched(field.name, true)}
            onChange={(_, country): void => {
                form.setFieldValue(field.name, country?.id);
            }}
            renderInput={(params): React.ReactElement => (
                <TextField {...other} {...params} error={Boolean(currentError)} variant={inputVariant} fullWidth />
            )}
        />
    );
};
