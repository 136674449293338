import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'enum';

export const useStyles = makeStyles((theme) => {
    const isDarkMode = theme.palette.type === Theme.Dark;
    return {
        dialog: {
            maxWidth: 890,
            borderRadius: 0,
            position: 'relative',
            '& hr': {
                borderColor: theme.palette.text.primary,
                borderBottomWidth: 0,
            },
        },
        dialogContent: {
            padding: theme.spacing(12),
            paddingTop: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(4),
                paddingTop: theme.spacing(2),
            },
        },
        dialogDescription: {
            marginTop: theme.spacing(4),
            fontSize: '17px',
            lineHeight: '24px',
            opacity: isDarkMode ? 0.8 : 0.9,
        },
    };
});
