import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Theme,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import { LoadingButton } from 'components/loading-button';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactCrop, { Crop, ReactCropProps } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const DEFAULT_CROP_STATE: Crop = { unit: '%', width: 100, height: 100, x: 0, y: 0 };
const DEFAULT_CIRCULAR_CROP_STATE: Crop = { unit: 'px', aspect: 1, width: 100, x: 0, y: 0 };

export type ImageCropperRef = { open: () => void; close: () => void };

type ImageCropperProps = Omit<ReactCropProps, 'src' | 'onChange' | 'onImageLoaded' | 'crop'> & {
    img: File | null;
    onSave: (file: File | null) => void;
    open: boolean;
    onClose: () => void;
    loading?: boolean;
};

export const ImageCropper: React.FC<ImageCropperProps> = ({
    img,
    circularCrop,
    onSave,
    open,
    onClose,
    loading,
    ...props
}) => {
    const classes = useStyles();

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [crop, setCrop] = useState<Crop>(circularCrop ? DEFAULT_CIRCULAR_CROP_STATE : DEFAULT_CROP_STATE);
    const [completedCrop, setCompletedCrop] = useState<Crop>();

    const imageRef = useRef<HTMLImageElement>();
    const previewCanvasRef = useRef<HTMLCanvasElement>(null);

    const onImageLoad = useCallback((target: HTMLImageElement) => {
        imageRef.current = target;
    }, []);

    const onCropChange = useCallback((crop: Crop) => {
        setCrop(crop);
    }, []);

    const onCropComplete = useCallback((crop: Crop) => {
        setCompletedCrop(crop);
    }, []);

    const getCroppedImage = () => {
        if (!previewCanvasRef.current || !img) return null;
        previewCanvasRef.current.toBlob(
            (blob) => {
                if (blob) {
                    const croppedFile = new File([blob], img.name, { type: img.type });
                    onSave(croppedFile);
                }
            },
            img.type,
            0.92,
        );
    };

    const convertImageToBlob = useCallback(() => {
        if (img) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                if (reader.result) {
                    setImageSrc(reader.result.toString());
                }
            });
            reader.readAsDataURL(img);
        }
    }, [img]);

    useEffect(() => {
        convertImageToBlob();
    }, [convertImageToBlob]);

    useEffect(() => {
        const image = imageRef.current;
        const canvas = previewCanvasRef.current;
        const { width = 0, height = 0, x = 0, y = 0 } = completedCrop || {};

        if (!image || !canvas || !completedCrop) return;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');

        if (!ctx) return;

        const pixelRatio = window.devicePixelRatio;

        canvas.width = width * pixelRatio;
        canvas.height = height * pixelRatio;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(image, x * scaleX, y * scaleY, width * scaleX, height * scaleY, 0, 0, width, height);
    }, [completedCrop]);

    return (
        <Dialog maxWidth="sm" fullWidth fullScreen={isMobile} open={open}>
            <DialogTitle className={classes.dialogTitle} disableTypography>
                <Typography variant="h3">Edit image</Typography>
            </DialogTitle>
            <DialogContent dividers className={classes.dialogContent}>
                <div className={classes.cropperContainer}>
                    {imageSrc ? (
                        <ReactCrop
                            {...props}
                            circularCrop={circularCrop}
                            crop={crop}
                            src={imageSrc}
                            onChange={onCropChange}
                            onImageLoaded={onImageLoad}
                            onComplete={onCropComplete}
                            className={classes.cropper}
                        />
                    ) : (
                        <h3>Image not uploaded</h3>
                    )}
                </div>
                <canvas
                    ref={previewCanvasRef}
                    style={{
                        display: 'none',
                    }}
                />
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={getCroppedImage}
                    loading={loading || false}
                    text="Save"
                    loadingText="Saving"
                />
                <Button variant="outlined" onClick={onClose}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = makeStyles(() => {
    return {
        dialogTitle: {
            display: 'flex',
            justifyContent: 'center',
        },
        dialogContent: {
            display: 'flex',
            flex: 1,
        },
        cropperContainer: {
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            backgroundColor: 'lightgrey',
            borderRadius: 8,
        },
        cropper: {
            display: 'flex',
            '& > div:first-child': {
                display: 'flex',
                height: '100%',
                '& img': {
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                },
            },
        },
    };
});
