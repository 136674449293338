import { Box, Tooltip, Typography } from '@material-ui/core';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import { RatioBox } from 'components';
import { PaymentMethod } from 'graphql/generated';
import React from 'react';
import colorMXID from 'utils/colorMXID';
import { useStyles } from './payment-method-item.style';

type PaymentMethodItemProps = {
    paymentMethod: PaymentMethod;
    index: number;
    currentSlideIndex: number;
};

export const PaymentMethodItem: React.FC<PaymentMethodItemProps> = ({ paymentMethod, currentSlideIndex, index }) => {
    const classes = useStyles();

    const creditCardBrandIcon = {
        visa: require('assets/icons/visa.svg').default,
        mastercard: require('assets/icons/mastercard.svg').default,
    }[paymentMethod.brand];

    return (
        <RatioBox ratio={0.628}>
            <Box
                className={classes.root}
                bgcolor={colorMXID(paymentMethod.id)}
                style={{ transform: index === currentSlideIndex ? 'scale(1.05)' : 'scale(1)' }}
            >
                <Typography className={classes.digits}>**** **** **** {paymentMethod.lastFour}</Typography>
                <Box display="flex" width="100%" justifyContent="flex-end">
                    <img style={{ width: 40, height: 40 }} src={creditCardBrandIcon} alt="payment method brand" />
                </Box>
                {paymentMethod.isDefault && (
                    <Box className={classes.favorite}>
                        <Tooltip title="Default payment method" placement="top">
                            <StarOutlineIcon />
                        </Tooltip>
                    </Box>
                )}
            </Box>
        </RatioBox>
    );
};
