import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
    return {
        compose: {
            margin: theme.spacing(0.5, 0),
            '& fieldset': {
                display: 'none',
            },
            '& .MuiInputBase-root': {
                height: 40,
                paddingRight: '2px !important',
                borderRadius: theme.spacing(4),
            },
            '& input': {
                fontSize: 16,
                fontWeight: 400,
                paddingLeft: theme.spacing(1),
                '&::placeholder': {
                    ...theme.typography.body1,
                    fontWeight: 400,
                    fontStyle: 'italic',
                    opacity: 0.3,
                },
            },
        },
    };
});
