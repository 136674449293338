import { Box } from '@material-ui/core';
import Switch, { SwitchProps } from '@material-ui/core/Switch';
import clsx from 'clsx';
import { useSettings } from 'context/SettingsContext';
import React from 'react';
import { useStyles } from './switch-theme.style';
import { ReactComponent as LightIcon } from 'assets/icons/light.svg';
import { ReactComponent as DarkIcon } from 'assets/icons/dark.svg';

export const SwitchTheme: React.FC<Omit<SwitchProps, 'color'> & { mode?: 'dark' | 'light' }> = ({
    mode = 'dark',
    ...props
}) => {
    const { isDarkMode, toggleTheme } = useSettings();

    const classes = useStyles({ mode })();

    return (
        <Box className={classes.root}>
            <Switch
                disableRipple
                onChange={toggleTheme}
                checked={isDarkMode}
                classes={{
                    root: classes.switch,
                    switchBase: classes.switchBase,
                    thumb: classes.thumb,
                    track: classes.track,
                    checked: classes.checked,
                }}
                {...props}
            />
            <LightIcon onClick={toggleTheme} className={clsx(classes.icon, classes.light, 'actionSwitchIcon')} />
            <DarkIcon onClick={toggleTheme} className={clsx(classes.icon, classes.dark)} />
        </Box>
    );
};
