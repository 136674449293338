import * as Yup from 'yup';

const notEqualTo = (ref: any, msg: any) => {
    return Yup.string().test({
        name: 'notEqualTo',
        exclusive: false,
        message: msg || '${path} must be the same as ${reference}',
        params: {
            reference: ref.path,
        },
        test: function (value: any) {
            return value !== this.resolve(ref);
        },
    });
};

Yup.addMethod<Yup.StringSchema>(Yup.string, 'notEqualTo', notEqualTo);

const ValidationSchema = Yup.object().shape({
    currentPassword: Yup.string().required('Required'),
    newPassword: (Yup as any)
        .string()
        .notEqualTo(Yup.ref('currentPassword'), 'New password should be different')
        .required('Required'),
});

export default ValidationSchema;
