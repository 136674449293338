import { Box, IconButton, useTheme } from '@material-ui/core';
import { ReactComponent as ArrowIcon } from 'assets/icons/actions/arrow.svg';
import clsx from 'clsx';
import { BlurhashImage } from 'components/image-blurhash/blurhash-image';
import { videoExtensions } from 'config';
import { FileInfo } from 'graphql/generated';
import React from 'react';
import Carousel from 'react-slick';
import { useStyles } from './event-slider.style';

type EventSliderProps = {
    files: FileInfo[];
};

export const EventSlider: React.FC<EventSliderProps> = ({ files }) => {
    const classes = useStyles();
    const theme = useTheme();

    function ArrowRight(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <IconButton disableRipple className={classes.arrow} onClick={onClick}>
                    <ArrowIcon style={{ transform: 'rotate(180deg)' }} />
                </IconButton>
            </div>
        );
    }

    function ArrowLeft(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{
                    ...style,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <IconButton disableRipple className={clsx(classes.arrow)} onClick={onClick}>
                    <ArrowIcon />
                </IconButton>
            </div>
        );
    }

    const sliderSettings = {
        dots: false,
        infinite: true,
        arrows: true,
        lazyLoad: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <ArrowRight />,
        prevArrow: <ArrowLeft />,
        responsive: [
            {
                breakpoint: theme.breakpoints.values.sm,
                settings: {
                    arrows: false,
                },
            },
        ],
    };

    return (
        <Box className={classes.images}>
            {files.length > 1 ? (
                <Carousel {...sliderSettings}>
                    {files.map((file) => {
                        const isVideo = videoExtensions.includes(file.extension);
                        return (
                            <Box key={file.id}>
                                {isVideo ? (
                                    <video src={file.url} autoPlay loop muted controls />
                                ) : (
                                    <BlurhashImage image={file} relativePositioning />
                                )}
                            </Box>
                        );
                    })}
                </Carousel>
            ) : files.length === 1 ? (
                <Box>
                    {videoExtensions.includes(files[0].extension) ? (
                        <video src={files[0].url} autoPlay loop muted controls />
                    ) : (
                        <BlurhashImage image={files[0]} relativePositioning />
                    )}
                </Box>
            ) : (
                <img src={require('assets/event.jpg')} />
            )}
        </Box>
    );
};
