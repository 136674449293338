import { Box } from '@material-ui/core';
import { useSettings } from 'context/SettingsContext';
import { Post } from 'graphql/generated';
import React from 'react';
import { getTranslationContent } from 'utils/translation-helper';
import { ReadonlyPostItem } from '../../readonly-post-item';
import { useStyles } from './repost-post-content.style';

type RepostPostContentProps = {
    post: Post;
    repost: Post;
};

export const RepostPostContent: React.FC<RepostPostContentProps> = ({ post, repost }) => {
    const classes = useStyles();
    const { language } = useSettings();

    const translations = post.translations.edges.map(({ node }) => node) || [];
    const postContent = getTranslationContent({ language, translations, requiredFields: ['body'] });

    return (
        <Box>
            {postContent.body && (
                <Box className={classes.body} dangerouslySetInnerHTML={{ __html: postContent.body as string }} />
            )}
            <Box className={classes.repost}>
                <ReadonlyPostItem post={repost} readonly={false} />
            </Box>
        </Box>
    );
};
