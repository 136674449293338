import { useAuthState } from 'context/auth/store';
import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

export const AuthorizedRoute: React.FC<RouteProps> = ({ location, ...rest }) => {
    const { isLoggedIn } = useAuthState();

    if (!isLoggedIn) {
        return (
            <Redirect
                push={false}
                to={{
                    pathname: '/login',
                    state: { from: location, redirected: true },
                }}
            />
        );
    }

    return <Route location={location} {...rest} />;
};

export default AuthorizedRoute;
