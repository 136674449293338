import { Box, makeStyles } from '@material-ui/core';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { FileInfo } from 'graphql/generated';
import React from 'react';

type VideoItemProps = JSX.IntrinsicElements['video'] & {
    file: FileInfo;
};

export const VideoItem: React.FC<VideoItemProps> = ({ file }) => {
    const classes = useStyles();
    return (
        <Box position="relative" style={{ width: '100%', height: '100%' }}>
            <video className={classes.video} src={file.url} />
            <PlayCircleOutlineIcon className={classes.playIcon} />
        </Box>
    );
};

export const useStyles = makeStyles(() => {
    return {
        video: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
        playIcon: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '3rem',
            color: '#fff',
            pointerEvents: 'none',
        },
    };
});
