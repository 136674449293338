import { Grid } from '@material-ui/core';
import { LanguageTabs } from 'components';
import { RichTextEditor } from 'components/formik';
import { useAuthState } from 'context/auth/store';
import { Field } from 'formik';
import { LanguageCode } from 'graphql/generated';
import React, { useState } from 'react';

type SharePostDialogFormProps = {
    errors: any;
    touched: any;
};

export const SharePostDialogForm: React.FC<SharePostDialogFormProps> = ({ errors, touched }) => {
    const { user, isManager } = useAuthState();
    const [langIndex, setLangIndex] = useState(0);
    const defaultLanguageIndex = Object.values(LanguageCode).findIndex((lang) => lang === LanguageCode.En);

    return (
        <Grid container style={{ marginBottom: '8px', marginTop: '8px' }} spacing={2}>
            <Grid item xs={12}>
                {isManager && (
                    <LanguageTabs
                        value={langIndex}
                        onChange={(index): void => setLangIndex(index)}
                        errors={errors}
                        touched={touched}
                    />
                )}
                <Field
                    component={RichTextEditor}
                    name={`translations[${isManager ? langIndex : defaultLanguageIndex}].body`}
                    placeholder={`What's on your mind, ${user?.firstName}?`}
                    noBorderTop={isManager}
                    minHeight={100}
                />
            </Grid>
        </Grid>
    );
};
