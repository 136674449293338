import { lighten, makeStyles } from '@material-ui/core/styles';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStyles = ({ mode }: { mode: 'dark' | 'light' }) =>
    makeStyles((theme) => {
        const isDark = mode === 'dark';
        return {
            root: {
                position: 'relative',
                marginLeft: theme.spacing(3),
                marginRight: theme.spacing(3),
            },
            switch: {
                width: 50,
                height: 25,
                padding: 0,
                display: 'flex',
                position: 'relative',
            },
            switchBase: {
                padding: 1.5,
                '&$checked': {
                    transform: 'translateX(25px)',
                    color: '#fff',
                    '& + $track': {
                        borderRadius: 50,
                        opacity: 1,
                    },
                },
            },
            checked: {
                '&:hover': {
                    backgroundColor: 'transparent !important',
                },
            },
            thumb: {
                width: 22,
                height: 22,
                boxShadow: 'none',
                color: `${isDark ? '#808080' : theme.palette.primary.dark} !important`,
            },
            track: {
                borderRadius: 50,
                opacity: '1 !important',
                backgroundColor: `${isDark ? '#333333' : lighten(theme.palette.background.default, 0.35)} !important`,
            },
            icon: {
                // color: 'red',
                position: 'absolute',
                fontSize: '16px',
                zIndex: 1,
                cursor: 'pointer',
                fill: isDark ? '#fff' : '#000',
            },
            light: {
                top: 3.5,
                left: -24,
            },
            dark: {
                top: 3.5,
                right: -24,
            },
        };
    });
