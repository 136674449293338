import { makeStyles } from '@material-ui/core/styles';
import { cardP, cardPMobile, mobileSize } from 'layout-config';

export const useStyles = makeStyles((theme) => {
    return {
        repost: {
            padding: theme.spacing(0, cardP),
            [theme.breakpoints.down(mobileSize)]: {
                padding: theme.spacing(0, cardPMobile),
            },
        },
        body: {
            ...theme.typography.body1,
            fontWeight: 500,
            padding: theme.spacing(2, cardP),
            paddingTop: theme.spacing(1),
            '& ol, & ul': {
                marginLeft: 20,
            },
            [theme.breakpoints.down(mobileSize)]: {
                padding: theme.spacing(1, cardPMobile),
            },
        },
    };
});
