import { ClearButton } from 'components/clear-button';
import { Field, FieldAttributes } from 'formik';
import React from 'react';

export type BaseFieldProps = FieldAttributes<any> & {
    allowClear?: boolean;
};

export const BaseField: React.FC<BaseFieldProps> = ({ allowClear, ...props }) => {
    const { name } = props;
    return (
        <Field
            {...props}
            InputProps={
                allowClear && {
                    endAdornment: <ClearButton name={name} {...props} />,
                }
            }
        />
    );
};
