import { Box, Card, Grid, Icon, IconButton, Typography } from '@material-ui/core';
import { LanguageTabs } from 'components';
import { RichTextEditor } from 'components/formik';
import { RatioBox } from 'components/ratio-box';
import { VideoPlayer } from 'components/video-player';
import { imageExtensions, imageTypes, videoExtensions, videoTypes } from 'config';
import { useAuthState } from 'context/auth/store';
import { Field, FormikValues } from 'formik';
import { LanguageCode } from 'graphql/generated';
import React, { useState } from 'react';
import { useStyles } from './edit-post-dialog-form.style';

type EditPostDialogFormProps = {
    values: FormikValues;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    loading: boolean;
    isRepost: boolean;
    isAuthor: boolean;
    errors: any;
    touched: any;
};

export const EditPostDialogForm: React.FC<EditPostDialogFormProps> = ({
    values,
    setFieldValue,
    loading,
    isRepost,
    isAuthor,
    errors,
    touched,
}) => {
    const classes = useStyles();
    const { user, isManager } = useAuthState();
    const [langIndex, setLangIndex] = useState(0);
    const defaultLanguageIndex = Object.values(LanguageCode).findIndex((lang) => lang === LanguageCode.En);
    const fieldName = `translations[${isManager ? langIndex : defaultLanguageIndex}].body`;

    return (
        <Grid container style={{ marginBottom: '8px', marginTop: '8px' }} spacing={2}>
            <Grid item xs={12}>
                {isManager && isAuthor && (
                    <LanguageTabs
                        value={langIndex}
                        onChange={(index): void => setLangIndex(index)}
                        errors={errors}
                        touched={touched}
                    />
                )}
                <Field
                    component={RichTextEditor}
                    name={fieldName}
                    placeholder={`What's on your mind, ${user?.firstName}?`}
                    noBorderTop={isManager}
                />
            </Grid>
            {!isRepost && (
                <Grid item xs={12}>
                    {values.files.map((file, i) => {
                        if (imageTypes.includes(file.type)) {
                            return (
                                <RatioBox key={i} containerClassName={classes.imageContainer}>
                                    <img
                                        className={classes.image}
                                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                        src={URL.createObjectURL(file)}
                                    />
                                    <IconButton
                                        disabled={loading}
                                        onClick={(): void => {
                                            const files = values.files.filter((_, index) => index !== i);
                                            setFieldValue('files', files);
                                        }}
                                        className={classes.photoDeleteButton}
                                    >
                                        <Icon style={{ fontSize: 16 }}>close</Icon>
                                    </IconButton>
                                </RatioBox>
                            );
                        } else if (videoTypes.includes(file.type)) {
                            return (
                                <RatioBox key={i} containerClassName={classes.imageContainer}>
                                    <VideoPlayer light={false} url={URL.createObjectURL(file)} />
                                    <IconButton
                                        disabled={loading}
                                        onClick={(): void => {
                                            const files = values.files.filter((_, index) => index !== i);
                                            setFieldValue('files', files);
                                        }}
                                        className={classes.photoDeleteButton}
                                    >
                                        <Icon style={{ fontSize: 16 }}>close</Icon>
                                    </IconButton>
                                </RatioBox>
                            );
                        } else {
                            return (
                                <Card key={i} className={classes.fileContainer} variant="outlined">
                                    <Box display="flex" alignItems="center">
                                        <Icon fontSize="small">attach_file</Icon>
                                        <Typography className={classes.fileName}>{file.name}</Typography>
                                    </Box>
                                    <IconButton
                                        disabled={loading}
                                        hidden={loading}
                                        onClick={(): void => {
                                            const files = values.files.filter((_, index) => index !== i);
                                            setFieldValue('files', files);
                                        }}
                                        className={classes.fileDeleteButton}
                                    >
                                        <Icon className={classes.fileDeleteIcon}>close</Icon>
                                    </IconButton>
                                </Card>
                            );
                        }
                    })}
                    {values.previousFiles.map((file, i) => {
                        if (imageExtensions.includes(file.extension)) {
                            return (
                                <RatioBox key={i} containerClassName={classes.imageContainer}>
                                    <img
                                        className={classes.image}
                                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                        src={file.url}
                                    />
                                    <IconButton
                                        disabled={loading}
                                        onClick={(): void => {
                                            const files = values.previousFiles.filter((_, index) => index !== i);
                                            setFieldValue('previousFiles', files);
                                        }}
                                        className={classes.photoDeleteButton}
                                    >
                                        <Icon style={{ fontSize: 16 }}>close</Icon>
                                    </IconButton>
                                </RatioBox>
                            );
                        } else if (videoExtensions.includes(file.extension)) {
                            return (
                                <RatioBox key={i} containerClassName={classes.imageContainer}>
                                    <VideoPlayer light={false} url={file.url} />
                                    <IconButton
                                        disabled={loading}
                                        onClick={(): void => {
                                            const files = values.previousFiles.filter((_, index) => index !== i);
                                            setFieldValue('previousFiles', files);
                                        }}
                                        className={classes.photoDeleteButton}
                                    >
                                        <Icon style={{ fontSize: 16 }}>close</Icon>
                                    </IconButton>
                                </RatioBox>
                            );
                        } else {
                            return (
                                <Card key={i} className={classes.fileContainer} variant="outlined">
                                    <Box display="flex" alignItems="center">
                                        <Icon fontSize="small">attach_file</Icon>
                                        <Typography className={classes.fileName}>{file.name}</Typography>
                                    </Box>
                                    <IconButton
                                        disabled={loading}
                                        hidden={loading}
                                        onClick={(): void => {
                                            const files = values.previousFiles.filter((_, index) => index !== i);
                                            setFieldValue('previousFiles', files);
                                        }}
                                        className={classes.fileDeleteButton}
                                    >
                                        <Icon className={classes.fileDeleteIcon}>close</Icon>
                                    </IconButton>
                                </Card>
                            );
                        }
                    })}
                </Grid>
            )}
        </Grid>
    );
};
