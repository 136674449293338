import { Avatar, AvatarProps } from '@material-ui/core';
import React from 'react';
import colorMXID from 'utils/colorMXID';
import { FontSize, getFontSize } from './utils';

export const ColorIDAvatar: React.FC<AvatarProps & { id: string; text?: string; fontSize?: FontSize }> = ({
    id,
    text,
    fontSize = 'md',
    src,
    style,
    children,
    ...props
}) => {
    const avatarStyles = src
        ? style
        : {
              color: '#fff',
              backgroundColor: colorMXID(id),
              fontSize: getFontSize(fontSize),
              ...style,
          };
    const firstLetter = text?.split('')[0].toUpperCase();

    return (
        <Avatar src={src} style={avatarStyles} {...props}>
            {children || firstLetter}
        </Avatar>
    );
};
