import { darken, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
    return {
        header: {
            backgroundColor: darken(theme.palette.background.paper, 0.025),
            padding: theme.spacing(1.5, 2),
        },
        content: {
            padding: 0,
        },
    };
});
