import React from 'react';
import createDOMPurify from 'dompurify';

const DOMPurify = createDOMPurify(window);

export const RichText: React.FC<JSX.IntrinsicElements['div'] & { text: string }> = ({ text, ...props }) => {
    const purifiedText = DOMPurify.sanitize(text);

    return <div dangerouslySetInnerHTML={{ __html: purifiedText }} {...props} />;
};
