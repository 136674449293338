import { makeStyles } from '@material-ui/core/styles';
import { LoadingButton } from 'components';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const useStyles = makeStyles(() => {
    return {
        root: {
            width: 'fit-content',
            '& div': {
                height: '100%',
            },
            '& img': {
                width: '100%',
                height: '100%',
                objectFit: 'contain',
            },
        },
        button: {
            boxShadow: 'none !important',
            borderRadius: '0 0 8px 8px',
            height: 48,
            '&:active': {
                transform: 'scale(1)',
            },
        },
    };
});

type CropperProps = {
    uploadedImage: File | undefined;
    imageBlob: string | null;
    handleSubmit: (file) => void;
    crop: any;
    onChange: (crop) => void;
    circularCrop?: boolean;
    loading: boolean;
};

export const Cropper: React.FC<CropperProps> = ({
    uploadedImage,
    imageBlob,
    handleSubmit,
    crop,
    onChange,
    circularCrop = false,
    loading,
}) => {
    const classes = useStyles();
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [completedCrop, setCompletedCrop] = useState<any>(null);

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    const submit = (canvas, crop): void => {
        if (!crop || !canvas || !uploadedImage) {
            return;
        }

        canvas.toBlob(
            (blob) => {
                handleSubmit(new File([blob], uploadedImage.name, { type: uploadedImage.type }));
            },
            'image/png',
            1,
        );
    };

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image: any = imgRef.current;
        const canvas: any = previewCanvasRef.current;
        const crop: any = completedCrop;

        if (image && canvas && crop) {
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;
            const ctx = canvas.getContext('2d');
            const pixelRatio = window.devicePixelRatio;

            canvas.width = crop.width * pixelRatio;
            canvas.height = crop.height * pixelRatio;

            ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
            ctx.imageSmoothingQuality = 'high';

            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width,
                crop.height,
            );
        }
    }, [completedCrop]);

    if (!imageBlob) {
        return null;
    }

    return (
        <>
            <ReactCrop
                src={imageBlob}
                className={classes.root}
                onImageLoaded={onLoad}
                crop={crop}
                circularCrop={circularCrop}
                onChange={onChange}
                onComplete={(c): void => {
                    setCompletedCrop(c);
                }}
            />
            <canvas
                ref={previewCanvasRef}
                // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                style={{
                    display: 'none',
                }}
            />
            <LoadingButton
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={!completedCrop?.width || !completedCrop?.height || loading}
                loading={loading}
                onClick={(): void => submit(previewCanvasRef.current, completedCrop)}
                text="Save"
                loadingText="Saving"
            />
        </>
    );
};
