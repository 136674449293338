import { Box, darken, Grid, IconButton, lighten, makeStyles, SvgIcon } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ReactComponent as ImageRemoveIcon } from 'assets/community-icons/image-remove.svg';
import { CircleIcon, EmptyState, Lightbox, RatioBox, Spinner } from 'components';
import { BlurhashImage } from 'components/image-blurhash';
import { imageExtensions, videoExtensions } from 'config';
import { Theme } from 'enum';
import { FileInfo } from 'graphql/generated';
import React, { useState } from 'react';
import { VideoItem } from './components';

type GalleryProps = {
    files: FileInfo[];
    deletable?: boolean;
    onDelete?: (id) => void;
    deleting?: boolean;
};

const useStyles = makeStyles((theme) => {
    const isDarkMode = theme.palette.type === Theme.Dark;
    return {
        container: {
            width: 'calc(100% + 2px)',
            margin: -1,
            '& .MuiGrid-item': {
                padding: 1,
            },
        },
        image: {
            position: 'relative',
            '& img, & video': {
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                transition: '.3s filter',
                '&:hover': {
                    filter: 'brightness(70%)',
                },
            },
        },
        spinner: {
            width: '24px !important',
            height: '24px !important',
        },
        deleteButton: {
            position: 'absolute',
            padding: 4,
            top: 0,
            right: 0,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            backgroundColor: isDarkMode
                ? lighten(theme.palette.background.paper, 0.05)
                : darken(theme.palette.background.paper, 0.05),
            '&:hover': {
                backgroundColor: isDarkMode
                    ? lighten(theme.palette.background.paper, 0.1)
                    : darken(theme.palette.background.paper, 0.1),
            },
        },
    };
});

const INITIAL_LIGHTBOX_STATE = { open: false, index: 0 };

export const Gallery: React.FC<GalleryProps> = ({ files, deletable, deleting, onDelete }) => {
    const classes = useStyles();

    const images = files.filter((file) => imageExtensions.includes(file.extension));

    const [lightboxState, setLightboxState] = useState(INITIAL_LIGHTBOX_STATE);

    if (files.length === 0) {
        return (
            <EmptyState
                media={
                    <CircleIcon radius={80} icon={<SvgIcon component={ImageRemoveIcon} style={{ fontSize: 70 }} />} />
                }
                primaryText="No images uploaded yet"
            />
        );
    }

    return (
        <Box>
            <Grid container spacing={1} className={classes.container}>
                {files.map((file) => {
                    const isVideo = videoExtensions.includes(file.extension);
                    return (
                        <Grid key={file.id} item xs={4} className={classes.image}>
                            <RatioBox ratio={1}>
                                <Box
                                    width="100%"
                                    height="100%"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        if (isVideo) {
                                            window.open(file.url, '_blank');
                                        } else {
                                            const imageIndex = images.findIndex((image) => image.id === file.id);
                                            setLightboxState({ open: true, index: imageIndex });
                                        }
                                    }}
                                >
                                    {isVideo ? <VideoItem file={file} /> : <BlurhashImage image={file} />}
                                </Box>
                            </RatioBox>
                            {deletable && (
                                <IconButton
                                    className={classes.deleteButton}
                                    onClick={() => onDelete?.(file.id)}
                                    disabled={deleting}
                                    disableRipple
                                    color="secondary"
                                    size="small"
                                >
                                    {deleting ? (
                                        <Spinner
                                            containerClassName={classes.spinner}
                                            spinnerClassName={classes.spinner}
                                        />
                                    ) : (
                                        <CloseIcon fontSize="small" />
                                    )}
                                </IconButton>
                            )}
                        </Grid>
                    );
                })}
            </Grid>
            {lightboxState.open && (
                <Lightbox
                    mainSrc={images[lightboxState.index].url}
                    nextSrc={images[(lightboxState.index + 1) % images.length].url}
                    prevSrc={images[(lightboxState.index + images.length - 1) % images.length].url}
                    onCloseRequest={() => setLightboxState(INITIAL_LIGHTBOX_STATE)}
                    onMovePrevRequest={() =>
                        setLightboxState((prev) => ({
                            ...prev,
                            index: (lightboxState.index + images.length - 1) % images.length,
                        }))
                    }
                    onMoveNextRequest={() =>
                        setLightboxState((prev) => ({ ...prev, index: (lightboxState.index + 1) % images.length }))
                    }
                />
            )}
        </Box>
    );
};
