import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
    return {
        root: {
            display: 'flex',
            width: 'fit-content',
            alignItems: 'center',
            borderRadius: 10,
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(0.75, 1.5),
        },
        iconContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: theme.spacing(0.25),
            marginRight: theme.spacing(1),
            borderRadius: '50%',
        },
        icon: {
            fontSize: 16,
        },
        guest: {
            backgroundColor: 'rgba(24, 119, 242, 0.08)',
            color: 'rgb(24, 119, 242)',
        },
        pendingApproval: {
            color: '#f44336',
            backgroundColor: 'rgba(244, 67, 54, 0.08)',
        },
        pendingPayment: {
            color: '#ff9800',
            backgroundColor: 'rgba(255, 152, 0, 0.08)',
        },
        approved: {
            color: '#4caf50',
            backgroundColor: 'rgba(76, 175, 80, 0.08)',
        },
    };
});
