import { Box, BoxProps, Button, Typography } from '@material-ui/core';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { RatioBox, Spinner } from 'components';
import { usePaymentMethods } from 'context/payment-methods/store';
import { usePaymentMethodsQuery } from 'graphql/generated';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import SlickSlider from 'react-slick';
import { AddPaymentMethodDialog } from '../add-payment-method-dialog';
import { PaymentMethodItem } from './payment-method-item';
import { useStyles } from './payment-method-selector.style';

type PaymentMethodSelector = {
    onPaymentMethodChange: (id: string) => void;
    headerProps?: BoxProps;
};

export const PaymentMethodSelector: React.FC<PaymentMethodSelector> = ({ headerProps, onPaymentMethodChange }) => {
    const classes = useStyles();
    const [{ paymentMethods, paymentMethodsLoading }, dispatch] = usePaymentMethods();
    const [addPaymentMethodDialogOpen, setAddPaymentMethodDialogOpen] = useState(false);
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

    const openAddPaymentMethodDialog = useCallback(() => setAddPaymentMethodDialogOpen(true), []);
    const closeAddPaymentMethodDialog = useCallback(() => setAddPaymentMethodDialogOpen(false), []);

    usePaymentMethodsQuery({
        fetchPolicy: 'network-only',
        onCompleted({ paymentMethods: { edges } }) {
            dispatch({ type: 'LOAD_PAYMENT_METHODS_SUCCESS', paymentMethods: edges.map(({ node }) => node) });
        },
    });

    const sliderRef = useRef<any>(null);

    const sliderSettings = {
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        infinite: false,
        centerPadding: '48px',
        afterChange: (index: number) => {
            onPaymentMethodChange(paymentMethods[index].id);
            setCurrentSlideIndex(index);
        },
        responsive: [
            {
                breakpoint: 460,
                settings: {
                    centerPadding: '24px',
                },
            },
        ],
    };

    useEffect(() => {
        const defaultPaymentMethod =
            paymentMethods.find((paymentMethod) => paymentMethod.isDefault) || paymentMethods[0];
        if (defaultPaymentMethod) {
            onPaymentMethodChange(defaultPaymentMethod.id);
            const defaultPaymentMethodIndex = paymentMethods
                .map((paymentMethod) => paymentMethod.id)
                .indexOf(defaultPaymentMethod.id);
            sliderRef.current.slickGoTo(defaultPaymentMethodIndex);
            setCurrentSlideIndex(defaultPaymentMethodIndex);
        }
    }, [paymentMethods, onPaymentMethodChange]);

    if (paymentMethodsLoading) {
        return (
            <RatioBox ratio={0.628}>
                <Spinner style={{ width: '100%', height: '100%' }} />
            </RatioBox>
        );
    }

    return (
        <Box className={classes.root}>
            <Box mb={4} display="flex" justifyContent="space-between" alignItems="center" {...headerProps}>
                <Typography className={classes.title}>Payment methods</Typography>
                <Button
                    className={classes.addButton}
                    startIcon={<CreditCardIcon fontSize="small" />}
                    onClick={openAddPaymentMethodDialog}
                >
                    New card
                </Button>
            </Box>
            {paymentMethods.length > 0 ? (
                <SlickSlider ref={sliderRef} {...sliderSettings}>
                    {paymentMethods.map((paymentMethod, index) => {
                        return (
                            <Box key={paymentMethod.id} className={classes.paymentMethodItem}>
                                <PaymentMethodItem
                                    paymentMethod={paymentMethod}
                                    index={index}
                                    currentSlideIndex={currentSlideIndex}
                                />
                            </Box>
                        );
                    })}
                </SlickSlider>
            ) : (
                <RatioBox ratio={0.628}>
                    <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
                        <Typography variant="h5">No payment methods available</Typography>
                    </Box>
                </RatioBox>
            )}
            <AddPaymentMethodDialog open={addPaymentMethodDialogOpen} onClose={closeAddPaymentMethodDialog} />
        </Box>
    );
};
