import { darken, lighten, makeStyles } from '@material-ui/core';
import { Theme } from 'enum';

export const useStyles = makeStyles((theme) => {
    const isDarkMode = theme.palette.type === Theme.Dark;
    return {
        uploader: {
            width: '100%',
            height: '100%',
            borderRadius: theme.shape.borderRadius,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: isDarkMode
                ? lighten(theme.palette.background.paper, 0.05)
                : darken(theme.palette.background.paper, 0.05),
            cursor: 'pointer',
            transition: '.3s background-color',
            '&:hover': {
                backgroundColor: isDarkMode
                    ? lighten(theme.palette.background.paper, 0.1)
                    : darken(theme.palette.background.paper, 0.1),
            },
        },
        imageContainer: {
            width: '100%',
            height: '100%',
            borderRadius: theme.shape.borderRadius,
            overflow: 'hidden',
            position: 'relative',
            '& img': {
                width: '100%',
                height: '100%',
                objectFit: 'cover',
            },
            '& .MuiIconButton-root': {
                position: 'absolute',
                padding: 4,
                top: 0,
                right: 0,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                backgroundColor: isDarkMode
                    ? lighten(theme.palette.background.paper, 0.05)
                    : darken(theme.palette.background.paper, 0.05),
            },
        },
    };
});
