import { Container, ContainerProps } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { ReactNode } from 'react';
import { useStyle } from './page-header.style';

interface PageHeaderProps extends Omit<ContainerProps, 'title' | 'children'> {
    title: string | ReactNode;
    children?: ReactNode;
}

export const PageHeader: React.FC<PageHeaderProps> = ({ title, children, maxWidth = 'md', ...other }) => {
    const classes = useStyle();

    return (
        <Container maxWidth={maxWidth} className={classes.root} {...other}>
            <Grid container className={classes.container} justifyContent="space-between">
                <Typography variant="h3">{title}</Typography>
                <Grid className={classes.rightContainer}>{children}</Grid>
            </Grid>
        </Container>
    );
};
