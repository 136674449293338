import { makeStyles } from '@material-ui/core';
import { bottomBarHeight, mobileSize } from 'layout-config';

export const useStyles = makeStyles((theme) => {
    return {
        fab: {
            position: 'fixed',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
            [theme.breakpoints.down(mobileSize)]: {
                bottom: bottomBarHeight + theme.spacing(2),
            },
        },
    };
});
