import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
    DateTime: any;
    /** The `Upload` scalar type represents a file upload. */
    Upload: any;
};

export type AcceptFollowInput = {
    followerId: Scalars['String'];
};

export type AcceptFollowPayload = {
    __typename?: 'AcceptFollowPayload';
    follow: Follow;
};

export type AcceptMembershipRequestInput = {
    requestId: Scalars['String'];
};

export type AcceptMembershipRequestPayload = {
    __typename?: 'AcceptMembershipRequestPayload';
    community: Community;
};

export type ActivateAccountInput = {
    token: Scalars['String'];
};

export type ActivateAccountPayload = {
    __typename?: 'ActivateAccountPayload';
    success: Scalars['Boolean'];
};

export type Activity = RelayNode & {
    __typename?: 'Activity';
    author: User;
    authorId: Scalars['String'];
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    meta: ActivityMeta;
    seen: Scalars['Boolean'];
    targetUserId: Scalars['String'];
    type: ActivityType;
    updatedAt: Scalars['DateTime'];
};

export type ActivityConnection = {
    __typename?: 'ActivityConnection';
    edges: Array<ActivityEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type ActivityEdge = {
    __typename?: 'ActivityEdge';
    node: Activity;
};

export type ActivityMeta = {
    __typename?: 'ActivityMeta';
    post: Post;
    postId: Scalars['String'];
    type: ActivityType;
};

export enum ActivityType {
    Comment = 'COMMENT',
    Like = 'LIKE',
    Repost = 'REPOST',
}

export type AddPaymentMethodInput = {
    isDefault: Scalars['Boolean'];
    paymentMethod: Scalars['String'];
};

export type AddPaymentMethodPayload = {
    __typename?: 'AddPaymentMethodPayload';
    paymentMethod: PaymentMethod;
};

export type Benefit = {
    __typename?: 'Benefit';
    icon: Scalars['String'];
    text: Scalars['String'];
};

export type BenefitInput = {
    icon: Scalars['String'];
    text: Scalars['String'];
};

export type BlurhashMeta = {
    __typename?: 'BlurhashMeta';
    hash: Scalars['String'];
    height: Scalars['Int'];
    width: Scalars['Int'];
};

export type BookmarkPostInput = {
    postId: Scalars['String'];
};

export type BookmarkPostPayload = {
    __typename?: 'BookmarkPostPayload';
    post: Post;
};

export type CancelFollowInput = {
    followingUserId: Scalars['String'];
};

export type CancelMembershipRequestInput = {
    communityId: Scalars['String'];
};

export type CancelMembershipRequestPayload = {
    __typename?: 'CancelMembershipRequestPayload';
    community: Community;
};

export type ChangePasswordInput = {
    currentPassword: Scalars['String'];
    newPassword: Scalars['String'];
};

export type ChangePasswordPayload = {
    __typename?: 'ChangePasswordPayload';
    success: Scalars['Boolean'];
};

export type ChatableUsersWhereInput = {
    keyword?: Maybe<Scalars['String']>;
};

export type City = RelayNode & {
    __typename?: 'City';
    country: Scalars['String'];
    id: Scalars['ID'];
    name: Scalars['String'];
};

export type CityConnection = {
    __typename?: 'CityConnection';
    edges: Array<CityEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type CityEdge = {
    __typename?: 'CityEdge';
    node: City;
};

export type Community = RelayNode & {
    __typename?: 'Community';
    benefits?: Maybe<Array<Benefit>>;
    category: CommunityCategory;
    city: City;
    cityId: Scalars['String'];
    id: Scalars['ID'];
    images: FileInfoConnection;
    invitations: CommunityInvitationConnection;
    isInternal: Scalars['Boolean'];
    joinRule?: Maybe<JoinRule>;
    logo?: Maybe<FileInfo>;
    logoId?: Maybe<Scalars['String']>;
    managers: ManagerConnection;
    memberCount: Scalars['Int'];
    membershipStatus?: Maybe<CommunityMembershipStatus>;
    pendingMembershipsCount: Scalars['Int'];
    translations: CommunityTranslationConnection;
};

export enum CommunityCategory {
    Club = 'CLUB',
    Community = 'COMMUNITY',
    Healthcare = 'HEALTHCARE',
    Hotel = 'HOTEL',
    Other = 'OTHER',
    Restaurant = 'RESTAURANT',
    Shop = 'SHOP',
}

export type CommunityConnection = {
    __typename?: 'CommunityConnection';
    edges: Array<CommunityEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type CommunityEdge = {
    __typename?: 'CommunityEdge';
    node: Community;
};

export type CommunityImageOrderInput = {
    id: Scalars['String'];
    order: Scalars['Int'];
};

export type CommunityInvitation = RelayNode & {
    __typename?: 'CommunityInvitation';
    community: Community;
    communityId: Scalars['String'];
    createdById: Scalars['String'];
    expiresAt: Scalars['DateTime'];
    id: Scalars['ID'];
    usageLimit: Scalars['Int'];
    usedCount: Scalars['Int'];
};

export type CommunityInvitationConnection = {
    __typename?: 'CommunityInvitationConnection';
    edges: Array<CommunityInvitationEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type CommunityInvitationEdge = {
    __typename?: 'CommunityInvitationEdge';
    node: CommunityInvitation;
};

export type CommunityMembersWhereInput = {
    communityId: Scalars['String'];
};

export type CommunityMembership = RelayNode & {
    __typename?: 'CommunityMembership';
    community: Community;
    communityId: Scalars['String'];
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    member: Member;
    memberId: Scalars['String'];
    rejectReason?: Maybe<Scalars['String']>;
    status: CommunityMembershipStatus;
    updatedAt: Scalars['DateTime'];
};

export type CommunityMembershipConnection = {
    __typename?: 'CommunityMembershipConnection';
    edges: Array<CommunityMembershipEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type CommunityMembershipEdge = {
    __typename?: 'CommunityMembershipEdge';
    node: CommunityMembership;
};

export enum CommunityMembershipStatus {
    Banned = 'BANNED',
    Joined = 'JOINED',
    None = 'NONE',
    PendingJoin = 'PENDING_JOIN',
    Rejected = 'REJECTED',
}

export enum CommunityPartnersOrderBy {
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
}

export type CommunityPartnersWhereInput = {
    categories?: Maybe<Array<CommunityCategory>>;
    communityId: Scalars['String'];
};

export type CommunityTranslation = RelayNode & {
    __typename?: 'CommunityTranslation';
    communityId: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    lang: LanguageCode;
    location: Scalars['String'];
    name: Scalars['String'];
};

export type CommunityTranslationConnection = {
    __typename?: 'CommunityTranslationConnection';
    edges: Array<CommunityTranslationEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type CommunityTranslationEdge = {
    __typename?: 'CommunityTranslationEdge';
    node: CommunityTranslation;
};

export type CommunityWhereInput = {
    category?: Maybe<CommunityCategory>;
    isCommunity?: Maybe<Scalars['Boolean']>;
    isGetAll?: Maybe<Scalars['Boolean']>;
    isPartner?: Maybe<Scalars['Boolean']>;
    keyword?: Maybe<Scalars['String']>;
};

export type Country = RelayNode & {
    __typename?: 'Country';
    code: Scalars['String'];
    id: Scalars['ID'];
    name: Scalars['String'];
};

export type CountryConnection = {
    __typename?: 'CountryConnection';
    edges: Array<CountryEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type CountryEdge = {
    __typename?: 'CountryEdge';
    node: Country;
};

export type CoverPhotoChangeMeta = {
    __typename?: 'CoverPhotoChangeMeta';
    coverPhotoUrl: Scalars['String'];
    type: PostType;
    user: User;
    userId: Scalars['String'];
};

export type CreateCommunityInput = {
    benefits?: Maybe<Array<BenefitInput>>;
    category: CommunityCategory;
    cityId: Scalars['String'];
    images?: Maybe<Array<Scalars['Upload']>>;
    isInternal: Scalars['Boolean'];
    joinRule?: Maybe<JoinRule>;
    logo?: Maybe<Scalars['Upload']>;
    partnerIds?: Maybe<Array<Scalars['String']>>;
    translations: Array<CreateCommunityTranslationInput>;
};

export type CreateCommunityInvitationInput = {
    communityId: Scalars['String'];
    expiresAt: Scalars['DateTime'];
    usageLimit: Scalars['Int'];
};

export type CreateCommunityInvitationPayload = {
    __typename?: 'CreateCommunityInvitationPayload';
    invitation: CommunityInvitation;
};

export type CreateCommunityPayload = {
    __typename?: 'CreateCommunityPayload';
    community: Community;
};

export type CreateCommunityTranslationInput = {
    description?: Maybe<Scalars['String']>;
    lang: LanguageCode;
    location: Scalars['String'];
    name: Scalars['String'];
};

export type CreateEventInput = {
    communityId: Scalars['String'];
    externalLink?: Maybe<Scalars['String']>;
    images?: Maybe<Array<EventImageInput>>;
    isDraft: Scalars['Boolean'];
    isExternal: Scalars['Boolean'];
    price?: Maybe<Scalars['Float']>;
    startTime?: Maybe<Scalars['DateTime']>;
    tags?: Maybe<Array<Scalars['String']>>;
    translations: Array<CreateEventTranslationInput>;
};

export type CreateEventPayload = {
    __typename?: 'CreateEventPayload';
    event: Event;
};

export type CreateEventTranslationInput = {
    address?: Maybe<Scalars['String']>;
    description: Scalars['String'];
    lang: LanguageCode;
    title: Scalars['String'];
};

export type CreateMemberProfileInput = {
    bio?: Maybe<Scalars['String']>;
    cityOfResidence: Scalars['String'];
    countryOfResidenceId: Scalars['String'];
    dateOfBirth: Scalars['DateTime'];
    email: Scalars['String'];
    firstName: Scalars['String'];
    gender?: Maybe<Gender>;
    lastName: Scalars['String'];
    maritalStatus?: Maybe<MaritalStatus>;
    phoneNumber: Scalars['String'];
};

export type CreateMemberProfilePayload = {
    __typename?: 'CreateMemberProfilePayload';
    memberProfile: MemberProfile;
};

export type CreatePostCommentInput = {
    postId: Scalars['String'];
    text: Scalars['String'];
};

export type CreatePostCommentPayload = {
    __typename?: 'CreatePostCommentPayload';
    comment: PostComment;
};

export type CreatePostInput = {
    communityId: Scalars['String'];
    files?: Maybe<Array<Scalars['Upload']>>;
    partnerId?: Maybe<Scalars['String']>;
    translations: Array<CreatePostTranslationInput>;
    visibility: PostVisibility;
};

export type CreatePostPayload = {
    __typename?: 'CreatePostPayload';
    post: Post;
};

export type CreatePostTranslationInput = {
    body?: Maybe<Scalars['String']>;
    lang: LanguageCode;
};

export type CredentialsInput = {
    email: Scalars['String'];
    password: Scalars['String'];
};

export type DefaultPostMeta = {
    __typename?: 'DefaultPostMeta';
    type: PostType;
};

export type DeleteCommunityImageInput = {
    communityId: Scalars['String'];
    imageId: Scalars['String'];
};

export type DeleteCommunityImagePayload = {
    __typename?: 'DeleteCommunityImagePayload';
    community: Community;
};

export type DeleteCommunityInvitationPayload = {
    __typename?: 'DeleteCommunityInvitationPayload';
    invitation: CommunityInvitation;
};

export type DeleteCommunityInvitationyInput = {
    invitationId: Scalars['String'];
};

export type DeleteCommunityPayload = {
    __typename?: 'DeleteCommunityPayload';
    id: Scalars['String'];
};

export type DeleteEventImageInput = {
    eventId: Scalars['String'];
    imageId: Scalars['String'];
};

export type DeleteEventImagePayload = {
    __typename?: 'DeleteEventImagePayload';
    event: Event;
};

export type DeleteEventPayload = {
    __typename?: 'DeleteEventPayload';
    id: Scalars['String'];
};

export type DeleteManagerInput = {
    communityId: Scalars['String'];
    managerId: Scalars['String'];
};

export type DeleteManagerPayload = {
    __typename?: 'DeleteManagerPayload';
    id: Scalars['String'];
};

export type DeletePostCommentPayload = {
    __typename?: 'DeletePostCommentPayload';
    comment: PostComment;
};

export type DeletePostPayload = {
    __typename?: 'DeletePostPayload';
    id: Scalars['String'];
};

export type DeleteUserImageInput = {
    imageId: Scalars['String'];
};

export type DislikePostInput = {
    postId: Scalars['String'];
};

export type DislikePostPayload = {
    __typename?: 'DislikePostPayload';
    likeCount: Scalars['Int'];
};

export type Event = RelayNode & {
    __typename?: 'Event';
    community: Community;
    communityId: Scalars['String'];
    createdById: Scalars['String'];
    externalLink?: Maybe<Scalars['String']>;
    featured: Scalars['Boolean'];
    id: Scalars['ID'];
    images: EventImageConnection;
    isDraft?: Maybe<Scalars['Boolean']>;
    isExternal: Scalars['Boolean'];
    pinned: Scalars['Boolean'];
    price?: Maybe<Scalars['Float']>;
    purchased: Scalars['Boolean'];
    startTime?: Maybe<Scalars['DateTime']>;
    tags?: Maybe<Array<Scalars['String']>>;
    translations: EventTranslationConnection;
    type: EventType;
};

export type EventConnection = {
    __typename?: 'EventConnection';
    edges: Array<EventEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type EventEdge = {
    __typename?: 'EventEdge';
    node: Event;
};

export type EventImage = RelayNode & {
    __typename?: 'EventImage';
    id: Scalars['ID'];
    image: FileInfo;
    order: Scalars['Int'];
};

export type EventImageConnection = {
    __typename?: 'EventImageConnection';
    edges: Array<EventImageEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type EventImageEdge = {
    __typename?: 'EventImageEdge';
    node: EventImage;
};

export type EventImageInput = {
    image: Scalars['Upload'];
    order: Scalars['Int'];
};

export type EventImageOrderInput = {
    id: Scalars['String'];
    order: Scalars['Int'];
};

export type EventMeta = {
    __typename?: 'EventMeta';
    event: Event;
    eventId: Scalars['String'];
    type: PostType;
};

export enum EventOrderBy {
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
    StartTimeAsc = 'START_TIME_ASC',
    StartTimeDesc = 'START_TIME_DESC',
    TitleAsc = 'TITLE_ASC',
    TitleDesc = 'TITLE_DESC',
}

export type EventPaymentInput = {
    eventId: Scalars['String'];
    paymentMethodId: Scalars['String'];
};

export type EventPaymentPayload = {
    __typename?: 'EventPaymentPayload';
    result: Scalars['Boolean'];
};

export type EventTranslation = RelayNode & {
    __typename?: 'EventTranslation';
    address?: Maybe<Scalars['String']>;
    description: Scalars['String'];
    eventId: Scalars['String'];
    id: Scalars['ID'];
    lang: LanguageCode;
    title: Scalars['String'];
};

export type EventTranslationConnection = {
    __typename?: 'EventTranslationConnection';
    edges: Array<EventTranslationEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type EventTranslationEdge = {
    __typename?: 'EventTranslationEdge';
    node: EventTranslation;
};

export enum EventType {
    Event = 'EVENT',
    Experience = 'EXPERIENCE',
}

export type EventWhereInput = {
    communityId?: Maybe<Scalars['String']>;
    keyword?: Maybe<Scalars['String']>;
    tags?: Maybe<Array<Scalars['String']>>;
};

export type ExperienceMeta = {
    __typename?: 'ExperienceMeta';
    experience: Event;
    experienceId: Scalars['String'];
    type: PostType;
};

export type FileInfo = RelayNode & {
    __typename?: 'FileInfo';
    blurhash?: Maybe<BlurhashMeta>;
    createdAt: Scalars['DateTime'];
    extension: Scalars['String'];
    id: Scalars['ID'];
    name: Scalars['String'];
    objectName?: Maybe<Scalars['String']>;
    size: Scalars['Int'];
    updatedAt: Scalars['DateTime'];
    url: Scalars['String'];
    version: Scalars['Int'];
};

export type FileInfoConnection = {
    __typename?: 'FileInfoConnection';
    edges: Array<FileInfoEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type FileInfoEdge = {
    __typename?: 'FileInfoEdge';
    node: FileInfo;
};

export type Follow = RelayNode & {
    __typename?: 'Follow';
    accepted: Scalars['Boolean'];
    acceptedAt?: Maybe<Scalars['DateTime']>;
    follower: User;
    followingUser: User;
    fromId: Scalars['String'];
    id: Scalars['ID'];
    toId: Scalars['String'];
};

export type FollowConnection = {
    __typename?: 'FollowConnection';
    edges: Array<FollowEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type FollowEdge = {
    __typename?: 'FollowEdge';
    node: Follow;
};

export enum FollowStatus {
    Following = 'FOLLOWING',
    FollowRequestSent = 'FOLLOW_REQUEST_SENT',
    None = 'NONE',
}

export type FollowUserInput = {
    followingUserId: Scalars['String'];
};

export type FollowUserPayload = {
    __typename?: 'FollowUserPayload';
    follow: Follow;
};

export type FollowWhereInput = {
    userId?: Maybe<Scalars['String']>;
};

export type ForgotPasswordInput = {
    email: Scalars['String'];
};

export type ForgotPasswordPayload = {
    __typename?: 'ForgotPasswordPayload';
    sent: Scalars['Boolean'];
};

export enum Gender {
    Female = 'FEMALE',
    Male = 'MALE',
}

export type JoinCommunityInput = {
    communityId: Scalars['String'];
};

export type JoinCommunityPayload = {
    __typename?: 'JoinCommunityPayload';
    community: Community;
};

export enum JoinRule {
    Invite = 'INVITE',
    Knock = 'KNOCK',
    Public = 'PUBLIC',
}

export enum LanguageCode {
    En = 'EN',
    Lv = 'LV',
    Ru = 'RU',
}

export type LeaveCommunityInput = {
    communityId: Scalars['String'];
};

export type LeaveCommunityPayload = {
    __typename?: 'LeaveCommunityPayload';
    community: Community;
};

export type LikePostInput = {
    postId: Scalars['String'];
};

export type LikePostPayload = {
    __typename?: 'LikePostPayload';
    likeCount: Scalars['Int'];
};

export type LoginPayload = {
    __typename?: 'LoginPayload';
    accessToken: Scalars['String'];
    matrixAuth?: Maybe<MatrixAuthData>;
};

export type Manager = RelayNode & {
    __typename?: 'Manager';
    avatarId?: Maybe<Scalars['String']>;
    coverPhotoId?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    type: ManagerType;
    user: User;
    userId: Scalars['String'];
};

export type ManagerConnection = {
    __typename?: 'ManagerConnection';
    edges: Array<ManagerEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type ManagerEdge = {
    __typename?: 'ManagerEdge';
    node: Manager;
};

export enum ManagerType {
    Admin = 'ADMIN',
    BranchManager = 'BRANCH_MANAGER',
    OperationalManager = 'OPERATIONAL_MANAGER',
    PartnerManager = 'PARTNER_MANAGER',
}

export enum MaritalStatus {
    HavePartner = 'HAVE_PARTNER',
    Married = 'MARRIED',
    Single = 'SINGLE',
}

export type MatrixAuthData = {
    __typename?: 'MatrixAuthData';
    access_token: Scalars['String'];
    device_id: Scalars['String'];
    home_server: Scalars['String'];
    user_id: Scalars['String'];
};

export type Member = RelayNode & {
    __typename?: 'Member';
    cityOfResidence: Scalars['String'];
    communities: CommunityConnection;
    countryOfResidence: Country;
    countryOfResidenceId: Scalars['String'];
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    maritalStatus?: Maybe<MaritalStatus>;
    profiles: MemberProfileConnection;
    status: MemberStatus;
    updatedAt: Scalars['DateTime'];
    user: User;
    userId: Scalars['String'];
    version: Scalars['Int'];
};

export type MemberConnection = {
    __typename?: 'MemberConnection';
    edges: Array<MemberEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type MemberEdge = {
    __typename?: 'MemberEdge';
    node: Member;
};

export enum MemberOrderBy {
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
}

export type MemberProfile = RelayNode & {
    __typename?: 'MemberProfile';
    avatarId?: Maybe<Scalars['String']>;
    bio?: Maybe<Scalars['String']>;
    cityOfResidence: Scalars['String'];
    countryOfResidence: Country;
    countryOfResidenceId: Scalars['String'];
    dateOfBirth: Scalars['DateTime'];
    email: Scalars['String'];
    firstName: Scalars['String'];
    gender?: Maybe<Gender>;
    id: Scalars['ID'];
    lastName: Scalars['String'];
    maritalStatus?: Maybe<MaritalStatus>;
    memberId: Scalars['String'];
    middleName?: Maybe<Scalars['String']>;
    phoneNumber: Scalars['String'];
};

export type MemberProfileConnection = {
    __typename?: 'MemberProfileConnection';
    edges: Array<MemberProfileEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type MemberProfileEdge = {
    __typename?: 'MemberProfileEdge';
    node: MemberProfile;
};

export enum MemberStatus {
    Approved = 'APPROVED',
    Guest = 'GUEST',
    PendingApproval = 'PENDING_APPROVAL',
    PendingPayment = 'PENDING_PAYMENT',
}

export type MemberWhereInput = {
    keyword?: Maybe<Scalars['String']>;
};

export type Mutation = {
    __typename?: 'Mutation';
    acceptFollow: AcceptFollowPayload;
    acceptMembershipRequest: AcceptMembershipRequestPayload;
    activateAccount: ActivateAccountPayload;
    addPaymentMethod: AddPaymentMethodPayload;
    bookmarkPost: BookmarkPostPayload;
    cancelFollow: Scalars['Boolean'];
    cancelMembershipRequest: CancelMembershipRequestPayload;
    cancelSubscription: Scalars['Boolean'];
    changePassword: ChangePasswordPayload;
    createCommunity: CreateCommunityPayload;
    createCommunityInvitation: CreateCommunityInvitationPayload;
    createEvent: CreateEventPayload;
    createExperience: CreateEventPayload;
    createMemberProfile: CreateMemberProfilePayload;
    createPost: CreatePostPayload;
    createPostComment: CreatePostCommentPayload;
    deleteCommunity: DeleteCommunityPayload;
    deleteCommunityImage: DeleteCommunityImagePayload;
    deleteCommunityInvitation: DeleteCommunityInvitationPayload;
    deleteEvent: DeleteEventPayload;
    deleteEventImage: DeleteEventImagePayload;
    deleteManager: DeleteManagerPayload;
    deleteMemberProfile: MemberProfile;
    deletePaymentMethod: Scalars['String'];
    deletePost: DeletePostPayload;
    deletePostComment: DeletePostCommentPayload;
    deleteUserImage: Scalars['String'];
    dislikePost: DislikePostPayload;
    eventPayment: EventPaymentPayload;
    followUser: FollowUserPayload;
    joinCommunity: JoinCommunityPayload;
    leaveCommunity: LeaveCommunityPayload;
    likePost: LikePostPayload;
    login: LoginPayload;
    registerManager: Manager;
    registerMatrixUser: MatrixAuthData;
    registerMember: Scalars['String'];
    rejectFollow: RejectFollowPayload;
    rejectMembershipRequest: RejectMembershipRequestPayload;
    removeFollower: Scalars['Boolean'];
    reorderCommunityImages: ReorderCommunityImagesPayload;
    reorderEventImages: ReorderEventImagesPayload;
    resetPassword: ResetPasswordPayload;
    sendPasswordResetEmail: ForgotPasswordPayload;
    sendVerificationEmail: SendVerifyEmailPayload;
    setDefaultPaymentMethod: SetDefaultPaymentMethodPayload;
    sharePost: SharePostPayload;
    subscription: SubscriptionPayload;
    togglePin: TogglePinEventPayload;
    unfollow: Scalars['Boolean'];
    updateCommunity: UpdateCommunityPayload;
    updateEvent: UpdateEventPayload;
    updateManager: UpdateManagerPayload;
    updateMember: UpdateMemberPayload;
    updateMemberProfile: UpdateMemberProfilePayload;
    updatePost: UpdatePostPayload;
    uploadCommunityImages: UploadCommunityImagesPayload;
    uploadCommunityLogo: UploadCommunityLogoPayload;
    uploadEventImages: UploadEventImagesPayload;
    uploadUserAvatar: UploadUserAvatarPayload;
    uploadUserCoverPhoto: UploadUserCoverPhotoPayload;
    uploadUserImages: UploadUserImagesPayload;
};

export type MutationAcceptFollowArgs = {
    input: AcceptFollowInput;
};

export type MutationAcceptMembershipRequestArgs = {
    input: AcceptMembershipRequestInput;
};

export type MutationActivateAccountArgs = {
    input: ActivateAccountInput;
};

export type MutationAddPaymentMethodArgs = {
    input: AddPaymentMethodInput;
};

export type MutationBookmarkPostArgs = {
    post: BookmarkPostInput;
};

export type MutationCancelFollowArgs = {
    input: CancelFollowInput;
};

export type MutationCancelMembershipRequestArgs = {
    input: CancelMembershipRequestInput;
};

export type MutationChangePasswordArgs = {
    input: ChangePasswordInput;
};

export type MutationCreateCommunityArgs = {
    community: CreateCommunityInput;
};

export type MutationCreateCommunityInvitationArgs = {
    input: CreateCommunityInvitationInput;
};

export type MutationCreateEventArgs = {
    event: CreateEventInput;
};

export type MutationCreateExperienceArgs = {
    experience: CreateEventInput;
};

export type MutationCreateMemberProfileArgs = {
    memberProfile: CreateMemberProfileInput;
};

export type MutationCreatePostArgs = {
    post: CreatePostInput;
};

export type MutationCreatePostCommentArgs = {
    comment: CreatePostCommentInput;
};

export type MutationDeleteCommunityArgs = {
    id: Scalars['String'];
};

export type MutationDeleteCommunityImageArgs = {
    community: DeleteCommunityImageInput;
};

export type MutationDeleteCommunityInvitationArgs = {
    input: DeleteCommunityInvitationyInput;
};

export type MutationDeleteEventArgs = {
    id: Scalars['String'];
};

export type MutationDeleteEventImageArgs = {
    event: DeleteEventImageInput;
};

export type MutationDeleteManagerArgs = {
    input: DeleteManagerInput;
};

export type MutationDeleteMemberProfileArgs = {
    id: Scalars['String'];
};

export type MutationDeletePaymentMethodArgs = {
    id: Scalars['String'];
};

export type MutationDeletePostArgs = {
    id: Scalars['String'];
};

export type MutationDeletePostCommentArgs = {
    id: Scalars['String'];
};

export type MutationDeleteUserImageArgs = {
    input: DeleteUserImageInput;
};

export type MutationDislikePostArgs = {
    post: DislikePostInput;
};

export type MutationEventPaymentArgs = {
    input: EventPaymentInput;
};

export type MutationFollowUserArgs = {
    input: FollowUserInput;
};

export type MutationJoinCommunityArgs = {
    input: JoinCommunityInput;
};

export type MutationLeaveCommunityArgs = {
    input: LeaveCommunityInput;
};

export type MutationLikePostArgs = {
    post: LikePostInput;
};

export type MutationLoginArgs = {
    credentials: CredentialsInput;
};

export type MutationRegisterManagerArgs = {
    manager: RegisterManagerInput;
};

export type MutationRegisterMatrixUserArgs = {
    input: RegisterMatrixUserInput;
};

export type MutationRegisterMemberArgs = {
    member: RegisterMemberInput;
};

export type MutationRejectFollowArgs = {
    input: RejectFollowInput;
};

export type MutationRejectMembershipRequestArgs = {
    input: RejectMembershipRequestInput;
};

export type MutationRemoveFollowerArgs = {
    input: RemoveFollowerInput;
};

export type MutationReorderCommunityImagesArgs = {
    community: ReorderCommunityImagesInput;
};

export type MutationReorderEventImagesArgs = {
    event: ReorderEventImagesInput;
};

export type MutationResetPasswordArgs = {
    input: ResetPasswordInput;
};

export type MutationSendPasswordResetEmailArgs = {
    input: ForgotPasswordInput;
};

export type MutationSendVerificationEmailArgs = {
    input: SendVerifyEmailInput;
};

export type MutationSetDefaultPaymentMethodArgs = {
    paymentMethodId: Scalars['String'];
};

export type MutationSharePostArgs = {
    post: SharePostInput;
};

export type MutationSubscriptionArgs = {
    input: SubscriptionInput;
};

export type MutationTogglePinArgs = {
    event: TogglePinEventInput;
};

export type MutationUnfollowArgs = {
    input: UnfollowInput;
};

export type MutationUpdateCommunityArgs = {
    community: UpdateCommunityInput;
};

export type MutationUpdateEventArgs = {
    event: UpdateEventInput;
};

export type MutationUpdateManagerArgs = {
    manager: UpdateManagerInput;
};

export type MutationUpdateMemberArgs = {
    member: UpdateMemberInput;
};

export type MutationUpdateMemberProfileArgs = {
    memberProfile: UpdateMemberProfileInput;
};

export type MutationUpdatePostArgs = {
    post: UpdatePostInput;
};

export type MutationUploadCommunityImagesArgs = {
    community: UploadCommunityImagesInput;
};

export type MutationUploadCommunityLogoArgs = {
    community: UploadCommunityLogoInput;
};

export type MutationUploadEventImagesArgs = {
    event: UploadEventImagesInput;
};

export type MutationUploadUserAvatarArgs = {
    input: UploadUserAvatarInput;
};

export type MutationUploadUserCoverPhotoArgs = {
    input: UploadUserCoverPhotoInput;
};

export type MutationUploadUserImagesArgs = {
    input: UploadUserImagesInput;
};

export type PageInfo = {
    __typename?: 'PageInfo';
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    page: Scalars['Int'];
    totalPages: Scalars['Int'];
};

export type PaymentMethod = {
    __typename?: 'PaymentMethod';
    brand: Scalars['String'];
    cartToken: Scalars['String'];
    expMonth: Scalars['Int'];
    expYear: Scalars['Int'];
    funding: Scalars['String'];
    id: Scalars['String'];
    isDefault: Scalars['Boolean'];
    lastFour: Scalars['String'];
};

export type PaymentMethodConnection = {
    __typename?: 'PaymentMethodConnection';
    edges: Array<PaymentMethodEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type PaymentMethodEdge = {
    __typename?: 'PaymentMethodEdge';
    node: PaymentMethod;
};

export enum PendingMembershipOrderBy {
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
}

export type PendingMembershipWhereInput = {
    communityId: Scalars['String'];
};

export type Plan = {
    __typename?: 'Plan';
    benefits?: Maybe<Array<Scalars['String']>>;
    code: Scalars['String'];
    id: Scalars['String'];
    price: Scalars['Float'];
};

export type PlanConnection = {
    __typename?: 'PlanConnection';
    edges: Array<PlanEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type PlanEdge = {
    __typename?: 'PlanEdge';
    node: Plan;
};

export type Post = RelayNode & {
    __typename?: 'Post';
    author: User;
    authorId: Scalars['String'];
    comments: PostCommentConnection;
    community: Community;
    communityId: Scalars['String'];
    createdAt: Scalars['DateTime'];
    files: FileInfoConnection;
    id: Scalars['ID'];
    isBookmarked?: Maybe<Scalars['Boolean']>;
    isLiked: Scalars['Boolean'];
    likeCount: Scalars['Int'];
    likes: PostLikeConnection;
    meta: PostMetaUnion;
    repostCount: Scalars['Int'];
    topComments: PostCommentConnection;
    translations: PostTranslationConnection;
    type: PostType;
    updatedAt: Scalars['DateTime'];
    visibility: PostVisibility;
};

export type PostCommentsArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
};

export type PostLikesArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
};

export type PostComment = RelayNode & {
    __typename?: 'PostComment';
    author: User;
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    postId: Scalars['String'];
    text: Scalars['String'];
    updatedAt: Scalars['DateTime'];
    userId: Scalars['String'];
};

export type PostCommentConnection = {
    __typename?: 'PostCommentConnection';
    edges: Array<PostCommentEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type PostCommentEdge = {
    __typename?: 'PostCommentEdge';
    node: PostComment;
};

export type PostConnection = {
    __typename?: 'PostConnection';
    edges: Array<PostEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type PostEdge = {
    __typename?: 'PostEdge';
    node: Post;
};

export type PostLike = RelayNode & {
    __typename?: 'PostLike';
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    postId: Scalars['String'];
    updatedAt: Scalars['DateTime'];
    user: User;
    userId: Scalars['String'];
    version: Scalars['Int'];
};

export type PostLikeConnection = {
    __typename?: 'PostLikeConnection';
    edges: Array<PostLikeEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type PostLikeEdge = {
    __typename?: 'PostLikeEdge';
    node: PostLike;
};

export type PostMetaUnion =
    | CoverPhotoChangeMeta
    | DefaultPostMeta
    | EventMeta
    | ExperienceMeta
    | ProfilePictureChangeMeta
    | RepostMeta;

export type PostTranslation = RelayNode & {
    __typename?: 'PostTranslation';
    body?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    lang: LanguageCode;
    postId: Scalars['String'];
};

export type PostTranslationConnection = {
    __typename?: 'PostTranslationConnection';
    edges: Array<PostTranslationEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type PostTranslationEdge = {
    __typename?: 'PostTranslationEdge';
    node: PostTranslation;
};

export enum PostType {
    CoverPhotoChange = 'COVER_PHOTO_CHANGE',
    Default = 'DEFAULT',
    Event = 'EVENT',
    Experience = 'EXPERIENCE',
    ProfilePictureChange = 'PROFILE_PICTURE_CHANGE',
    Repost = 'REPOST',
}

export enum PostVisibility {
    Followers = 'FOLLOWERS',
    Members = 'MEMBERS',
    Private = 'PRIVATE',
    Public = 'PUBLIC',
}

export type PostWhereInput = {
    communityId: Scalars['String'];
};

export type ProfilePictureChangeMeta = {
    __typename?: 'ProfilePictureChangeMeta';
    profilePictureUrl: Scalars['String'];
    type: PostType;
    user: User;
    userId: Scalars['String'];
};

export type Query = {
    __typename?: 'Query';
    activities: ActivityConnection;
    activityCount: Scalars['Int'];
    approvedMembers?: Maybe<UserConnection>;
    bookmarkedPosts: PostConnection;
    chatableUsers: UserConnection;
    cities: CityConnection;
    communities: CommunityConnection;
    community: Community;
    communityInvitation: CommunityInvitation;
    communityMembers: MemberConnection;
    communityPartners: CommunityConnection;
    countries: CountryConnection;
    dasboards: ReportConnection;
    event: Event;
    events: EventConnection;
    experience: Event;
    experiences: EventConnection;
    featuredEvents: EventConnection;
    fileInfo: FileInfo;
    followRequests: FollowConnection;
    followerCount: Scalars['Int'];
    followers: UserConnection;
    followingCount: Scalars['Int'];
    followings: UserConnection;
    guests?: Maybe<UserConnection>;
    me?: Maybe<User>;
    member?: Maybe<Member>;
    members?: Maybe<MemberConnection>;
    pastEvents: EventConnection;
    paymentMethods: PaymentMethodConnection;
    pendingMemberships: CommunityMembershipConnection;
    peopleAvailableToChat: UserConnection;
    plans: PlanConnection;
    post: Post;
    posts: PostConnection;
    reports: ReportConnection;
    repostedUsers: UserConnection;
    upcomingEvents: EventConnection;
    user: User;
    userPosts: PostConnection;
    users: UserConnection;
};

export type QueryActivitiesArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
};

export type QueryApprovedMembersArgs = {
    orderBy?: Maybe<MemberOrderBy>;
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<MemberWhereInput>;
};

export type QueryBookmarkedPostsArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where: PostWhereInput;
};

export type QueryChatableUsersArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<ChatableUsersWhereInput>;
};

export type QueryCitiesArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
};

export type QueryCommunitiesArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<CommunityWhereInput>;
};

export type QueryCommunityArgs = {
    id: Scalars['String'];
};

export type QueryCommunityInvitationArgs = {
    id: Scalars['String'];
};

export type QueryCommunityMembersArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where: CommunityMembersWhereInput;
};

export type QueryCommunityPartnersArgs = {
    orderBy?: Maybe<CommunityPartnersOrderBy>;
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where: CommunityPartnersWhereInput;
};

export type QueryCountriesArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
};

export type QueryEventArgs = {
    id: Scalars['String'];
};

export type QueryEventsArgs = {
    orderBy?: Maybe<EventOrderBy>;
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<EventWhereInput>;
};

export type QueryExperienceArgs = {
    id: Scalars['String'];
};

export type QueryExperiencesArgs = {
    orderBy?: Maybe<EventOrderBy>;
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<EventWhereInput>;
};

export type QueryFileInfoArgs = {
    id: Scalars['String'];
};

export type QueryFollowRequestsArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<FollowWhereInput>;
};

export type QueryFollowerCountArgs = {
    where?: Maybe<FollowWhereInput>;
};

export type QueryFollowersArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<FollowWhereInput>;
};

export type QueryFollowingCountArgs = {
    where?: Maybe<FollowWhereInput>;
};

export type QueryFollowingsArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<FollowWhereInput>;
};

export type QueryGuestsArgs = {
    orderBy?: Maybe<MemberOrderBy>;
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<MemberWhereInput>;
};

export type QueryMemberArgs = {
    id: Scalars['String'];
};

export type QueryMembersArgs = {
    orderBy?: Maybe<MemberOrderBy>;
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<MemberWhereInput>;
};

export type QueryPastEventsArgs = {
    orderBy?: Maybe<EventOrderBy>;
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<EventWhereInput>;
};

export type QueryPendingMembershipsArgs = {
    orderBy?: Maybe<PendingMembershipOrderBy>;
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where: PendingMembershipWhereInput;
};

export type QueryPeopleAvailableToChatArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<UserWhereInput>;
};

export type QueryPlansArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
};

export type QueryPostArgs = {
    id: Scalars['String'];
};

export type QueryPostsArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where: PostWhereInput;
};

export type QueryRepostedUsersArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where: RepostedUserWhereInput;
};

export type QueryUpcomingEventsArgs = {
    orderBy?: Maybe<EventOrderBy>;
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<EventWhereInput>;
};

export type QueryUserArgs = {
    id: Scalars['String'];
};

export type QueryUserPostsArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    userId: Scalars['String'];
    where: PostWhereInput;
};

export type QueryUsersArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<UserWhereInput>;
};

export type RegisterManagerInput = {
    communityId: Scalars['String'];
    email: Scalars['String'];
    firstName: Scalars['String'];
    incognito: Scalars['Boolean'];
    lastName: Scalars['String'];
    nickname: Scalars['String'];
    password: Scalars['String'];
    phoneNumber: Scalars['String'];
};

export type RegisterMatrixUserInput = {
    nickname: Scalars['String'];
    password: Scalars['String'];
};

export type RegisterMemberInput = {
    cityOfResidence: Scalars['String'];
    countryOfResidenceId: Scalars['String'];
    dateOfBirth?: Maybe<Scalars['DateTime']>;
    email: Scalars['String'];
    firstName: Scalars['String'];
    gender?: Maybe<Gender>;
    incognito: Scalars['Boolean'];
    invitationId?: Maybe<Scalars['String']>;
    lastName: Scalars['String'];
    nickname: Scalars['String'];
    password: Scalars['String'];
    phoneNumber: Scalars['String'];
};

export type RejectFollowInput = {
    followerId: Scalars['String'];
};

export type RejectFollowPayload = {
    __typename?: 'RejectFollowPayload';
    follow: Follow;
};

export type RejectMembershipRequestInput = {
    rejectReason?: Maybe<Scalars['String']>;
    requestId: Scalars['String'];
};

export type RejectMembershipRequestPayload = {
    __typename?: 'RejectMembershipRequestPayload';
    id: Scalars['String'];
};

export type RelayNode = {
    id: Scalars['ID'];
};

export type RemoveFollowerInput = {
    followerId: Scalars['String'];
};

export type ReorderCommunityImagesInput = {
    communityId: Scalars['String'];
    images: Array<CommunityImageOrderInput>;
};

export type ReorderCommunityImagesPayload = {
    __typename?: 'ReorderCommunityImagesPayload';
    community: Community;
};

export type ReorderEventImagesInput = {
    eventId: Scalars['String'];
    images: Array<EventImageOrderInput>;
};

export type ReorderEventImagesPayload = {
    __typename?: 'ReorderEventImagesPayload';
    event: Event;
};

export type Report = RelayNode & {
    __typename?: 'Report';
    code: Scalars['String'];
    embedUrl: Scalars['String'];
    id: Scalars['ID'];
};

export type ReportConnection = {
    __typename?: 'ReportConnection';
    edges: Array<ReportEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type ReportEdge = {
    __typename?: 'ReportEdge';
    node: Report;
};

export type RepostMeta = {
    __typename?: 'RepostMeta';
    post: Post;
    postId: Scalars['String'];
    type: PostType;
};

export type RepostedUserWhereInput = {
    postId: Scalars['String'];
};

export type ResetPasswordInput = {
    password: Scalars['String'];
    token: Scalars['String'];
};

export type ResetPasswordPayload = {
    __typename?: 'ResetPasswordPayload';
    success: Scalars['Boolean'];
};

export type Role = RelayNode & {
    __typename?: 'Role';
    id: Scalars['ID'];
    name: Scalars['String'];
};

export type SendVerifyEmailInput = {
    email: Scalars['String'];
};

export type SendVerifyEmailPayload = {
    __typename?: 'SendVerifyEmailPayload';
    sent: Scalars['Boolean'];
};

export type SetDefaultPaymentMethodPayload = {
    __typename?: 'SetDefaultPaymentMethodPayload';
    paymentMethodId: Scalars['String'];
};

export type SharePostInput = {
    communityId: Scalars['String'];
    files?: Maybe<Array<Scalars['Upload']>>;
    postId: Scalars['String'];
    translations: Array<CreatePostTranslationInput>;
    visibility: PostVisibility;
};

export type SharePostPayload = {
    __typename?: 'SharePostPayload';
    post: Post;
};

export type SubscriptionEdge = {
    __typename?: 'SubscriptionEdge';
    node: SubscriptionNode;
};

export type SubscriptionInput = {
    paymentMethodId: Scalars['String'];
    planId: Scalars['String'];
};

export type SubscriptionNode = {
    __typename?: 'SubscriptionNode';
    expiresAt: Scalars['DateTime'];
    id: Scalars['String'];
    plan?: Maybe<Plan>;
    planId: Scalars['String'];
    status: SubscriptionStatus;
    userId: Scalars['String'];
};

export type SubscriptionPayload = {
    __typename?: 'SubscriptionPayload';
    result: Scalars['Boolean'];
};

export enum SubscriptionStatus {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE',
}

export type TogglePinEventInput = {
    eventId: Scalars['String'];
    pinned: Scalars['Boolean'];
};

export type TogglePinEventPayload = {
    __typename?: 'TogglePinEventPayload';
    event: Event;
};

export type UnfollowInput = {
    followingUserId: Scalars['String'];
};

export type UpdateCommunityInput = {
    benefits?: Maybe<Array<BenefitInput>>;
    category: CommunityCategory;
    cityId: Scalars['String'];
    id: Scalars['String'];
    isInternal: Scalars['Boolean'];
    joinRule?: Maybe<JoinRule>;
    partnerIds?: Maybe<Array<Scalars['String']>>;
    translations: Array<UpdateCommunityTranslationInput>;
};

export type UpdateCommunityPayload = {
    __typename?: 'UpdateCommunityPayload';
    community: Community;
};

export type UpdateCommunityTranslationInput = {
    description?: Maybe<Scalars['String']>;
    lang: LanguageCode;
    location: Scalars['String'];
    name: Scalars['String'];
};

export type UpdateEventInput = {
    communityId: Scalars['String'];
    externalLink?: Maybe<Scalars['String']>;
    id: Scalars['String'];
    isDraft: Scalars['Boolean'];
    isExternal: Scalars['Boolean'];
    price?: Maybe<Scalars['Float']>;
    startTime?: Maybe<Scalars['DateTime']>;
    tags?: Maybe<Array<Scalars['String']>>;
    translations: Array<UpdateEventTranslationInput>;
};

export type UpdateEventPayload = {
    __typename?: 'UpdateEventPayload';
    event: Event;
};

export type UpdateEventTranslationInput = {
    address?: Maybe<Scalars['String']>;
    description: Scalars['String'];
    lang: LanguageCode;
    title: Scalars['String'];
};

export type UpdateManagerInput = {
    bio?: Maybe<Scalars['String']>;
    dateOfBirth?: Maybe<Scalars['DateTime']>;
    firstName: Scalars['String'];
    gender?: Maybe<Scalars['String']>;
    incognito: Scalars['Boolean'];
    lastName: Scalars['String'];
    nickname: Scalars['String'];
    phoneNumber: Scalars['String'];
};

export type UpdateManagerPayload = {
    __typename?: 'UpdateManagerPayload';
    manager: Manager;
};

export type UpdateMemberInput = {
    bio?: Maybe<Scalars['String']>;
    cityOfResidence: Scalars['String'];
    countryOfResidenceId: Scalars['String'];
    dateOfBirth?: Maybe<Scalars['DateTime']>;
    firstName: Scalars['String'];
    gender?: Maybe<Gender>;
    incognito: Scalars['Boolean'];
    lastName: Scalars['String'];
    maritalStatus?: Maybe<MaritalStatus>;
    nickname?: Maybe<Scalars['String']>;
    phoneNumber: Scalars['String'];
};

export type UpdateMemberPayload = {
    __typename?: 'UpdateMemberPayload';
    member: Member;
};

export type UpdateMemberProfileInput = {
    bio?: Maybe<Scalars['String']>;
    cityOfResidence: Scalars['String'];
    countryOfResidenceId: Scalars['String'];
    dateOfBirth: Scalars['DateTime'];
    email: Scalars['String'];
    firstName: Scalars['String'];
    gender?: Maybe<Gender>;
    id: Scalars['String'];
    lastName: Scalars['String'];
    maritalStatus?: Maybe<MaritalStatus>;
    phoneNumber: Scalars['String'];
};

export type UpdateMemberProfilePayload = {
    __typename?: 'UpdateMemberProfilePayload';
    memberProfile: MemberProfile;
};

export type UpdatePostInput = {
    communityId: Scalars['String'];
    files?: Maybe<Array<Scalars['Upload']>>;
    id: Scalars['String'];
    previousFileIds?: Maybe<Array<Scalars['String']>>;
    translations: Array<UpdatePostTranslationInput>;
    visibility: PostVisibility;
};

export type UpdatePostPayload = {
    __typename?: 'UpdatePostPayload';
    post: Post;
};

export type UpdatePostTranslationInput = {
    body?: Maybe<Scalars['String']>;
    lang: LanguageCode;
};

export type UploadCommunityImagesInput = {
    communityId: Scalars['String'];
    images: Array<Scalars['Upload']>;
};

export type UploadCommunityImagesPayload = {
    __typename?: 'UploadCommunityImagesPayload';
    community: Community;
};

export type UploadCommunityLogoInput = {
    communityId: Scalars['String'];
    logo: Scalars['Upload'];
};

export type UploadCommunityLogoPayload = {
    __typename?: 'UploadCommunityLogoPayload';
    community: Community;
};

export type UploadEventImagesInput = {
    eventId: Scalars['String'];
    images: Array<Scalars['Upload']>;
};

export type UploadEventImagesPayload = {
    __typename?: 'UploadEventImagesPayload';
    event: Event;
};

export type UploadUserAvatarInput = {
    avatar: Scalars['Upload'];
};

export type UploadUserAvatarPayload = {
    __typename?: 'UploadUserAvatarPayload';
    user: User;
};

export type UploadUserCoverPhotoInput = {
    coverPhoto: Scalars['Upload'];
};

export type UploadUserCoverPhotoPayload = {
    __typename?: 'UploadUserCoverPhotoPayload';
    user: User;
};

export type UploadUserImagesInput = {
    images: Array<Scalars['Upload']>;
};

export type UploadUserImagesPayload = {
    __typename?: 'UploadUserImagesPayload';
    user: User;
};

export type User = RelayNode & {
    __typename?: 'User';
    avatar?: Maybe<FileInfo>;
    bio?: Maybe<Scalars['String']>;
    communities: CommunityConnection;
    coverPhoto?: Maybe<FileInfo>;
    dateOfBirth?: Maybe<Scalars['DateTime']>;
    email: Scalars['String'];
    firstName: Scalars['String'];
    followStatus: FollowStatus;
    fullName: Scalars['String'];
    gender?: Maybe<Gender>;
    id: Scalars['ID'];
    images: FileInfoConnection;
    incognito?: Maybe<Scalars['Boolean']>;
    isChatable: Scalars['Boolean'];
    lastName: Scalars['String'];
    manager?: Maybe<Manager>;
    matrixAuth?: Maybe<MatrixAuthData>;
    member?: Maybe<Member>;
    nickname?: Maybe<Scalars['String']>;
    phoneNumber?: Maybe<Scalars['String']>;
    postCount?: Maybe<Scalars['Int']>;
    role: Role;
    statusText?: Maybe<Scalars['String']>;
    subscription?: Maybe<SubscriptionNode>;
};

export type UserConnection = {
    __typename?: 'UserConnection';
    edges: Array<UserEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type UserEdge = {
    __typename?: 'UserEdge';
    node: User;
};

export type UserWhereInput = {
    communityId?: Maybe<Scalars['String']>;
    firstName?: Maybe<Scalars['String']>;
    keyword?: Maybe<Scalars['String']>;
    lastName?: Maybe<Scalars['String']>;
    nickName?: Maybe<Scalars['String']>;
};

export type AvatarPartsFragment = { __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'> & {
        blurhash?: Maybe<{ __typename?: 'BlurhashMeta' } & Pick<BlurhashMeta, 'hash'>>;
    };

export type EventPartsFragment = { __typename?: 'Event' } & Pick<
    Event,
    | 'id'
    | 'createdById'
    | 'communityId'
    | 'startTime'
    | 'tags'
    | 'type'
    | 'pinned'
    | 'price'
    | 'isExternal'
    | 'externalLink'
    | 'isDraft'
    | 'purchased'
> & {
        translations: { __typename?: 'EventTranslationConnection' } & {
            edges: Array<
                { __typename?: 'EventTranslationEdge' } & {
                    node: { __typename?: 'EventTranslation' } & Pick<
                        EventTranslation,
                        'id' | 'lang' | 'title' | 'description' | 'address'
                    >;
                }
            >;
        };
        images: { __typename?: 'EventImageConnection' } & {
            edges: Array<
                { __typename?: 'EventImageEdge' } & {
                    node: { __typename?: 'EventImage' } & Pick<EventImage, 'id' | 'order'> & {
                            image: { __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url' | 'name' | 'extension'> & {
                                    blurhash?: Maybe<
                                        { __typename?: 'BlurhashMeta' } & Pick<
                                            BlurhashMeta,
                                            'hash' | 'width' | 'height'
                                        >
                                    >;
                                };
                        };
                }
            >;
        };
    };

export type ExperiencePartsFragment = { __typename?: 'Event' } & Pick<
    Event,
    | 'id'
    | 'createdById'
    | 'communityId'
    | 'startTime'
    | 'tags'
    | 'price'
    | 'isExternal'
    | 'isDraft'
    | 'externalLink'
    | 'purchased'
    | 'pinned'
> & {
        translations: { __typename?: 'EventTranslationConnection' } & {
            edges: Array<
                { __typename?: 'EventTranslationEdge' } & {
                    node: { __typename?: 'EventTranslation' } & Pick<
                        EventTranslation,
                        'id' | 'lang' | 'title' | 'description' | 'address'
                    >;
                }
            >;
        };
        images: { __typename?: 'EventImageConnection' } & {
            edges: Array<
                { __typename?: 'EventImageEdge' } & {
                    node: { __typename?: 'EventImage' } & Pick<EventImage, 'id' | 'order'> & {
                            image: { __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url' | 'name' | 'extension'> & {
                                    blurhash?: Maybe<
                                        { __typename?: 'BlurhashMeta' } & Pick<
                                            BlurhashMeta,
                                            'hash' | 'width' | 'height'
                                        >
                                    >;
                                };
                        };
                }
            >;
        };
    };

export type MemberProfilePartsFragment = { __typename?: 'MemberProfile' } & Pick<
    MemberProfile,
    | 'id'
    | 'firstName'
    | 'lastName'
    | 'email'
    | 'bio'
    | 'phoneNumber'
    | 'dateOfBirth'
    | 'cityOfResidence'
    | 'countryOfResidenceId'
    | 'maritalStatus'
> & { countryOfResidence: { __typename?: 'Country' } & Pick<Country, 'id' | 'name'> };

export type PostPartsFragment = { __typename?: 'Post' } & Pick<
    Post,
    | 'id'
    | 'communityId'
    | 'repostCount'
    | 'likeCount'
    | 'isLiked'
    | 'isBookmarked'
    | 'visibility'
    | 'type'
    | 'createdAt'
    | 'authorId'
> & {
        translations: { __typename?: 'PostTranslationConnection' } & {
            edges: Array<
                { __typename?: 'PostTranslationEdge' } & {
                    node: { __typename?: 'PostTranslation' } & Pick<PostTranslation, 'id' | 'lang' | 'body'>;
                }
            >;
        };
        files: { __typename?: 'FileInfoConnection' } & {
            edges: Array<
                { __typename?: 'FileInfoEdge' } & {
                    node: { __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'name' | 'url' | 'extension'> & {
                            blurhash?: Maybe<
                                { __typename?: 'BlurhashMeta' } & Pick<BlurhashMeta, 'hash' | 'width' | 'height'>
                            >;
                        };
                }
            >;
        };
        comments: { __typename?: 'PostCommentConnection' } & Pick<PostCommentConnection, 'totalCount'> & {
                edges: Array<
                    { __typename?: 'PostCommentEdge' } & {
                        node: { __typename?: 'PostComment' } & Pick<PostComment, 'id' | 'text' | 'createdAt'> & {
                                author: { __typename?: 'User' } & Pick<User, 'id' | 'fullName' | 'statusText'> & {
                                        role: { __typename?: 'Role' } & Pick<Role, 'id' | 'name'>;
                                        avatar?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
                                        member?: Maybe<{ __typename?: 'Member' } & Pick<Member, 'id'>>;
                                    };
                            };
                    }
                >;
                pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'hasNextPage'>;
            };
        topComments: { __typename?: 'PostCommentConnection' } & {
            edges: Array<
                { __typename?: 'PostCommentEdge' } & {
                    node: { __typename?: 'PostComment' } & Pick<PostComment, 'id' | 'text' | 'userId' | 'createdAt'> & {
                            author: { __typename?: 'User' } & Pick<User, 'id' | 'fullName' | 'email' | 'statusText'> & {
                                    role: { __typename?: 'Role' } & Pick<Role, 'id' | 'name'>;
                                    avatar?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
                                    member?: Maybe<{ __typename?: 'Member' } & Pick<Member, 'id'>>;
                                };
                        };
                }
            >;
        };
        author: { __typename?: 'User' } & Pick<User, 'id' | 'fullName' | 'statusText'> & {
                avatar?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
                role: { __typename?: 'Role' } & Pick<Role, 'id' | 'name'>;
                member?: Maybe<{ __typename?: 'Member' } & Pick<Member, 'id'>>;
            };
        meta:
            | ({ __typename?: 'CoverPhotoChangeMeta' } & Pick<CoverPhotoChangeMeta, 'coverPhotoUrl'>)
            | ({ __typename?: 'DefaultPostMeta' } & Pick<DefaultPostMeta, 'type'>)
            | ({ __typename?: 'EventMeta' } & Pick<EventMeta, 'eventId'> & {
                      event: { __typename?: 'Event' } & EventPartsFragment;
                  })
            | ({ __typename?: 'ExperienceMeta' } & Pick<ExperienceMeta, 'experienceId' | 'type'> & {
                      experience: { __typename?: 'Event' } & ExperiencePartsFragment;
                  })
            | ({ __typename?: 'ProfilePictureChangeMeta' } & Pick<ProfilePictureChangeMeta, 'profilePictureUrl'>)
            | { __typename?: 'RepostMeta' };
    };

export type UserPartsFragment = { __typename?: 'User' } & Pick<
    User,
    'id' | 'fullName' | 'postCount' | 'bio' | 'followStatus' | 'statusText' | 'email' | 'isChatable'
> & {
        role: { __typename?: 'Role' } & Pick<Role, 'id' | 'name'>;
        avatar?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
        coverPhoto?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
        member?: Maybe<{ __typename?: 'Member' } & Pick<Member, 'id'>>;
        matrixAuth?: Maybe<{ __typename?: 'MatrixAuthData' } & Pick<MatrixAuthData, 'user_id'>>;
    };

export type ActivateAccountMutationVariables = Exact<{
    input: ActivateAccountInput;
}>;

export type ActivateAccountMutation = { __typename?: 'Mutation' } & {
    activateAccount: { __typename?: 'ActivateAccountPayload' } & Pick<ActivateAccountPayload, 'success'>;
};

export type ChangePasswordMutationVariables = Exact<{
    input: ChangePasswordInput;
}>;

export type ChangePasswordMutation = { __typename?: 'Mutation' } & {
    changePassword: { __typename?: 'ChangePasswordPayload' } & Pick<ChangePasswordPayload, 'success'>;
};

export type LoginMutationVariables = Exact<{
    credentials: CredentialsInput;
}>;

export type LoginMutation = { __typename?: 'Mutation' } & {
    login: { __typename?: 'LoginPayload' } & Pick<LoginPayload, 'accessToken'> & {
            matrixAuth?: Maybe<
                { __typename?: 'MatrixAuthData' } & Pick<
                    MatrixAuthData,
                    'home_server' | 'access_token' | 'user_id' | 'device_id'
                >
            >;
        };
};

export type RegisterMatrixUserMutationVariables = Exact<{
    input: RegisterMatrixUserInput;
}>;

export type RegisterMatrixUserMutation = { __typename?: 'Mutation' } & {
    registerMatrixUser: { __typename?: 'MatrixAuthData' } & Pick<
        MatrixAuthData,
        'access_token' | 'device_id' | 'home_server' | 'user_id'
    >;
};

export type RegisterMemberMutationVariables = Exact<{
    member: RegisterMemberInput;
}>;

export type RegisterMemberMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'registerMember'>;

export type ResetPasswordMutationVariables = Exact<{
    input: ResetPasswordInput;
}>;

export type ResetPasswordMutation = { __typename?: 'Mutation' } & {
    resetPassword: { __typename?: 'ResetPasswordPayload' } & Pick<ResetPasswordPayload, 'success'>;
};

export type SendPasswordResetEmailMutationVariables = Exact<{
    input: ForgotPasswordInput;
}>;

export type SendPasswordResetEmailMutation = { __typename?: 'Mutation' } & {
    sendPasswordResetEmail: { __typename?: 'ForgotPasswordPayload' } & Pick<ForgotPasswordPayload, 'sent'>;
};

export type SendVerifyEmailMutationVariables = Exact<{
    input: SendVerifyEmailInput;
}>;

export type SendVerifyEmailMutation = { __typename?: 'Mutation' } & {
    sendVerificationEmail: { __typename?: 'SendVerifyEmailPayload' } & Pick<SendVerifyEmailPayload, 'sent'>;
};

export type AcceptMembershipRequestMutationVariables = Exact<{
    input: AcceptMembershipRequestInput;
}>;

export type AcceptMembershipRequestMutation = { __typename?: 'Mutation' } & {
    acceptMembershipRequest: { __typename?: 'AcceptMembershipRequestPayload' } & {
        community: { __typename?: 'Community' } & Pick<Community, 'id'>;
    };
};

export type CancelMembershipRequestMutationVariables = Exact<{
    input: CancelMembershipRequestInput;
}>;

export type CancelMembershipRequestMutation = { __typename?: 'Mutation' } & {
    cancelMembershipRequest: { __typename?: 'CancelMembershipRequestPayload' } & {
        community: { __typename?: 'Community' } & Pick<Community, 'id' | 'membershipStatus'>;
    };
};

export type CreateCommunityInvitationMutationVariables = Exact<{
    input: CreateCommunityInvitationInput;
}>;

export type CreateCommunityInvitationMutation = { __typename?: 'Mutation' } & {
    createCommunityInvitation: { __typename?: 'CreateCommunityInvitationPayload' } & {
        invitation: { __typename?: 'CommunityInvitation' } & Pick<
            CommunityInvitation,
            'id' | 'usageLimit' | 'usedCount' | 'expiresAt'
        >;
    };
};

export type CreateCommunityMutationVariables = Exact<{
    community: CreateCommunityInput;
}>;

export type CreateCommunityMutation = { __typename?: 'Mutation' } & {
    createCommunity: { __typename?: 'CreateCommunityPayload' } & {
        community: { __typename?: 'Community' } & Pick<Community, 'id'>;
    };
};

export type DeleteCommunityImageMutationVariables = Exact<{
    community: DeleteCommunityImageInput;
}>;

export type DeleteCommunityImageMutation = { __typename?: 'Mutation' } & {
    deleteCommunityImage: { __typename?: 'DeleteCommunityImagePayload' } & {
        community: { __typename?: 'Community' } & Pick<Community, 'id'>;
    };
};

export type DeleteCommunityInvitationMutationVariables = Exact<{
    input: DeleteCommunityInvitationyInput;
}>;

export type DeleteCommunityInvitationMutation = { __typename?: 'Mutation' } & {
    deleteCommunityInvitation: { __typename?: 'DeleteCommunityInvitationPayload' } & {
        invitation: { __typename?: 'CommunityInvitation' } & Pick<CommunityInvitation, 'id'>;
    };
};

export type DeleteCommunityMutationVariables = Exact<{
    id: Scalars['String'];
}>;

export type DeleteCommunityMutation = { __typename?: 'Mutation' } & {
    deleteCommunity: { __typename?: 'DeleteCommunityPayload' } & Pick<DeleteCommunityPayload, 'id'>;
};

export type JoinCommunityMutationVariables = Exact<{
    input: JoinCommunityInput;
}>;

export type JoinCommunityMutation = { __typename?: 'Mutation' } & {
    joinCommunity: { __typename?: 'JoinCommunityPayload' } & {
        community: { __typename?: 'Community' } & Pick<Community, 'id' | 'membershipStatus'>;
    };
};

export type LeaveCommunityMutationVariables = Exact<{
    input: LeaveCommunityInput;
}>;

export type LeaveCommunityMutation = { __typename?: 'Mutation' } & {
    leaveCommunity: { __typename?: 'LeaveCommunityPayload' } & {
        community: { __typename?: 'Community' } & Pick<Community, 'id' | 'membershipStatus'>;
    };
};

export type RejectMembershipRequestMutationVariables = Exact<{
    input: RejectMembershipRequestInput;
}>;

export type RejectMembershipRequestMutation = { __typename?: 'Mutation' } & {
    rejectMembershipRequest: { __typename?: 'RejectMembershipRequestPayload' } & Pick<
        RejectMembershipRequestPayload,
        'id'
    >;
};

export type UpdateCommunityMutationVariables = Exact<{
    community: UpdateCommunityInput;
}>;

export type UpdateCommunityMutation = { __typename?: 'Mutation' } & {
    updateCommunity: { __typename?: 'UpdateCommunityPayload' } & {
        community: { __typename?: 'Community' } & Pick<Community, 'id'>;
    };
};

export type UploadCommunityImagesMutationVariables = Exact<{
    community: UploadCommunityImagesInput;
}>;

export type UploadCommunityImagesMutation = { __typename?: 'Mutation' } & {
    uploadCommunityImages: { __typename?: 'UploadCommunityImagesPayload' } & {
        community: { __typename?: 'Community' } & Pick<Community, 'id'> & {
                images: { __typename?: 'FileInfoConnection' } & {
                    edges: Array<
                        { __typename?: 'FileInfoEdge' } & {
                            node: { __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url' | 'extension'> & {
                                    blurhash?: Maybe<
                                        { __typename?: 'BlurhashMeta' } & Pick<
                                            BlurhashMeta,
                                            'hash' | 'width' | 'height'
                                        >
                                    >;
                                };
                        }
                    >;
                };
            };
    };
};

export type UploadCommunityLogoMutationVariables = Exact<{
    community: UploadCommunityLogoInput;
}>;

export type UploadCommunityLogoMutation = { __typename?: 'Mutation' } & {
    uploadCommunityLogo: { __typename?: 'UploadCommunityLogoPayload' } & {
        community: { __typename?: 'Community' } & Pick<Community, 'id'> & {
                logo?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
            };
    };
};

export type CreateEventMutationVariables = Exact<{
    event: CreateEventInput;
}>;

export type CreateEventMutation = { __typename?: 'Mutation' } & {
    createEvent: { __typename?: 'CreateEventPayload' } & { event: { __typename?: 'Event' } & Pick<Event, 'id'> };
};

export type CreateExperienceMutationVariables = Exact<{
    experience: CreateEventInput;
}>;

export type CreateExperienceMutation = { __typename?: 'Mutation' } & {
    createExperience: { __typename?: 'CreateEventPayload' } & { event: { __typename?: 'Event' } & Pick<Event, 'id'> };
};

export type DeleteEventImageMutationVariables = Exact<{
    event: DeleteEventImageInput;
}>;

export type DeleteEventImageMutation = { __typename?: 'Mutation' } & {
    deleteEventImage: { __typename?: 'DeleteEventImagePayload' } & {
        event: { __typename?: 'Event' } & Pick<Event, 'id'>;
    };
};

export type DeleteEventMutationVariables = Exact<{
    id: Scalars['String'];
}>;

export type DeleteEventMutation = { __typename?: 'Mutation' } & {
    deleteEvent: { __typename?: 'DeleteEventPayload' } & Pick<DeleteEventPayload, 'id'>;
};

export type EventPaymentMutationVariables = Exact<{
    input: EventPaymentInput;
}>;

export type EventPaymentMutation = { __typename?: 'Mutation' } & {
    eventPayment: { __typename?: 'EventPaymentPayload' } & Pick<EventPaymentPayload, 'result'>;
};

export type ReorderEventImagesMutationVariables = Exact<{
    event: ReorderEventImagesInput;
}>;

export type ReorderEventImagesMutation = { __typename?: 'Mutation' } & {
    reorderEventImages: { __typename?: 'ReorderEventImagesPayload' } & {
        event: { __typename?: 'Event' } & EventPartsFragment;
    };
};

export type TogglePinMutationVariables = Exact<{
    event: TogglePinEventInput;
}>;

export type TogglePinMutation = { __typename?: 'Mutation' } & {
    togglePin: { __typename?: 'TogglePinEventPayload' } & {
        event: { __typename?: 'Event' } & Pick<Event, 'id' | 'pinned'>;
    };
};

export type UpdateEventMutationVariables = Exact<{
    event: UpdateEventInput;
}>;

export type UpdateEventMutation = { __typename?: 'Mutation' } & {
    updateEvent: { __typename?: 'UpdateEventPayload' } & { event: { __typename?: 'Event' } & Pick<Event, 'id'> };
};

export type UploadEventImagesMutationVariables = Exact<{
    event: UploadEventImagesInput;
}>;

export type UploadEventImagesMutation = { __typename?: 'Mutation' } & {
    uploadEventImages: { __typename?: 'UploadEventImagesPayload' } & {
        event: { __typename?: 'Event' } & Pick<Event, 'id'> & {
                images: { __typename?: 'EventImageConnection' } & {
                    edges: Array<
                        { __typename?: 'EventImageEdge' } & {
                            node: { __typename?: 'EventImage' } & Pick<EventImage, 'id' | 'order'> & {
                                    image: { __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'> & {
                                            blurhash?: Maybe<
                                                { __typename?: 'BlurhashMeta' } & Pick<
                                                    BlurhashMeta,
                                                    'hash' | 'width' | 'height'
                                                >
                                            >;
                                        };
                                };
                        }
                    >;
                };
            };
    };
};

export type AcceptFollowMutationVariables = Exact<{
    input: AcceptFollowInput;
}>;

export type AcceptFollowMutation = { __typename?: 'Mutation' } & {
    acceptFollow: { __typename?: 'AcceptFollowPayload' } & {
        follow: { __typename?: 'Follow' } & Pick<Follow, 'id' | 'accepted'>;
    };
};

export type CancelFollowMutationVariables = Exact<{
    input: CancelFollowInput;
}>;

export type CancelFollowMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'cancelFollow'>;

export type FollowMutationVariables = Exact<{
    input: FollowUserInput;
}>;

export type FollowMutation = { __typename?: 'Mutation' } & {
    followUser: { __typename?: 'FollowUserPayload' } & { follow: { __typename?: 'Follow' } & Pick<Follow, 'id'> };
};

export type RejectFollowMutationVariables = Exact<{
    input: RejectFollowInput;
}>;

export type RejectFollowMutation = { __typename?: 'Mutation' } & {
    rejectFollow: { __typename?: 'RejectFollowPayload' } & {
        follow: { __typename?: 'Follow' } & Pick<Follow, 'id' | 'accepted'>;
    };
};

export type RemoveFollowerMutationVariables = Exact<{
    input: RemoveFollowerInput;
}>;

export type RemoveFollowerMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'removeFollower'>;

export type UnfollowMutationVariables = Exact<{
    input: UnfollowInput;
}>;

export type UnfollowMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'unfollow'>;

export type DeleteManagerMutationVariables = Exact<{
    input: DeleteManagerInput;
}>;

export type DeleteManagerMutation = { __typename?: 'Mutation' } & {
    deleteManager: { __typename?: 'DeleteManagerPayload' } & Pick<DeleteManagerPayload, 'id'>;
};

export type RegisterManagerMutationVariables = Exact<{
    manager: RegisterManagerInput;
}>;

export type RegisterManagerMutation = { __typename?: 'Mutation' } & {
    registerManager: { __typename?: 'Manager' } & Pick<Manager, 'id'> & {
            user: { __typename?: 'User' } & Pick<User, 'id' | 'fullName'> & {
                    avatar?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
                };
        };
};

export type UpdateManagerMutationVariables = Exact<{
    manager: UpdateManagerInput;
}>;

export type UpdateManagerMutation = { __typename?: 'Mutation' } & {
    updateManager: { __typename?: 'UpdateManagerPayload' } & {
        manager: { __typename?: 'Manager' } & Pick<Manager, 'id'> & {
                user: { __typename?: 'User' } & Pick<
                    User,
                    'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'bio' | 'dateOfBirth' | 'gender'
                >;
            };
    };
};

export type CreateMemberProfileMutationVariables = Exact<{
    memberProfile: CreateMemberProfileInput;
}>;

export type CreateMemberProfileMutation = { __typename?: 'Mutation' } & {
    createMemberProfile: { __typename?: 'CreateMemberProfilePayload' } & {
        memberProfile: { __typename?: 'MemberProfile' } & Pick<MemberProfile, 'id'>;
    };
};

export type DeleteMemberProfileMutationVariables = Exact<{
    id: Scalars['String'];
}>;

export type DeleteMemberProfileMutation = { __typename?: 'Mutation' } & {
    deleteMemberProfile: { __typename?: 'MemberProfile' } & Pick<MemberProfile, 'firstName'>;
};

export type UpdateMemberProfileMutationVariables = Exact<{
    memberProfile: UpdateMemberProfileInput;
}>;

export type UpdateMemberProfileMutation = { __typename?: 'Mutation' } & {
    updateMemberProfile: { __typename?: 'UpdateMemberProfilePayload' } & {
        memberProfile: { __typename?: 'MemberProfile' } & MemberProfilePartsFragment;
    };
};

export type UpdateMemberMutationVariables = Exact<{
    member: UpdateMemberInput;
}>;

export type UpdateMemberMutation = { __typename?: 'Mutation' } & {
    updateMember: { __typename?: 'UpdateMemberPayload' } & {
        member: { __typename?: 'Member' } & Pick<
            Member,
            'id' | 'maritalStatus' | 'cityOfResidence' | 'countryOfResidenceId'
        > & {
                countryOfResidence: { __typename?: 'Country' } & Pick<Country, 'id' | 'name'>;
                user: { __typename?: 'User' } & Pick<
                    User,
                    'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'dateOfBirth' | 'incognito' | 'nickname'
                >;
            };
    };
};

export type AddPaymentMethodMutationVariables = Exact<{
    input: AddPaymentMethodInput;
}>;

export type AddPaymentMethodMutation = { __typename?: 'Mutation' } & {
    addPaymentMethod: { __typename?: 'AddPaymentMethodPayload' } & {
        paymentMethod: { __typename?: 'PaymentMethod' } & Pick<
            PaymentMethod,
            'id' | 'brand' | 'cartToken' | 'funding' | 'lastFour' | 'expYear' | 'expMonth' | 'isDefault'
        >;
    };
};

export type CancelSubscriptionMutationVariables = Exact<{ [key: string]: never }>;

export type CancelSubscriptionMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'cancelSubscription'>;

export type DeletePaymentMethodMutationVariables = Exact<{
    id: Scalars['String'];
}>;

export type DeletePaymentMethodMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'deletePaymentMethod'>;

export type SetDefaultPaymentMethodMutationVariables = Exact<{
    paymentMethodId: Scalars['String'];
}>;

export type SetDefaultPaymentMethodMutation = { __typename?: 'Mutation' } & {
    setDefaultPaymentMethod: { __typename?: 'SetDefaultPaymentMethodPayload' } & Pick<
        SetDefaultPaymentMethodPayload,
        'paymentMethodId'
    >;
};

export type SubscribeMutationVariables = Exact<{
    input: SubscriptionInput;
}>;

export type SubscribeMutation = { __typename?: 'Mutation' } & {
    subscription: { __typename?: 'SubscriptionPayload' } & Pick<SubscriptionPayload, 'result'>;
};

export type BookmarkPostMutationVariables = Exact<{
    post: BookmarkPostInput;
}>;

export type BookmarkPostMutation = { __typename?: 'Mutation' } & {
    bookmarkPost: { __typename?: 'BookmarkPostPayload' } & {
        post: { __typename?: 'Post' } & Pick<Post, 'id' | 'isBookmarked'>;
    };
};

export type CreatePostCommentMutationVariables = Exact<{
    comment: CreatePostCommentInput;
}>;

export type CreatePostCommentMutation = { __typename?: 'Mutation' } & {
    createPostComment: { __typename?: 'CreatePostCommentPayload' } & {
        comment: { __typename?: 'PostComment' } & Pick<PostComment, 'id' | 'text' | 'userId' | 'createdAt'> & {
                author: { __typename?: 'User' } & Pick<User, 'id' | 'fullName' | 'email' | 'statusText'> & {
                        role: { __typename?: 'Role' } & Pick<Role, 'id' | 'name'>;
                        avatar?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
                        member?: Maybe<{ __typename?: 'Member' } & Pick<Member, 'id'>>;
                    };
            };
    };
};

export type CreatePostMutationVariables = Exact<{
    post: CreatePostInput;
}>;

export type CreatePostMutation = { __typename?: 'Mutation' } & {
    createPost: { __typename?: 'CreatePostPayload' } & {
        post: { __typename?: 'Post' } & {
            meta:
                | { __typename?: 'CoverPhotoChangeMeta' }
                | { __typename?: 'DefaultPostMeta' }
                | { __typename?: 'EventMeta' }
                | { __typename?: 'ExperienceMeta' }
                | { __typename?: 'ProfilePictureChangeMeta' }
                | ({ __typename?: 'RepostMeta' } & { post: { __typename?: 'Post' } & PostPartsFragment });
        } & PostPartsFragment;
    };
};

export type DeletePostCommentMutationVariables = Exact<{
    id: Scalars['String'];
}>;

export type DeletePostCommentMutation = { __typename?: 'Mutation' } & {
    deletePostComment: { __typename?: 'DeletePostCommentPayload' } & {
        comment: { __typename?: 'PostComment' } & Pick<PostComment, 'id'>;
    };
};

export type DeletePostMutationVariables = Exact<{
    id: Scalars['String'];
}>;

export type DeletePostMutation = { __typename?: 'Mutation' } & {
    deletePost: { __typename?: 'DeletePostPayload' } & Pick<DeletePostPayload, 'id'>;
};

export type DislikePostMutationVariables = Exact<{
    post: DislikePostInput;
}>;

export type DislikePostMutation = { __typename?: 'Mutation' } & {
    dislikePost: { __typename?: 'DislikePostPayload' } & Pick<DislikePostPayload, 'likeCount'>;
};

export type LikePostMutationVariables = Exact<{
    post: LikePostInput;
}>;

export type LikePostMutation = { __typename?: 'Mutation' } & {
    likePost: { __typename?: 'LikePostPayload' } & Pick<LikePostPayload, 'likeCount'>;
};

export type SharePostMutationVariables = Exact<{
    post: SharePostInput;
}>;

export type SharePostMutation = { __typename?: 'Mutation' } & {
    sharePost: { __typename?: 'SharePostPayload' } & {
        post: { __typename?: 'Post' } & {
            meta:
                | { __typename?: 'CoverPhotoChangeMeta' }
                | { __typename?: 'DefaultPostMeta' }
                | { __typename?: 'EventMeta' }
                | { __typename?: 'ExperienceMeta' }
                | { __typename?: 'ProfilePictureChangeMeta' }
                | ({ __typename?: 'RepostMeta' } & { post: { __typename?: 'Post' } & PostPartsFragment });
        } & PostPartsFragment;
    };
};

export type UpdatePostMutationVariables = Exact<{
    post: UpdatePostInput;
}>;

export type UpdatePostMutation = { __typename?: 'Mutation' } & {
    updatePost: { __typename?: 'UpdatePostPayload' } & {
        post: { __typename?: 'Post' } & {
            meta:
                | { __typename?: 'CoverPhotoChangeMeta' }
                | { __typename?: 'DefaultPostMeta' }
                | { __typename?: 'EventMeta' }
                | { __typename?: 'ExperienceMeta' }
                | { __typename?: 'ProfilePictureChangeMeta' }
                | ({ __typename?: 'RepostMeta' } & { post: { __typename?: 'Post' } & PostPartsFragment });
        } & PostPartsFragment;
    };
};

export type UploadUserAvatarMutationVariables = Exact<{
    input: UploadUserAvatarInput;
}>;

export type UploadUserAvatarMutation = { __typename?: 'Mutation' } & {
    uploadUserAvatar: { __typename?: 'UploadUserAvatarPayload' } & {
        user: { __typename?: 'User' } & UserPartsFragment;
    };
};

export type UploadUserCoverPhotoMutationVariables = Exact<{
    input: UploadUserCoverPhotoInput;
}>;

export type UploadUserCoverPhotoMutation = { __typename?: 'Mutation' } & {
    uploadUserCoverPhoto: { __typename?: 'UploadUserCoverPhotoPayload' } & {
        user: { __typename?: 'User' } & UserPartsFragment;
    };
};

export type ActivityCountQueryVariables = Exact<{ [key: string]: never }>;

export type ActivityCountQuery = { __typename?: 'Query' } & Pick<Query, 'activityCount'>;

export type ChatableUsersQueryVariables = Exact<{
    page?: Maybe<Scalars['Int']>;
    where?: Maybe<ChatableUsersWhereInput>;
}>;

export type ChatableUsersQuery = { __typename?: 'Query' } & {
    chatableUsers: { __typename?: 'UserConnection' } & {
        edges: Array<
            { __typename?: 'UserEdge' } & {
                node: { __typename?: 'User' } & Pick<User, 'id' | 'fullName'> & {
                        avatar?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
                        matrixAuth?: Maybe<
                            { __typename?: 'MatrixAuthData' } & Pick<
                                MatrixAuthData,
                                'access_token' | 'device_id' | 'home_server' | 'user_id'
                            >
                        >;
                    };
            }
        >;
        pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'hasNextPage'>;
    };
};

export type CitiesQueryVariables = Exact<{ [key: string]: never }>;

export type CitiesQuery = { __typename?: 'Query' } & {
    cities: { __typename?: 'CityConnection' } & {
        edges: Array<{ __typename?: 'CityEdge' } & { node: { __typename?: 'City' } & Pick<City, 'id' | 'name'> }>;
    };
};

export type CommunitiesQueryVariables = Exact<{
    page?: Maybe<Scalars['Int']>;
    where?: Maybe<CommunityWhereInput>;
}>;

export type CommunitiesQuery = { __typename?: 'Query' } & {
    communities: { __typename?: 'CommunityConnection' } & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'hasNextPage'>;
        edges: Array<
            { __typename?: 'CommunityEdge' } & {
                node: { __typename?: 'Community' } & Pick<Community, 'id' | 'joinRule'> & {
                        translations: { __typename?: 'CommunityTranslationConnection' } & {
                            edges: Array<
                                { __typename?: 'CommunityTranslationEdge' } & {
                                    node: { __typename?: 'CommunityTranslation' } & Pick<
                                        CommunityTranslation,
                                        'id' | 'lang' | 'name' | 'location' | 'description' | 'communityId'
                                    >;
                                }
                            >;
                        };
                        images: { __typename?: 'FileInfoConnection' } & {
                            edges: Array<
                                { __typename?: 'FileInfoEdge' } & {
                                    node: { __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>;
                                }
                            >;
                        };
                        logo?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
                    };
            }
        >;
    };
};

export type CommunityInvitationQueryVariables = Exact<{
    communityInvitationId: Scalars['String'];
}>;

export type CommunityInvitationQuery = { __typename?: 'Query' } & {
    communityInvitation: { __typename?: 'CommunityInvitation' } & Pick<
        CommunityInvitation,
        'id' | 'usageLimit' | 'usedCount' | 'expiresAt'
    > & {
            community: { __typename?: 'Community' } & Pick<Community, 'id'> & {
                    translations: { __typename?: 'CommunityTranslationConnection' } & {
                        edges: Array<
                            { __typename?: 'CommunityTranslationEdge' } & {
                                node: { __typename?: 'CommunityTranslation' } & Pick<
                                    CommunityTranslation,
                                    'id' | 'lang' | 'name'
                                >;
                            }
                        >;
                    };
                };
        };
};

export type CommunityMembersQueryVariables = Exact<{
    where: CommunityMembersWhereInput;
    page?: Maybe<Scalars['Int']>;
}>;

export type CommunityMembersQuery = { __typename?: 'Query' } & {
    communityMembers: { __typename?: 'MemberConnection' } & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'hasNextPage'>;
        edges: Array<
            { __typename?: 'MemberEdge' } & {
                node: { __typename?: 'Member' } & Pick<Member, 'id' | 'status' | 'createdAt'> & {
                        user: { __typename?: 'User' } & Pick<
                            User,
                            | 'id'
                            | 'firstName'
                            | 'lastName'
                            | 'bio'
                            | 'email'
                            | 'phoneNumber'
                            | 'dateOfBirth'
                            | 'fullName'
                        > & { avatar?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>> };
                    };
            }
        >;
    };
};

export type CommunityQueryVariables = Exact<{
    id: Scalars['String'];
}>;

export type CommunityQuery = { __typename?: 'Query' } & {
    community: { __typename?: 'Community' } & Pick<
        Community,
        'id' | 'membershipStatus' | 'joinRule' | 'category' | 'isInternal' | 'memberCount'
    > & {
            translations: { __typename?: 'CommunityTranslationConnection' } & {
                edges: Array<
                    { __typename?: 'CommunityTranslationEdge' } & {
                        node: { __typename?: 'CommunityTranslation' } & Pick<
                            CommunityTranslation,
                            'id' | 'lang' | 'name' | 'description' | 'location'
                        >;
                    }
                >;
            };
            city: { __typename?: 'City' } & Pick<City, 'id' | 'name'>;
            benefits?: Maybe<Array<{ __typename?: 'Benefit' } & Pick<Benefit, 'text' | 'icon'>>>;
            managers: { __typename?: 'ManagerConnection' } & {
                edges: Array<
                    { __typename?: 'ManagerEdge' } & {
                        node: { __typename?: 'Manager' } & Pick<Manager, 'id' | 'userId'> & {
                                user: { __typename?: 'User' } & Pick<User, 'id' | 'fullName'> & {
                                        avatar?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
                                    };
                            };
                    }
                >;
            };
            invitations: { __typename?: 'CommunityInvitationConnection' } & {
                edges: Array<
                    { __typename?: 'CommunityInvitationEdge' } & {
                        node: { __typename?: 'CommunityInvitation' } & Pick<
                            CommunityInvitation,
                            'id' | 'usageLimit' | 'usedCount' | 'expiresAt'
                        >;
                    }
                >;
            };
            logo?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
            images: { __typename?: 'FileInfoConnection' } & {
                edges: Array<
                    { __typename?: 'FileInfoEdge' } & {
                        node: { __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url' | 'extension'> & {
                                blurhash?: Maybe<
                                    { __typename?: 'BlurhashMeta' } & Pick<BlurhashMeta, 'hash' | 'width' | 'height'>
                                >;
                            };
                    }
                >;
            };
        };
};

export type CommunityPartnersQueryVariables = Exact<{
    where: CommunityPartnersWhereInput;
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    orderBy?: Maybe<CommunityPartnersOrderBy>;
}>;

export type CommunityPartnersQuery = { __typename?: 'Query' } & {
    communityPartners: { __typename?: 'CommunityConnection' } & Pick<CommunityConnection, 'totalCount'> & {
            edges: Array<
                { __typename?: 'CommunityEdge' } & {
                    node: { __typename?: 'Community' } & Pick<Community, 'id'> & {
                            translations: { __typename?: 'CommunityTranslationConnection' } & {
                                edges: Array<
                                    { __typename?: 'CommunityTranslationEdge' } & {
                                        node: { __typename?: 'CommunityTranslation' } & Pick<
                                            CommunityTranslation,
                                            'id' | 'communityId' | 'description' | 'lang' | 'location' | 'name'
                                        >;
                                    }
                                >;
                            };
                            images: { __typename?: 'FileInfoConnection' } & {
                                edges: Array<
                                    { __typename?: 'FileInfoEdge' } & {
                                        node: { __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'name'>;
                                    }
                                >;
                            };
                            logo?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
                        };
                }
            >;
            pageInfo: { __typename?: 'PageInfo' } & Pick<
                PageInfo,
                'page' | 'totalPages' | 'hasPreviousPage' | 'hasNextPage'
            >;
        };
};

export type PendingMembershipsQueryVariables = Exact<{
    where: PendingMembershipWhereInput;
    page?: Maybe<Scalars['Int']>;
}>;

export type PendingMembershipsQuery = { __typename?: 'Query' } & {
    pendingMemberships: { __typename?: 'CommunityMembershipConnection' } & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'hasNextPage'>;
        edges: Array<
            { __typename?: 'CommunityMembershipEdge' } & {
                node: { __typename?: 'CommunityMembership' } & Pick<CommunityMembership, 'id'> & {
                        member: { __typename?: 'Member' } & Pick<Member, 'id'> & {
                                user: { __typename?: 'User' } & Pick<User, 'id' | 'fullName'> & {
                                        avatar?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
                                    };
                            };
                    };
            }
        >;
    };
};

export type UserCommunitiesQueryVariables = Exact<{ [key: string]: never }>;

export type UserCommunitiesQuery = { __typename?: 'Query' } & {
    me?: Maybe<
        { __typename?: 'User' } & Pick<User, 'id'> & {
                communities: { __typename?: 'CommunityConnection' } & {
                    edges: Array<
                        { __typename?: 'CommunityEdge' } & {
                            node: { __typename?: 'Community' } & Pick<
                                Community,
                                'id' | 'memberCount' | 'pendingMembershipsCount'
                            > & {
                                    translations: { __typename?: 'CommunityTranslationConnection' } & {
                                        edges: Array<
                                            { __typename?: 'CommunityTranslationEdge' } & {
                                                node: { __typename?: 'CommunityTranslation' } & Pick<
                                                    CommunityTranslation,
                                                    'id' | 'name' | 'location'
                                                >;
                                            }
                                        >;
                                    };
                                    city: { __typename?: 'City' } & Pick<City, 'id' | 'name'>;
                                };
                        }
                    >;
                };
            }
    >;
};

export type CountriesQueryVariables = Exact<{ [key: string]: never }>;

export type CountriesQuery = { __typename?: 'Query' } & {
    countries: { __typename?: 'CountryConnection' } & {
        edges: Array<
            { __typename?: 'CountryEdge' } & {
                node: { __typename?: 'Country' } & Pick<Country, 'id' | 'code' | 'name'>;
            }
        >;
    };
};

export type EventQueryVariables = Exact<{
    id: Scalars['String'];
}>;

export type EventQuery = { __typename?: 'Query' } & { event: { __typename?: 'Event' } & EventPartsFragment };

export type EventsQueryVariables = Exact<{
    page?: Maybe<Scalars['Int']>;
    where?: Maybe<EventWhereInput>;
}>;

export type EventsQuery = { __typename?: 'Query' } & {
    events: { __typename?: 'EventConnection' } & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'hasNextPage'>;
        edges: Array<{ __typename?: 'EventEdge' } & { node: { __typename?: 'Event' } & EventPartsFragment }>;
    };
};

export type ExperienceQueryVariables = Exact<{
    id: Scalars['String'];
}>;

export type ExperienceQuery = { __typename?: 'Query' } & {
    experience: { __typename?: 'Event' } & ExperiencePartsFragment;
};

export type ExperiencesQueryVariables = Exact<{
    page?: Maybe<Scalars['Int']>;
    where?: Maybe<EventWhereInput>;
}>;

export type ExperiencesQuery = { __typename?: 'Query' } & {
    experiences: { __typename?: 'EventConnection' } & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'hasNextPage'>;
        edges: Array<{ __typename?: 'EventEdge' } & { node: { __typename?: 'Event' } & ExperiencePartsFragment }>;
    };
};

export type PastEventsQueryVariables = Exact<{
    page?: Maybe<Scalars['Int']>;
    where?: Maybe<EventWhereInput>;
}>;

export type PastEventsQuery = { __typename?: 'Query' } & {
    pastEvents: { __typename?: 'EventConnection' } & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'hasNextPage'>;
        edges: Array<{ __typename?: 'EventEdge' } & { node: { __typename?: 'Event' } & EventPartsFragment }>;
    };
};

export type PlansQueryVariables = Exact<{ [key: string]: never }>;

export type PlansQuery = { __typename?: 'Query' } & {
    plans: { __typename?: 'PlanConnection' } & {
        edges: Array<
            { __typename?: 'PlanEdge' } & {
                node: { __typename?: 'Plan' } & Pick<Plan, 'id' | 'code' | 'price' | 'benefits'>;
            }
        >;
    };
};

export type UpcomingEventsQueryVariables = Exact<{
    page?: Maybe<Scalars['Int']>;
    where?: Maybe<EventWhereInput>;
}>;

export type UpcomingEventsQuery = { __typename?: 'Query' } & {
    upcomingEvents: { __typename?: 'EventConnection' } & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'hasNextPage'>;
        edges: Array<{ __typename?: 'EventEdge' } & { node: { __typename?: 'Event' } & EventPartsFragment }>;
    };
};

export type MeManagerQueryVariables = Exact<{ [key: string]: never }>;

export type MeManagerQuery = { __typename?: 'Query' } & {
    me?: Maybe<
        { __typename?: 'User' } & Pick<
            User,
            | 'id'
            | 'firstName'
            | 'lastName'
            | 'email'
            | 'bio'
            | 'phoneNumber'
            | 'dateOfBirth'
            | 'gender'
            | 'incognito'
            | 'nickname'
        > & { avatar?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>> }
    >;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = { __typename?: 'Query' } & {
    me?: Maybe<
        { __typename?: 'User' } & Pick<User, 'id' | 'email' | 'firstName' | 'lastName' | 'fullName' | 'statusText'> & {
                avatar?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
                role: { __typename?: 'Role' } & Pick<Role, 'id' | 'name'>;
                matrixAuth?: Maybe<
                    { __typename?: 'MatrixAuthData' } & Pick<
                        MatrixAuthData,
                        'access_token' | 'device_id' | 'home_server' | 'user_id'
                    >
                >;
                member?: Maybe<{ __typename?: 'Member' } & Pick<Member, 'id' | 'status'>>;
            }
    >;
};

export type MeMemberQueryVariables = Exact<{ [key: string]: never }>;

export type MeMemberQuery = { __typename?: 'Query' } & {
    me?: Maybe<
        { __typename?: 'User' } & Pick<User, 'id' | 'incognito' | 'nickname'> & {
                subscription?: Maybe<
                    { __typename?: 'SubscriptionNode' } & Pick<SubscriptionNode, 'id' | 'expiresAt'> & {
                            plan?: Maybe<{ __typename?: 'Plan' } & Pick<Plan, 'id' | 'code' | 'benefits'>>;
                        }
                >;
                avatar?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
                member?: Maybe<
                    { __typename?: 'Member' } & Pick<
                        Member,
                        'id' | 'maritalStatus' | 'cityOfResidence' | 'countryOfResidenceId'
                    > & {
                            countryOfResidence: { __typename?: 'Country' } & Pick<Country, 'id' | 'name'>;
                            user: { __typename?: 'User' } & Pick<
                                User,
                                | 'id'
                                | 'firstName'
                                | 'lastName'
                                | 'bio'
                                | 'gender'
                                | 'email'
                                | 'phoneNumber'
                                | 'dateOfBirth'
                                | 'incognito'
                                | 'nickname'
                            >;
                            profiles: { __typename?: 'MemberProfileConnection' } & {
                                edges: Array<
                                    { __typename?: 'MemberProfileEdge' } & {
                                        node: { __typename?: 'MemberProfile' } & MemberProfilePartsFragment;
                                    }
                                >;
                            };
                        }
                >;
            }
    >;
};

export type MemberQueryVariables = Exact<{
    id: Scalars['String'];
}>;

export type MemberQuery = { __typename?: 'Query' } & {
    member?: Maybe<
        { __typename?: 'Member' } & Pick<Member, 'id' | 'status' | 'maritalStatus' | 'cityOfResidence'> & {
                countryOfResidence: { __typename?: 'Country' } & Pick<Country, 'id' | 'name'>;
                user: { __typename?: 'User' } & Pick<
                    User,
                    'id' | 'firstName' | 'lastName' | 'bio' | 'email' | 'phoneNumber' | 'dateOfBirth'
                >;
                profiles: { __typename?: 'MemberProfileConnection' } & {
                    edges: Array<
                        { __typename?: 'MemberProfileEdge' } & {
                            node: { __typename?: 'MemberProfile' } & MemberProfilePartsFragment;
                        }
                    >;
                };
            }
    >;
};

export type MembersQueryVariables = Exact<{
    where: MemberWhereInput;
}>;

export type MembersQuery = { __typename?: 'Query' } & {
    members?: Maybe<
        { __typename?: 'MemberConnection' } & {
            edges: Array<
                { __typename?: 'MemberEdge' } & {
                    node: { __typename?: 'Member' } & Pick<Member, 'id' | 'status' | 'createdAt'> & {
                            user: { __typename?: 'User' } & Pick<
                                User,
                                'id' | 'firstName' | 'lastName' | 'bio' | 'email' | 'phoneNumber' | 'dateOfBirth'
                            >;
                        };
                }
            >;
        }
    >;
};

export type PaymentMethodsQueryVariables = Exact<{ [key: string]: never }>;

export type PaymentMethodsQuery = { __typename?: 'Query' } & {
    paymentMethods: { __typename?: 'PaymentMethodConnection' } & {
        edges: Array<
            { __typename?: 'PaymentMethodEdge' } & {
                node: { __typename?: 'PaymentMethod' } & Pick<
                    PaymentMethod,
                    'id' | 'isDefault' | 'lastFour' | 'funding' | 'expYear' | 'expMonth' | 'brand' | 'cartToken'
                >;
            }
        >;
    };
};

export type PostDetailQueryVariables = Exact<{
    id: Scalars['String'];
}>;

export type PostDetailQuery = { __typename?: 'Query' } & {
    post: { __typename?: 'Post' } & {
        meta:
            | { __typename?: 'CoverPhotoChangeMeta' }
            | { __typename?: 'DefaultPostMeta' }
            | { __typename?: 'EventMeta' }
            | { __typename?: 'ExperienceMeta' }
            | { __typename?: 'ProfilePictureChangeMeta' }
            | ({ __typename?: 'RepostMeta' } & { post: { __typename?: 'Post' } & PostPartsFragment });
    } & PostPartsFragment;
};

export type PostQueryVariables = Exact<{
    id: Scalars['String'];
    page?: Maybe<Scalars['Int']>;
}>;

export type PostQuery = { __typename?: 'Query' } & {
    post: { __typename?: 'Post' } & Pick<Post, 'id' | 'visibility'> & {
            comments: { __typename?: 'PostCommentConnection' } & {
                edges: Array<
                    { __typename?: 'PostCommentEdge' } & {
                        node: { __typename?: 'PostComment' } & Pick<
                            PostComment,
                            'id' | 'text' | 'userId' | 'createdAt'
                        > & {
                                author: { __typename?: 'User' } & Pick<User, 'id' | 'fullName' | 'statusText'> & {
                                        role: { __typename?: 'Role' } & Pick<Role, 'id' | 'name'>;
                                        avatar?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
                                        member?: Maybe<{ __typename?: 'Member' } & Pick<Member, 'id'>>;
                                    };
                            };
                    }
                >;
                pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'hasNextPage'>;
            };
        };
};

export type PostsQueryVariables = Exact<{
    page?: Maybe<Scalars['Int']>;
    where: PostWhereInput;
}>;

export type PostsQuery = { __typename?: 'Query' } & {
    posts: { __typename?: 'PostConnection' } & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'totalPages'>;
        edges: Array<
            { __typename?: 'PostEdge' } & {
                node: { __typename?: 'Post' } & {
                    meta:
                        | { __typename?: 'CoverPhotoChangeMeta' }
                        | { __typename?: 'DefaultPostMeta' }
                        | { __typename?: 'EventMeta' }
                        | { __typename?: 'ExperienceMeta' }
                        | { __typename?: 'ProfilePictureChangeMeta' }
                        | ({ __typename?: 'RepostMeta' } & { post: { __typename?: 'Post' } & PostPartsFragment });
                } & PostPartsFragment;
            }
        >;
    };
};

export type RepostedUsersQueryVariables = Exact<{
    where: RepostedUserWhereInput;
    page?: Maybe<Scalars['Int']>;
}>;

export type RepostedUsersQuery = { __typename?: 'Query' } & {
    repostedUsers: { __typename?: 'UserConnection' } & {
        edges: Array<
            { __typename?: 'UserEdge' } & {
                node: { __typename?: 'User' } & Pick<User, 'id' | 'fullName'> & {
                        avatar?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
                    };
            }
        >;
        pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'hasNextPage'>;
    };
};

export type SavedPostsQueryVariables = Exact<{
    page?: Maybe<Scalars['Int']>;
    where: PostWhereInput;
}>;

export type SavedPostsQuery = { __typename?: 'Query' } & {
    bookmarkedPosts: { __typename?: 'PostConnection' } & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'totalPages'>;
        edges: Array<
            { __typename?: 'PostEdge' } & {
                node: { __typename?: 'Post' } & {
                    meta:
                        | { __typename?: 'CoverPhotoChangeMeta' }
                        | { __typename?: 'DefaultPostMeta' }
                        | { __typename?: 'EventMeta' }
                        | { __typename?: 'ExperienceMeta' }
                        | { __typename?: 'ProfilePictureChangeMeta' }
                        | ({ __typename?: 'RepostMeta' } & { post: { __typename?: 'Post' } & PostPartsFragment });
                } & PostPartsFragment;
            }
        >;
    };
};

export type UserPostsQueryVariables = Exact<{
    page?: Maybe<Scalars['Int']>;
    userId: Scalars['String'];
    where: PostWhereInput;
}>;

export type UserPostsQuery = { __typename?: 'Query' } & {
    userPosts: { __typename?: 'PostConnection' } & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'totalPages'>;
        edges: Array<
            { __typename?: 'PostEdge' } & {
                node: { __typename?: 'Post' } & {
                    meta:
                        | { __typename?: 'CoverPhotoChangeMeta' }
                        | { __typename?: 'DefaultPostMeta' }
                        | { __typename?: 'EventMeta' }
                        | { __typename?: 'ExperienceMeta' }
                        | { __typename?: 'ProfilePictureChangeMeta' }
                        | ({ __typename?: 'RepostMeta' } & { post: { __typename?: 'Post' } & PostPartsFragment });
                } & PostPartsFragment;
            }
        >;
    };
};

export type ReportsQueryVariables = Exact<{ [key: string]: never }>;

export type ReportsQuery = { __typename?: 'Query' } & {
    reports: { __typename?: 'ReportConnection' } & {
        edges: Array<
            { __typename?: 'ReportEdge' } & {
                node: { __typename?: 'Report' } & Pick<Report, 'code' | 'embedUrl' | 'id'>;
            }
        >;
    };
};

export type SearchQueryVariables = Exact<{
    keyword: Scalars['String'];
}>;

export type SearchQuery = { __typename?: 'Query' } & {
    users: { __typename?: 'UserConnection' } & {
        edges: Array<
            { __typename?: 'UserEdge' } & {
                node: { __typename?: 'User' } & Pick<User, 'id' | 'fullName'> & {
                        avatar?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
                    };
            }
        >;
    };
    events: { __typename?: 'EventConnection' } & {
        edges: Array<
            { __typename?: 'EventEdge' } & {
                node: { __typename?: 'Event' } & Pick<
                    Event,
                    'id' | 'startTime' | 'tags' | 'type' | 'price' | 'purchased'
                > & {
                        translations: { __typename?: 'EventTranslationConnection' } & {
                            edges: Array<
                                { __typename?: 'EventTranslationEdge' } & {
                                    node: { __typename?: 'EventTranslation' } & Pick<
                                        EventTranslation,
                                        'id' | 'lang' | 'title' | 'description' | 'address'
                                    >;
                                }
                            >;
                        };
                        images: { __typename?: 'EventImageConnection' } & {
                            edges: Array<
                                { __typename?: 'EventImageEdge' } & {
                                    node: { __typename?: 'EventImage' } & Pick<EventImage, 'id' | 'order'> & {
                                            image: { __typename?: 'FileInfo' } & Pick<
                                                FileInfo,
                                                'id' | 'url' | 'name' | 'extension'
                                            > & {
                                                    blurhash?: Maybe<
                                                        { __typename?: 'BlurhashMeta' } & Pick<
                                                            BlurhashMeta,
                                                            'hash' | 'width' | 'height'
                                                        >
                                                    >;
                                                };
                                        };
                                }
                            >;
                        };
                    };
            }
        >;
    };
    experiences: { __typename?: 'EventConnection' } & {
        edges: Array<
            { __typename?: 'EventEdge' } & {
                node: { __typename?: 'Event' } & Pick<
                    Event,
                    'id' | 'startTime' | 'tags' | 'type' | 'price' | 'purchased'
                > & {
                        translations: { __typename?: 'EventTranslationConnection' } & {
                            edges: Array<
                                { __typename?: 'EventTranslationEdge' } & {
                                    node: { __typename?: 'EventTranslation' } & Pick<
                                        EventTranslation,
                                        'id' | 'lang' | 'title' | 'description' | 'address'
                                    >;
                                }
                            >;
                        };
                        images: { __typename?: 'EventImageConnection' } & {
                            edges: Array<
                                { __typename?: 'EventImageEdge' } & {
                                    node: { __typename?: 'EventImage' } & Pick<EventImage, 'id' | 'order'> & {
                                            image: { __typename?: 'FileInfo' } & Pick<
                                                FileInfo,
                                                'id' | 'url' | 'name' | 'extension'
                                            > & {
                                                    blurhash?: Maybe<
                                                        { __typename?: 'BlurhashMeta' } & Pick<
                                                            BlurhashMeta,
                                                            'hash' | 'width' | 'height'
                                                        >
                                                    >;
                                                };
                                        };
                                }
                            >;
                        };
                    };
            }
        >;
    };
    communities: { __typename?: 'CommunityConnection' } & {
        edges: Array<
            { __typename?: 'CommunityEdge' } & {
                node: { __typename?: 'Community' } & Pick<Community, 'id'> & {
                        logo?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
                        translations: { __typename?: 'CommunityTranslationConnection' } & {
                            edges: Array<
                                { __typename?: 'CommunityTranslationEdge' } & {
                                    node: { __typename?: 'CommunityTranslation' } & Pick<
                                        CommunityTranslation,
                                        'id' | 'lang' | 'name' | 'location' | 'description'
                                    >;
                                }
                            >;
                        };
                    };
            }
        >;
    };
};

export type ActivitiesQueryVariables = Exact<{
    page?: Maybe<Scalars['Int']>;
}>;

export type ActivitiesQuery = { __typename?: 'Query' } & {
    activities: { __typename?: 'ActivityConnection' } & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'hasNextPage'>;
        edges: Array<
            { __typename?: 'ActivityEdge' } & {
                node: { __typename?: 'Activity' } & Pick<Activity, 'id' | 'type' | 'createdAt'> & {
                        author: { __typename?: 'User' } & Pick<User, 'id' | 'fullName'> & {
                                avatar?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
                            };
                        meta: { __typename?: 'ActivityMeta' } & {
                            post: { __typename?: 'Post' } & Pick<Post, 'id'> & {
                                    files: { __typename?: 'FileInfoConnection' } & {
                                        edges: Array<
                                            { __typename?: 'FileInfoEdge' } & {
                                                node: { __typename?: 'FileInfo' } & Pick<
                                                    FileInfo,
                                                    'id' | 'extension' | 'url'
                                                >;
                                            }
                                        >;
                                    };
                                };
                        };
                    };
            }
        >;
    };
};

export type ApprovedMembersQueryVariables = Exact<{ [key: string]: never }>;

export type ApprovedMembersQuery = { __typename?: 'Query' } & {
    approvedMembers?: Maybe<
        { __typename?: 'UserConnection' } & {
            edges: Array<
                { __typename?: 'UserEdge' } & {
                    node: { __typename?: 'User' } & Pick<User, 'id' | 'fullName' | 'statusText'> & {
                            role: { __typename?: 'Role' } & Pick<Role, 'id' | 'name'>;
                            avatar?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
                            member?: Maybe<{ __typename?: 'Member' } & Pick<Member, 'id'>>;
                        };
                }
            >;
        }
    >;
};

export type FollowRequestsQueryVariables = Exact<{
    page?: Maybe<Scalars['Int']>;
    where?: Maybe<FollowWhereInput>;
}>;

export type FollowRequestsQuery = { __typename?: 'Query' } & {
    followRequests: { __typename?: 'FollowConnection' } & Pick<FollowConnection, 'totalCount'> & {
            edges: Array<
                { __typename?: 'FollowEdge' } & {
                    node: { __typename?: 'Follow' } & Pick<Follow, 'id'> & {
                            follower: { __typename?: 'User' } & Pick<User, 'id' | 'fullName'> & {
                                    avatar?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
                                };
                        };
                }
            >;
            pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'hasNextPage'>;
        };
};

export type FollowersQueryVariables = Exact<{
    page?: Maybe<Scalars['Int']>;
    where?: Maybe<FollowWhereInput>;
}>;

export type FollowersQuery = { __typename?: 'Query' } & {
    followers: { __typename?: 'UserConnection' } & {
        edges: Array<
            { __typename?: 'UserEdge' } & {
                node: { __typename?: 'User' } & Pick<User, 'id' | 'fullName' | 'incognito' | 'nickname'> & {
                        avatar?: Maybe<
                            { __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'> & {
                                    blurhash?: Maybe<{ __typename?: 'BlurhashMeta' } & Pick<BlurhashMeta, 'hash'>>;
                                }
                        >;
                    };
            }
        >;
        pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'hasNextPage'>;
    };
};

export type FollowingsQueryVariables = Exact<{
    page?: Maybe<Scalars['Int']>;
    where?: Maybe<FollowWhereInput>;
}>;

export type FollowingsQuery = { __typename?: 'Query' } & {
    followings: { __typename?: 'UserConnection' } & {
        edges: Array<
            { __typename?: 'UserEdge' } & {
                node: { __typename?: 'User' } & Pick<User, 'id' | 'fullName' | 'incognito' | 'nickname'> & {
                        avatar?: Maybe<
                            { __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'> & {
                                    blurhash?: Maybe<{ __typename?: 'BlurhashMeta' } & Pick<BlurhashMeta, 'hash'>>;
                                }
                        >;
                    };
            }
        >;
        pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'hasNextPage'>;
    };
};

export type GuestsQueryVariables = Exact<{ [key: string]: never }>;

export type GuestsQuery = { __typename?: 'Query' } & {
    guests?: Maybe<
        { __typename?: 'UserConnection' } & {
            edges: Array<
                { __typename?: 'UserEdge' } & {
                    node: { __typename?: 'User' } & Pick<User, 'id' | 'fullName' | 'statusText'> & {
                            role: { __typename?: 'Role' } & Pick<Role, 'id' | 'name'>;
                            avatar?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
                            member?: Maybe<{ __typename?: 'Member' } & Pick<Member, 'id'>>;
                        };
                }
            >;
        }
    >;
};

export type UserQueryVariables = Exact<{
    userId: Scalars['String'];
}>;

export type UserQuery = { __typename?: 'Query' } & Pick<Query, 'followerCount' | 'followingCount'> & {
        user: { __typename?: 'User' } & UserPartsFragment;
    };

export const AvatarPartsFragmentDoc = gql`
    fragment AvatarParts on FileInfo {
        id
        url
        blurhash {
            hash
        }
    }
`;
export const MemberProfilePartsFragmentDoc = gql`
    fragment MemberProfileParts on MemberProfile {
        id
        firstName
        lastName
        email
        bio
        phoneNumber
        dateOfBirth
        cityOfResidence
        countryOfResidence {
            id
            name
        }
        countryOfResidenceId
        maritalStatus
    }
`;
export const EventPartsFragmentDoc = gql`
    fragment EventParts on Event {
        id
        createdById
        translations {
            edges {
                node {
                    id
                    lang
                    title
                    description
                    address
                }
            }
        }
        communityId
        startTime
        tags
        type
        pinned
        price
        isExternal
        externalLink
        isDraft
        purchased
        images {
            edges {
                node {
                    id
                    order
                    image {
                        id
                        url
                        name
                        extension
                        blurhash {
                            hash
                            width
                            height
                        }
                    }
                }
            }
        }
    }
`;
export const ExperiencePartsFragmentDoc = gql`
    fragment ExperienceParts on Event {
        id
        createdById
        translations {
            edges {
                node {
                    id
                    lang
                    title
                    description
                    address
                }
            }
        }
        communityId
        startTime
        tags
        price
        isExternal
        isDraft
        externalLink
        purchased
        pinned
        images {
            edges {
                node {
                    id
                    order
                    image {
                        id
                        url
                        name
                        extension
                        blurhash {
                            hash
                            width
                            height
                        }
                    }
                }
            }
        }
    }
`;
export const PostPartsFragmentDoc = gql`
    fragment PostParts on Post {
        id
        translations {
            edges {
                node {
                    id
                    lang
                    body
                }
            }
        }
        communityId
        repostCount
        likeCount
        isLiked
        isBookmarked
        visibility
        type
        createdAt
        files {
            edges {
                node {
                    id
                    name
                    url
                    extension
                    blurhash {
                        hash
                        width
                        height
                    }
                }
            }
        }
        comments {
            totalCount
            edges {
                node {
                    id
                    text
                    createdAt
                    author {
                        id
                        fullName
                        role {
                            id
                            name
                        }
                        statusText
                        avatar {
                            id
                            url
                        }
                        member {
                            id
                        }
                    }
                }
            }
            pageInfo {
                hasNextPage
            }
        }
        topComments {
            edges {
                node {
                    id
                    text
                    userId
                    createdAt
                    author {
                        id
                        fullName
                        email
                        role {
                            id
                            name
                        }
                        statusText
                        avatar {
                            id
                            url
                        }
                        member {
                            id
                        }
                    }
                }
            }
        }
        authorId
        author {
            id
            fullName
            avatar {
                id
                url
            }
            role {
                id
                name
            }
            statusText
            member {
                id
            }
        }
        meta {
            ... on EventMeta {
                eventId
                event {
                    ...EventParts
                }
            }
            ... on ExperienceMeta {
                experienceId
                type
                experience {
                    ...ExperienceParts
                }
            }
            ... on ProfilePictureChangeMeta {
                profilePictureUrl
            }
            ... on CoverPhotoChangeMeta {
                coverPhotoUrl
            }
            ... on DefaultPostMeta {
                type
            }
        }
    }
    ${EventPartsFragmentDoc}
    ${ExperiencePartsFragmentDoc}
`;
export const UserPartsFragmentDoc = gql`
    fragment UserParts on User {
        id
        fullName
        postCount
        bio
        followStatus
        role {
            id
            name
        }
        statusText
        avatar {
            id
            url
        }
        coverPhoto {
            id
            url
        }
        email
        member {
            id
        }
        isChatable
        matrixAuth {
            user_id
        }
    }
`;
export const ActivateAccountDocument = gql`
    mutation ActivateAccount($input: ActivateAccountInput!) {
        activateAccount(input: $input) {
            success
        }
    }
`;
export type ActivateAccountMutationFn = Apollo.MutationFunction<
    ActivateAccountMutation,
    ActivateAccountMutationVariables
>;

/**
 * __useActivateAccountMutation__
 *
 * To run a mutation, you first call `useActivateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateAccountMutation, { data, loading, error }] = useActivateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateAccountMutation(
    baseOptions?: Apollo.MutationHookOptions<ActivateAccountMutation, ActivateAccountMutationVariables>,
) {
    return Apollo.useMutation<ActivateAccountMutation, ActivateAccountMutationVariables>(
        ActivateAccountDocument,
        baseOptions,
    );
}
export type ActivateAccountMutationHookResult = ReturnType<typeof useActivateAccountMutation>;
export type ActivateAccountMutationResult = Apollo.MutationResult<ActivateAccountMutation>;
export type ActivateAccountMutationOptions = Apollo.BaseMutationOptions<
    ActivateAccountMutation,
    ActivateAccountMutationVariables
>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($input: ChangePasswordInput!) {
        changePassword(input: $input) {
            success
        }
    }
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(
    baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>,
) {
    return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(
        ChangePasswordDocument,
        baseOptions,
    );
}
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
>;
export const LoginDocument = gql`
    mutation Login($credentials: CredentialsInput!) {
        login(credentials: $credentials) {
            accessToken
            matrixAuth {
                home_server
                access_token
                user_id
                device_id
            }
        }
    }
`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      credentials: // value for 'credentials'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
    return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const RegisterMatrixUserDocument = gql`
    mutation RegisterMatrixUser($input: RegisterMatrixUserInput!) {
        registerMatrixUser(input: $input) {
            access_token
            device_id
            home_server
            user_id
        }
    }
`;
export type RegisterMatrixUserMutationFn = Apollo.MutationFunction<
    RegisterMatrixUserMutation,
    RegisterMatrixUserMutationVariables
>;

/**
 * __useRegisterMatrixUserMutation__
 *
 * To run a mutation, you first call `useRegisterMatrixUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMatrixUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMatrixUserMutation, { data, loading, error }] = useRegisterMatrixUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMatrixUserMutation(
    baseOptions?: Apollo.MutationHookOptions<RegisterMatrixUserMutation, RegisterMatrixUserMutationVariables>,
) {
    return Apollo.useMutation<RegisterMatrixUserMutation, RegisterMatrixUserMutationVariables>(
        RegisterMatrixUserDocument,
        baseOptions,
    );
}
export type RegisterMatrixUserMutationHookResult = ReturnType<typeof useRegisterMatrixUserMutation>;
export type RegisterMatrixUserMutationResult = Apollo.MutationResult<RegisterMatrixUserMutation>;
export type RegisterMatrixUserMutationOptions = Apollo.BaseMutationOptions<
    RegisterMatrixUserMutation,
    RegisterMatrixUserMutationVariables
>;
export const RegisterMemberDocument = gql`
    mutation RegisterMember($member: RegisterMemberInput!) {
        registerMember(member: $member)
    }
`;
export type RegisterMemberMutationFn = Apollo.MutationFunction<RegisterMemberMutation, RegisterMemberMutationVariables>;

/**
 * __useRegisterMemberMutation__
 *
 * To run a mutation, you first call `useRegisterMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMemberMutation, { data, loading, error }] = useRegisterMemberMutation({
 *   variables: {
 *      member: // value for 'member'
 *   },
 * });
 */
export function useRegisterMemberMutation(
    baseOptions?: Apollo.MutationHookOptions<RegisterMemberMutation, RegisterMemberMutationVariables>,
) {
    return Apollo.useMutation<RegisterMemberMutation, RegisterMemberMutationVariables>(
        RegisterMemberDocument,
        baseOptions,
    );
}
export type RegisterMemberMutationHookResult = ReturnType<typeof useRegisterMemberMutation>;
export type RegisterMemberMutationResult = Apollo.MutationResult<RegisterMemberMutation>;
export type RegisterMemberMutationOptions = Apollo.BaseMutationOptions<
    RegisterMemberMutation,
    RegisterMemberMutationVariables
>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($input: ResetPasswordInput!) {
        resetPassword(input: $input) {
            success
        }
    }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(
    baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>,
) {
    return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(
        ResetPasswordDocument,
        baseOptions,
    );
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
>;
export const SendPasswordResetEmailDocument = gql`
    mutation SendPasswordResetEmail($input: ForgotPasswordInput!) {
        sendPasswordResetEmail(input: $input) {
            sent
        }
    }
`;
export type SendPasswordResetEmailMutationFn = Apollo.MutationFunction<
    SendPasswordResetEmailMutation,
    SendPasswordResetEmailMutationVariables
>;

/**
 * __useSendPasswordResetEmailMutation__
 *
 * To run a mutation, you first call `useSendPasswordResetEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPasswordResetEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPasswordResetEmailMutation, { data, loading, error }] = useSendPasswordResetEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendPasswordResetEmailMutation(
    baseOptions?: Apollo.MutationHookOptions<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables>,
) {
    return Apollo.useMutation<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables>(
        SendPasswordResetEmailDocument,
        baseOptions,
    );
}
export type SendPasswordResetEmailMutationHookResult = ReturnType<typeof useSendPasswordResetEmailMutation>;
export type SendPasswordResetEmailMutationResult = Apollo.MutationResult<SendPasswordResetEmailMutation>;
export type SendPasswordResetEmailMutationOptions = Apollo.BaseMutationOptions<
    SendPasswordResetEmailMutation,
    SendPasswordResetEmailMutationVariables
>;
export const SendVerifyEmailDocument = gql`
    mutation SendVerifyEmail($input: SendVerifyEmailInput!) {
        sendVerificationEmail(input: $input) {
            sent
        }
    }
`;
export type SendVerifyEmailMutationFn = Apollo.MutationFunction<
    SendVerifyEmailMutation,
    SendVerifyEmailMutationVariables
>;

/**
 * __useSendVerifyEmailMutation__
 *
 * To run a mutation, you first call `useSendVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendVerifyEmailMutation, { data, loading, error }] = useSendVerifyEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendVerifyEmailMutation(
    baseOptions?: Apollo.MutationHookOptions<SendVerifyEmailMutation, SendVerifyEmailMutationVariables>,
) {
    return Apollo.useMutation<SendVerifyEmailMutation, SendVerifyEmailMutationVariables>(
        SendVerifyEmailDocument,
        baseOptions,
    );
}
export type SendVerifyEmailMutationHookResult = ReturnType<typeof useSendVerifyEmailMutation>;
export type SendVerifyEmailMutationResult = Apollo.MutationResult<SendVerifyEmailMutation>;
export type SendVerifyEmailMutationOptions = Apollo.BaseMutationOptions<
    SendVerifyEmailMutation,
    SendVerifyEmailMutationVariables
>;
export const AcceptMembershipRequestDocument = gql`
    mutation AcceptMembershipRequest($input: AcceptMembershipRequestInput!) {
        acceptMembershipRequest(input: $input) {
            community {
                id
            }
        }
    }
`;
export type AcceptMembershipRequestMutationFn = Apollo.MutationFunction<
    AcceptMembershipRequestMutation,
    AcceptMembershipRequestMutationVariables
>;

/**
 * __useAcceptMembershipRequestMutation__
 *
 * To run a mutation, you first call `useAcceptMembershipRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptMembershipRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptMembershipRequestMutation, { data, loading, error }] = useAcceptMembershipRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptMembershipRequestMutation(
    baseOptions?: Apollo.MutationHookOptions<AcceptMembershipRequestMutation, AcceptMembershipRequestMutationVariables>,
) {
    return Apollo.useMutation<AcceptMembershipRequestMutation, AcceptMembershipRequestMutationVariables>(
        AcceptMembershipRequestDocument,
        baseOptions,
    );
}
export type AcceptMembershipRequestMutationHookResult = ReturnType<typeof useAcceptMembershipRequestMutation>;
export type AcceptMembershipRequestMutationResult = Apollo.MutationResult<AcceptMembershipRequestMutation>;
export type AcceptMembershipRequestMutationOptions = Apollo.BaseMutationOptions<
    AcceptMembershipRequestMutation,
    AcceptMembershipRequestMutationVariables
>;
export const CancelMembershipRequestDocument = gql`
    mutation CancelMembershipRequest($input: CancelMembershipRequestInput!) {
        cancelMembershipRequest(input: $input) {
            community {
                id
                membershipStatus
            }
        }
    }
`;
export type CancelMembershipRequestMutationFn = Apollo.MutationFunction<
    CancelMembershipRequestMutation,
    CancelMembershipRequestMutationVariables
>;

/**
 * __useCancelMembershipRequestMutation__
 *
 * To run a mutation, you first call `useCancelMembershipRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelMembershipRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelMembershipRequestMutation, { data, loading, error }] = useCancelMembershipRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelMembershipRequestMutation(
    baseOptions?: Apollo.MutationHookOptions<CancelMembershipRequestMutation, CancelMembershipRequestMutationVariables>,
) {
    return Apollo.useMutation<CancelMembershipRequestMutation, CancelMembershipRequestMutationVariables>(
        CancelMembershipRequestDocument,
        baseOptions,
    );
}
export type CancelMembershipRequestMutationHookResult = ReturnType<typeof useCancelMembershipRequestMutation>;
export type CancelMembershipRequestMutationResult = Apollo.MutationResult<CancelMembershipRequestMutation>;
export type CancelMembershipRequestMutationOptions = Apollo.BaseMutationOptions<
    CancelMembershipRequestMutation,
    CancelMembershipRequestMutationVariables
>;
export const CreateCommunityInvitationDocument = gql`
    mutation CreateCommunityInvitation($input: CreateCommunityInvitationInput!) {
        createCommunityInvitation(input: $input) {
            invitation {
                id
                usageLimit
                usedCount
                expiresAt
            }
        }
    }
`;
export type CreateCommunityInvitationMutationFn = Apollo.MutationFunction<
    CreateCommunityInvitationMutation,
    CreateCommunityInvitationMutationVariables
>;

/**
 * __useCreateCommunityInvitationMutation__
 *
 * To run a mutation, you first call `useCreateCommunityInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommunityInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommunityInvitationMutation, { data, loading, error }] = useCreateCommunityInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCommunityInvitationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateCommunityInvitationMutation,
        CreateCommunityInvitationMutationVariables
    >,
) {
    return Apollo.useMutation<CreateCommunityInvitationMutation, CreateCommunityInvitationMutationVariables>(
        CreateCommunityInvitationDocument,
        baseOptions,
    );
}
export type CreateCommunityInvitationMutationHookResult = ReturnType<typeof useCreateCommunityInvitationMutation>;
export type CreateCommunityInvitationMutationResult = Apollo.MutationResult<CreateCommunityInvitationMutation>;
export type CreateCommunityInvitationMutationOptions = Apollo.BaseMutationOptions<
    CreateCommunityInvitationMutation,
    CreateCommunityInvitationMutationVariables
>;
export const CreateCommunityDocument = gql`
    mutation CreateCommunity($community: CreateCommunityInput!) {
        createCommunity(community: $community) {
            community {
                id
            }
        }
    }
`;
export type CreateCommunityMutationFn = Apollo.MutationFunction<
    CreateCommunityMutation,
    CreateCommunityMutationVariables
>;

/**
 * __useCreateCommunityMutation__
 *
 * To run a mutation, you first call `useCreateCommunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommunityMutation, { data, loading, error }] = useCreateCommunityMutation({
 *   variables: {
 *      community: // value for 'community'
 *   },
 * });
 */
export function useCreateCommunityMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateCommunityMutation, CreateCommunityMutationVariables>,
) {
    return Apollo.useMutation<CreateCommunityMutation, CreateCommunityMutationVariables>(
        CreateCommunityDocument,
        baseOptions,
    );
}
export type CreateCommunityMutationHookResult = ReturnType<typeof useCreateCommunityMutation>;
export type CreateCommunityMutationResult = Apollo.MutationResult<CreateCommunityMutation>;
export type CreateCommunityMutationOptions = Apollo.BaseMutationOptions<
    CreateCommunityMutation,
    CreateCommunityMutationVariables
>;
export const DeleteCommunityImageDocument = gql`
    mutation DeleteCommunityImage($community: DeleteCommunityImageInput!) {
        deleteCommunityImage(community: $community) {
            community {
                id
            }
        }
    }
`;
export type DeleteCommunityImageMutationFn = Apollo.MutationFunction<
    DeleteCommunityImageMutation,
    DeleteCommunityImageMutationVariables
>;

/**
 * __useDeleteCommunityImageMutation__
 *
 * To run a mutation, you first call `useDeleteCommunityImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommunityImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommunityImageMutation, { data, loading, error }] = useDeleteCommunityImageMutation({
 *   variables: {
 *      community: // value for 'community'
 *   },
 * });
 */
export function useDeleteCommunityImageMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteCommunityImageMutation, DeleteCommunityImageMutationVariables>,
) {
    return Apollo.useMutation<DeleteCommunityImageMutation, DeleteCommunityImageMutationVariables>(
        DeleteCommunityImageDocument,
        baseOptions,
    );
}
export type DeleteCommunityImageMutationHookResult = ReturnType<typeof useDeleteCommunityImageMutation>;
export type DeleteCommunityImageMutationResult = Apollo.MutationResult<DeleteCommunityImageMutation>;
export type DeleteCommunityImageMutationOptions = Apollo.BaseMutationOptions<
    DeleteCommunityImageMutation,
    DeleteCommunityImageMutationVariables
>;
export const DeleteCommunityInvitationDocument = gql`
    mutation DeleteCommunityInvitation($input: DeleteCommunityInvitationyInput!) {
        deleteCommunityInvitation(input: $input) {
            invitation {
                id
            }
        }
    }
`;
export type DeleteCommunityInvitationMutationFn = Apollo.MutationFunction<
    DeleteCommunityInvitationMutation,
    DeleteCommunityInvitationMutationVariables
>;

/**
 * __useDeleteCommunityInvitationMutation__
 *
 * To run a mutation, you first call `useDeleteCommunityInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommunityInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommunityInvitationMutation, { data, loading, error }] = useDeleteCommunityInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCommunityInvitationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteCommunityInvitationMutation,
        DeleteCommunityInvitationMutationVariables
    >,
) {
    return Apollo.useMutation<DeleteCommunityInvitationMutation, DeleteCommunityInvitationMutationVariables>(
        DeleteCommunityInvitationDocument,
        baseOptions,
    );
}
export type DeleteCommunityInvitationMutationHookResult = ReturnType<typeof useDeleteCommunityInvitationMutation>;
export type DeleteCommunityInvitationMutationResult = Apollo.MutationResult<DeleteCommunityInvitationMutation>;
export type DeleteCommunityInvitationMutationOptions = Apollo.BaseMutationOptions<
    DeleteCommunityInvitationMutation,
    DeleteCommunityInvitationMutationVariables
>;
export const DeleteCommunityDocument = gql`
    mutation DeleteCommunity($id: String!) {
        deleteCommunity(id: $id) {
            id
        }
    }
`;
export type DeleteCommunityMutationFn = Apollo.MutationFunction<
    DeleteCommunityMutation,
    DeleteCommunityMutationVariables
>;

/**
 * __useDeleteCommunityMutation__
 *
 * To run a mutation, you first call `useDeleteCommunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommunityMutation, { data, loading, error }] = useDeleteCommunityMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCommunityMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteCommunityMutation, DeleteCommunityMutationVariables>,
) {
    return Apollo.useMutation<DeleteCommunityMutation, DeleteCommunityMutationVariables>(
        DeleteCommunityDocument,
        baseOptions,
    );
}
export type DeleteCommunityMutationHookResult = ReturnType<typeof useDeleteCommunityMutation>;
export type DeleteCommunityMutationResult = Apollo.MutationResult<DeleteCommunityMutation>;
export type DeleteCommunityMutationOptions = Apollo.BaseMutationOptions<
    DeleteCommunityMutation,
    DeleteCommunityMutationVariables
>;
export const JoinCommunityDocument = gql`
    mutation JoinCommunity($input: JoinCommunityInput!) {
        joinCommunity(input: $input) {
            community {
                id
                membershipStatus
            }
        }
    }
`;
export type JoinCommunityMutationFn = Apollo.MutationFunction<JoinCommunityMutation, JoinCommunityMutationVariables>;

/**
 * __useJoinCommunityMutation__
 *
 * To run a mutation, you first call `useJoinCommunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinCommunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinCommunityMutation, { data, loading, error }] = useJoinCommunityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJoinCommunityMutation(
    baseOptions?: Apollo.MutationHookOptions<JoinCommunityMutation, JoinCommunityMutationVariables>,
) {
    return Apollo.useMutation<JoinCommunityMutation, JoinCommunityMutationVariables>(
        JoinCommunityDocument,
        baseOptions,
    );
}
export type JoinCommunityMutationHookResult = ReturnType<typeof useJoinCommunityMutation>;
export type JoinCommunityMutationResult = Apollo.MutationResult<JoinCommunityMutation>;
export type JoinCommunityMutationOptions = Apollo.BaseMutationOptions<
    JoinCommunityMutation,
    JoinCommunityMutationVariables
>;
export const LeaveCommunityDocument = gql`
    mutation LeaveCommunity($input: LeaveCommunityInput!) {
        leaveCommunity(input: $input) {
            community {
                id
                membershipStatus
            }
        }
    }
`;
export type LeaveCommunityMutationFn = Apollo.MutationFunction<LeaveCommunityMutation, LeaveCommunityMutationVariables>;

/**
 * __useLeaveCommunityMutation__
 *
 * To run a mutation, you first call `useLeaveCommunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveCommunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveCommunityMutation, { data, loading, error }] = useLeaveCommunityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLeaveCommunityMutation(
    baseOptions?: Apollo.MutationHookOptions<LeaveCommunityMutation, LeaveCommunityMutationVariables>,
) {
    return Apollo.useMutation<LeaveCommunityMutation, LeaveCommunityMutationVariables>(
        LeaveCommunityDocument,
        baseOptions,
    );
}
export type LeaveCommunityMutationHookResult = ReturnType<typeof useLeaveCommunityMutation>;
export type LeaveCommunityMutationResult = Apollo.MutationResult<LeaveCommunityMutation>;
export type LeaveCommunityMutationOptions = Apollo.BaseMutationOptions<
    LeaveCommunityMutation,
    LeaveCommunityMutationVariables
>;
export const RejectMembershipRequestDocument = gql`
    mutation RejectMembershipRequest($input: RejectMembershipRequestInput!) {
        rejectMembershipRequest(input: $input) {
            id
        }
    }
`;
export type RejectMembershipRequestMutationFn = Apollo.MutationFunction<
    RejectMembershipRequestMutation,
    RejectMembershipRequestMutationVariables
>;

/**
 * __useRejectMembershipRequestMutation__
 *
 * To run a mutation, you first call `useRejectMembershipRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectMembershipRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectMembershipRequestMutation, { data, loading, error }] = useRejectMembershipRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectMembershipRequestMutation(
    baseOptions?: Apollo.MutationHookOptions<RejectMembershipRequestMutation, RejectMembershipRequestMutationVariables>,
) {
    return Apollo.useMutation<RejectMembershipRequestMutation, RejectMembershipRequestMutationVariables>(
        RejectMembershipRequestDocument,
        baseOptions,
    );
}
export type RejectMembershipRequestMutationHookResult = ReturnType<typeof useRejectMembershipRequestMutation>;
export type RejectMembershipRequestMutationResult = Apollo.MutationResult<RejectMembershipRequestMutation>;
export type RejectMembershipRequestMutationOptions = Apollo.BaseMutationOptions<
    RejectMembershipRequestMutation,
    RejectMembershipRequestMutationVariables
>;
export const UpdateCommunityDocument = gql`
    mutation UpdateCommunity($community: UpdateCommunityInput!) {
        updateCommunity(community: $community) {
            community {
                id
            }
        }
    }
`;
export type UpdateCommunityMutationFn = Apollo.MutationFunction<
    UpdateCommunityMutation,
    UpdateCommunityMutationVariables
>;

/**
 * __useUpdateCommunityMutation__
 *
 * To run a mutation, you first call `useUpdateCommunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommunityMutation, { data, loading, error }] = useUpdateCommunityMutation({
 *   variables: {
 *      community: // value for 'community'
 *   },
 * });
 */
export function useUpdateCommunityMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateCommunityMutation, UpdateCommunityMutationVariables>,
) {
    return Apollo.useMutation<UpdateCommunityMutation, UpdateCommunityMutationVariables>(
        UpdateCommunityDocument,
        baseOptions,
    );
}
export type UpdateCommunityMutationHookResult = ReturnType<typeof useUpdateCommunityMutation>;
export type UpdateCommunityMutationResult = Apollo.MutationResult<UpdateCommunityMutation>;
export type UpdateCommunityMutationOptions = Apollo.BaseMutationOptions<
    UpdateCommunityMutation,
    UpdateCommunityMutationVariables
>;
export const UploadCommunityImagesDocument = gql`
    mutation UploadCommunityImages($community: UploadCommunityImagesInput!) {
        uploadCommunityImages(community: $community) {
            community {
                id
                images {
                    edges {
                        node {
                            id
                            url
                            extension
                            blurhash {
                                hash
                                width
                                height
                            }
                        }
                    }
                }
            }
        }
    }
`;
export type UploadCommunityImagesMutationFn = Apollo.MutationFunction<
    UploadCommunityImagesMutation,
    UploadCommunityImagesMutationVariables
>;

/**
 * __useUploadCommunityImagesMutation__
 *
 * To run a mutation, you first call `useUploadCommunityImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadCommunityImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadCommunityImagesMutation, { data, loading, error }] = useUploadCommunityImagesMutation({
 *   variables: {
 *      community: // value for 'community'
 *   },
 * });
 */
export function useUploadCommunityImagesMutation(
    baseOptions?: Apollo.MutationHookOptions<UploadCommunityImagesMutation, UploadCommunityImagesMutationVariables>,
) {
    return Apollo.useMutation<UploadCommunityImagesMutation, UploadCommunityImagesMutationVariables>(
        UploadCommunityImagesDocument,
        baseOptions,
    );
}
export type UploadCommunityImagesMutationHookResult = ReturnType<typeof useUploadCommunityImagesMutation>;
export type UploadCommunityImagesMutationResult = Apollo.MutationResult<UploadCommunityImagesMutation>;
export type UploadCommunityImagesMutationOptions = Apollo.BaseMutationOptions<
    UploadCommunityImagesMutation,
    UploadCommunityImagesMutationVariables
>;
export const UploadCommunityLogoDocument = gql`
    mutation UploadCommunityLogo($community: UploadCommunityLogoInput!) {
        uploadCommunityLogo(community: $community) {
            community {
                id
                logo {
                    id
                    url
                }
            }
        }
    }
`;
export type UploadCommunityLogoMutationFn = Apollo.MutationFunction<
    UploadCommunityLogoMutation,
    UploadCommunityLogoMutationVariables
>;

/**
 * __useUploadCommunityLogoMutation__
 *
 * To run a mutation, you first call `useUploadCommunityLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadCommunityLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadCommunityLogoMutation, { data, loading, error }] = useUploadCommunityLogoMutation({
 *   variables: {
 *      community: // value for 'community'
 *   },
 * });
 */
export function useUploadCommunityLogoMutation(
    baseOptions?: Apollo.MutationHookOptions<UploadCommunityLogoMutation, UploadCommunityLogoMutationVariables>,
) {
    return Apollo.useMutation<UploadCommunityLogoMutation, UploadCommunityLogoMutationVariables>(
        UploadCommunityLogoDocument,
        baseOptions,
    );
}
export type UploadCommunityLogoMutationHookResult = ReturnType<typeof useUploadCommunityLogoMutation>;
export type UploadCommunityLogoMutationResult = Apollo.MutationResult<UploadCommunityLogoMutation>;
export type UploadCommunityLogoMutationOptions = Apollo.BaseMutationOptions<
    UploadCommunityLogoMutation,
    UploadCommunityLogoMutationVariables
>;
export const CreateEventDocument = gql`
    mutation CreateEvent($event: CreateEventInput!) {
        createEvent(event: $event) {
            event {
                id
            }
        }
    }
`;
export type CreateEventMutationFn = Apollo.MutationFunction<CreateEventMutation, CreateEventMutationVariables>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useCreateEventMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateEventMutation, CreateEventMutationVariables>,
) {
    return Apollo.useMutation<CreateEventMutation, CreateEventMutationVariables>(CreateEventDocument, baseOptions);
}
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = Apollo.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<CreateEventMutation, CreateEventMutationVariables>;
export const CreateExperienceDocument = gql`
    mutation CreateExperience($experience: CreateEventInput!) {
        createExperience(experience: $experience) {
            event {
                id
            }
        }
    }
`;
export type CreateExperienceMutationFn = Apollo.MutationFunction<
    CreateExperienceMutation,
    CreateExperienceMutationVariables
>;

/**
 * __useCreateExperienceMutation__
 *
 * To run a mutation, you first call `useCreateExperienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExperienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExperienceMutation, { data, loading, error }] = useCreateExperienceMutation({
 *   variables: {
 *      experience: // value for 'experience'
 *   },
 * });
 */
export function useCreateExperienceMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateExperienceMutation, CreateExperienceMutationVariables>,
) {
    return Apollo.useMutation<CreateExperienceMutation, CreateExperienceMutationVariables>(
        CreateExperienceDocument,
        baseOptions,
    );
}
export type CreateExperienceMutationHookResult = ReturnType<typeof useCreateExperienceMutation>;
export type CreateExperienceMutationResult = Apollo.MutationResult<CreateExperienceMutation>;
export type CreateExperienceMutationOptions = Apollo.BaseMutationOptions<
    CreateExperienceMutation,
    CreateExperienceMutationVariables
>;
export const DeleteEventImageDocument = gql`
    mutation DeleteEventImage($event: DeleteEventImageInput!) {
        deleteEventImage(event: $event) {
            event {
                id
            }
        }
    }
`;
export type DeleteEventImageMutationFn = Apollo.MutationFunction<
    DeleteEventImageMutation,
    DeleteEventImageMutationVariables
>;

/**
 * __useDeleteEventImageMutation__
 *
 * To run a mutation, you first call `useDeleteEventImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventImageMutation, { data, loading, error }] = useDeleteEventImageMutation({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useDeleteEventImageMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteEventImageMutation, DeleteEventImageMutationVariables>,
) {
    return Apollo.useMutation<DeleteEventImageMutation, DeleteEventImageMutationVariables>(
        DeleteEventImageDocument,
        baseOptions,
    );
}
export type DeleteEventImageMutationHookResult = ReturnType<typeof useDeleteEventImageMutation>;
export type DeleteEventImageMutationResult = Apollo.MutationResult<DeleteEventImageMutation>;
export type DeleteEventImageMutationOptions = Apollo.BaseMutationOptions<
    DeleteEventImageMutation,
    DeleteEventImageMutationVariables
>;
export const DeleteEventDocument = gql`
    mutation DeleteEvent($id: String!) {
        deleteEvent(id: $id) {
            id
        }
    }
`;
export type DeleteEventMutationFn = Apollo.MutationFunction<DeleteEventMutation, DeleteEventMutationVariables>;

/**
 * __useDeleteEventMutation__
 *
 * To run a mutation, you first call `useDeleteEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventMutation, { data, loading, error }] = useDeleteEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEventMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteEventMutation, DeleteEventMutationVariables>,
) {
    return Apollo.useMutation<DeleteEventMutation, DeleteEventMutationVariables>(DeleteEventDocument, baseOptions);
}
export type DeleteEventMutationHookResult = ReturnType<typeof useDeleteEventMutation>;
export type DeleteEventMutationResult = Apollo.MutationResult<DeleteEventMutation>;
export type DeleteEventMutationOptions = Apollo.BaseMutationOptions<DeleteEventMutation, DeleteEventMutationVariables>;
export const EventPaymentDocument = gql`
    mutation eventPayment($input: EventPaymentInput!) {
        eventPayment(input: $input) {
            result
        }
    }
`;
export type EventPaymentMutationFn = Apollo.MutationFunction<EventPaymentMutation, EventPaymentMutationVariables>;

/**
 * __useEventPaymentMutation__
 *
 * To run a mutation, you first call `useEventPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEventPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eventPaymentMutation, { data, loading, error }] = useEventPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEventPaymentMutation(
    baseOptions?: Apollo.MutationHookOptions<EventPaymentMutation, EventPaymentMutationVariables>,
) {
    return Apollo.useMutation<EventPaymentMutation, EventPaymentMutationVariables>(EventPaymentDocument, baseOptions);
}
export type EventPaymentMutationHookResult = ReturnType<typeof useEventPaymentMutation>;
export type EventPaymentMutationResult = Apollo.MutationResult<EventPaymentMutation>;
export type EventPaymentMutationOptions = Apollo.BaseMutationOptions<
    EventPaymentMutation,
    EventPaymentMutationVariables
>;
export const ReorderEventImagesDocument = gql`
    mutation ReorderEventImages($event: ReorderEventImagesInput!) {
        reorderEventImages(event: $event) {
            event {
                ...EventParts
            }
        }
    }
    ${EventPartsFragmentDoc}
`;
export type ReorderEventImagesMutationFn = Apollo.MutationFunction<
    ReorderEventImagesMutation,
    ReorderEventImagesMutationVariables
>;

/**
 * __useReorderEventImagesMutation__
 *
 * To run a mutation, you first call `useReorderEventImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderEventImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderEventImagesMutation, { data, loading, error }] = useReorderEventImagesMutation({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useReorderEventImagesMutation(
    baseOptions?: Apollo.MutationHookOptions<ReorderEventImagesMutation, ReorderEventImagesMutationVariables>,
) {
    return Apollo.useMutation<ReorderEventImagesMutation, ReorderEventImagesMutationVariables>(
        ReorderEventImagesDocument,
        baseOptions,
    );
}
export type ReorderEventImagesMutationHookResult = ReturnType<typeof useReorderEventImagesMutation>;
export type ReorderEventImagesMutationResult = Apollo.MutationResult<ReorderEventImagesMutation>;
export type ReorderEventImagesMutationOptions = Apollo.BaseMutationOptions<
    ReorderEventImagesMutation,
    ReorderEventImagesMutationVariables
>;
export const TogglePinDocument = gql`
    mutation TogglePin($event: TogglePinEventInput!) {
        togglePin(event: $event) {
            event {
                id
                pinned
            }
        }
    }
`;
export type TogglePinMutationFn = Apollo.MutationFunction<TogglePinMutation, TogglePinMutationVariables>;

/**
 * __useTogglePinMutation__
 *
 * To run a mutation, you first call `useTogglePinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTogglePinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [togglePinMutation, { data, loading, error }] = useTogglePinMutation({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useTogglePinMutation(
    baseOptions?: Apollo.MutationHookOptions<TogglePinMutation, TogglePinMutationVariables>,
) {
    return Apollo.useMutation<TogglePinMutation, TogglePinMutationVariables>(TogglePinDocument, baseOptions);
}
export type TogglePinMutationHookResult = ReturnType<typeof useTogglePinMutation>;
export type TogglePinMutationResult = Apollo.MutationResult<TogglePinMutation>;
export type TogglePinMutationOptions = Apollo.BaseMutationOptions<TogglePinMutation, TogglePinMutationVariables>;
export const UpdateEventDocument = gql`
    mutation UpdateEvent($event: UpdateEventInput!) {
        updateEvent(event: $event) {
            event {
                id
            }
        }
    }
`;
export type UpdateEventMutationFn = Apollo.MutationFunction<UpdateEventMutation, UpdateEventMutationVariables>;

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventMutation, { data, loading, error }] = useUpdateEventMutation({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useUpdateEventMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateEventMutation, UpdateEventMutationVariables>,
) {
    return Apollo.useMutation<UpdateEventMutation, UpdateEventMutationVariables>(UpdateEventDocument, baseOptions);
}
export type UpdateEventMutationHookResult = ReturnType<typeof useUpdateEventMutation>;
export type UpdateEventMutationResult = Apollo.MutationResult<UpdateEventMutation>;
export type UpdateEventMutationOptions = Apollo.BaseMutationOptions<UpdateEventMutation, UpdateEventMutationVariables>;
export const UploadEventImagesDocument = gql`
    mutation UploadEventImages($event: UploadEventImagesInput!) {
        uploadEventImages(event: $event) {
            event {
                id
                images {
                    edges {
                        node {
                            id
                            order
                            image {
                                id
                                url
                                blurhash {
                                    hash
                                    width
                                    height
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
export type UploadEventImagesMutationFn = Apollo.MutationFunction<
    UploadEventImagesMutation,
    UploadEventImagesMutationVariables
>;

/**
 * __useUploadEventImagesMutation__
 *
 * To run a mutation, you first call `useUploadEventImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadEventImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadEventImagesMutation, { data, loading, error }] = useUploadEventImagesMutation({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useUploadEventImagesMutation(
    baseOptions?: Apollo.MutationHookOptions<UploadEventImagesMutation, UploadEventImagesMutationVariables>,
) {
    return Apollo.useMutation<UploadEventImagesMutation, UploadEventImagesMutationVariables>(
        UploadEventImagesDocument,
        baseOptions,
    );
}
export type UploadEventImagesMutationHookResult = ReturnType<typeof useUploadEventImagesMutation>;
export type UploadEventImagesMutationResult = Apollo.MutationResult<UploadEventImagesMutation>;
export type UploadEventImagesMutationOptions = Apollo.BaseMutationOptions<
    UploadEventImagesMutation,
    UploadEventImagesMutationVariables
>;
export const AcceptFollowDocument = gql`
    mutation AcceptFollow($input: AcceptFollowInput!) {
        acceptFollow(input: $input) {
            follow {
                id
                accepted
            }
        }
    }
`;
export type AcceptFollowMutationFn = Apollo.MutationFunction<AcceptFollowMutation, AcceptFollowMutationVariables>;

/**
 * __useAcceptFollowMutation__
 *
 * To run a mutation, you first call `useAcceptFollowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptFollowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptFollowMutation, { data, loading, error }] = useAcceptFollowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptFollowMutation(
    baseOptions?: Apollo.MutationHookOptions<AcceptFollowMutation, AcceptFollowMutationVariables>,
) {
    return Apollo.useMutation<AcceptFollowMutation, AcceptFollowMutationVariables>(AcceptFollowDocument, baseOptions);
}
export type AcceptFollowMutationHookResult = ReturnType<typeof useAcceptFollowMutation>;
export type AcceptFollowMutationResult = Apollo.MutationResult<AcceptFollowMutation>;
export type AcceptFollowMutationOptions = Apollo.BaseMutationOptions<
    AcceptFollowMutation,
    AcceptFollowMutationVariables
>;
export const CancelFollowDocument = gql`
    mutation CancelFollow($input: CancelFollowInput!) {
        cancelFollow(input: $input)
    }
`;
export type CancelFollowMutationFn = Apollo.MutationFunction<CancelFollowMutation, CancelFollowMutationVariables>;

/**
 * __useCancelFollowMutation__
 *
 * To run a mutation, you first call `useCancelFollowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelFollowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelFollowMutation, { data, loading, error }] = useCancelFollowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelFollowMutation(
    baseOptions?: Apollo.MutationHookOptions<CancelFollowMutation, CancelFollowMutationVariables>,
) {
    return Apollo.useMutation<CancelFollowMutation, CancelFollowMutationVariables>(CancelFollowDocument, baseOptions);
}
export type CancelFollowMutationHookResult = ReturnType<typeof useCancelFollowMutation>;
export type CancelFollowMutationResult = Apollo.MutationResult<CancelFollowMutation>;
export type CancelFollowMutationOptions = Apollo.BaseMutationOptions<
    CancelFollowMutation,
    CancelFollowMutationVariables
>;
export const FollowDocument = gql`
    mutation Follow($input: FollowUserInput!) {
        followUser(input: $input) {
            follow {
                id
            }
        }
    }
`;
export type FollowMutationFn = Apollo.MutationFunction<FollowMutation, FollowMutationVariables>;

/**
 * __useFollowMutation__
 *
 * To run a mutation, you first call `useFollowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followMutation, { data, loading, error }] = useFollowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFollowMutation(baseOptions?: Apollo.MutationHookOptions<FollowMutation, FollowMutationVariables>) {
    return Apollo.useMutation<FollowMutation, FollowMutationVariables>(FollowDocument, baseOptions);
}
export type FollowMutationHookResult = ReturnType<typeof useFollowMutation>;
export type FollowMutationResult = Apollo.MutationResult<FollowMutation>;
export type FollowMutationOptions = Apollo.BaseMutationOptions<FollowMutation, FollowMutationVariables>;
export const RejectFollowDocument = gql`
    mutation RejectFollow($input: RejectFollowInput!) {
        rejectFollow(input: $input) {
            follow {
                id
                accepted
            }
        }
    }
`;
export type RejectFollowMutationFn = Apollo.MutationFunction<RejectFollowMutation, RejectFollowMutationVariables>;

/**
 * __useRejectFollowMutation__
 *
 * To run a mutation, you first call `useRejectFollowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectFollowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectFollowMutation, { data, loading, error }] = useRejectFollowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectFollowMutation(
    baseOptions?: Apollo.MutationHookOptions<RejectFollowMutation, RejectFollowMutationVariables>,
) {
    return Apollo.useMutation<RejectFollowMutation, RejectFollowMutationVariables>(RejectFollowDocument, baseOptions);
}
export type RejectFollowMutationHookResult = ReturnType<typeof useRejectFollowMutation>;
export type RejectFollowMutationResult = Apollo.MutationResult<RejectFollowMutation>;
export type RejectFollowMutationOptions = Apollo.BaseMutationOptions<
    RejectFollowMutation,
    RejectFollowMutationVariables
>;
export const RemoveFollowerDocument = gql`
    mutation RemoveFollower($input: RemoveFollowerInput!) {
        removeFollower(input: $input)
    }
`;
export type RemoveFollowerMutationFn = Apollo.MutationFunction<RemoveFollowerMutation, RemoveFollowerMutationVariables>;

/**
 * __useRemoveFollowerMutation__
 *
 * To run a mutation, you first call `useRemoveFollowerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFollowerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFollowerMutation, { data, loading, error }] = useRemoveFollowerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveFollowerMutation(
    baseOptions?: Apollo.MutationHookOptions<RemoveFollowerMutation, RemoveFollowerMutationVariables>,
) {
    return Apollo.useMutation<RemoveFollowerMutation, RemoveFollowerMutationVariables>(
        RemoveFollowerDocument,
        baseOptions,
    );
}
export type RemoveFollowerMutationHookResult = ReturnType<typeof useRemoveFollowerMutation>;
export type RemoveFollowerMutationResult = Apollo.MutationResult<RemoveFollowerMutation>;
export type RemoveFollowerMutationOptions = Apollo.BaseMutationOptions<
    RemoveFollowerMutation,
    RemoveFollowerMutationVariables
>;
export const UnfollowDocument = gql`
    mutation Unfollow($input: UnfollowInput!) {
        unfollow(input: $input)
    }
`;
export type UnfollowMutationFn = Apollo.MutationFunction<UnfollowMutation, UnfollowMutationVariables>;

/**
 * __useUnfollowMutation__
 *
 * To run a mutation, you first call `useUnfollowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnfollowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unfollowMutation, { data, loading, error }] = useUnfollowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnfollowMutation(
    baseOptions?: Apollo.MutationHookOptions<UnfollowMutation, UnfollowMutationVariables>,
) {
    return Apollo.useMutation<UnfollowMutation, UnfollowMutationVariables>(UnfollowDocument, baseOptions);
}
export type UnfollowMutationHookResult = ReturnType<typeof useUnfollowMutation>;
export type UnfollowMutationResult = Apollo.MutationResult<UnfollowMutation>;
export type UnfollowMutationOptions = Apollo.BaseMutationOptions<UnfollowMutation, UnfollowMutationVariables>;
export const DeleteManagerDocument = gql`
    mutation DeleteManager($input: DeleteManagerInput!) {
        deleteManager(input: $input) {
            id
        }
    }
`;
export type DeleteManagerMutationFn = Apollo.MutationFunction<DeleteManagerMutation, DeleteManagerMutationVariables>;

/**
 * __useDeleteManagerMutation__
 *
 * To run a mutation, you first call `useDeleteManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManagerMutation, { data, loading, error }] = useDeleteManagerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteManagerMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteManagerMutation, DeleteManagerMutationVariables>,
) {
    return Apollo.useMutation<DeleteManagerMutation, DeleteManagerMutationVariables>(
        DeleteManagerDocument,
        baseOptions,
    );
}
export type DeleteManagerMutationHookResult = ReturnType<typeof useDeleteManagerMutation>;
export type DeleteManagerMutationResult = Apollo.MutationResult<DeleteManagerMutation>;
export type DeleteManagerMutationOptions = Apollo.BaseMutationOptions<
    DeleteManagerMutation,
    DeleteManagerMutationVariables
>;
export const RegisterManagerDocument = gql`
    mutation RegisterManager($manager: RegisterManagerInput!) {
        registerManager(manager: $manager) {
            id
            user {
                id
                fullName
                avatar {
                    id
                    url
                }
            }
        }
    }
`;
export type RegisterManagerMutationFn = Apollo.MutationFunction<
    RegisterManagerMutation,
    RegisterManagerMutationVariables
>;

/**
 * __useRegisterManagerMutation__
 *
 * To run a mutation, you first call `useRegisterManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerManagerMutation, { data, loading, error }] = useRegisterManagerMutation({
 *   variables: {
 *      manager: // value for 'manager'
 *   },
 * });
 */
export function useRegisterManagerMutation(
    baseOptions?: Apollo.MutationHookOptions<RegisterManagerMutation, RegisterManagerMutationVariables>,
) {
    return Apollo.useMutation<RegisterManagerMutation, RegisterManagerMutationVariables>(
        RegisterManagerDocument,
        baseOptions,
    );
}
export type RegisterManagerMutationHookResult = ReturnType<typeof useRegisterManagerMutation>;
export type RegisterManagerMutationResult = Apollo.MutationResult<RegisterManagerMutation>;
export type RegisterManagerMutationOptions = Apollo.BaseMutationOptions<
    RegisterManagerMutation,
    RegisterManagerMutationVariables
>;
export const UpdateManagerDocument = gql`
    mutation UpdateManager($manager: UpdateManagerInput!) {
        updateManager(manager: $manager) {
            manager {
                id
                user {
                    id
                    firstName
                    lastName
                    email
                    phoneNumber
                    bio
                    dateOfBirth
                    gender
                }
            }
        }
    }
`;
export type UpdateManagerMutationFn = Apollo.MutationFunction<UpdateManagerMutation, UpdateManagerMutationVariables>;

/**
 * __useUpdateManagerMutation__
 *
 * To run a mutation, you first call `useUpdateManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManagerMutation, { data, loading, error }] = useUpdateManagerMutation({
 *   variables: {
 *      manager: // value for 'manager'
 *   },
 * });
 */
export function useUpdateManagerMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateManagerMutation, UpdateManagerMutationVariables>,
) {
    return Apollo.useMutation<UpdateManagerMutation, UpdateManagerMutationVariables>(
        UpdateManagerDocument,
        baseOptions,
    );
}
export type UpdateManagerMutationHookResult = ReturnType<typeof useUpdateManagerMutation>;
export type UpdateManagerMutationResult = Apollo.MutationResult<UpdateManagerMutation>;
export type UpdateManagerMutationOptions = Apollo.BaseMutationOptions<
    UpdateManagerMutation,
    UpdateManagerMutationVariables
>;
export const CreateMemberProfileDocument = gql`
    mutation CreateMemberProfile($memberProfile: CreateMemberProfileInput!) {
        createMemberProfile(memberProfile: $memberProfile) {
            memberProfile {
                id
            }
        }
    }
`;
export type CreateMemberProfileMutationFn = Apollo.MutationFunction<
    CreateMemberProfileMutation,
    CreateMemberProfileMutationVariables
>;

/**
 * __useCreateMemberProfileMutation__
 *
 * To run a mutation, you first call `useCreateMemberProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemberProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemberProfileMutation, { data, loading, error }] = useCreateMemberProfileMutation({
 *   variables: {
 *      memberProfile: // value for 'memberProfile'
 *   },
 * });
 */
export function useCreateMemberProfileMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateMemberProfileMutation, CreateMemberProfileMutationVariables>,
) {
    return Apollo.useMutation<CreateMemberProfileMutation, CreateMemberProfileMutationVariables>(
        CreateMemberProfileDocument,
        baseOptions,
    );
}
export type CreateMemberProfileMutationHookResult = ReturnType<typeof useCreateMemberProfileMutation>;
export type CreateMemberProfileMutationResult = Apollo.MutationResult<CreateMemberProfileMutation>;
export type CreateMemberProfileMutationOptions = Apollo.BaseMutationOptions<
    CreateMemberProfileMutation,
    CreateMemberProfileMutationVariables
>;
export const DeleteMemberProfileDocument = gql`
    mutation DeleteMemberProfile($id: String!) {
        deleteMemberProfile(id: $id) {
            firstName
        }
    }
`;
export type DeleteMemberProfileMutationFn = Apollo.MutationFunction<
    DeleteMemberProfileMutation,
    DeleteMemberProfileMutationVariables
>;

/**
 * __useDeleteMemberProfileMutation__
 *
 * To run a mutation, you first call `useDeleteMemberProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMemberProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMemberProfileMutation, { data, loading, error }] = useDeleteMemberProfileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMemberProfileMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteMemberProfileMutation, DeleteMemberProfileMutationVariables>,
) {
    return Apollo.useMutation<DeleteMemberProfileMutation, DeleteMemberProfileMutationVariables>(
        DeleteMemberProfileDocument,
        baseOptions,
    );
}
export type DeleteMemberProfileMutationHookResult = ReturnType<typeof useDeleteMemberProfileMutation>;
export type DeleteMemberProfileMutationResult = Apollo.MutationResult<DeleteMemberProfileMutation>;
export type DeleteMemberProfileMutationOptions = Apollo.BaseMutationOptions<
    DeleteMemberProfileMutation,
    DeleteMemberProfileMutationVariables
>;
export const UpdateMemberProfileDocument = gql`
    mutation UpdateMemberProfile($memberProfile: UpdateMemberProfileInput!) {
        updateMemberProfile(memberProfile: $memberProfile) {
            memberProfile {
                ...MemberProfileParts
            }
        }
    }
    ${MemberProfilePartsFragmentDoc}
`;
export type UpdateMemberProfileMutationFn = Apollo.MutationFunction<
    UpdateMemberProfileMutation,
    UpdateMemberProfileMutationVariables
>;

/**
 * __useUpdateMemberProfileMutation__
 *
 * To run a mutation, you first call `useUpdateMemberProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberProfileMutation, { data, loading, error }] = useUpdateMemberProfileMutation({
 *   variables: {
 *      memberProfile: // value for 'memberProfile'
 *   },
 * });
 */
export function useUpdateMemberProfileMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateMemberProfileMutation, UpdateMemberProfileMutationVariables>,
) {
    return Apollo.useMutation<UpdateMemberProfileMutation, UpdateMemberProfileMutationVariables>(
        UpdateMemberProfileDocument,
        baseOptions,
    );
}
export type UpdateMemberProfileMutationHookResult = ReturnType<typeof useUpdateMemberProfileMutation>;
export type UpdateMemberProfileMutationResult = Apollo.MutationResult<UpdateMemberProfileMutation>;
export type UpdateMemberProfileMutationOptions = Apollo.BaseMutationOptions<
    UpdateMemberProfileMutation,
    UpdateMemberProfileMutationVariables
>;
export const UpdateMemberDocument = gql`
    mutation UpdateMember($member: UpdateMemberInput!) {
        updateMember(member: $member) {
            member {
                id
                maritalStatus
                cityOfResidence
                countryOfResidence {
                    id
                    name
                }
                countryOfResidenceId
                user {
                    id
                    firstName
                    lastName
                    email
                    phoneNumber
                    dateOfBirth
                    incognito
                    nickname
                }
            }
        }
    }
`;
export type UpdateMemberMutationFn = Apollo.MutationFunction<UpdateMemberMutation, UpdateMemberMutationVariables>;

/**
 * __useUpdateMemberMutation__
 *
 * To run a mutation, you first call `useUpdateMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberMutation, { data, loading, error }] = useUpdateMemberMutation({
 *   variables: {
 *      member: // value for 'member'
 *   },
 * });
 */
export function useUpdateMemberMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateMemberMutation, UpdateMemberMutationVariables>,
) {
    return Apollo.useMutation<UpdateMemberMutation, UpdateMemberMutationVariables>(UpdateMemberDocument, baseOptions);
}
export type UpdateMemberMutationHookResult = ReturnType<typeof useUpdateMemberMutation>;
export type UpdateMemberMutationResult = Apollo.MutationResult<UpdateMemberMutation>;
export type UpdateMemberMutationOptions = Apollo.BaseMutationOptions<
    UpdateMemberMutation,
    UpdateMemberMutationVariables
>;
export const AddPaymentMethodDocument = gql`
    mutation addPaymentMethod($input: AddPaymentMethodInput!) {
        addPaymentMethod(input: $input) {
            paymentMethod {
                id
                brand
                cartToken
                funding
                lastFour
                expYear
                expMonth
                isDefault
            }
        }
    }
`;
export type AddPaymentMethodMutationFn = Apollo.MutationFunction<
    AddPaymentMethodMutation,
    AddPaymentMethodMutationVariables
>;

/**
 * __useAddPaymentMethodMutation__
 *
 * To run a mutation, you first call `useAddPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPaymentMethodMutation, { data, loading, error }] = useAddPaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPaymentMethodMutation(
    baseOptions?: Apollo.MutationHookOptions<AddPaymentMethodMutation, AddPaymentMethodMutationVariables>,
) {
    return Apollo.useMutation<AddPaymentMethodMutation, AddPaymentMethodMutationVariables>(
        AddPaymentMethodDocument,
        baseOptions,
    );
}
export type AddPaymentMethodMutationHookResult = ReturnType<typeof useAddPaymentMethodMutation>;
export type AddPaymentMethodMutationResult = Apollo.MutationResult<AddPaymentMethodMutation>;
export type AddPaymentMethodMutationOptions = Apollo.BaseMutationOptions<
    AddPaymentMethodMutation,
    AddPaymentMethodMutationVariables
>;
export const CancelSubscriptionDocument = gql`
    mutation cancelSubscription {
        cancelSubscription
    }
`;
export type CancelSubscriptionMutationFn = Apollo.MutationFunction<
    CancelSubscriptionMutation,
    CancelSubscriptionMutationVariables
>;

/**
 * __useCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubscriptionMutation, { data, loading, error }] = useCancelSubscriptionMutation({
 *   variables: {
 *   },
 * });
 */
export function useCancelSubscriptionMutation(
    baseOptions?: Apollo.MutationHookOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>,
) {
    return Apollo.useMutation<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>(
        CancelSubscriptionDocument,
        baseOptions,
    );
}
export type CancelSubscriptionMutationHookResult = ReturnType<typeof useCancelSubscriptionMutation>;
export type CancelSubscriptionMutationResult = Apollo.MutationResult<CancelSubscriptionMutation>;
export type CancelSubscriptionMutationOptions = Apollo.BaseMutationOptions<
    CancelSubscriptionMutation,
    CancelSubscriptionMutationVariables
>;
export const DeletePaymentMethodDocument = gql`
    mutation deletePaymentMethod($id: String!) {
        deletePaymentMethod(id: $id)
    }
`;
export type DeletePaymentMethodMutationFn = Apollo.MutationFunction<
    DeletePaymentMethodMutation,
    DeletePaymentMethodMutationVariables
>;

/**
 * __useDeletePaymentMethodMutation__
 *
 * To run a mutation, you first call `useDeletePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentMethodMutation, { data, loading, error }] = useDeletePaymentMethodMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePaymentMethodMutation(
    baseOptions?: Apollo.MutationHookOptions<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>,
) {
    return Apollo.useMutation<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>(
        DeletePaymentMethodDocument,
        baseOptions,
    );
}
export type DeletePaymentMethodMutationHookResult = ReturnType<typeof useDeletePaymentMethodMutation>;
export type DeletePaymentMethodMutationResult = Apollo.MutationResult<DeletePaymentMethodMutation>;
export type DeletePaymentMethodMutationOptions = Apollo.BaseMutationOptions<
    DeletePaymentMethodMutation,
    DeletePaymentMethodMutationVariables
>;
export const SetDefaultPaymentMethodDocument = gql`
    mutation setDefaultPaymentMethod($paymentMethodId: String!) {
        setDefaultPaymentMethod(paymentMethodId: $paymentMethodId) {
            paymentMethodId
        }
    }
`;
export type SetDefaultPaymentMethodMutationFn = Apollo.MutationFunction<
    SetDefaultPaymentMethodMutation,
    SetDefaultPaymentMethodMutationVariables
>;

/**
 * __useSetDefaultPaymentMethodMutation__
 *
 * To run a mutation, you first call `useSetDefaultPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDefaultPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDefaultPaymentMethodMutation, { data, loading, error }] = useSetDefaultPaymentMethodMutation({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useSetDefaultPaymentMethodMutation(
    baseOptions?: Apollo.MutationHookOptions<SetDefaultPaymentMethodMutation, SetDefaultPaymentMethodMutationVariables>,
) {
    return Apollo.useMutation<SetDefaultPaymentMethodMutation, SetDefaultPaymentMethodMutationVariables>(
        SetDefaultPaymentMethodDocument,
        baseOptions,
    );
}
export type SetDefaultPaymentMethodMutationHookResult = ReturnType<typeof useSetDefaultPaymentMethodMutation>;
export type SetDefaultPaymentMethodMutationResult = Apollo.MutationResult<SetDefaultPaymentMethodMutation>;
export type SetDefaultPaymentMethodMutationOptions = Apollo.BaseMutationOptions<
    SetDefaultPaymentMethodMutation,
    SetDefaultPaymentMethodMutationVariables
>;
export const SubscribeDocument = gql`
    mutation subscribe($input: SubscriptionInput!) {
        subscription(input: $input) {
            result
        }
    }
`;
export type SubscribeMutationFn = Apollo.MutationFunction<SubscribeMutation, SubscribeMutationVariables>;

/**
 * __useSubscribeMutation__
 *
 * To run a mutation, you first call `useSubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeMutation, { data, loading, error }] = useSubscribeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubscribeMutation(
    baseOptions?: Apollo.MutationHookOptions<SubscribeMutation, SubscribeMutationVariables>,
) {
    return Apollo.useMutation<SubscribeMutation, SubscribeMutationVariables>(SubscribeDocument, baseOptions);
}
export type SubscribeMutationHookResult = ReturnType<typeof useSubscribeMutation>;
export type SubscribeMutationResult = Apollo.MutationResult<SubscribeMutation>;
export type SubscribeMutationOptions = Apollo.BaseMutationOptions<SubscribeMutation, SubscribeMutationVariables>;
export const BookmarkPostDocument = gql`
    mutation BookmarkPost($post: BookmarkPostInput!) {
        bookmarkPost(post: $post) {
            post {
                id
                isBookmarked
            }
        }
    }
`;
export type BookmarkPostMutationFn = Apollo.MutationFunction<BookmarkPostMutation, BookmarkPostMutationVariables>;

/**
 * __useBookmarkPostMutation__
 *
 * To run a mutation, you first call `useBookmarkPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookmarkPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookmarkPostMutation, { data, loading, error }] = useBookmarkPostMutation({
 *   variables: {
 *      post: // value for 'post'
 *   },
 * });
 */
export function useBookmarkPostMutation(
    baseOptions?: Apollo.MutationHookOptions<BookmarkPostMutation, BookmarkPostMutationVariables>,
) {
    return Apollo.useMutation<BookmarkPostMutation, BookmarkPostMutationVariables>(BookmarkPostDocument, baseOptions);
}
export type BookmarkPostMutationHookResult = ReturnType<typeof useBookmarkPostMutation>;
export type BookmarkPostMutationResult = Apollo.MutationResult<BookmarkPostMutation>;
export type BookmarkPostMutationOptions = Apollo.BaseMutationOptions<
    BookmarkPostMutation,
    BookmarkPostMutationVariables
>;
export const CreatePostCommentDocument = gql`
    mutation CreatePostComment($comment: CreatePostCommentInput!) {
        createPostComment(comment: $comment) {
            comment {
                id
                text
                userId
                createdAt
                author {
                    id
                    fullName
                    email
                    role {
                        id
                        name
                    }
                    statusText
                    avatar {
                        id
                        url
                    }
                    member {
                        id
                    }
                }
            }
        }
    }
`;
export type CreatePostCommentMutationFn = Apollo.MutationFunction<
    CreatePostCommentMutation,
    CreatePostCommentMutationVariables
>;

/**
 * __useCreatePostCommentMutation__
 *
 * To run a mutation, you first call `useCreatePostCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostCommentMutation, { data, loading, error }] = useCreatePostCommentMutation({
 *   variables: {
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useCreatePostCommentMutation(
    baseOptions?: Apollo.MutationHookOptions<CreatePostCommentMutation, CreatePostCommentMutationVariables>,
) {
    return Apollo.useMutation<CreatePostCommentMutation, CreatePostCommentMutationVariables>(
        CreatePostCommentDocument,
        baseOptions,
    );
}
export type CreatePostCommentMutationHookResult = ReturnType<typeof useCreatePostCommentMutation>;
export type CreatePostCommentMutationResult = Apollo.MutationResult<CreatePostCommentMutation>;
export type CreatePostCommentMutationOptions = Apollo.BaseMutationOptions<
    CreatePostCommentMutation,
    CreatePostCommentMutationVariables
>;
export const CreatePostDocument = gql`
    mutation CreatePost($post: CreatePostInput!) {
        createPost(post: $post) {
            post {
                ...PostParts
                meta {
                    ... on RepostMeta {
                        post {
                            ...PostParts
                        }
                    }
                }
            }
        }
    }
    ${PostPartsFragmentDoc}
`;
export type CreatePostMutationFn = Apollo.MutationFunction<CreatePostMutation, CreatePostMutationVariables>;

/**
 * __useCreatePostMutation__
 *
 * To run a mutation, you first call `useCreatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostMutation, { data, loading, error }] = useCreatePostMutation({
 *   variables: {
 *      post: // value for 'post'
 *   },
 * });
 */
export function useCreatePostMutation(
    baseOptions?: Apollo.MutationHookOptions<CreatePostMutation, CreatePostMutationVariables>,
) {
    return Apollo.useMutation<CreatePostMutation, CreatePostMutationVariables>(CreatePostDocument, baseOptions);
}
export type CreatePostMutationHookResult = ReturnType<typeof useCreatePostMutation>;
export type CreatePostMutationResult = Apollo.MutationResult<CreatePostMutation>;
export type CreatePostMutationOptions = Apollo.BaseMutationOptions<CreatePostMutation, CreatePostMutationVariables>;
export const DeletePostCommentDocument = gql`
    mutation DeletePostComment($id: String!) {
        deletePostComment(id: $id) {
            comment {
                id
            }
        }
    }
`;
export type DeletePostCommentMutationFn = Apollo.MutationFunction<
    DeletePostCommentMutation,
    DeletePostCommentMutationVariables
>;

/**
 * __useDeletePostCommentMutation__
 *
 * To run a mutation, you first call `useDeletePostCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePostCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePostCommentMutation, { data, loading, error }] = useDeletePostCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePostCommentMutation(
    baseOptions?: Apollo.MutationHookOptions<DeletePostCommentMutation, DeletePostCommentMutationVariables>,
) {
    return Apollo.useMutation<DeletePostCommentMutation, DeletePostCommentMutationVariables>(
        DeletePostCommentDocument,
        baseOptions,
    );
}
export type DeletePostCommentMutationHookResult = ReturnType<typeof useDeletePostCommentMutation>;
export type DeletePostCommentMutationResult = Apollo.MutationResult<DeletePostCommentMutation>;
export type DeletePostCommentMutationOptions = Apollo.BaseMutationOptions<
    DeletePostCommentMutation,
    DeletePostCommentMutationVariables
>;
export const DeletePostDocument = gql`
    mutation DeletePost($id: String!) {
        deletePost(id: $id) {
            id
        }
    }
`;
export type DeletePostMutationFn = Apollo.MutationFunction<DeletePostMutation, DeletePostMutationVariables>;

/**
 * __useDeletePostMutation__
 *
 * To run a mutation, you first call `useDeletePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePostMutation, { data, loading, error }] = useDeletePostMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePostMutation(
    baseOptions?: Apollo.MutationHookOptions<DeletePostMutation, DeletePostMutationVariables>,
) {
    return Apollo.useMutation<DeletePostMutation, DeletePostMutationVariables>(DeletePostDocument, baseOptions);
}
export type DeletePostMutationHookResult = ReturnType<typeof useDeletePostMutation>;
export type DeletePostMutationResult = Apollo.MutationResult<DeletePostMutation>;
export type DeletePostMutationOptions = Apollo.BaseMutationOptions<DeletePostMutation, DeletePostMutationVariables>;
export const DislikePostDocument = gql`
    mutation DislikePost($post: DislikePostInput!) {
        dislikePost(post: $post) {
            likeCount
        }
    }
`;
export type DislikePostMutationFn = Apollo.MutationFunction<DislikePostMutation, DislikePostMutationVariables>;

/**
 * __useDislikePostMutation__
 *
 * To run a mutation, you first call `useDislikePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDislikePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dislikePostMutation, { data, loading, error }] = useDislikePostMutation({
 *   variables: {
 *      post: // value for 'post'
 *   },
 * });
 */
export function useDislikePostMutation(
    baseOptions?: Apollo.MutationHookOptions<DislikePostMutation, DislikePostMutationVariables>,
) {
    return Apollo.useMutation<DislikePostMutation, DislikePostMutationVariables>(DislikePostDocument, baseOptions);
}
export type DislikePostMutationHookResult = ReturnType<typeof useDislikePostMutation>;
export type DislikePostMutationResult = Apollo.MutationResult<DislikePostMutation>;
export type DislikePostMutationOptions = Apollo.BaseMutationOptions<DislikePostMutation, DislikePostMutationVariables>;
export const LikePostDocument = gql`
    mutation LikePost($post: LikePostInput!) {
        likePost(post: $post) {
            likeCount
        }
    }
`;
export type LikePostMutationFn = Apollo.MutationFunction<LikePostMutation, LikePostMutationVariables>;

/**
 * __useLikePostMutation__
 *
 * To run a mutation, you first call `useLikePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLikePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [likePostMutation, { data, loading, error }] = useLikePostMutation({
 *   variables: {
 *      post: // value for 'post'
 *   },
 * });
 */
export function useLikePostMutation(
    baseOptions?: Apollo.MutationHookOptions<LikePostMutation, LikePostMutationVariables>,
) {
    return Apollo.useMutation<LikePostMutation, LikePostMutationVariables>(LikePostDocument, baseOptions);
}
export type LikePostMutationHookResult = ReturnType<typeof useLikePostMutation>;
export type LikePostMutationResult = Apollo.MutationResult<LikePostMutation>;
export type LikePostMutationOptions = Apollo.BaseMutationOptions<LikePostMutation, LikePostMutationVariables>;
export const SharePostDocument = gql`
    mutation SharePost($post: SharePostInput!) {
        sharePost(post: $post) {
            post {
                ...PostParts
                meta {
                    ... on RepostMeta {
                        post {
                            ...PostParts
                        }
                    }
                }
            }
        }
    }
    ${PostPartsFragmentDoc}
`;
export type SharePostMutationFn = Apollo.MutationFunction<SharePostMutation, SharePostMutationVariables>;

/**
 * __useSharePostMutation__
 *
 * To run a mutation, you first call `useSharePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSharePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sharePostMutation, { data, loading, error }] = useSharePostMutation({
 *   variables: {
 *      post: // value for 'post'
 *   },
 * });
 */
export function useSharePostMutation(
    baseOptions?: Apollo.MutationHookOptions<SharePostMutation, SharePostMutationVariables>,
) {
    return Apollo.useMutation<SharePostMutation, SharePostMutationVariables>(SharePostDocument, baseOptions);
}
export type SharePostMutationHookResult = ReturnType<typeof useSharePostMutation>;
export type SharePostMutationResult = Apollo.MutationResult<SharePostMutation>;
export type SharePostMutationOptions = Apollo.BaseMutationOptions<SharePostMutation, SharePostMutationVariables>;
export const UpdatePostDocument = gql`
    mutation UpdatePost($post: UpdatePostInput!) {
        updatePost(post: $post) {
            post {
                ...PostParts
                meta {
                    ... on RepostMeta {
                        post {
                            ...PostParts
                        }
                    }
                }
            }
        }
    }
    ${PostPartsFragmentDoc}
`;
export type UpdatePostMutationFn = Apollo.MutationFunction<UpdatePostMutation, UpdatePostMutationVariables>;

/**
 * __useUpdatePostMutation__
 *
 * To run a mutation, you first call `useUpdatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostMutation, { data, loading, error }] = useUpdatePostMutation({
 *   variables: {
 *      post: // value for 'post'
 *   },
 * });
 */
export function useUpdatePostMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdatePostMutation, UpdatePostMutationVariables>,
) {
    return Apollo.useMutation<UpdatePostMutation, UpdatePostMutationVariables>(UpdatePostDocument, baseOptions);
}
export type UpdatePostMutationHookResult = ReturnType<typeof useUpdatePostMutation>;
export type UpdatePostMutationResult = Apollo.MutationResult<UpdatePostMutation>;
export type UpdatePostMutationOptions = Apollo.BaseMutationOptions<UpdatePostMutation, UpdatePostMutationVariables>;
export const UploadUserAvatarDocument = gql`
    mutation UploadUserAvatar($input: UploadUserAvatarInput!) {
        uploadUserAvatar(input: $input) {
            user {
                ...UserParts
            }
        }
    }
    ${UserPartsFragmentDoc}
`;
export type UploadUserAvatarMutationFn = Apollo.MutationFunction<
    UploadUserAvatarMutation,
    UploadUserAvatarMutationVariables
>;

/**
 * __useUploadUserAvatarMutation__
 *
 * To run a mutation, you first call `useUploadUserAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadUserAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadUserAvatarMutation, { data, loading, error }] = useUploadUserAvatarMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadUserAvatarMutation(
    baseOptions?: Apollo.MutationHookOptions<UploadUserAvatarMutation, UploadUserAvatarMutationVariables>,
) {
    return Apollo.useMutation<UploadUserAvatarMutation, UploadUserAvatarMutationVariables>(
        UploadUserAvatarDocument,
        baseOptions,
    );
}
export type UploadUserAvatarMutationHookResult = ReturnType<typeof useUploadUserAvatarMutation>;
export type UploadUserAvatarMutationResult = Apollo.MutationResult<UploadUserAvatarMutation>;
export type UploadUserAvatarMutationOptions = Apollo.BaseMutationOptions<
    UploadUserAvatarMutation,
    UploadUserAvatarMutationVariables
>;
export const UploadUserCoverPhotoDocument = gql`
    mutation UploadUserCoverPhoto($input: UploadUserCoverPhotoInput!) {
        uploadUserCoverPhoto(input: $input) {
            user {
                ...UserParts
            }
        }
    }
    ${UserPartsFragmentDoc}
`;
export type UploadUserCoverPhotoMutationFn = Apollo.MutationFunction<
    UploadUserCoverPhotoMutation,
    UploadUserCoverPhotoMutationVariables
>;

/**
 * __useUploadUserCoverPhotoMutation__
 *
 * To run a mutation, you first call `useUploadUserCoverPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadUserCoverPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadUserCoverPhotoMutation, { data, loading, error }] = useUploadUserCoverPhotoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadUserCoverPhotoMutation(
    baseOptions?: Apollo.MutationHookOptions<UploadUserCoverPhotoMutation, UploadUserCoverPhotoMutationVariables>,
) {
    return Apollo.useMutation<UploadUserCoverPhotoMutation, UploadUserCoverPhotoMutationVariables>(
        UploadUserCoverPhotoDocument,
        baseOptions,
    );
}
export type UploadUserCoverPhotoMutationHookResult = ReturnType<typeof useUploadUserCoverPhotoMutation>;
export type UploadUserCoverPhotoMutationResult = Apollo.MutationResult<UploadUserCoverPhotoMutation>;
export type UploadUserCoverPhotoMutationOptions = Apollo.BaseMutationOptions<
    UploadUserCoverPhotoMutation,
    UploadUserCoverPhotoMutationVariables
>;
export const ActivityCountDocument = gql`
    query ActivityCount {
        activityCount
    }
`;

/**
 * __useActivityCountQuery__
 *
 * To run a query within a React component, call `useActivityCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useActivityCountQuery(
    baseOptions?: Apollo.QueryHookOptions<ActivityCountQuery, ActivityCountQueryVariables>,
) {
    return Apollo.useQuery<ActivityCountQuery, ActivityCountQueryVariables>(ActivityCountDocument, baseOptions);
}
export function useActivityCountLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ActivityCountQuery, ActivityCountQueryVariables>,
) {
    return Apollo.useLazyQuery<ActivityCountQuery, ActivityCountQueryVariables>(ActivityCountDocument, baseOptions);
}
export type ActivityCountQueryHookResult = ReturnType<typeof useActivityCountQuery>;
export type ActivityCountLazyQueryHookResult = ReturnType<typeof useActivityCountLazyQuery>;
export type ActivityCountQueryResult = Apollo.QueryResult<ActivityCountQuery, ActivityCountQueryVariables>;
export const ChatableUsersDocument = gql`
    query ChatableUsers($page: Int, $where: ChatableUsersWhereInput) {
        chatableUsers(page: $page, where: $where) {
            edges {
                node {
                    id
                    fullName
                    avatar {
                        id
                        url
                    }
                    matrixAuth {
                        access_token
                        device_id
                        home_server
                        user_id
                    }
                }
            }
            pageInfo {
                hasNextPage
            }
        }
    }
`;

/**
 * __useChatableUsersQuery__
 *
 * To run a query within a React component, call `useChatableUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatableUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatableUsersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useChatableUsersQuery(
    baseOptions?: Apollo.QueryHookOptions<ChatableUsersQuery, ChatableUsersQueryVariables>,
) {
    return Apollo.useQuery<ChatableUsersQuery, ChatableUsersQueryVariables>(ChatableUsersDocument, baseOptions);
}
export function useChatableUsersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ChatableUsersQuery, ChatableUsersQueryVariables>,
) {
    return Apollo.useLazyQuery<ChatableUsersQuery, ChatableUsersQueryVariables>(ChatableUsersDocument, baseOptions);
}
export type ChatableUsersQueryHookResult = ReturnType<typeof useChatableUsersQuery>;
export type ChatableUsersLazyQueryHookResult = ReturnType<typeof useChatableUsersLazyQuery>;
export type ChatableUsersQueryResult = Apollo.QueryResult<ChatableUsersQuery, ChatableUsersQueryVariables>;
export const CitiesDocument = gql`
    query Cities {
        cities {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`;

/**
 * __useCitiesQuery__
 *
 * To run a query within a React component, call `useCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCitiesQuery(baseOptions?: Apollo.QueryHookOptions<CitiesQuery, CitiesQueryVariables>) {
    return Apollo.useQuery<CitiesQuery, CitiesQueryVariables>(CitiesDocument, baseOptions);
}
export function useCitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CitiesQuery, CitiesQueryVariables>) {
    return Apollo.useLazyQuery<CitiesQuery, CitiesQueryVariables>(CitiesDocument, baseOptions);
}
export type CitiesQueryHookResult = ReturnType<typeof useCitiesQuery>;
export type CitiesLazyQueryHookResult = ReturnType<typeof useCitiesLazyQuery>;
export type CitiesQueryResult = Apollo.QueryResult<CitiesQuery, CitiesQueryVariables>;
export const CommunitiesDocument = gql`
    query Communities($page: Int, $where: CommunityWhereInput) {
        communities(page: $page, where: $where) {
            pageInfo {
                hasNextPage
            }
            edges {
                node {
                    id
                    translations {
                        edges {
                            node {
                                id
                                lang
                                name
                                location
                                description
                                communityId
                            }
                        }
                    }
                    joinRule
                    images {
                        edges {
                            node {
                                id
                                url
                            }
                        }
                    }
                    logo {
                        id
                        url
                    }
                }
            }
        }
    }
`;

/**
 * __useCommunitiesQuery__
 *
 * To run a query within a React component, call `useCommunitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunitiesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCommunitiesQuery(
    baseOptions?: Apollo.QueryHookOptions<CommunitiesQuery, CommunitiesQueryVariables>,
) {
    return Apollo.useQuery<CommunitiesQuery, CommunitiesQueryVariables>(CommunitiesDocument, baseOptions);
}
export function useCommunitiesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CommunitiesQuery, CommunitiesQueryVariables>,
) {
    return Apollo.useLazyQuery<CommunitiesQuery, CommunitiesQueryVariables>(CommunitiesDocument, baseOptions);
}
export type CommunitiesQueryHookResult = ReturnType<typeof useCommunitiesQuery>;
export type CommunitiesLazyQueryHookResult = ReturnType<typeof useCommunitiesLazyQuery>;
export type CommunitiesQueryResult = Apollo.QueryResult<CommunitiesQuery, CommunitiesQueryVariables>;
export const CommunityInvitationDocument = gql`
    query CommunityInvitation($communityInvitationId: String!) {
        communityInvitation(id: $communityInvitationId) {
            id
            usageLimit
            usedCount
            expiresAt
            community {
                id
                translations {
                    edges {
                        node {
                            id
                            lang
                            name
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useCommunityInvitationQuery__
 *
 * To run a query within a React component, call `useCommunityInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunityInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunityInvitationQuery({
 *   variables: {
 *      communityInvitationId: // value for 'communityInvitationId'
 *   },
 * });
 */
export function useCommunityInvitationQuery(
    baseOptions: Apollo.QueryHookOptions<CommunityInvitationQuery, CommunityInvitationQueryVariables>,
) {
    return Apollo.useQuery<CommunityInvitationQuery, CommunityInvitationQueryVariables>(
        CommunityInvitationDocument,
        baseOptions,
    );
}
export function useCommunityInvitationLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CommunityInvitationQuery, CommunityInvitationQueryVariables>,
) {
    return Apollo.useLazyQuery<CommunityInvitationQuery, CommunityInvitationQueryVariables>(
        CommunityInvitationDocument,
        baseOptions,
    );
}
export type CommunityInvitationQueryHookResult = ReturnType<typeof useCommunityInvitationQuery>;
export type CommunityInvitationLazyQueryHookResult = ReturnType<typeof useCommunityInvitationLazyQuery>;
export type CommunityInvitationQueryResult = Apollo.QueryResult<
    CommunityInvitationQuery,
    CommunityInvitationQueryVariables
>;
export const CommunityMembersDocument = gql`
    query CommunityMembers($where: CommunityMembersWhereInput!, $page: Int) {
        communityMembers(where: $where, page: $page) {
            pageInfo {
                hasNextPage
            }
            edges {
                node {
                    id
                    status
                    createdAt
                    user {
                        id
                        firstName
                        lastName
                        bio
                        email
                        phoneNumber
                        dateOfBirth
                        fullName
                        avatar {
                            id
                            url
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useCommunityMembersQuery__
 *
 * To run a query within a React component, call `useCommunityMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunityMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunityMembersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useCommunityMembersQuery(
    baseOptions: Apollo.QueryHookOptions<CommunityMembersQuery, CommunityMembersQueryVariables>,
) {
    return Apollo.useQuery<CommunityMembersQuery, CommunityMembersQueryVariables>(
        CommunityMembersDocument,
        baseOptions,
    );
}
export function useCommunityMembersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CommunityMembersQuery, CommunityMembersQueryVariables>,
) {
    return Apollo.useLazyQuery<CommunityMembersQuery, CommunityMembersQueryVariables>(
        CommunityMembersDocument,
        baseOptions,
    );
}
export type CommunityMembersQueryHookResult = ReturnType<typeof useCommunityMembersQuery>;
export type CommunityMembersLazyQueryHookResult = ReturnType<typeof useCommunityMembersLazyQuery>;
export type CommunityMembersQueryResult = Apollo.QueryResult<CommunityMembersQuery, CommunityMembersQueryVariables>;
export const CommunityDocument = gql`
    query Community($id: String!) {
        community(id: $id) {
            id
            translations {
                edges {
                    node {
                        id
                        lang
                        name
                        description
                        location
                    }
                }
            }
            membershipStatus
            joinRule
            category
            isInternal
            memberCount
            city {
                id
                name
            }
            benefits {
                text
                icon
            }
            managers {
                edges {
                    node {
                        id
                        user {
                            id
                            fullName
                            avatar {
                                id
                                url
                            }
                        }
                        userId
                    }
                }
            }
            invitations {
                edges {
                    node {
                        id
                        usageLimit
                        usedCount
                        expiresAt
                    }
                }
            }
            logo {
                id
                url
            }
            images {
                edges {
                    node {
                        id
                        url
                        extension
                        blurhash {
                            hash
                            width
                            height
                        }
                    }
                }
            }
            managers {
                edges {
                    node {
                        userId
                    }
                }
            }
        }
    }
`;

/**
 * __useCommunityQuery__
 *
 * To run a query within a React component, call `useCommunityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCommunityQuery(baseOptions: Apollo.QueryHookOptions<CommunityQuery, CommunityQueryVariables>) {
    return Apollo.useQuery<CommunityQuery, CommunityQueryVariables>(CommunityDocument, baseOptions);
}
export function useCommunityLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CommunityQuery, CommunityQueryVariables>,
) {
    return Apollo.useLazyQuery<CommunityQuery, CommunityQueryVariables>(CommunityDocument, baseOptions);
}
export type CommunityQueryHookResult = ReturnType<typeof useCommunityQuery>;
export type CommunityLazyQueryHookResult = ReturnType<typeof useCommunityLazyQuery>;
export type CommunityQueryResult = Apollo.QueryResult<CommunityQuery, CommunityQueryVariables>;
export const CommunityPartnersDocument = gql`
    query CommunityPartners(
        $where: CommunityPartnersWhereInput!
        $page: Int
        $pageSize: Int
        $orderBy: CommunityPartnersOrderBy
    ) {
        communityPartners(where: $where, page: $page, pageSize: $pageSize, orderBy: $orderBy) {
            edges {
                node {
                    id
                    translations {
                        edges {
                            node {
                                id
                                communityId
                                description
                                lang
                                location
                                name
                            }
                        }
                    }
                    images {
                        edges {
                            node {
                                id
                                name
                            }
                        }
                    }
                    logo {
                        id
                        url
                    }
                }
            }
            totalCount
            pageInfo {
                page
                totalPages
                hasPreviousPage
                hasNextPage
            }
        }
    }
`;

/**
 * __useCommunityPartnersQuery__
 *
 * To run a query within a React component, call `useCommunityPartnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunityPartnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunityPartnersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useCommunityPartnersQuery(
    baseOptions: Apollo.QueryHookOptions<CommunityPartnersQuery, CommunityPartnersQueryVariables>,
) {
    return Apollo.useQuery<CommunityPartnersQuery, CommunityPartnersQueryVariables>(
        CommunityPartnersDocument,
        baseOptions,
    );
}
export function useCommunityPartnersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CommunityPartnersQuery, CommunityPartnersQueryVariables>,
) {
    return Apollo.useLazyQuery<CommunityPartnersQuery, CommunityPartnersQueryVariables>(
        CommunityPartnersDocument,
        baseOptions,
    );
}
export type CommunityPartnersQueryHookResult = ReturnType<typeof useCommunityPartnersQuery>;
export type CommunityPartnersLazyQueryHookResult = ReturnType<typeof useCommunityPartnersLazyQuery>;
export type CommunityPartnersQueryResult = Apollo.QueryResult<CommunityPartnersQuery, CommunityPartnersQueryVariables>;
export const PendingMembershipsDocument = gql`
    query PendingMemberships($where: PendingMembershipWhereInput!, $page: Int) {
        pendingMemberships(where: $where, page: $page) {
            pageInfo {
                hasNextPage
            }
            edges {
                node {
                    id
                    member {
                        id
                        user {
                            id
                            fullName
                            avatar {
                                id
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __usePendingMembershipsQuery__
 *
 * To run a query within a React component, call `usePendingMembershipsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingMembershipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingMembershipsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePendingMembershipsQuery(
    baseOptions: Apollo.QueryHookOptions<PendingMembershipsQuery, PendingMembershipsQueryVariables>,
) {
    return Apollo.useQuery<PendingMembershipsQuery, PendingMembershipsQueryVariables>(
        PendingMembershipsDocument,
        baseOptions,
    );
}
export function usePendingMembershipsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<PendingMembershipsQuery, PendingMembershipsQueryVariables>,
) {
    return Apollo.useLazyQuery<PendingMembershipsQuery, PendingMembershipsQueryVariables>(
        PendingMembershipsDocument,
        baseOptions,
    );
}
export type PendingMembershipsQueryHookResult = ReturnType<typeof usePendingMembershipsQuery>;
export type PendingMembershipsLazyQueryHookResult = ReturnType<typeof usePendingMembershipsLazyQuery>;
export type PendingMembershipsQueryResult = Apollo.QueryResult<
    PendingMembershipsQuery,
    PendingMembershipsQueryVariables
>;
export const UserCommunitiesDocument = gql`
    query UserCommunities {
        me {
            id
            communities {
                edges {
                    node {
                        id
                        memberCount
                        pendingMembershipsCount
                        translations {
                            edges {
                                node {
                                    id
                                    name
                                    location
                                }
                            }
                        }
                        city {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useUserCommunitiesQuery__
 *
 * To run a query within a React component, call `useUserCommunitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCommunitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCommunitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserCommunitiesQuery(
    baseOptions?: Apollo.QueryHookOptions<UserCommunitiesQuery, UserCommunitiesQueryVariables>,
) {
    return Apollo.useQuery<UserCommunitiesQuery, UserCommunitiesQueryVariables>(UserCommunitiesDocument, baseOptions);
}
export function useUserCommunitiesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<UserCommunitiesQuery, UserCommunitiesQueryVariables>,
) {
    return Apollo.useLazyQuery<UserCommunitiesQuery, UserCommunitiesQueryVariables>(
        UserCommunitiesDocument,
        baseOptions,
    );
}
export type UserCommunitiesQueryHookResult = ReturnType<typeof useUserCommunitiesQuery>;
export type UserCommunitiesLazyQueryHookResult = ReturnType<typeof useUserCommunitiesLazyQuery>;
export type UserCommunitiesQueryResult = Apollo.QueryResult<UserCommunitiesQuery, UserCommunitiesQueryVariables>;
export const CountriesDocument = gql`
    query Countries {
        countries {
            edges {
                node {
                    id
                    code
                    name
                }
            }
        }
    }
`;

/**
 * __useCountriesQuery__
 *
 * To run a query within a React component, call `useCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountriesQuery(baseOptions?: Apollo.QueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
    return Apollo.useQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, baseOptions);
}
export function useCountriesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CountriesQuery, CountriesQueryVariables>,
) {
    return Apollo.useLazyQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, baseOptions);
}
export type CountriesQueryHookResult = ReturnType<typeof useCountriesQuery>;
export type CountriesLazyQueryHookResult = ReturnType<typeof useCountriesLazyQuery>;
export type CountriesQueryResult = Apollo.QueryResult<CountriesQuery, CountriesQueryVariables>;
export const EventDocument = gql`
    query Event($id: String!) {
        event(id: $id) {
            ...EventParts
        }
    }
    ${EventPartsFragmentDoc}
`;

/**
 * __useEventQuery__
 *
 * To run a query within a React component, call `useEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventQuery(baseOptions: Apollo.QueryHookOptions<EventQuery, EventQueryVariables>) {
    return Apollo.useQuery<EventQuery, EventQueryVariables>(EventDocument, baseOptions);
}
export function useEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventQuery, EventQueryVariables>) {
    return Apollo.useLazyQuery<EventQuery, EventQueryVariables>(EventDocument, baseOptions);
}
export type EventQueryHookResult = ReturnType<typeof useEventQuery>;
export type EventLazyQueryHookResult = ReturnType<typeof useEventLazyQuery>;
export type EventQueryResult = Apollo.QueryResult<EventQuery, EventQueryVariables>;
export const EventsDocument = gql`
    query Events($page: Int, $where: EventWhereInput) {
        events(page: $page, where: $where) {
            pageInfo {
                hasNextPage
            }
            edges {
                node {
                    ...EventParts
                }
            }
        }
    }
    ${EventPartsFragmentDoc}
`;

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useEventsQuery(baseOptions?: Apollo.QueryHookOptions<EventsQuery, EventsQueryVariables>) {
    return Apollo.useQuery<EventsQuery, EventsQueryVariables>(EventsDocument, baseOptions);
}
export function useEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventsQuery, EventsQueryVariables>) {
    return Apollo.useLazyQuery<EventsQuery, EventsQueryVariables>(EventsDocument, baseOptions);
}
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>;
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>;
export type EventsQueryResult = Apollo.QueryResult<EventsQuery, EventsQueryVariables>;
export const ExperienceDocument = gql`
    query Experience($id: String!) {
        experience(id: $id) {
            ...ExperienceParts
        }
    }
    ${ExperiencePartsFragmentDoc}
`;

/**
 * __useExperienceQuery__
 *
 * To run a query within a React component, call `useExperienceQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperienceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperienceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExperienceQuery(baseOptions: Apollo.QueryHookOptions<ExperienceQuery, ExperienceQueryVariables>) {
    return Apollo.useQuery<ExperienceQuery, ExperienceQueryVariables>(ExperienceDocument, baseOptions);
}
export function useExperienceLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ExperienceQuery, ExperienceQueryVariables>,
) {
    return Apollo.useLazyQuery<ExperienceQuery, ExperienceQueryVariables>(ExperienceDocument, baseOptions);
}
export type ExperienceQueryHookResult = ReturnType<typeof useExperienceQuery>;
export type ExperienceLazyQueryHookResult = ReturnType<typeof useExperienceLazyQuery>;
export type ExperienceQueryResult = Apollo.QueryResult<ExperienceQuery, ExperienceQueryVariables>;
export const ExperiencesDocument = gql`
    query Experiences($page: Int, $where: EventWhereInput) {
        experiences(page: $page, where: $where) {
            pageInfo {
                hasNextPage
            }
            edges {
                node {
                    ...ExperienceParts
                }
            }
        }
    }
    ${ExperiencePartsFragmentDoc}
`;

/**
 * __useExperiencesQuery__
 *
 * To run a query within a React component, call `useExperiencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperiencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperiencesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useExperiencesQuery(
    baseOptions?: Apollo.QueryHookOptions<ExperiencesQuery, ExperiencesQueryVariables>,
) {
    return Apollo.useQuery<ExperiencesQuery, ExperiencesQueryVariables>(ExperiencesDocument, baseOptions);
}
export function useExperiencesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ExperiencesQuery, ExperiencesQueryVariables>,
) {
    return Apollo.useLazyQuery<ExperiencesQuery, ExperiencesQueryVariables>(ExperiencesDocument, baseOptions);
}
export type ExperiencesQueryHookResult = ReturnType<typeof useExperiencesQuery>;
export type ExperiencesLazyQueryHookResult = ReturnType<typeof useExperiencesLazyQuery>;
export type ExperiencesQueryResult = Apollo.QueryResult<ExperiencesQuery, ExperiencesQueryVariables>;
export const PastEventsDocument = gql`
    query PastEvents($page: Int, $where: EventWhereInput) {
        pastEvents(page: $page, where: $where) {
            pageInfo {
                hasNextPage
            }
            edges {
                node {
                    ...EventParts
                }
            }
        }
    }
    ${EventPartsFragmentDoc}
`;

/**
 * __usePastEventsQuery__
 *
 * To run a query within a React component, call `usePastEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePastEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePastEventsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePastEventsQuery(baseOptions?: Apollo.QueryHookOptions<PastEventsQuery, PastEventsQueryVariables>) {
    return Apollo.useQuery<PastEventsQuery, PastEventsQueryVariables>(PastEventsDocument, baseOptions);
}
export function usePastEventsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<PastEventsQuery, PastEventsQueryVariables>,
) {
    return Apollo.useLazyQuery<PastEventsQuery, PastEventsQueryVariables>(PastEventsDocument, baseOptions);
}
export type PastEventsQueryHookResult = ReturnType<typeof usePastEventsQuery>;
export type PastEventsLazyQueryHookResult = ReturnType<typeof usePastEventsLazyQuery>;
export type PastEventsQueryResult = Apollo.QueryResult<PastEventsQuery, PastEventsQueryVariables>;
export const PlansDocument = gql`
    query plans {
        plans {
            edges {
                node {
                    id
                    code
                    price
                    benefits
                }
            }
        }
    }
`;

/**
 * __usePlansQuery__
 *
 * To run a query within a React component, call `usePlansQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlansQuery({
 *   variables: {
 *   },
 * });
 */
export function usePlansQuery(baseOptions?: Apollo.QueryHookOptions<PlansQuery, PlansQueryVariables>) {
    return Apollo.useQuery<PlansQuery, PlansQueryVariables>(PlansDocument, baseOptions);
}
export function usePlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlansQuery, PlansQueryVariables>) {
    return Apollo.useLazyQuery<PlansQuery, PlansQueryVariables>(PlansDocument, baseOptions);
}
export type PlansQueryHookResult = ReturnType<typeof usePlansQuery>;
export type PlansLazyQueryHookResult = ReturnType<typeof usePlansLazyQuery>;
export type PlansQueryResult = Apollo.QueryResult<PlansQuery, PlansQueryVariables>;
export const UpcomingEventsDocument = gql`
    query UpcomingEvents($page: Int, $where: EventWhereInput) {
        upcomingEvents(page: $page, where: $where) {
            pageInfo {
                hasNextPage
            }
            edges {
                node {
                    ...EventParts
                }
            }
        }
    }
    ${EventPartsFragmentDoc}
`;

/**
 * __useUpcomingEventsQuery__
 *
 * To run a query within a React component, call `useUpcomingEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcomingEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcomingEventsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpcomingEventsQuery(
    baseOptions?: Apollo.QueryHookOptions<UpcomingEventsQuery, UpcomingEventsQueryVariables>,
) {
    return Apollo.useQuery<UpcomingEventsQuery, UpcomingEventsQueryVariables>(UpcomingEventsDocument, baseOptions);
}
export function useUpcomingEventsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<UpcomingEventsQuery, UpcomingEventsQueryVariables>,
) {
    return Apollo.useLazyQuery<UpcomingEventsQuery, UpcomingEventsQueryVariables>(UpcomingEventsDocument, baseOptions);
}
export type UpcomingEventsQueryHookResult = ReturnType<typeof useUpcomingEventsQuery>;
export type UpcomingEventsLazyQueryHookResult = ReturnType<typeof useUpcomingEventsLazyQuery>;
export type UpcomingEventsQueryResult = Apollo.QueryResult<UpcomingEventsQuery, UpcomingEventsQueryVariables>;
export const MeManagerDocument = gql`
    query MeManager {
        me {
            id
            firstName
            lastName
            email
            bio
            phoneNumber
            dateOfBirth
            gender
            incognito
            nickname
            avatar {
                id
                url
            }
        }
    }
`;

/**
 * __useMeManagerQuery__
 *
 * To run a query within a React component, call `useMeManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeManagerQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeManagerQuery(baseOptions?: Apollo.QueryHookOptions<MeManagerQuery, MeManagerQueryVariables>) {
    return Apollo.useQuery<MeManagerQuery, MeManagerQueryVariables>(MeManagerDocument, baseOptions);
}
export function useMeManagerLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<MeManagerQuery, MeManagerQueryVariables>,
) {
    return Apollo.useLazyQuery<MeManagerQuery, MeManagerQueryVariables>(MeManagerDocument, baseOptions);
}
export type MeManagerQueryHookResult = ReturnType<typeof useMeManagerQuery>;
export type MeManagerLazyQueryHookResult = ReturnType<typeof useMeManagerLazyQuery>;
export type MeManagerQueryResult = Apollo.QueryResult<MeManagerQuery, MeManagerQueryVariables>;
export const MeDocument = gql`
    query Me {
        me {
            id
            email
            firstName
            lastName
            fullName
            avatar {
                id
                url
            }
            statusText
            role {
                id
                name
            }
            matrixAuth {
                access_token
                device_id
                home_server
                user_id
            }
            member {
                id
                status
            }
        }
    }
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
    return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
}
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
    return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const MeMemberDocument = gql`
    query MeMember {
        me {
            subscription {
                id
                expiresAt
                plan {
                    id
                    code
                    benefits
                }
            }
            id
            incognito
            nickname
            avatar {
                id
                url
            }
            member {
                id
                maritalStatus
                cityOfResidence
                countryOfResidence {
                    id
                    name
                }
                countryOfResidenceId
                user {
                    id
                    firstName
                    lastName
                    bio
                    gender
                    email
                    phoneNumber
                    dateOfBirth
                    incognito
                    nickname
                }
                profiles {
                    edges {
                        node {
                            ...MemberProfileParts
                        }
                    }
                }
            }
        }
    }
    ${MemberProfilePartsFragmentDoc}
`;

/**
 * __useMeMemberQuery__
 *
 * To run a query within a React component, call `useMeMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeMemberQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeMemberQuery(baseOptions?: Apollo.QueryHookOptions<MeMemberQuery, MeMemberQueryVariables>) {
    return Apollo.useQuery<MeMemberQuery, MeMemberQueryVariables>(MeMemberDocument, baseOptions);
}
export function useMeMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeMemberQuery, MeMemberQueryVariables>) {
    return Apollo.useLazyQuery<MeMemberQuery, MeMemberQueryVariables>(MeMemberDocument, baseOptions);
}
export type MeMemberQueryHookResult = ReturnType<typeof useMeMemberQuery>;
export type MeMemberLazyQueryHookResult = ReturnType<typeof useMeMemberLazyQuery>;
export type MeMemberQueryResult = Apollo.QueryResult<MeMemberQuery, MeMemberQueryVariables>;
export const MemberDocument = gql`
    query Member($id: String!) {
        member(id: $id) {
            id
            status
            maritalStatus
            cityOfResidence
            countryOfResidence {
                id
                name
            }
            user {
                id
                firstName
                lastName
                bio
                email
                phoneNumber
                dateOfBirth
            }
            profiles {
                edges {
                    node {
                        ...MemberProfileParts
                    }
                }
            }
        }
    }
    ${MemberProfilePartsFragmentDoc}
`;

/**
 * __useMemberQuery__
 *
 * To run a query within a React component, call `useMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMemberQuery(baseOptions: Apollo.QueryHookOptions<MemberQuery, MemberQueryVariables>) {
    return Apollo.useQuery<MemberQuery, MemberQueryVariables>(MemberDocument, baseOptions);
}
export function useMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MemberQuery, MemberQueryVariables>) {
    return Apollo.useLazyQuery<MemberQuery, MemberQueryVariables>(MemberDocument, baseOptions);
}
export type MemberQueryHookResult = ReturnType<typeof useMemberQuery>;
export type MemberLazyQueryHookResult = ReturnType<typeof useMemberLazyQuery>;
export type MemberQueryResult = Apollo.QueryResult<MemberQuery, MemberQueryVariables>;
export const MembersDocument = gql`
    query Members($where: MemberWhereInput!) {
        members(where: $where) {
            edges {
                node {
                    id
                    status
                    createdAt
                    user {
                        id
                        firstName
                        lastName
                        bio
                        email
                        phoneNumber
                        dateOfBirth
                    }
                }
            }
        }
    }
`;

/**
 * __useMembersQuery__
 *
 * To run a query within a React component, call `useMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembersQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useMembersQuery(baseOptions: Apollo.QueryHookOptions<MembersQuery, MembersQueryVariables>) {
    return Apollo.useQuery<MembersQuery, MembersQueryVariables>(MembersDocument, baseOptions);
}
export function useMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembersQuery, MembersQueryVariables>) {
    return Apollo.useLazyQuery<MembersQuery, MembersQueryVariables>(MembersDocument, baseOptions);
}
export type MembersQueryHookResult = ReturnType<typeof useMembersQuery>;
export type MembersLazyQueryHookResult = ReturnType<typeof useMembersLazyQuery>;
export type MembersQueryResult = Apollo.QueryResult<MembersQuery, MembersQueryVariables>;
export const PaymentMethodsDocument = gql`
    query PaymentMethods {
        paymentMethods {
            edges {
                node {
                    id
                    isDefault
                    lastFour
                    funding
                    expYear
                    expMonth
                    brand
                    cartToken
                }
            }
        }
    }
`;

/**
 * __usePaymentMethodsQuery__
 *
 * To run a query within a React component, call `usePaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentMethodsQuery(
    baseOptions?: Apollo.QueryHookOptions<PaymentMethodsQuery, PaymentMethodsQueryVariables>,
) {
    return Apollo.useQuery<PaymentMethodsQuery, PaymentMethodsQueryVariables>(PaymentMethodsDocument, baseOptions);
}
export function usePaymentMethodsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<PaymentMethodsQuery, PaymentMethodsQueryVariables>,
) {
    return Apollo.useLazyQuery<PaymentMethodsQuery, PaymentMethodsQueryVariables>(PaymentMethodsDocument, baseOptions);
}
export type PaymentMethodsQueryHookResult = ReturnType<typeof usePaymentMethodsQuery>;
export type PaymentMethodsLazyQueryHookResult = ReturnType<typeof usePaymentMethodsLazyQuery>;
export type PaymentMethodsQueryResult = Apollo.QueryResult<PaymentMethodsQuery, PaymentMethodsQueryVariables>;
export const PostDetailDocument = gql`
    query PostDetail($id: String!) {
        post(id: $id) {
            ...PostParts
            meta {
                ... on RepostMeta {
                    post {
                        ...PostParts
                    }
                }
            }
        }
    }
    ${PostPartsFragmentDoc}
`;

/**
 * __usePostDetailQuery__
 *
 * To run a query within a React component, call `usePostDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePostDetailQuery(baseOptions: Apollo.QueryHookOptions<PostDetailQuery, PostDetailQueryVariables>) {
    return Apollo.useQuery<PostDetailQuery, PostDetailQueryVariables>(PostDetailDocument, baseOptions);
}
export function usePostDetailLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<PostDetailQuery, PostDetailQueryVariables>,
) {
    return Apollo.useLazyQuery<PostDetailQuery, PostDetailQueryVariables>(PostDetailDocument, baseOptions);
}
export type PostDetailQueryHookResult = ReturnType<typeof usePostDetailQuery>;
export type PostDetailLazyQueryHookResult = ReturnType<typeof usePostDetailLazyQuery>;
export type PostDetailQueryResult = Apollo.QueryResult<PostDetailQuery, PostDetailQueryVariables>;
export const PostDocument = gql`
    query Post($id: String!, $page: Int) {
        post(id: $id) {
            id
            visibility
            comments(page: $page) {
                edges {
                    node {
                        id
                        text
                        userId
                        createdAt
                        author {
                            id
                            fullName
                            role {
                                id
                                name
                            }
                            statusText
                            avatar {
                                id
                                url
                            }
                            member {
                                id
                            }
                        }
                    }
                }
                pageInfo {
                    hasNextPage
                }
            }
        }
    }
`;

/**
 * __usePostQuery__
 *
 * To run a query within a React component, call `usePostQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostQuery({
 *   variables: {
 *      id: // value for 'id'
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePostQuery(baseOptions: Apollo.QueryHookOptions<PostQuery, PostQueryVariables>) {
    return Apollo.useQuery<PostQuery, PostQueryVariables>(PostDocument, baseOptions);
}
export function usePostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostQuery, PostQueryVariables>) {
    return Apollo.useLazyQuery<PostQuery, PostQueryVariables>(PostDocument, baseOptions);
}
export type PostQueryHookResult = ReturnType<typeof usePostQuery>;
export type PostLazyQueryHookResult = ReturnType<typeof usePostLazyQuery>;
export type PostQueryResult = Apollo.QueryResult<PostQuery, PostQueryVariables>;
export const PostsDocument = gql`
    query Posts($page: Int, $where: PostWhereInput!) {
        posts(page: $page, where: $where) {
            pageInfo {
                totalPages
            }
            edges {
                node {
                    ...PostParts
                    meta {
                        ... on RepostMeta {
                            post {
                                ...PostParts
                            }
                        }
                    }
                }
            }
        }
    }
    ${PostPartsFragmentDoc}
`;

/**
 * __usePostsQuery__
 *
 * To run a query within a React component, call `usePostsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePostsQuery(baseOptions: Apollo.QueryHookOptions<PostsQuery, PostsQueryVariables>) {
    return Apollo.useQuery<PostsQuery, PostsQueryVariables>(PostsDocument, baseOptions);
}
export function usePostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostsQuery, PostsQueryVariables>) {
    return Apollo.useLazyQuery<PostsQuery, PostsQueryVariables>(PostsDocument, baseOptions);
}
export type PostsQueryHookResult = ReturnType<typeof usePostsQuery>;
export type PostsLazyQueryHookResult = ReturnType<typeof usePostsLazyQuery>;
export type PostsQueryResult = Apollo.QueryResult<PostsQuery, PostsQueryVariables>;
export const RepostedUsersDocument = gql`
    query RepostedUsers($where: RepostedUserWhereInput!, $page: Int) {
        repostedUsers(where: $where, page: $page) {
            edges {
                node {
                    id
                    fullName
                    avatar {
                        id
                        url
                    }
                }
            }
            pageInfo {
                hasNextPage
            }
        }
    }
`;

/**
 * __useRepostedUsersQuery__
 *
 * To run a query within a React component, call `useRepostedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useRepostedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRepostedUsersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useRepostedUsersQuery(
    baseOptions: Apollo.QueryHookOptions<RepostedUsersQuery, RepostedUsersQueryVariables>,
) {
    return Apollo.useQuery<RepostedUsersQuery, RepostedUsersQueryVariables>(RepostedUsersDocument, baseOptions);
}
export function useRepostedUsersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<RepostedUsersQuery, RepostedUsersQueryVariables>,
) {
    return Apollo.useLazyQuery<RepostedUsersQuery, RepostedUsersQueryVariables>(RepostedUsersDocument, baseOptions);
}
export type RepostedUsersQueryHookResult = ReturnType<typeof useRepostedUsersQuery>;
export type RepostedUsersLazyQueryHookResult = ReturnType<typeof useRepostedUsersLazyQuery>;
export type RepostedUsersQueryResult = Apollo.QueryResult<RepostedUsersQuery, RepostedUsersQueryVariables>;
export const SavedPostsDocument = gql`
    query SavedPosts($page: Int, $where: PostWhereInput!) {
        bookmarkedPosts(page: $page, where: $where) {
            pageInfo {
                totalPages
            }
            edges {
                node {
                    ...PostParts
                    meta {
                        ... on RepostMeta {
                            post {
                                ...PostParts
                            }
                        }
                    }
                }
            }
        }
    }
    ${PostPartsFragmentDoc}
`;

/**
 * __useSavedPostsQuery__
 *
 * To run a query within a React component, call `useSavedPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSavedPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSavedPostsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSavedPostsQuery(baseOptions: Apollo.QueryHookOptions<SavedPostsQuery, SavedPostsQueryVariables>) {
    return Apollo.useQuery<SavedPostsQuery, SavedPostsQueryVariables>(SavedPostsDocument, baseOptions);
}
export function useSavedPostsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<SavedPostsQuery, SavedPostsQueryVariables>,
) {
    return Apollo.useLazyQuery<SavedPostsQuery, SavedPostsQueryVariables>(SavedPostsDocument, baseOptions);
}
export type SavedPostsQueryHookResult = ReturnType<typeof useSavedPostsQuery>;
export type SavedPostsLazyQueryHookResult = ReturnType<typeof useSavedPostsLazyQuery>;
export type SavedPostsQueryResult = Apollo.QueryResult<SavedPostsQuery, SavedPostsQueryVariables>;
export const UserPostsDocument = gql`
    query UserPosts($page: Int, $userId: String!, $where: PostWhereInput!) {
        userPosts(page: $page, userId: $userId, where: $where) {
            pageInfo {
                totalPages
            }
            edges {
                node {
                    ...PostParts
                    meta {
                        ... on RepostMeta {
                            post {
                                ...PostParts
                            }
                        }
                    }
                }
            }
        }
    }
    ${PostPartsFragmentDoc}
`;

/**
 * __useUserPostsQuery__
 *
 * To run a query within a React component, call `useUserPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPostsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      userId: // value for 'userId'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUserPostsQuery(baseOptions: Apollo.QueryHookOptions<UserPostsQuery, UserPostsQueryVariables>) {
    return Apollo.useQuery<UserPostsQuery, UserPostsQueryVariables>(UserPostsDocument, baseOptions);
}
export function useUserPostsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<UserPostsQuery, UserPostsQueryVariables>,
) {
    return Apollo.useLazyQuery<UserPostsQuery, UserPostsQueryVariables>(UserPostsDocument, baseOptions);
}
export type UserPostsQueryHookResult = ReturnType<typeof useUserPostsQuery>;
export type UserPostsLazyQueryHookResult = ReturnType<typeof useUserPostsLazyQuery>;
export type UserPostsQueryResult = Apollo.QueryResult<UserPostsQuery, UserPostsQueryVariables>;
export const ReportsDocument = gql`
    query Reports {
        reports {
            edges {
                node {
                    code
                    embedUrl
                    id
                }
            }
        }
    }
`;

/**
 * __useReportsQuery__
 *
 * To run a query within a React component, call `useReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReportsQuery(baseOptions?: Apollo.QueryHookOptions<ReportsQuery, ReportsQueryVariables>) {
    return Apollo.useQuery<ReportsQuery, ReportsQueryVariables>(ReportsDocument, baseOptions);
}
export function useReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportsQuery, ReportsQueryVariables>) {
    return Apollo.useLazyQuery<ReportsQuery, ReportsQueryVariables>(ReportsDocument, baseOptions);
}
export type ReportsQueryHookResult = ReturnType<typeof useReportsQuery>;
export type ReportsLazyQueryHookResult = ReturnType<typeof useReportsLazyQuery>;
export type ReportsQueryResult = Apollo.QueryResult<ReportsQuery, ReportsQueryVariables>;
export const SearchDocument = gql`
    query Search($keyword: String!) {
        users(where: { keyword: $keyword }) {
            edges {
                node {
                    id
                    fullName
                    avatar {
                        id
                        url
                    }
                }
            }
        }
        events(where: { keyword: $keyword }) {
            edges {
                node {
                    id
                    translations {
                        edges {
                            node {
                                id
                                lang
                                title
                                description
                                address
                            }
                        }
                    }
                    startTime
                    tags
                    type
                    price
                    purchased
                    images {
                        edges {
                            node {
                                id
                                order
                                image {
                                    id
                                    url
                                    name
                                    extension
                                    blurhash {
                                        hash
                                        width
                                        height
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        experiences(where: { keyword: $keyword }) {
            edges {
                node {
                    id
                    translations {
                        edges {
                            node {
                                id
                                lang
                                title
                                description
                                address
                            }
                        }
                    }
                    startTime
                    tags
                    type
                    price
                    purchased
                    images {
                        edges {
                            node {
                                id
                                order
                                image {
                                    id
                                    url
                                    name
                                    extension
                                    blurhash {
                                        hash
                                        width
                                        height
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        communities(where: { keyword: $keyword }) {
            edges {
                node {
                    id
                    logo {
                        id
                        url
                    }
                    translations {
                        edges {
                            node {
                                id
                                lang
                                name
                                location
                                description
                            }
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useSearchQuery(baseOptions: Apollo.QueryHookOptions<SearchQuery, SearchQueryVariables>) {
    return Apollo.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, baseOptions);
}
export function useSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>) {
    return Apollo.useLazyQuery<SearchQuery, SearchQueryVariables>(SearchDocument, baseOptions);
}
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = Apollo.QueryResult<SearchQuery, SearchQueryVariables>;
export const ActivitiesDocument = gql`
    query Activities($page: Int) {
        activities(page: $page) {
            pageInfo {
                hasNextPage
            }
            edges {
                node {
                    id
                    type
                    createdAt
                    author {
                        id
                        fullName
                        avatar {
                            id
                            url
                        }
                    }
                    createdAt
                    meta {
                        post {
                            id
                            files {
                                edges {
                                    node {
                                        id
                                        extension
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useActivitiesQuery__
 *
 * To run a query within a React component, call `useActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivitiesQuery({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function useActivitiesQuery(baseOptions?: Apollo.QueryHookOptions<ActivitiesQuery, ActivitiesQueryVariables>) {
    return Apollo.useQuery<ActivitiesQuery, ActivitiesQueryVariables>(ActivitiesDocument, baseOptions);
}
export function useActivitiesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ActivitiesQuery, ActivitiesQueryVariables>,
) {
    return Apollo.useLazyQuery<ActivitiesQuery, ActivitiesQueryVariables>(ActivitiesDocument, baseOptions);
}
export type ActivitiesQueryHookResult = ReturnType<typeof useActivitiesQuery>;
export type ActivitiesLazyQueryHookResult = ReturnType<typeof useActivitiesLazyQuery>;
export type ActivitiesQueryResult = Apollo.QueryResult<ActivitiesQuery, ActivitiesQueryVariables>;
export const ApprovedMembersDocument = gql`
    query ApprovedMembers {
        approvedMembers {
            edges {
                node {
                    id
                    fullName
                    role {
                        id
                        name
                    }
                    statusText
                    avatar {
                        id
                        url
                    }
                    member {
                        id
                    }
                }
            }
        }
    }
`;

/**
 * __useApprovedMembersQuery__
 *
 * To run a query within a React component, call `useApprovedMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useApprovedMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApprovedMembersQuery({
 *   variables: {
 *   },
 * });
 */
export function useApprovedMembersQuery(
    baseOptions?: Apollo.QueryHookOptions<ApprovedMembersQuery, ApprovedMembersQueryVariables>,
) {
    return Apollo.useQuery<ApprovedMembersQuery, ApprovedMembersQueryVariables>(ApprovedMembersDocument, baseOptions);
}
export function useApprovedMembersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ApprovedMembersQuery, ApprovedMembersQueryVariables>,
) {
    return Apollo.useLazyQuery<ApprovedMembersQuery, ApprovedMembersQueryVariables>(
        ApprovedMembersDocument,
        baseOptions,
    );
}
export type ApprovedMembersQueryHookResult = ReturnType<typeof useApprovedMembersQuery>;
export type ApprovedMembersLazyQueryHookResult = ReturnType<typeof useApprovedMembersLazyQuery>;
export type ApprovedMembersQueryResult = Apollo.QueryResult<ApprovedMembersQuery, ApprovedMembersQueryVariables>;
export const FollowRequestsDocument = gql`
    query FollowRequests($page: Int, $where: FollowWhereInput) {
        followRequests(page: $page, where: $where) {
            edges {
                node {
                    id
                    follower {
                        id
                        fullName
                        avatar {
                            id
                            url
                        }
                    }
                }
            }
            pageInfo {
                hasNextPage
            }
            totalCount
        }
    }
`;

/**
 * __useFollowRequestsQuery__
 *
 * To run a query within a React component, call `useFollowRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFollowRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFollowRequestsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFollowRequestsQuery(
    baseOptions?: Apollo.QueryHookOptions<FollowRequestsQuery, FollowRequestsQueryVariables>,
) {
    return Apollo.useQuery<FollowRequestsQuery, FollowRequestsQueryVariables>(FollowRequestsDocument, baseOptions);
}
export function useFollowRequestsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<FollowRequestsQuery, FollowRequestsQueryVariables>,
) {
    return Apollo.useLazyQuery<FollowRequestsQuery, FollowRequestsQueryVariables>(FollowRequestsDocument, baseOptions);
}
export type FollowRequestsQueryHookResult = ReturnType<typeof useFollowRequestsQuery>;
export type FollowRequestsLazyQueryHookResult = ReturnType<typeof useFollowRequestsLazyQuery>;
export type FollowRequestsQueryResult = Apollo.QueryResult<FollowRequestsQuery, FollowRequestsQueryVariables>;
export const FollowersDocument = gql`
    query Followers($page: Int, $where: FollowWhereInput) {
        followers(page: $page, where: $where) {
            edges {
                node {
                    id
                    fullName
                    avatar {
                        id
                        blurhash {
                            hash
                        }
                        url
                    }
                    incognito
                    nickname
                }
            }
            pageInfo {
                hasNextPage
            }
        }
    }
`;

/**
 * __useFollowersQuery__
 *
 * To run a query within a React component, call `useFollowersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFollowersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFollowersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFollowersQuery(baseOptions?: Apollo.QueryHookOptions<FollowersQuery, FollowersQueryVariables>) {
    return Apollo.useQuery<FollowersQuery, FollowersQueryVariables>(FollowersDocument, baseOptions);
}
export function useFollowersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<FollowersQuery, FollowersQueryVariables>,
) {
    return Apollo.useLazyQuery<FollowersQuery, FollowersQueryVariables>(FollowersDocument, baseOptions);
}
export type FollowersQueryHookResult = ReturnType<typeof useFollowersQuery>;
export type FollowersLazyQueryHookResult = ReturnType<typeof useFollowersLazyQuery>;
export type FollowersQueryResult = Apollo.QueryResult<FollowersQuery, FollowersQueryVariables>;
export const FollowingsDocument = gql`
    query Followings($page: Int, $where: FollowWhereInput) {
        followings(page: $page, where: $where) {
            edges {
                node {
                    id
                    fullName
                    avatar {
                        id
                        blurhash {
                            hash
                        }
                        url
                    }
                    incognito
                    nickname
                }
            }
            pageInfo {
                hasNextPage
            }
        }
    }
`;

/**
 * __useFollowingsQuery__
 *
 * To run a query within a React component, call `useFollowingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFollowingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFollowingsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFollowingsQuery(baseOptions?: Apollo.QueryHookOptions<FollowingsQuery, FollowingsQueryVariables>) {
    return Apollo.useQuery<FollowingsQuery, FollowingsQueryVariables>(FollowingsDocument, baseOptions);
}
export function useFollowingsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<FollowingsQuery, FollowingsQueryVariables>,
) {
    return Apollo.useLazyQuery<FollowingsQuery, FollowingsQueryVariables>(FollowingsDocument, baseOptions);
}
export type FollowingsQueryHookResult = ReturnType<typeof useFollowingsQuery>;
export type FollowingsLazyQueryHookResult = ReturnType<typeof useFollowingsLazyQuery>;
export type FollowingsQueryResult = Apollo.QueryResult<FollowingsQuery, FollowingsQueryVariables>;
export const GuestsDocument = gql`
    query Guests {
        guests {
            edges {
                node {
                    id
                    fullName
                    role {
                        id
                        name
                    }
                    statusText
                    avatar {
                        id
                        url
                    }
                    member {
                        id
                    }
                }
            }
        }
    }
`;

/**
 * __useGuestsQuery__
 *
 * To run a query within a React component, call `useGuestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGuestsQuery(baseOptions?: Apollo.QueryHookOptions<GuestsQuery, GuestsQueryVariables>) {
    return Apollo.useQuery<GuestsQuery, GuestsQueryVariables>(GuestsDocument, baseOptions);
}
export function useGuestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuestsQuery, GuestsQueryVariables>) {
    return Apollo.useLazyQuery<GuestsQuery, GuestsQueryVariables>(GuestsDocument, baseOptions);
}
export type GuestsQueryHookResult = ReturnType<typeof useGuestsQuery>;
export type GuestsLazyQueryHookResult = ReturnType<typeof useGuestsLazyQuery>;
export type GuestsQueryResult = Apollo.QueryResult<GuestsQuery, GuestsQueryVariables>;
export const UserDocument = gql`
    query User($userId: String!) {
        user(id: $userId) {
            ...UserParts
        }
        followerCount(where: { userId: $userId })
        followingCount(where: { userId: $userId })
    }
    ${UserPartsFragmentDoc}
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
    return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
}
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
    return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
