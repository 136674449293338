import { ConfigStore } from '@mediusoft/matrix-chat';
import { FileInfo, Member, User } from 'graphql/generated';
import produce, { Draft } from 'immer';
import { Reducer } from 'react';

export const ACCESS_TOKEN_KEY = '@Auth/accessToken';

export const getAccessToken = (): string | null => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);

    if ([null, undefined, 'null', 'undefined'].includes(accessToken)) {
        return null;
    }

    try {
        return JSON.parse(accessToken as string);
    } catch (_) {
        return accessToken;
    }
};

type UserProps = Pick<
    User,
    'id' | 'firstName' | 'lastName' | 'fullName' | 'role' | 'email' | 'statusText' | 'matrixAuth'
> & { avatar?: Pick<FileInfo, 'id' | 'url'> | undefined | null; member?: Pick<Member, 'id' | 'status'> | null };

// #region State
export type AuthState = {
    accessToken?: string | null;
    isLoggedIn: boolean;
    isManager: boolean;
    user?: UserProps | null;
    userLoading: boolean;
    userLoadingError?: string;
    userRefetchAck: number;
};

export const INITIAL_AUTH_STATE: AuthState = {
    accessToken: getAccessToken(),
    isLoggedIn: !!getAccessToken(),
    isManager: false,
    user: undefined,
    userLoading: true,
    userLoadingError: undefined,
    userRefetchAck: Date.now(),
};
// #endregion

// #region Sync Actions
type LoadUserStartedAction = { type: 'LOAD_USER_STARTED' };
type LoadUserSuccessAction = { type: 'LOAD_USER_SUCCESS'; user?: UserProps | null };
type LoadUserFailedAction = { type: 'LOAD_USER_FAILED'; error: string };
type SaveAccessTokenAction = { type: 'SAVE_ACCESS_TOKEN'; accessToken: string | null };
type UpdateUserAction = { type: 'UPDATE_USER'; user: Partial<UserProps> };
type LogOutAction = { type: 'LOG_OUT' };

export type AuthAction =
    | SaveAccessTokenAction
    | LoadUserStartedAction
    | LoadUserSuccessAction
    | LoadUserFailedAction
    | UpdateUserAction
    | LogOutAction;

const userLoaded = (draft: Draft<AuthState>, action: LoadUserSuccessAction) => {
    const user = action.user;
    if (user) {
        draft.user = user;
        draft.isManager = user.role.name === 'manager';
        draft.userLoading = false;
    }
};

const updateUser = (draft: Draft<AuthState>, action: UpdateUserAction): void => {
    if (draft.user && action.user) {
        draft.user = { ...draft.user, ...action.user };
    }
};

const saveAccessToken = (draft: Draft<AuthState>, action: SaveAccessTokenAction) => {
    draft.accessToken = action.accessToken;
    if (action.accessToken) {
        localStorage.setItem(ACCESS_TOKEN_KEY, action.accessToken);
    }
};

const logOut = (draft: Draft<AuthState>) => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    draft.accessToken = undefined;
    draft.isManager = false;
    draft.user = undefined;
    ConfigStore.clearMatrixAuthData();
};

export const reducer: Reducer<AuthState, AuthAction> = produce((draft: Draft<AuthState>, action: AuthAction): void => {
    switch (action.type) {
        case 'SAVE_ACCESS_TOKEN':
            saveAccessToken(draft, action);
            break;
        case 'LOAD_USER_STARTED':
            draft.userLoading = true;
            break;
        case 'LOAD_USER_FAILED':
            draft.userLoadingError = action.error;
            draft.userLoading = false;
            break;
        case 'LOAD_USER_SUCCESS':
            userLoaded(draft, action);
            break;
        case 'UPDATE_USER':
            updateUser(draft, action);
            break;
        case 'LOAD_USER_SUCCESS':
            userLoaded(draft, action);
            break;
        case 'LOG_OUT':
            logOut(draft);
            break;
        default:
            break;
    }
});
// #endregion
