const colors = ['#368bd6', '#ac3ba8', '#03b381', '#e64f7a', '#ff812d', '#2dc2c5', '#5c56f5', '#74d12c'];

const hashCode = (str: string): number => {
    let hash = 0;
    let i: number;
    let chr: number;
    if (str.length === 0) {
        return hash;
    }
    for (i = 0; i < str.length; i += 1) {
        chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0;
    }
    return Math.abs(hash);
};

export default function colorMXID(userId: string): string {
    const colorNumber = hashCode(userId) % 8;
    return colors[colorNumber];
}

/*
List of (100) visually distinct colors for maybe future use :) 
'#00e06b', '#0b44c2', '#01756e', '#0230a8', '#08dc5d', '#07b46d', '#048f8a', '#0d8d7c', '#003d20', '#0670dd', '#0d0b3a', '#091ebf', '#0cb5a1', '#081216', '#06d160', '#04f7c9', '#0bbb23', '#04febd', '#0cbe40', '#0d7044', '#0e4d7c', '#09df0e', '#004329', '#012c80', '#08907d', '#01f7ab', '#0679a5', '#06409c', '#092e17', '#0b641e', '#092e03', '#0a96a7', '#0d2328', '#09a266', '#0c031e', '#0701c2', '#0e1874', '#0ec217', '#07d917', '#048110', '#005062', '#087c67', '#0a5c3c', '#0c570e', '#09ae6c', '#01efea', '#0ac460', '#089e7f', '#01d668', '#0edc51', '#074f3c', '#03a994', '#034f13', '#035c03', '#00fd65', '#0934c5', '#0d773c', '#0ee4ca', '#0117e2', '#0a236c', '#008402', '#050b9b', '#011705', '#0e0416', '#0c0bc4', '#0dad8f', '#010bf8', '#0c1f47', '#0cabf9', '#0bf69d', '#0dafbc', '#043e22', '#0bb796', '#0cd9bb', '#0c8c2b', '#08ddb7', '#07ccdd', '#008e25', '#016b92', '#0ed0b6', '#0f37cc', '#09527b', '#03e0db', '#057ca7', '#054441', '#03dd13', '#0b06e6', '#0570d0', '#0b0999', '#0cd998', '#01e5d1', '#09d6c0', '#07cc6d', '#0cd14e', '#0a9114', '#07522a', '#068e58', '#055d4b', '#055316', '#066c5d'
*/
