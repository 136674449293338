import { Box, Button, Card, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { ReactComponent as LocationIcon } from 'assets/icons/location.svg';
import { EventSlider } from 'components/event-slider';
import { Link } from 'components/link';
import { useSettings } from 'context/SettingsContext';
import { Event } from 'graphql/generated';
import { DateTime } from 'luxon';
import React from 'react';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import { getTranslationContent } from 'utils/translation-helper';
import { useStyles } from './event-card.style';
import { Payment } from './payment';

const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis);

type EventCardProps = { event: Event; refetch?: any; isPast?: boolean };

export const EventCard: React.FC<EventCardProps> = ({ event, isPast = false }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));
    const { language } = useSettings();

    const translations = event.translations.edges.map(({ node }) => node);

    const { address, title, description } = getTranslationContent({
        translations,
        language,
        requiredFields: ['title', 'description'],
    });

    const images = event.images.edges.map(({ node }) => node.image);
    const tags = event.tags || [];

    const startDate = DateTime.fromISO(event.startTime).toFormat('dd/MM/yyyy');

    return (
        <Card className={classes.root}>
            <Box className={classes.slider}>
                <EventSlider files={images} />
            </Box>
            <Box className={classes.content}>
                {address && (
                    <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
                        <Box display="flex" alignItems="flex-end">
                            <LocationIcon className={classes.locationIcon} />
                            <Typography color="textSecondary">{address}</Typography>
                        </Box>
                    </Box>
                )}

                <Typography variant="h3" gutterBottom style={{ textTransform: 'uppercase', letterSpacing: 1 }}>
                    {title}
                </Typography>

                {event.startTime && (
                    <Typography color="primary" variant="h5">
                        {startDate}
                    </Typography>
                )}

                <ResponsiveEllipsis
                    maxLine={isSm ? '6' : '12'}
                    className={classes.description}
                    unsafeHTML={description}
                    ellipsis="..."
                    basedOn="letters"
                />

                <Link to={`/d/event/${event.id}`}>
                    <Button
                        style={{ width: 'fit-content', textTransform: 'uppercase', padding: '8px 24px' }}
                        variant="outlined"
                    >
                        <Typography variant="body2">Learn more</Typography>
                    </Button>
                </Link>
                {tags.length > 0 && (
                    <Box my={3} display="flex" flexWrap="wrap">
                        {tags.map((tag, i) => (
                            <Link key={i} to={`/d/event/search?tags=${tag}`}>
                                <Typography
                                    style={{
                                        fontStyle: 'italic',
                                        marginRight: 8,
                                        marginBottom: 8,
                                    }}
                                    variant="h5"
                                    color="primary"
                                >
                                    #{tag}
                                </Typography>
                            </Link>
                        ))}
                    </Box>
                )}

                {event.externalLink && (
                    <Box style={{ margin: '20px 0' }}>
                        <a style={{ wordBreak: 'break-word' }} target="blank" href={event.externalLink}>
                            <Typography>{event.externalLink}</Typography>
                        </a>
                    </Box>
                )}

                {!isPast && <Payment id={event.id} price={event.price} purchased={event.purchased} title={title} />}
            </Box>
        </Card>
    );
};
