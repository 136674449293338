export type FontSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | number;

export const getFontSize = (fontSize: FontSize) => {
    switch (fontSize) {
        case 'xs':
            return 12;
        case 'sm':
            return 16;
        case 'md':
            return 24;
        case 'lg':
            return 32;
        case 'xl':
            return 40;
        default:
            return fontSize;
    }
};
