import React from 'react';
import usePortal from 'react-cool-portal';

export const Portal: React.FC<{ portalID: string; disablePortal?: boolean }> = ({
    portalID,
    disablePortal = false,
    children,
}) => {
    const { Portal: CoolPortal } = usePortal({
        containerId: portalID,
        autoRemoveContainer: false,
        clickOutsideToHide: false,
        escToHide: false,
    });

    if (disablePortal) {
        return <>{children}</>;
    }

    return <CoolPortal>{children}</CoolPortal>;
};
