import { Button, Grid, InputLabel, MenuItem } from '@material-ui/core';
import { DatePickerField } from 'components';
import { Field, Form, Formik, FormikValues } from 'formik';
import { TextField } from 'formik-material-ui';
import { MaritalStatus } from 'graphql/generated';
import React from 'react';
import { CountryField } from '../country-field';
import { useStyles } from './member-profile-form.style';
import { validationSchema } from './validationSchema';

type MemberProfileFormProps = {
    initialValues: FormikValues;
    onSubmit: (formData: any, { setSubmitting: any }) => void;
    onDelete?: (id: any) => void;
    onCancelCreate?: () => void;
    updating: boolean;
};

export const MemberProfileForm: React.FC<MemberProfileFormProps> = ({
    initialValues,
    onSubmit,
    onDelete,
    onCancelCreate,
    updating,
}) => {
    const classes = useStyles();

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ handleSubmit }): React.ReactNode => (
                <Grid container className={classes.root}>
                    <Form onSubmit={handleSubmit}>
                        <Grid item container style={{ position: 'relative' }}>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <Grid container spacing={4}>
                                        <Grid item xs={12}>
                                            <InputLabel style={{ marginBottom: 5 }}>Bio</InputLabel>
                                            <Field
                                                component={TextField}
                                                fullWidth
                                                multiline
                                                minRows={8}
                                                maxRows={10}
                                                variant="outlined"
                                                name="bio"
                                                placeholder="About me"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputLabel style={{ marginBottom: 5 }}>
                                        First Name <span style={{ color: 'red' }}>*</span>
                                    </InputLabel>
                                    <Field component={TextField} fullWidth variant="outlined" name={`firstName`} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputLabel style={{ marginBottom: 5 }}>
                                        Last Name <span style={{ color: 'red' }}>*</span>
                                    </InputLabel>
                                    <Field component={TextField} fullWidth variant="outlined" name={`lastName`} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputLabel style={{ marginBottom: 5 }}>
                                        Email <span style={{ color: 'red' }}>*</span>
                                    </InputLabel>
                                    <Field
                                        disabled={!onCancelCreate}
                                        component={TextField}
                                        fullWidth
                                        variant="outlined"
                                        name={`email`}
                                        type="email"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputLabel style={{ marginBottom: 5 }}>
                                        Phone number <span style={{ color: 'red' }}>*</span>
                                    </InputLabel>
                                    <Field component={TextField} fullWidth variant="outlined" name={`phoneNumber`} />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <InputLabel style={{ marginBottom: 5 }}>
                                        City of Residence <span style={{ color: 'red' }}>*</span>
                                    </InputLabel>
                                    <Field
                                        component={TextField}
                                        fullWidth
                                        variant="outlined"
                                        name={`cityOfResidence`}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputLabel style={{ marginBottom: 5 }}>
                                        Country of Residence <span style={{ color: 'red' }}>*</span>
                                    </InputLabel>
                                    <Field component={CountryField} name="countryOfResidenceId" />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <InputLabel style={{ marginBottom: 5 }}>
                                        Date of Birth <span style={{ color: 'red' }}>*</span>
                                    </InputLabel>
                                    <DatePickerField
                                        showError={false}
                                        openTo="year"
                                        views={['year', 'month', 'date']}
                                        name="dateOfBirth"
                                        disableFuture
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputLabel style={{ marginBottom: 5 }}>
                                        Marital Status <span style={{ color: 'red' }}>*</span>
                                    </InputLabel>
                                    <Field
                                        component={TextField}
                                        select
                                        fullWidth
                                        variant="outlined"
                                        name="maritalStatus"
                                        SelectProps={{ displayEmpty: true }}
                                    >
                                        <MenuItem value={undefined}>Don&apos;t specify</MenuItem>
                                        <MenuItem value={MaritalStatus.Single}>Single</MenuItem>
                                        <MenuItem value={MaritalStatus.HavePartner}>Have Partner</MenuItem>
                                        <MenuItem value={MaritalStatus.Married}>Married</MenuItem>
                                    </Field>
                                </Grid>
                                <Grid item container justifyContent="flex-end" xs={12}>
                                    {onDelete && (
                                        <Button
                                            style={{ backgroundColor: '#ff5252', color: '#fff', marginRight: 20 }}
                                            variant="contained"
                                            onClick={onDelete}
                                        >
                                            Remove member
                                        </Button>
                                    )}
                                    {onCancelCreate && (
                                        <Button
                                            style={{ backgroundColor: '#ff5252', color: '#fff', marginRight: 20 }}
                                            variant="contained"
                                            onClick={onCancelCreate}
                                        >
                                            Cancel
                                        </Button>
                                    )}
                                    <Button color="primary" variant="contained" type="submit" disabled={updating}>
                                        {onCancelCreate ? 'Create member' : updating ? 'Updating' : 'Update'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                </Grid>
            )}
        </Formik>
    );
};
