import { alpha, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => {
    return {
        title: {
            ...theme.typography.h6,
            fontSize: '0.75rem',
            fontWeight: 700,
            textTransform: 'uppercase',
            color: alpha(theme.palette.text.secondary, 0.7),
        },
        text: {
            fontSize: '0.925rem',
            opacity: 0.8,
        },
    };
});
