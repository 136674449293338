import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
    return {
        container: {
            width: 'calc(100% + 2px)',
            margin: -1,
            '& > div': {
                padding: '1px !important',
            },
            '& img': {
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'top',
                cursor: 'pointer',
                transition: '.3s',
                '&:hover': {
                    filter: 'brightness(70%)',
                },
            },
        },
        overlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0,0,0,0.8)',
            color: 'white',
            cursor: 'pointer',
            fontSize: 30,
            fontWeight: 600,
            transition: '.3s',
            '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.9)',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: 24,
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: 18,
            },
        },
    };
});
