import { Card, IconButton, Typography } from '@material-ui/core';
import React from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import { ReactComponent as VisaIcon } from 'assets/icons/visa.svg';
import { ReactComponent as MasterIcon } from 'assets/icons/mastercard.svg';
import { useStyles } from '../payment-item/payment-item.style';
import { useNotifications } from 'context/NotificationsContext';

export const PaymentItem: React.FC<{
    id: string;
    brand: string;
    funding: string;
    lastFour: string;
    expYear: number;
    expMonth: number;
    isDefault: boolean;
    setDefault: any;
    deletePayment: any;
}> = ({ lastFour, expMonth, expYear, brand, isDefault, id, setDefault, deletePayment }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const classes = useStyles();
    const handleClose = () => {
        setAnchorEl(null);
    };
    const { confirm } = useNotifications();

    return (
        <Card className={classes.mainCard}>
            <div>
                <Typography style={{ margin: '4px 0' }} variant="subtitle1">
                    xxxx-xxxx-xxxx-{lastFour} {isDefault && '(Default)'}
                </Typography>

                {brand === 'visa' ? (
                    <VisaIcon className={classes.cardIcon} />
                ) : brand === 'mastercard' ? (
                    <MasterIcon className={classes.cardIcon} />
                ) : (
                    <></>
                )}
                <Typography variant="subtitle2">
                    Expires {expMonth}/{expYear}
                </Typography>
            </div>
            {!isDefault && (
                <div>
                    <IconButton size="small" onClick={handleClick}>
                        <MoreHorizIcon />
                    </IconButton>
                    <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose} TransitionComponent={Fade}>
                        <MenuItem
                            onClick={() => {
                                setDefault(id);
                                handleClose();
                            }}
                        >
                            Make default
                        </MenuItem>
                        <MenuItem
                            onClick={(): void => {
                                confirm({
                                    onConfirm: () => {
                                        deletePayment(id);
                                        handleClose();
                                    },
                                    title: 'Delete payment method',
                                    confirmText: 'Are you sure you want to delete this payment method?',
                                });
                            }}
                        >
                            Delete
                        </MenuItem>
                    </Menu>
                </div>
            )}
        </Card>
    );
};
