import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => {
    return {
        root: {
            '& .MuiFormHelperText-root': {
                display: 'none',
            },
        },
    };
});
