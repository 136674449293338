import { Box, IconButton, useTheme } from '@material-ui/core';
import { ReactComponent as ArrowIcon } from 'assets/icons/actions/arrow.svg';
import clsx from 'clsx';
import { Lightbox, RatioBox } from 'components';
import { BlurhashImage } from 'components/image-blurhash/blurhash-image';
import { imageExtensions, videoExtensions } from 'config';
import { FileInfo } from 'graphql/generated';
import React, { useState } from 'react';
import Carousel from 'react-slick';
import { Dots } from './dots';
import { useStyles } from './slider.style';

type SliderProps = {
    files: FileInfo[];
    portalID?: string;
};

const INITIAL_LIGHTBOX_STATE = { open: false, index: 0 };

export const Slider: React.FC<SliderProps> = ({ files, portalID }) => {
    const classes = useStyles();
    const theme = useTheme();
    const [lightboxState, setLightboxState] = useState(INITIAL_LIGHTBOX_STATE);
    const [currentIdx, setCurrentIdx] = useState(0);

    const images = files.filter((file) => imageExtensions.includes(file.extension));

    function ArrowRight(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <IconButton disableRipple className={classes.arrow} onClick={onClick}>
                    <ArrowIcon style={{ transform: 'rotate(180deg)' }} />
                </IconButton>
            </div>
        );
    }

    function ArrowLeft(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{
                    ...style,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <IconButton disableRipple className={clsx(classes.arrow)} onClick={onClick}>
                    <ArrowIcon />
                </IconButton>
            </div>
        );
    }

    const sliderSettings = {
        dots: false,
        infinite: true,
        arrows: true,
        lazyLoad: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <ArrowRight />,
        prevArrow: <ArrowLeft />,
        responsive: [
            {
                breakpoint: theme.breakpoints.values.sm,
                settings: {
                    arrows: false,
                },
            },
        ],
        afterChange: (idx) => setCurrentIdx(idx),
    };

    if (files.length === 0) {
        return null;
    }

    return (
        <Box className={classes.images}>
            {files.length > 1 ? (
                <Carousel {...sliderSettings}>
                    {files.map((file) => {
                        const isVideo = videoExtensions.includes(file.extension);
                        return (
                            <Box
                                key={file.id}
                                onClick={() => {
                                    if (isVideo) {
                                        window.open(file.url, '_blank');
                                    } else {
                                        const imageIndex = images.findIndex((image) => image.id === file.id);
                                        setLightboxState({ open: true, index: imageIndex });
                                    }
                                }}
                            >
                                <RatioBox>
                                    {isVideo ? (
                                        <video src={file.url} autoPlay loop muted controls={false} />
                                    ) : (
                                        <BlurhashImage image={file} />
                                    )}
                                </RatioBox>
                            </Box>
                        );
                    })}
                </Carousel>
            ) : files.length === 1 ? (
                <Box
                    onClick={() => {
                        const isVideo = videoExtensions.includes(files[0].extension);
                        if (isVideo) {
                            window.open(files[0].url, '_blank');
                        } else {
                            setLightboxState({ open: true, index: 0 });
                        }
                    }}
                >
                    <RatioBox>
                        {videoExtensions.includes(files[0].extension) ? (
                            <video src={files[0].url} autoPlay loop muted controls={false} />
                        ) : (
                            <BlurhashImage image={files[0]} />
                        )}
                    </RatioBox>
                </Box>
            ) : (
                <RatioBox>
                    <img src={require('assets/event.jpg')} />
                </RatioBox>
            )}
            {portalID && files.length > 1 && <Dots portalID={portalID} active={currentIdx} length={files.length} />}
            {lightboxState.open && (
                <Lightbox
                    mainSrc={images[lightboxState.index].url}
                    nextSrc={images[(lightboxState.index + 1) % images.length].url}
                    prevSrc={images[(lightboxState.index + images.length - 1) % images.length].url}
                    onCloseRequest={(): void => setLightboxState(INITIAL_LIGHTBOX_STATE)}
                    onMovePrevRequest={(): void =>
                        setLightboxState((prev) => ({
                            ...prev,
                            index: (lightboxState.index + images.length - 1) % images.length,
                        }))
                    }
                    onMoveNextRequest={(): void =>
                        setLightboxState((prev) => ({ ...prev, index: (lightboxState.index + 1) % images.length }))
                    }
                />
            )}
        </Box>
    );
};
