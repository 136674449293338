import { makeStyles } from '@material-ui/core';
import { mobileSize } from 'layout-config';

export const useStyles = makeStyles((theme) => {
    return {
        actions: {
            marginLeft: -6,
            '& .MuiIconButton-root:not(:last-child)': {
                marginRight: theme.spacing(0.3),
            },
            [theme.breakpoints.down(mobileSize)]: {
                marginLeft: -8.25,
            },
        },
        likeActive: {
            '& path, & circle, & rect:not(:last-child)': {
                fill: theme.palette.error.main,
            },
        },
        likeCount: {
            fontStyle: 'italic',
            fontSize: '1rem',
        },
    };
});
