import { Box, IconButton } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { ColorIDAvatar, LoadingButton } from 'components';
import { EmojiPicker } from 'components/formik';
import { useAuthState } from 'context/auth/store';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import {
    PostComment,
    useCreatePostCommentMutation,
    useDeletePostCommentMutation,
    usePostLazyQuery,
} from 'graphql/generated';
import React, { useEffect, useState } from 'react';
import { deepOmit } from 'utils/object-helper';
import { PostCommentItem } from './post-comment-item';
import { useStyles } from './post-comments.style';
import validationSchema from './validation-schema';

type PostCommentsProps = {
    totalCount: number;
    initialComments: PostComment[];
    postId: string;
    authorId: string;
    inputRef: React.RefObject<HTMLInputElement>;
};

export const PostComments: React.FC<PostCommentsProps> = ({
    totalCount,
    initialComments: initComments,
    postId,
    authorId,
    inputRef,
}) => {
    const classes = useStyles();
    const { user } = useAuthState();
    const [refetchable, setRefetchable] = useState(false);
    const [comments, setComments] = useState<PostComment[]>([]);
    const [initialComments, setInitialComments] = useState<PostComment[]>(initComments.reverse().map((c) => c));
    const [page, setPage] = useState(1);
    const [cursorPosition, setCursorPosition] = useState<number>();

    const updateCursorPosition = (position: number | undefined): void => {
        if (position !== undefined) {
            setCursorPosition(position);
        }
    };

    const initialValues = {
        text: '',
    };

    const [loadComments, { data, loading }] = usePostLazyQuery({
        variables: {
            id: postId,
            page,
        },
    });

    const [createPostComment] = useCreatePostCommentMutation({
        onCompleted(result) {
            const comment = deepOmit(result.createPostComment.comment, ['__typename']);
            if (comments.length === 0) {
                setInitialComments((prev) => [...prev, comment as PostComment]);
            } else {
                setComments((prev) => [...prev, comment as PostComment]);
            }
        },
    });

    const [deleteComment] = useDeletePostCommentMutation({
        onCompleted({ deletePostComment: { comment } }) {
            if (comments.length === 0) {
                const newComments = initialComments.filter((c) => c.id !== comment.id);
                setInitialComments(newComments);
            } else {
                const newComments = comments.filter((c) => c.id !== comment.id);
                setComments(newComments);
            }
        },
    });

    const hasNextPage = data?.post.comments.pageInfo.hasNextPage;
    const showButton = comments.length === 0 ? totalCount > 3 : hasNextPage;

    const loadMore = (): void => {
        if (refetchable) {
            setPage((prev) => prev + 1);
        } else {
            loadComments();
            setRefetchable(true);
        }
    };

    const onSubmit = ({ text }, { resetForm }): void => {
        createPostComment({ variables: { comment: { postId, text } } });
        resetForm();
        inputRef.current?.focus();
    };

    useEffect(() => {
        if (data && !loading) {
            const newComments = data?.post.comments.edges.map(({ node }) => node) || [];
            setComments((prev) => [...(newComments.reverse() as PostComment[]), ...prev]);
        }
    }, [data, loading]);

    return (
        <Box>
            {showButton && (
                <Box mb={2}>
                    <LoadingButton
                        fullWidth
                        color="primary"
                        onClick={loadMore}
                        loading={loading}
                        text="Load earlier comments"
                        loadingText="Loading"
                    />
                </Box>
            )}
            {(comments.length > 0 ? comments : initialComments).map((comment) => (
                <PostCommentItem
                    key={comment.id}
                    comment={comment as PostComment}
                    deleteComment={deleteComment}
                    authorId={authorId}
                />
            ))}

            <Formik {...{ initialValues, onSubmit, validationSchema }}>
                {({ handleSubmit, values }): React.ReactNode => (
                    <Form onSubmit={handleSubmit}>
                        <Box display="flex" alignItems="center">
                            <Box mr={1}>
                                <ColorIDAvatar
                                    id={user?.id || ''}
                                    text={user?.firstName || ''}
                                    fontSize="sm"
                                    src={user?.avatar?.url}
                                />
                            </Box>
                            <Field
                                component={TextField}
                                className={classes.compose}
                                margin="dense"
                                fullWidth
                                variant="outlined"
                                placeholder="Leave a comment"
                                name="text"
                                autoCorrect="off"
                                FormHelperTextProps={{ style: { display: 'none' } }}
                                InputProps={{
                                    endAdornment: (
                                        <Field
                                            name="text"
                                            component={EmojiPicker}
                                            cursorPosition={cursorPosition}
                                            updateCursorPosition={updateCursorPosition}
                                        />
                                    ),
                                    onClick: (e): void => updateCursorPosition(e.target.selectionStart),
                                    onKeyUp: (e): void => updateCursorPosition(e.target.selectionStart),
                                }}
                                inputRef={inputRef}
                            />
                            {!!values.text.trim() && (
                                <IconButton type="submit" size="small">
                                    <SendIcon />
                                </IconButton>
                            )}
                        </Box>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};
