import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
    return {
        root: {
            '& .emoji-mart': {
                [theme.breakpoints.down('xs')]: {
                    width: '85vw !important',
                },
            },
            '& .emoji-mart-preview': {
                display: 'none',
            },
        },
        icon: {
            color: theme.palette.text.secondary,
        },
        open: {
            color: theme.palette.primary.main,
        },
    };
});
