import i18next from 'i18next';
import { DateTime, DurationObjectUnits } from 'luxon';

export const UI_DATE_FORMAT = 'dd.MM.yyyy';
export const UI_DATETIME_FORMAT = 'dd.MM.yyyy HH:mm';

const units: (keyof Pick<DurationObjectUnits, 'year' | 'month' | 'week' | 'day' | 'hour' | 'minute' | 'second'>)[] = [
    'year',
    'month',
    'week',
    'day',
    'hour',
    'minute',
    'second',
];

export const timeAgo = (date: string) => {
    const dateTime = DateTime.fromISO(date);

    if (dateTime.diffNow('minutes').minutes > -1) {
        return i18next.t('dateTime:justNow');
    }

    if (dateTime.diffNow('hours').hours < -24) {
        return DateTime.fromISO(date).setLocale(i18next.language).toFormat('d MMMM yyyy, hh:mm');
    }

    const diff = dateTime.diffNow().shiftTo(...units);
    const unit = units.find((unit) => diff.get(unit) !== 0) || 'second';

    const relativeFormatter = new Intl.RelativeTimeFormat(i18next.language, {
        numeric: 'auto',
    });
    return relativeFormatter.format(Math.trunc(diff.as(unit)), unit);
};

export const utcNow = (): any => DateTime.utc();

export const toUIDate = (dateTime: any, withTime: boolean): string =>
    dateTime.toFormat(withTime ? UI_DATETIME_FORMAT : UI_DATE_FORMAT);

export const toISODate = (dateTime: any, withTime: string): any => (withTime ? dateTime.toISO() : dateTime.toISODate());

export const fromJSDate = (jsDate: Date): DateTime => DateTime.fromJSDate(jsDate);

export const fromISODate = (isoDate: string): any => DateTime.fromISO(isoDate);

export const fromUIDate = (dateStr: string, withTime?: boolean): any =>
    DateTime.fromFormat(dateStr, withTime ? UI_DATETIME_FORMAT : UI_DATE_FORMAT);

export const trimEmptyTime = (dateStr: string): string => dateStr.replace('00:00', '').trim();

export const toLocalUIDateTime = (isoDate: string): string => {
    const localDateTime = fromISODate(isoDate).toLocal();
    return trimEmptyTime(toUIDate(localDateTime, true));
};

const dateDiffInDays = (posted: string): number => {
    const today = new Date();
    const postedTime = new Date(posted);
    const result = (Number(today) - Number(postedTime)) / 86400000;

    return Math.floor(result);
};

export const getDatePosted = (posted: string): number => {
    const postedDaysAgo = dateDiffInDays(posted);

    return postedDaysAgo;
};

export const getMessageDateText = (posted: string): string => {
    const postedDate = DateTime.fromISO(posted);
    const postedDaysAgo = postedDate.diffNow('days').days;
    if (postedDaysAgo > -1) {
        return postedDate.toFormat('HH:mm');
    } else if (postedDaysAgo < -1 && postedDaysAgo > -2) {
        return `Yesterday ${postedDate.toFormat('HH:mm')}`;
    } else {
        return `${postedDate.toFormat('MMMM d, HH:mm')}`;
    }
};
