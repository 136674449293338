import clsx from 'clsx';
import React, { HTMLAttributes } from 'react';
import { Helmet } from 'react-helmet';
import { useStyle } from './page.style';

export const Page: React.FC<HTMLAttributes<HTMLDivElement>> = ({
    onScroll,
    children,
    title = '',
    className,
    ...rest
}) => {
    const classes = useStyle();

    const pageTitle = title ? `THE - ${title}` : 'THE';

    return (
        <div onScroll={onScroll} className={classes.root}>
            <div className={clsx(classes.main, className)} {...rest}>
                <Helmet>
                    <title>{pageTitle}</title>
                </Helmet>
                {children}
            </div>
        </div>
    );
};
