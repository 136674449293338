import { Box, Button } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import { ImageCropper } from 'components';
import React, { useCallback, useState } from 'react';
import { useStyles } from './croppable-image.style';

type CroppableImageProps = {
    image: File;
    onSaveCrop: (croppedImage: File | null, index: number) => void;
    index: number;
};

export const CroppableImage: React.FC<CroppableImageProps> = ({ index, image, onSaveCrop }) => {
    const classes = useStyles();
    const [cropperOpen, setCropperOpen] = useState(false);

    const openCropper = useCallback(() => setCropperOpen(true), []);
    const closeCropper = useCallback(() => setCropperOpen(false), []);

    return (
        <Box className={classes.root}>
            <img className={classes.image} src={URL.createObjectURL(image)} />
            <Button
                className={classes.button}
                startIcon={<Edit />}
                variant="contained"
                onClick={openCropper}
                size="small"
            >
                Edit
            </Button>
            <ImageCropper
                img={image}
                open={cropperOpen}
                onClose={closeCropper}
                onSave={(croppedImage) => {
                    onSaveCrop(croppedImage, index);
                    closeCropper();
                }}
            />
        </Box>
    );
};
