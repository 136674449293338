import { PageInfo, Post } from 'graphql/generated';
import produce, { Draft } from 'immer';
import { Reducer } from 'react';

// #region State
export type FeedState = {
    posts: Post[];
    initialLoadFinished: boolean;
    page: number;
    canLoadMore: boolean;
    createPostDialogOpen: boolean;
};

export const INITIAL_FEED_STATE: FeedState = {
    posts: [],
    initialLoadFinished: false,
    page: 1,
    canLoadMore: false,
    createPostDialogOpen: false,
};
// #endregion

// #region Sync Actions
type AddPostAction = { type: 'ADD_POST'; post: Post };
type EditPostAction = { type: 'EDIT_POST'; post: Post };
type DeletePostAction = { type: 'DELETE_POST'; postId: Post['id'] };
type PaginateFeedAction = { type: 'PAGINATE_FEED'; posts: Post[]; pageInfo: PageInfo };
type ChangePageAction = { type: 'CHANGE_PAGE'; page: number };
type InitialLoadFinishedAction = { type: 'INITIAL_LOAD_FINISHED' };
type OpenCreatePostDialogAction = { type: 'OPEN_CREATE_POST_DIALOG' };
type CloseCreatePostDialogAction = { type: 'CLOSE_CREATE_POST_DIALOG' };
type ResetFeedAction = { type: 'RESET_FEED' };

export type FeedAction =
    | AddPostAction
    | EditPostAction
    | DeletePostAction
    | PaginateFeedAction
    | ChangePageAction
    | InitialLoadFinishedAction
    | OpenCreatePostDialogAction
    | CloseCreatePostDialogAction
    | ResetFeedAction;

const addPost = (draft: Draft<FeedState>, post: Post) => {
    draft.posts = [post, ...draft.posts];
};

const editPost = (draft: Draft<FeedState>, post: Post) => {
    draft.posts = draft.posts.map((p) => (p.id === post.id ? post : p));
};

const deletePost = (draft: Draft<FeedState>, postId: Post['id']) => {
    draft.posts = draft.posts.filter((p) => p.id !== postId);
};

const paginateFeed = (draft: Draft<FeedState>, posts: Post[], pageInfo: PageInfo) => {
    const oldPosts = draft.posts.filter((p) => posts.every((pp) => pp.id !== p.id));
    draft.posts = [...oldPosts, ...posts];
    if (draft.page + 1 <= pageInfo.totalPages) {
        draft.canLoadMore = true;
    } else {
        draft.canLoadMore = false;
    }
};

export const reducer: Reducer<FeedState, FeedAction> = produce((draft: Draft<FeedState>, action: FeedAction): void => {
    switch (action.type) {
        case 'ADD_POST':
            addPost(draft, action.post);
            break;
        case 'EDIT_POST':
            editPost(draft, action.post);
            break;
        case 'DELETE_POST':
            deletePost(draft, action.postId);
            break;
        case 'PAGINATE_FEED':
            paginateFeed(draft, action.posts, action.pageInfo);
            break;
        case 'CHANGE_PAGE':
            draft.page = action.page;
            break;
        case 'INITIAL_LOAD_FINISHED':
            draft.initialLoadFinished = true;
            break;
        case 'OPEN_CREATE_POST_DIALOG':
            draft.createPostDialogOpen = true;
            break;
        case 'CLOSE_CREATE_POST_DIALOG':
            draft.createPostDialogOpen = false;
            break;
        case 'RESET_FEED':
            draft.posts = [];
            draft.canLoadMore = false;
            draft.initialLoadFinished = false;
            draft.page = 1;
            break;
        default:
            break;
    }
});
// #endregion
