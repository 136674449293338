import { ApolloClient, ApolloLink, InMemoryCache, Observable, Operation, ApolloProvider } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { API_URL } from 'config';
import { SettingsProvider } from 'context/SettingsContext';
import possibleTypes from 'fragment-types.json';
import React from 'react';
import AppComponent from './App';
import 'i18n';
import { getAccessToken } from 'context/auth/reducer';
import { ChatProvider, useChatState } from '@mediusoft/matrix-chat';
import { InitializeMatrix } from './initialize-matrix';
import { AuthProvider, useAuthState } from 'context/auth/store';

const cache = new InMemoryCache({ possibleTypes });

const request = (operation: Operation): void => {
    if (getAccessToken()) {
        operation.setContext({
            headers: {
                authorization: `Bearer ${getAccessToken()}`,
            },
        });
    }
};

const requestLink = new ApolloLink(
    (operation, forward) =>
        new Observable((observer) => {
            let handle: any;
            Promise.resolve(operation)
                .then((oper) => request(oper))
                .then(() => {
                    handle = forward(operation).subscribe({
                        next: observer.next.bind(observer),
                        error: observer.error.bind(observer),
                        complete: observer.complete.bind(observer),
                    });
                })
                .catch(observer.error.bind(observer));

            return (): void => {
                if (handle) handle.unsubscribe();
            };
        }),
);

const client = new ApolloClient({
    link: ApolloLink.from([
        onError(({ networkError, graphQLErrors }) => {
            if (graphQLErrors && graphQLErrors.length > 0) {
                // eslint-disable-next-line no-console
                console.group('GraphQL Errors');
                graphQLErrors.forEach((error) => {
                    console.error(error);
                });
                // eslint-disable-next-line no-console
                console.groupEnd();
            }

            if (networkError) {
                console.warn('Network Error');
                console.error(networkError);
            }
        }),
        requestLink,
        createUploadLink({ uri: `${API_URL}/graphql` }),
    ]),
    cache,
});

const LoadMatrix = () => {
    const { chatIsReady } = useChatState();
    if (chatIsReady) {
        return <InitializeMatrix />;
    } else {
        return <></>;
    }
};

const App = () => {
    const { user } = useAuthState();
    const matrixAuth = user?.matrixAuth;

    if (matrixAuth) {
        return (
            <ChatProvider baseUrl="https://synapse.thelobbylifestyle.com" matrixAuth={matrixAuth}>
                <AppComponent />
                <LoadMatrix />
            </ChatProvider>
        );
    }
    return <AppComponent />;
};

declare module 'react' {
    interface HTMLAttributes<T> {
        attribution?: string;
        page_id?: string;
    }
}

const Setup: React.FC = () => {
    return (
        <ApolloProvider client={client}>
            <AuthProvider>
                <SettingsProvider>
                    <App />
                </SettingsProvider>
            </AuthProvider>
            <div key={Date()}>
                <div
                    id="fb-customer-chat"
                    className="fb-customerchat"
                    page_id="156324748242458"
                    attribution="biz_inbox"
                ></div>
            </div>
        </ApolloProvider>
    );
};

export default Setup;
