import { Card, CardProps, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'enum';
import React from 'react';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            backgroundColor: theme.palette.type === Theme.Light ? 'rgb(228, 230, 235)' : 'rgba(255, 255, 255, 0.1)',
            padding: theme.spacing(0.5, 1.5),
            borderRadius: 6,
            margin: 4,
            marginRight: 8,
            marginLeft: 0,
        },
        text: {
            fontWeight: 500,
        },
    };
});

export const TextBlock: React.FC<CardProps> = ({ children, ...props }) => {
    const classes = useStyles();

    return (
        <Card elevation={0} className={classes.root} {...props}>
            <Typography className={classes.text} variant="body2">
                {children}
            </Typography>
        </Card>
    );
};
