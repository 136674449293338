import { MenuItem, Select, FormControl } from '@material-ui/core';
import { useUserCommunities } from 'context/user-communities/store';
import { initialCategories } from 'context/user-communities/reducer';
import React from 'react';
import { useStyles } from './partners-selector.style';
import { PartnersCategory } from 'enum';

export const PartnersSelector: React.FC = () => {
    const classes = useStyles();
    const [{ communityCategories }, dispatch] = useUserCommunities();

    const handleChange = (event: any) => {
        let value;

        if (event.target.value.length === 0) {
            value = initialCategories;
        } else {
            value = event.target.value;
        }

        dispatch({
            type: 'COMMUNITY_CATEGORY_CHANGED',
            categories: typeof value === 'string' ? value.split(',') : value,
        });
    };

    return (
        <FormControl>
            <Select
                className={classes.root}
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                value={communityCategories}
                onChange={handleChange}
                variant="outlined"
                margin="dense"
            >
                {Object.keys(PartnersCategory).map((category) => {
                    return (
                        <MenuItem key={category} value={PartnersCategory[category]}>
                            {category}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};
