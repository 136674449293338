import { Community, useUserCommunitiesLazyQuery } from 'graphql/generated';
import { Dispatch, Reducer, useEffect, useReducer } from 'react';
import { createContainer } from 'react-tracked';
import { UserCommunitiesAction, UserCommunitiesState, INITIAL_USER_COMMUNITIES_STATE, reducer } from './reducer';

const useValue = (): [UserCommunitiesState, Dispatch<UserCommunitiesAction>] => {
    const [state, dispatch] = useReducer<Reducer<UserCommunitiesState, UserCommunitiesAction>>(
        reducer,
        INITIAL_USER_COMMUNITIES_STATE,
    );

    const [loadCommunities] = useUserCommunitiesLazyQuery({
        fetchPolicy: 'network-only',
        onCompleted({ me }) {
            const communities = (me?.communities.edges.map(({ node }) => node) as Community[]) || [];
            dispatch({ type: 'LOAD_COMMUNITIES_SUCCESS', communities });
        },
        onError(error) {
            dispatch({ type: 'LOAD_COMMUNITIES_FAILED', error: error.message });
        },
    });

    useEffect(() => {
        dispatch({ type: 'LOAD_COMMUNITIES_STARTED' });
        loadCommunities();
    }, [loadCommunities, state.communityRefetchAck]);

    return [state, dispatch];
};

export const {
    Provider: UserCommunitiesProvider,
    useTracked: useUserCommunities,
    useTrackedState: useUserCommunitiesState,
    useUpdate: useUserCommunitiesDispatch,
    useSelector: useUserCommunitiesStateSelector,
} = createContainer(useValue);
