import React from 'react';
import ReactPlayer from 'react-player';

export const VideoPlayer = ({ light = true, url, ...props }): React.ReactElement => {
    return (
        <ReactPlayer
            style={{ backgroundColor: '#000', outline: 'none' }}
            width="100%"
            height="100%"
            light={light}
            controls
            url={url}
            {...props}
        />
    );
};
