import { makeStyles } from '@material-ui/core';
import { mobileSize } from 'config';

export const useStyles = makeStyles((theme) => {
    return {
        text: {
            fontSize: '1.1rem',
            fontWeight: 400,
            color: theme.palette.text.secondary,
            [theme.breakpoints.down(mobileSize)]: {
                fontStyle: 'italic',
            },
        },
    };
});
