import { MenuItem, MenuItemProps } from '@material-ui/core';
import { useUserCommunitiesState } from 'context/user-communities/store';
import { useSettings } from 'context/SettingsContext';
import { Field, FieldAttributes } from 'formik';
import { TextField, TextFieldProps } from 'formik-material-ui';
import React from 'react';
import { getTranslationContent } from 'utils/translation-helper';

type CommunityFieldProps = {
    name: string;
    label?: string;
    inputVariant?: TextFieldProps['variant'];
    menuItemProps?: MenuItemProps;
} & FieldAttributes<unknown>;

export const CommunityField: React.FC<CommunityFieldProps> = ({
    name,
    label,
    inputVariant = 'outlined',
    menuItemProps,
    ...props
}) => {
    const { language } = useSettings();
    const { communities } = useUserCommunitiesState();

    return (
        <Field component={TextField} select fullWidth variant={inputVariant} label={label} name={name} {...props}>
            {communities.map((community) => {
                const translations = community.translations.edges.map(({ node }) => node) || [];
                const communityContent = getTranslationContent({
                    translations,
                    language,
                    requiredFields: ['name'],
                });
                return (
                    <MenuItem key={community.id} value={community.id} {...menuItemProps} button>
                        {communityContent.name}
                    </MenuItem>
                );
            })}
        </Field>
    );
};
