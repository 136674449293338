import { Box } from '@material-ui/core';
import { Portal } from 'components/portal';
import React from 'react';
import CarouselDots from 'react-carousel-dots';
import { useStyles } from './dots.style';

export const Dots: React.FC<{ portalID: string; active: number; length: number }> = ({ portalID, active, length }) => {
    const classes = useStyles();

    return (
        <Portal portalID={portalID}>
            <Box className={classes.root}>
                <CarouselDots length={length} active={active} size={6} margin={2} />
            </Box>
        </Portal>
    );
};
