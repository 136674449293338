import produce, { Draft } from 'immer';
import { Reducer } from 'react';

// #region State
export type LayoutState = {
    sidebarCollapsed: boolean;
};

export const INITIAL_LAYOUT_STATE: LayoutState = {
    sidebarCollapsed: false,
};
// #endregion

// #region Sync Actions
type SetSidebarCollapsedAction = { type: 'SET_SIDEBAR_COLLAPSED'; collapsed: boolean };

export type LayoutAction = SetSidebarCollapsedAction;

export const reducer: Reducer<LayoutState, LayoutAction> = produce(
    (draft: Draft<LayoutState>, action: LayoutAction): void => {
        switch (action.type) {
            case 'SET_SIDEBAR_COLLAPSED':
                draft.sidebarCollapsed = action.collapsed;
                break;
            default:
                break;
        }
    },
);
// #endregion
