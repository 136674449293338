import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
    return {
        header: {
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 0,
        },
        cover: {
            flexGrow: 1,
            position: 'relative',
            display: 'flex',
            backgroundColor: '#424242',
        },
        coverImage: {
            flex: 1,
            height: '100%',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            display: 'flex',
            alignItems: 'flex-end',
        },
        glass: {
            background: 'linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.6))',
            backdropFilter: 'blur(3px)',
            width: '100%',
        },
        content: {
            padding: theme.spacing(2.5),
        },
        info: {
            display: 'flex',
            flexDirection: 'column',
            color: 'rgb(255,255,255)',
            '& h5': {
                fontWeight: 700,
                textTransform: 'uppercase',
                color: 'rgb(240, 40, 74)',
                textShadow: '0px 0px 6px rgba(0,0,0,0.2)',
            },
            '& h2': {
                fontWeight: 700,
                margin: theme.spacing(0.5, 0),
                color: 'rgb(255,255,255)',
                textShadow: '0px 0px 6px rgba(0,0,0,0.2)',
            },
            '& span': {
                color: 'rgb(255,255,255)',
                textShadow: '0px 0px 6px rgba(0,0,0,0.2)',
            },
            [theme.breakpoints.down('xs')]: {
                '& h5': {
                    fontSize: '0.9rem',
                },
                '& h2': {
                    fontSize: '1.3rem',
                },
            },
        },
        isExpired: {
            color: 'inherit !important',
            opacity: 0.7,
        },
        actionButtons: {
            display: 'flex',
            alignItems: 'flex-end',
        },
        deleteButton: {
            color: theme.palette.error.main,
        },
    };
});
