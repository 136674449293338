import { Dispatch, Reducer, useReducer } from 'react';
import { createContainer } from 'react-tracked';
import { INITIAL_FEED_STATE, FeedAction, FeedState, reducer } from './reducer';

const useValue = (): [FeedState, Dispatch<FeedAction>] => {
    const [state, dispatch] = useReducer<Reducer<FeedState, FeedAction>>(reducer, INITIAL_FEED_STATE);

    return [state, dispatch];
};

export const {
    Provider: FeedProvider,
    useTracked: useFeed,
    useTrackedState: useFeedState,
    useUpdate: useFeedDispatch,
    useSelector: useFeedStateSelector,
} = createContainer(useValue);
