import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
    return {
        menuButton: {
            height: 'fit-content',
            opacity: 0,
            transition: '.2s opacity',
            color: theme.palette.text.secondary,
        },
        openButtonActive: {
            opacity: 1,
        },
    };
});
