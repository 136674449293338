import { MutationFunctionOptions } from '@apollo/react-common';
import { SvgIcon, useMediaQuery, useTheme } from '@material-ui/core';
import { ReactComponent as TrashCanOutline } from 'assets/community-icons/trash-can-outline.svg';
import clsx from 'clsx';
import { ActionMenu, Actions } from 'components/action-menu';
import { useNotifications } from 'context/NotificationsContext';
import { ExecutionResult } from 'graphql';
import { DeletePostCommentMutation, Exact, PostComment } from 'graphql/generated';
import { mobileSize } from 'layout-config';
import React from 'react';
import { useStyles } from './post-comment-item-actions.style';

type PostCommentItemActionsProps = {
    id: PostComment['id'];
    deleteComment: (
        options?: MutationFunctionOptions<DeletePostCommentMutation, Exact<{ id: string }>> | undefined,
    ) => Promise<ExecutionResult<DeletePostCommentMutation>>;
};

export const PostCommentItemActions: React.FC<PostCommentItemActionsProps> = ({ id, deleteComment }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(mobileSize));
    const { confirm } = useNotifications();

    const handleDelete = (): void => {
        confirm({
            onConfirm: () => deleteComment({ variables: { id } }),
            title: 'Delete Comment',
            confirmText: 'Are you sure you want to delete this comment?',
        });
    };

    const actions: Actions = [
        {
            primaryText: 'Delete comment',
            startIcon: <SvgIcon component={TrashCanOutline} />,
            onClick: handleDelete,
        },
    ];

    return (
        <ActionMenu
            actions={actions}
            buttonProps={{
                className: clsx(classes.menuButton, isMobile && classes.openButtonActive, 'comment-actions-button'),
                activeClassName: 'comment-actions-button-active',
            }}
        />
    );
};
