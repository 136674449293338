import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
    return {
        root: {
            [theme.breakpoints.down('xs')]: {
                borderRadius: 0,
                border: 'none',
                backgroundColor: theme.palette.background.default,
            },
        },
        button: {
            height: 40,
            backgroundColor: 'transparent !important',
        },
        buttonLabel: {
            justifyContent: 'flex-start',
            fontWeight: 400,
            textAlign: 'left',
            whiteSpace: 'nowrap',
            color: theme.palette.text.primary,
        },
    };
});
