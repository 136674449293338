import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Icon,
    IconButton,
    Typography,
} from '@material-ui/core';
import React from 'react';
import { useStyles } from './confirmation-dialog.style';

type ConfirmationDialogProps = {
    open: boolean;
    onClose: any;
    onConfirm: any;
    title: string;
    confirmText: string;
};

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
    open,
    onClose,
    onConfirm,
    title,
    confirmText,
}) => {
    const classes = useStyles();
    return (
        <Dialog open={open} onClose={onClose} classes={{ paper: classes.dialogPaper }}>
            <DialogTitle disableTypography className={classes.title}>
                <Typography variant="h5">{title}</Typography>
                <IconButton size="small" onClick={onClose}>
                    <Icon>close</Icon>
                </IconButton>
            </DialogTitle>
            <Divider />
            {confirmText && (
                <DialogContent className={classes.content}>
                    <Typography className={classes.confirmText}>{confirmText}</Typography>
                </DialogContent>
            )}
            <DialogActions className={classes.actions}>
                <Button onClick={onClose} variant="text" color="primary">
                    Cancel
                </Button>
                <Button className={classes.button} onClick={onConfirm} variant="contained" color="primary">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};
