import { Box, Icon, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { MemberStatus } from 'graphql/generated';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './member-status-badge.style';

export const MemberStatusBadge: React.FC<{ status: MemberStatus }> = ({ status }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const icons = {
        [MemberStatus.Guest]: 'person',
        [MemberStatus.PendingApproval]: 'timelapse',
        [MemberStatus.PendingPayment]: 'toll',
        [MemberStatus.Approved]: 'check',
    };

    const statusClass = {
        [MemberStatus.Guest]: classes.guest,
        [MemberStatus.PendingApproval]: classes.pendingApproval,
        [MemberStatus.PendingPayment]: classes.pendingPayment,
        [MemberStatus.Approved]: classes.approved,
    };

    return (
        <Box className={classes.root}>
            <Box className={clsx(classes.iconContainer, statusClass[status])}>
                <Icon className={classes.icon}>{icons[status]}</Icon>
            </Box>
            <Typography variant="h5">{t(`enum:memberStatus_${status}`)}</Typography>
        </Box>
    );
};
