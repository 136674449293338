import { Box, Divider, IconButton, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { Spinner, UserListItem } from 'components';
import { User, useRepostedUsersLazyQuery } from 'graphql/generated';
import React, { useEffect, useState } from 'react';

type RepostedUsersDialogProps = {
    repostCount: number;
    postId: string;
};

export const RepostedUsersDialog: React.FC<RepostedUsersDialogProps> = ({ repostCount, postId }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const [open, setOpen] = useState(false);
    const [repostedUsers, setRepostedUsers] = useState<User[]>([]);
    const [page, setPage] = useState(1);

    const openDialog = (): void => setOpen(true);
    const closeDialog = (): void => setOpen(false);

    const [loadRepostedUsers, { data, loading }] = useRepostedUsersLazyQuery({
        fetchPolicy: 'network-only',
        onCompleted({ repostedUsers: { edges } }) {
            const newRepostedUsers = (edges.map(({ node }) => node) as User[]) || [];
            setRepostedUsers((prev) => [...prev, ...newRepostedUsers]);
        },
    });

    const hasNextPage = data?.repostedUsers.pageInfo.hasNextPage;

    const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
        const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
        const thresholdReached = scrollTop + clientHeight + 100 > scrollHeight;
        if (thresholdReached && !loading && hasNextPage) {
            setPage((prev) => prev + 1);
        }
    };

    const resetState = () => {
        setRepostedUsers([]);
        setPage(1);
    };

    useEffect(() => {
        if (open) {
            loadRepostedUsers({ variables: { where: { postId }, page } });
        }
    }, [loadRepostedUsers, open, page, postId]);

    return (
        <>
            <Box onClick={openDialog} style={{ cursor: 'pointer' }}>
                <Typography style={{ fontSize: '1rem', fontStyle: 'italic' }}>&nbsp;· {repostCount} shares </Typography>
            </Box>
            <Dialog
                open={open}
                fullScreen={isMobile}
                maxWidth="sm"
                fullWidth
                TransitionProps={{ onExited: resetState }}
            >
                <DialogTitle disableTypography>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h3">Users who shared this post</Typography>
                        <IconButton size="small" onClick={closeDialog}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <Divider />
                <DialogContent
                    style={{
                        minHeight: 300,
                        display: 'flex',
                        flexDirection: 'column',
                        padding: 0,
                        overflowX: 'hidden',
                    }}
                    onScroll={handleScroll}
                >
                    {repostedUsers.map((user) => (
                        <UserListItem key={user.id} user={user} />
                    ))}
                    {loading && (
                        <Spinner style={{ width: '100%', height: 24 }} spinnerStyle={{ width: 24, height: 24 }} />
                    )}
                    {repostedUsers.length === 0 && !loading && (
                        <Box flexGrow={1} display="flex" justifyContent="center" alignItems="center">
                            <Typography variant="h4">No users shared this post</Typography>
                        </Box>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
};
