import { Grid, Icon, IconButton, InputLabel } from '@material-ui/core';
import { LoadingButton } from 'components';
import { useNotifications } from 'context/NotificationsContext';
import { Field, Formik, FormikProps, FormikValues } from 'formik';
import { TextField } from 'formik-material-ui';
import { useChangePasswordMutation } from 'graphql/generated';
import React, { useRef, useState } from 'react';
import validationSchema from './validation-schema';

export const ChangePasswordForm: React.FC = () => {
    const { notify } = useNotifications();
    const formRef = useRef<FormikProps<FormikValues>>(null);
    const [passwordFieldType, setPasswordFieldType] = useState('password');

    const toggleShowPassword = (): void => {
        if (passwordFieldType === 'password') {
            setPasswordFieldType('text');
        } else {
            setPasswordFieldType('password');
        }
    };

    const initialValues = { currentPassword: '', newPassword: '' };

    const [changePassword, { loading }] = useChangePasswordMutation({
        onCompleted() {
            notify({
                type: 'success',
                message: 'Password changed successfully!',
            });
            formRef.current?.resetForm();
        },
        onError(error) {
            const message = error.message.replace('GraphQL error: ', '');
            notify({
                type: 'error',
                message,
            });
            formRef.current?.setSubmitting(false);
        },
    });

    const onSubmit = (input): void => {
        changePassword({ variables: { input } });
    };

    return (
        <Formik {...{ initialValues, onSubmit, validationSchema }} innerRef={formRef}>
            {({ submitForm }): React.ReactElement => {
                return (
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                            <InputLabel style={{ marginBottom: 5 }}>
                                Current password <span style={{ color: 'red' }}>*</span>
                            </InputLabel>
                            <Field
                                component={TextField}
                                variant="outlined"
                                name="currentPassword"
                                fullWidth
                                type="password"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputLabel style={{ marginBottom: 5 }}>
                                New password <span style={{ color: 'red' }}>*</span>
                            </InputLabel>
                            <Field
                                component={TextField}
                                variant="outlined"
                                name="newPassword"
                                fullWidth
                                type={passwordFieldType}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton onClick={toggleShowPassword}>
                                            <Icon>
                                                {passwordFieldType === 'password' ? 'visibility_off' : 'visibility'}
                                            </Icon>
                                        </IconButton>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item container justifyContent="flex-end" xs={12}>
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                onClick={submitForm}
                                text="Change"
                                loadingText="Changing"
                                loading={loading}
                            />
                        </Grid>
                    </Grid>
                );
            }}
        </Formik>
    );
};
