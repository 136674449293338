import React from 'react';
import { matrixClient, RoomStore } from '@mediusoft/matrix-chat';
import { useAuth } from 'context/auth/store';

export const InitializeMatrix: React.FC = () => {
    const [{ user }] = useAuth();
    (async () => {
        const matrixProfileInfo = await matrixClient.getProfileInfo(user?.matrixAuth?.user_id);
        const matrixAvatar = matrixProfileInfo.avatar_url;
        if (matrixAvatar !== user?.avatar?.url) {
            RoomStore.uploadAvatar(user?.avatar?.url);
        }
    })();

    return <></>;
};
