import { makeStyles } from '@material-ui/core/styles';
import { mobileSize } from 'config';
import { cardP, cardPMobile } from 'layout-config';

export const useStyles = makeStyles((theme) => {
    return {
        root: {
            overflow: 'visible',
            paddingBottom: theme.spacing(2),
            [theme.breakpoints.down(mobileSize)]: {
                borderRadius: 0,
                backgroundColor: theme.palette.background.default,
                boxShadow: 'none',
                borderTop: `1px solid ${theme.palette.divider}`,
                borderBottom: `1px solid ${theme.palette.divider}`,
            },
        },
        footer: {
            padding: theme.spacing(0, cardP),
            [theme.breakpoints.down(mobileSize)]: {
                padding: theme.spacing(0, cardPMobile),
            },
        },
        actions: {
            marginTop: theme.spacing(2),
            [theme.breakpoints.down(mobileSize)]: {
                marginTop: theme.spacing(1),
            },
        },
        comments: {
            marginTop: theme.spacing(3),
            [theme.breakpoints.down(mobileSize)]: {
                marginTop: theme.spacing(2),
            },
        },
        publishDate: {
            marginTop: theme.spacing(2),
            fontStyle: 'italic',
            fontWeight: 400,
        },
    };
});
