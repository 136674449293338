import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => {
    return {
        root: {
            width: '100%',
            height: '100%',
            position: 'relative',
        },
        image: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
        button: {
            position: 'absolute',
            top: 4,
            left: 4,
            borderRadius: 4,
            boxShadow: 'none',
        },
    };
});
