import React from 'react';
import LightboxC, { ILightBoxProps } from 'react-image-lightbox';
import { useStyles } from './lightbox.style';

export const Lightbox: React.FC<ILightBoxProps> = ({ ...props }) => {
    const classes = useStyles();
    return (
        <LightboxC
            wrapperClassName={classes.lightbox}
            reactModalStyle={{
                overlay: {
                    zIndex: 1500,
                },
            }}
            animationDuration={600}
            {...props}
        />
    );
};
