import { makeStyles } from '@material-ui/core/styles';
import { mobileSize } from 'config';

export const useStyle = makeStyles((theme) => {
    return {
        root: {
            marginRight: 0,
            paddingRight: theme.spacing(5),
            [theme.breakpoints.down(mobileSize)]: {
                padding: theme.spacing(0, 1),
                overflow: 'hidden',
            },
        },
        container: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            minHeight: theme.spacing(4.5),
            marginBottom: theme.spacing(3),
            [theme.breakpoints.down(mobileSize)]: {
                marginBottom: theme.spacing(1),
            },
        },

        rightContainer: {
            justifyItems: 'flex-end',
        },
    };
});
