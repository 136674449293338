import { Tab, TabProps, Tabs as MUITabs, TabsProps as MUITabsProps, Tooltip } from '@material-ui/core';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { getSearchParams, setSearchParams } from 'utils/browser-utils';
import { isEmptyObject } from 'utils/validationHelper';

export type TabList = ({ path: string; label: React.ReactNode; hidden?: boolean; tooltip?: string } & TabProps)[];

type TabsProps = MUITabsProps & {
    tabs: TabList;
    currentTab: number;
    setCurrentTab: Dispatch<SetStateAction<number>>;
};

export const Tabs: React.FC<TabsProps> = ({ tabs, currentTab, setCurrentTab, ...props }) => {
    const handleChangeTab = (_, newValue): void => {
        setSearchParams({ tab: tabs[newValue].path });
        setCurrentTab(newValue);
    };

    useEffect(() => {
        const path = getSearchParams().tab as string;
        const isEmpty = isEmptyObject(tabs);
        if (path && !isEmpty) {
            tabs.map((t, idx) => {
                if (t.path === path && !t.disabled) {
                    setCurrentTab(idx);
                }
            });
        }
    }, [tabs, setCurrentTab]);

    return (
        <MUITabs
            variant="scrollable"
            scrollButtons="auto"
            textColor="primary"
            indicatorColor="primary"
            value={currentTab}
            onChange={handleChangeTab}
            {...props}
        >
            {Object.values(tabs).map(({ label, hidden, tooltip, ...rest }, index) =>
                hidden ? null : tooltip ? (
                    <Tooltip key={index} title={tooltip}>
                        <Tab label={label} {...rest} />
                    </Tooltip>
                ) : (
                    <Tab key={index} label={label} {...rest} />
                ),
            )}
        </MUITabs>
    );
};
