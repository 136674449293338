import { ClickAwayListener, useTheme, Box, IconButton, Tooltip } from '@material-ui/core';
import { Picker, BaseEmoji } from 'emoji-mart';
import React, { useState } from 'react';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import { FieldProps } from 'formik';
import { useStyles } from './emoji-picker.style';
import clsx from 'clsx';

export const EmojiPicker: React.FC<
    FieldProps & { cursorPosition?: number; updateCursorPosition: (position: number | undefined) => void }
> = ({ field, form, cursorPosition, updateCursorPosition, ...props }) => {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = useState(false);

    const toggleOpen = (): void => setOpen((prev) => !prev);

    const handleChange = (emoji: BaseEmoji): void => {
        if (cursorPosition !== undefined) {
            form.setFieldValue(
                field.name,
                field.value.slice(0, cursorPosition) +
                    emoji.native +
                    field.value.slice(cursorPosition, field.value.length),
            );
            updateCursorPosition(cursorPosition + emoji.native.length);
        } else {
            form.setFieldValue(field.name, field.value + emoji.native);
        }
    };

    return (
        <Box className={classes.root}>
            <Tooltip title="Insert an emoji">
                <IconButton size="small" className={clsx(classes.icon, open && classes.open)} onClick={toggleOpen}>
                    <InsertEmoticonIcon />
                </IconButton>
            </Tooltip>
            {open ? (
                <ClickAwayListener onClickAway={toggleOpen}>
                    <Picker
                        style={{ position: 'absolute', bottom: 30, right: 0, zIndex: 1 }}
                        color={theme.palette.primary.main}
                        theme={theme.palette.type}
                        showPreview={false}
                        onSelect={handleChange}
                        {...props}
                    />
                </ClickAwayListener>
            ) : null}
        </Box>
    );
};
