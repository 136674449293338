import { useScrollTrigger, Zoom } from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useStyles } from './scroll-top.style';

export const ScrollTop: React.FC<{ className?: string }> = ({ children, className = '' }) => {
    const classes = useStyles();
    const [scrollAnchor, setScrollAnchor] = useState<any>(undefined);
    const trigger = useScrollTrigger({ target: scrollAnchor, disableHysteresis: true, threshold: 100 });

    const handleClick = () => {
        if (scrollAnchor) {
            scrollAnchor.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    };

    useEffect(() => {
        setScrollAnchor(window);
    }, []);

    return (
        <Zoom in={trigger}>
            <div onClick={handleClick} role="presentation" className={clsx(classes.container, className)}>
                {children}
            </div>
        </Zoom>
    );
};
