import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'enum';

export const useStyles = makeStyles((theme) => {
    return {
        dialog: {
            minWidth: 500,
            maxWidth: 500,
            '& ::-webkit-scrollbar': {
                width: 8,
                marginRight: 4,
                [theme.breakpoints.down('xs')]: {
                    width: 4,
                },
            },
            '& ::-webkit-scrollbar-thumb': {
                borderRadius: 4,
                backgroundColor: theme.palette.type === Theme.Light ? 'rgb(188, 192, 196)' : 'rgba(255, 255, 255, 0.3)',
            },
            [theme.breakpoints.down('xs')]: {
                maxWidth: 'unset',
                minWidth: 'unset',
            },
        },

        dialogTitle: {
            padding: theme.spacing(1.25, 2),
        },
        dialogHeader: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        divider: {
            margin: '10px 0',
            marginBottom: 16,
        },
        dialogContent: {
            padding: theme.spacing(0, 2),
            minHeight: 300,
            maxHeight: 600,
            [theme.breakpoints.down('xs')]: {
                maxHeight: 'unset',
            },
        },
        dialogActions: {
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(2),
        },
        attachments: {
            display: 'flex',
            marginBottom: theme.spacing(2),
            padding: theme.spacing(1, 2),
            alignItems: 'center',
            borderRadius: 6,
            width: '100%',
        },
        photoButton: {
            padding: 8,
            color: '#20bf6b',
        },
        fileButton: {
            padding: 8,
            color: '#f7b731',
        },
    };
});
