import { ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import { ColorIDAvatar, Link } from 'components';
import { User } from 'graphql/generated';
import React from 'react';

export const UserListItem: React.FC<{ user: User }> = ({ user }) => {
    return (
        <Link to={`/d/profile/${user.id}`}>
            <ListItem button>
                <ListItemAvatar>
                    <ColorIDAvatar id={user.id} text={user.fullName} fontSize="sm" src={user.avatar?.url} />
                </ListItemAvatar>
                <ListItemText primary={user.fullName} />
            </ListItem>
        </Link>
    );
};
