import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'enum';

export const useStyles = makeStyles((theme) => {
    return {
        image: {
            width: '100%',
            height: 'auto',
            objectFit: 'cover',
            borderRadius: 4,
        },
        imageContainer: {
            marginBottom: theme.spacing(1),
        },
        photoDeleteButton: {
            position: 'absolute',
            top: 4,
            right: 4,
            padding: 6,
            backgroundColor: 'rgba(30, 30, 30)',
            color: 'white',
            '&:hover': {
                backgroundColor: 'rgba(50, 50, 50)',
            },
        },
        fileContainer: {
            marginBottom: theme.spacing(1),
            padding: theme.spacing(1, 2),
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        fileName: {
            margin: theme.spacing(0, 1),
            wordBreak: 'break-all',
        },
        fileDeleteButton: {
            padding: 6,
            backgroundColor: theme.palette.type === Theme.Light ? 'rgb(228, 230, 235)' : 'rgba(30, 30, 30)',
            '&:hover': {
                backgroundColor: theme.palette.type === Theme.Light ? 'rgb(200, 202, 205)' : 'rgba(50, 50, 50)',
            },
        },
        fileDeleteIcon: {
            fontSize: 16,
            color: theme.palette.type === Theme.Light ? 'rgba(0, 0, 0, 0.54)' : 'rgba(255, 255, 255, 0.7)',
        },
    };
});
