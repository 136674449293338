import { Dispatch, Reducer, useReducer } from 'react';
import { createContainer } from 'react-tracked';
import { INITIAL_PAYMENT_METHODS_STATE, reducer, PaymentMethodsAction, PaymentMethodsState } from './reducer';

const useValue = (): [PaymentMethodsState, Dispatch<PaymentMethodsAction>] => {
    const [state, dispatch] = useReducer<Reducer<PaymentMethodsState, PaymentMethodsAction>>(
        reducer,
        INITIAL_PAYMENT_METHODS_STATE,
    );
    return [state, dispatch];
};

export const {
    Provider: PaymentMethodsProvider,
    useTracked: usePaymentMethods,
    useTrackedState: usePaymentMethodsState,
    useUpdate: usePaymentMethodsDispatch,
    useSelector: usePaymentMethodsStateSelector,
} = createContainer(useValue);
