import React from 'react';

export const TabPanel: React.FC<{ value: number; index: number }> = ({ value, index, children, ...props }) => {
    return (
        <div
            style={{
                flex: 1,
                display: value !== index ? 'none' : 'flex',
                flexDirection: 'column',
            }}
            id={`simple-tabpanel-${index}`}
            {...props}
        >
            {value === index && children}
        </div>
    );
};
