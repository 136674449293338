import { PaletteType } from '@material-ui/core';
import { LanguageCode } from 'graphql/generated';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'react-use';
import { LANGUAGE_KEY, THEME_KEY } from 'utils/localStorage';

const DEFAULT_LANGUAGE = LanguageCode.En;
const DEFAULT_THEME = 'light';

type SettingsContextProps = {
    language: LanguageCode;
    setLanguage: any;
    changeLanguage: (lang: LanguageCode) => void;
    isDarkMode: boolean;
    theme?: PaletteType;
    toggleTheme: () => void;
    isHeaderConsistent: boolean;
    setIsHeaderConsistent: React.Dispatch<React.SetStateAction<boolean>>;
    headerHidden: boolean;
    setHeaderHidden: React.Dispatch<React.SetStateAction<boolean>>;
};

const SettingsContext = createContext<SettingsContextProps>({
    language: DEFAULT_LANGUAGE,

    isDarkMode: false,
    theme: DEFAULT_THEME,
    toggleTheme: () => {
        //
    },
    changeLanguage: () => {
        //
    },
    isHeaderConsistent: true,
    setIsHeaderConsistent: () => {
        //
    },
    headerHidden: false,
    setHeaderHidden: () => {
        //
    },
    setLanguage: () => {
        //
    },
});
export const useSettings = (): SettingsContextProps => useContext<SettingsContextProps>(SettingsContext);

export const SettingsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [language = DEFAULT_LANGUAGE, setLanguage] = useLocalStorage<LanguageCode>(LANGUAGE_KEY, DEFAULT_LANGUAGE, {
        raw: true,
    });
    const { i18n } = useTranslation();

    useEffect(() => {
        const isLanguageSupported = Object.values(LanguageCode).includes(language);
        if (!isLanguageSupported) {
            setLanguage(DEFAULT_LANGUAGE);
        }
    }, []);

    const [theme, setTheme] = useLocalStorage<PaletteType>(THEME_KEY, DEFAULT_THEME, { raw: true });
    const [isHeaderConsistent, setIsHeaderConsistent] = useState(true);
    const [headerHidden, setHeaderHidden] = useState(false);

    const changeLanguage = (lang: LanguageCode): void => {
        if (lang !== language) {
            setLanguage(lang);
            i18n.changeLanguage(lang.toLowerCase());
        }
    };

    const isDarkMode = theme === 'dark';
    const toggleTheme = (): void => setTheme(isDarkMode ? 'light' : 'dark');

    return (
        <SettingsContext.Provider
            value={{
                language,
                setLanguage,
                changeLanguage,
                isDarkMode,
                theme,
                toggleTheme,
                setIsHeaderConsistent,
                isHeaderConsistent,
                setHeaderHidden,
                headerHidden,
            }}
        >
            {children}
        </SettingsContext.Provider>
    );
};
