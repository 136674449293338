import { Box, Card } from '@material-ui/core';
import { Post } from 'graphql/generated';
import React from 'react';
import { PostHeader } from '../post-header';
import { useStyles } from './readonly-post-item.style';
import { getPostContent } from '../../utils';
import { Link } from 'components/link';

type ReadonlyPostItemProps = {
    post: Post;
    readonly?: boolean;
};

export const ReadonlyPostItem: React.FC<ReadonlyPostItemProps> = ({ post, readonly = true }) => {
    const classes = useStyles();

    return (
        <>
            {readonly ? (
                <Card variant="outlined" elevation={0} className={classes.root}>
                    <Box>
                        <PostHeader post={post} readonly />
                        <Box overflow="hidden">{getPostContent(post, true)}</Box>
                    </Box>
                </Card>
            ) : (
                <Link to={`/d/post/${post.id}`} style={{ overflow: 'hidden' }}>
                    <Card variant="outlined" elevation={0} className={classes.root}>
                        <Box>
                            <PostHeader post={post} readonly />
                            <Box overflow="hidden">{getPostContent(post, true)}</Box>
                        </Box>
                    </Card>
                </Link>
            )}
        </>
    );
};
