import LuxonUtils from '@date-io/luxon';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import Routes from 'routes';
import './App.css';

const App: React.FC = () => {
    return (
        <MuiPickersUtilsProvider utils={LuxonUtils}>
            <Routes />
        </MuiPickersUtilsProvider>
    );
};

export default App;
