import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { useStyles } from './about-dialog.style';

type AboutDialogProps = {
    openAbout: boolean;
    setOpenAbout: (boolean) => void;
};

export const AboutDialog: React.FC<AboutDialogProps> = ({ openAbout, setOpenAbout }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = () => {
        setOpenAbout(false);
    };

    return (
        <Dialog
            open={openAbout}
            onClose={handleClose}
            fullScreen={isMobile}
            maxWidth={false}
            classes={{ paper: classes.dialog }}
        >
            <DialogTitle style={{ display: 'flex', justifyContent: 'flex-end', padding: 16 }}>
                <IconButton size="small" onClick={() => setOpenAbout(false)}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Typography style={{ lineHeight: 1 }} variant="h3">
                    About us
                </Typography>

                <Box className={classes.dialogDescription}>
                    <p dir="ltr">
                        <span>
                            THE LOBBY LIFESTYLE &ndash; YOUR BEST GUIDE INTO THE WORLD OF EXQUISITE CUSTOMISED
                            EXPERIENCES, EVENTS AND CONCIERGE SUPPORT.
                        </span>
                    </p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">
                        <a href="tel:+37126575953">+37126575953</a>
                    </p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">
                        <a href="mailto:welcome@thelobbylifestyle.com"> welcome@thelobbylifestyle.com</a>
                    </p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">
                        <span>
                            THE LOBBY Lifestyle provides an inviting ambience for individuals to interact, share
                            lifestyle, get acquainted with other community members, who can share remarkable
                            experiences, hidden gems, insights and global opportunities for discovering{' '}
                        </span>
                        <b>what is possible even if it seems impossible.</b>
                    </p>
                    <br />
                    <hr />
                    <br />
                    <div dir="ltr">
                        <span>COMMUNITY</span>
                    </div>
                    <p>&nbsp;</p>
                    <p dir="ltr">
                        <span>
                            We are proud to host a community, composed of personalities who foster quality living each
                            in their own way, nevertheless with much in common.
                        </span>
                    </p>
                    <p>&nbsp;</p>
                    <p dir="ltr">
                        <span>
                            The platform allows community members to chat, participate in different projects and
                            activities, establish new connections, find friends and share visions.
                        </span>
                    </p>
                    <p>&nbsp;</p>
                    <div dir="ltr">
                        <span>ART OF LIVING</span>
                    </div>
                    <p>&nbsp;</p>
                    <p dir="ltr">
                        <span>
                            Discover and enjoy a unique lifestyle in travel, wellness, leisure, enrichment, art,
                            culture, music, theatre, cinema, sport, gastronomy and much more activities.
                        </span>
                    </p>
                    <p>&nbsp;</p>
                    <p dir="ltr">
                        <span>
                            We create signature events and experiences, both offline and online, to challenge and enrich
                            your life and range of interests.
                        </span>
                    </p>
                    <p>&nbsp;</p>
                    <div dir="ltr">
                        <span>TRAVEL AND HOSPITALITY</span>
                    </div>
                    <p>&nbsp;</p>
                    <p dir="ltr">
                        <span>
                            We organize tailored-made travel including activities and entertainment for groups and
                            individuals.
                        </span>
                    </p>
                    <p>&nbsp;</p>
                    <p dir="ltr">
                        <span>
                            Concierge service worldwide, from ordinary to extraordinary, making magic if necessary.
                        </span>
                    </p>
                    <p>&nbsp;</p>
                    <p dir="ltr">
                        <span>We stand ready to curate the most demanding bespoke journeys.</span>
                    </p>
                    <br />
                    <hr />
                    <br />
                    <p dir="ltr">
                        <span>
                            THE LOBBY Lifestyle team will be happy to discuss all the opportunities and listen to your
                            individual wishes for discovery of local specialities, create your personal route, arrange
                            the transportation and coordinate all the visits.
                        </span>
                    </p>
                    <p>
                        <span>
                            <span>&nbsp;</span>
                        </span>
                    </p>
                    <p dir="ltr">
                        <span>
                            THE LOBBY Lifestyle develops experiences for learning and exchange, bringing diverse
                            creative communities into deep dialogue with the history, culture, and natural environment
                            of a destination.
                        </span>
                    </p>
                    <p>
                        <span>
                            <span>&nbsp;</span>
                        </span>
                    </p>
                    <p dir="ltr">
                        <span>
                            Part creative residency, part cultural exchange, part immersive hospitality experiment, we
                            bring together artists, writers, scientists, artisans, farmers, designers, chefs and other
                            place-makers for site-specific gatherings in support of research, conversation,
                            collaboration, and discoveries.
                        </span>
                    </p>
                    <p>
                        <span>
                            <span>&nbsp;</span>
                        </span>
                    </p>
                    <p dir="ltr">
                        <span>THE LOBBY Lifestyle Team</span>
                    </p>
                    <p>
                        <span>
                            <span>&nbsp;</span>
                        </span>
                    </p>
                    <p dir="ltr">
                        <span>Jelena Osipkova</span>
                    </p>
                    <p dir="ltr">
                        <span>Chief of Happiness</span>
                    </p>
                    <p dir="ltr">
                        <a href="tel:+37129530225">+371 29530225</a>
                    </p>
                    <p dir="ltr">
                        <a href="mailto:jelena@thelobby.lv">jelena@thelobby.lv</a>
                    </p>
                    <p>
                        <span>
                            <span>&nbsp;</span>
                        </span>
                    </p>
                    <p dir="ltr">
                        <span>Olga Sivel</span>
                    </p>
                    <p dir="ltr">
                        <span>Head of Creativity</span>
                    </p>
                    <p dir="ltr">
                        <a href="tel:+37125635300">+371 25635300</a>
                    </p>
                    <p>
                        <span>
                            <span>&nbsp;</span>
                        </span>
                    </p>
                    <p>&nbsp;</p>

                    <p dir="ltr">
                        <i style={{ fontSize: '16px', fontWeight: 'bold' }}>
                            &ldquo;The heart of human excellence often begins to beat when you discover a pursuit that
                            absorbs you, frees you, challenges you, or gives you a sense of meaning, joy, or
                            passion&rsquo;&rsquo;
                        </i>
                    </p>
                    <p>&nbsp;</p>
                    <div dir="ltr">
                        <span>Terry Orlick</span>
                    </div>
                </Box>
            </DialogContent>
        </Dialog>
    );
};
