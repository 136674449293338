import { useMeLazyQuery } from 'graphql/generated';
import { Dispatch, Reducer, useEffect, useReducer } from 'react';
import { createContainer } from 'react-tracked';
import { AuthAction, AuthState, INITIAL_AUTH_STATE, reducer } from './reducer';

const useValue = (): [AuthState, Dispatch<AuthAction>] => {
    const [state, dispatch] = useReducer<Reducer<AuthState, AuthAction>>(reducer, INITIAL_AUTH_STATE);

    const [loadUser] = useMeLazyQuery({
        fetchPolicy: 'network-only',
        onCompleted({ me: user }) {
            dispatch({ type: 'LOAD_USER_SUCCESS', user });
        },
        onError(error) {
            dispatch({ type: 'LOAD_USER_FAILED', error: error.message });
        },
    });

    useEffect(() => {
        if (state.accessToken) {
            dispatch({ type: 'LOAD_USER_STARTED' });
            loadUser();
        }
    }, [loadUser, state.userRefetchAck, state.accessToken]);

    return [{ ...state, isLoggedIn: !!state.accessToken }, dispatch];
};

export const {
    Provider: AuthProvider,
    useTracked: useAuth,
    useTrackedState: useAuthState,
    useUpdate: useAuthDispatch,
    useSelector: useAuthStateSelector,
} = createContainer(useValue);
