import { Box, Card, Typography } from '@material-ui/core';
import { RatioBox } from 'components';
import { Link } from 'components/link';
import { Event } from 'graphql/generated';
import { DateTime } from 'luxon';
import React from 'react';
import { useStyles } from './experience-post-content.style';
import { useSettings } from 'context/SettingsContext';

type ExperiencePostContentProps = {
    experience: Event;
};

export const ExperiencePostContent: React.FC<ExperiencePostContentProps> = ({ experience }) => {
    const classes = useStyles();
    const startDate = DateTime.fromISO(experience?.startTime);

    const date = startDate.toFormat('EEEE, MMMM d, yyyy') + ' at ' + startDate.toFormat('HH:mm');
    const isExpired = startDate < DateTime.fromISO(new Date().toISOString()) ? classes.isExpired : undefined;
    const image = experience?.images.edges[0]?.node.image.url || require('assets/event.jpg');
    const { language } = useSettings();

    const experienceContent = experience.translations.edges.find(({ node }) => node.lang === language)?.node;
    return (
        <Link to={`/d/experience/${experience.id}`}>
            <Card className={classes.header}>
                <RatioBox containerClassName={classes.cover}>
                    <Box
                        className={classes.coverImage}
                        style={{
                            backgroundImage: `url(${image})`,
                        }}
                    >
                        <div className={classes.glass}>
                            <Box display="flex" flexDirection="column" className={classes.content}>
                                <Box className={classes.info} display="flex" flexDirection="column" flexGrow={1}>
                                    {experience.startTime && (
                                        <Typography className={isExpired} variant="h5" component="h5">
                                            {date}
                                        </Typography>
                                    )}
                                    <Typography className={isExpired} variant="h2" component="h2">
                                        {experienceContent?.title}
                                    </Typography>
                                    {experienceContent?.address && (
                                        <Typography className={isExpired} variant="body1" component="span">
                                            {experienceContent?.address}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                        </div>
                    </Box>
                </RatioBox>
            </Card>
        </Link>
    );
};
