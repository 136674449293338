import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => {
    return {
        tab: {
            minWidth: 60,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
        },
    };
});
