import { alpha, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => {
    return {
        root: {
            overflow: 'hidden',
            '& .slick-list': {
                overflow: 'visible',
            },
            '& .slick-next': {
                right: theme.spacing(1),
            },
            '& .slick-prev': {
                left: theme.spacing(1),
                zIndex: 1,
            },
        },
        title: {
            ...theme.typography.h6,
            fontSize: '0.75rem',
            fontWeight: 700,
            textTransform: 'uppercase',
            color: alpha(theme.palette.text.secondary, 0.7),
        },
        addButton: {
            ...theme.typography.h6,
            color: alpha(theme.palette.text.secondary, 0.7),
            fontWeight: 600,
            padding: theme.spacing(0.75, 2),
        },
        paymentMethodItem: {
            padding: theme.spacing(0, 2),
            [theme.breakpoints.down(460)]: {
                padding: theme.spacing(0, 1),
            },
            '&:focus': {
                outline: 'none',
            },
        },
    };
});
