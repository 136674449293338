import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => {
    return {
        mainCard: {
            borderRadius: '3px',
            padding: '20px 20px 40px 20px',
            display: 'flex',
            justifyContent: 'space-between',
            position: 'relative',
        },
        cardIcon: {
            width: 40,
            height: 40,
            bottom: 0,
            right: 20,
            position: 'absolute',
        },
    };
});
