import { Box } from '@material-ui/core';
import { darken, lighten, makeStyles } from '@material-ui/core/styles';
import { Theme } from 'enum';
import React, { ReactNode } from 'react';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            backgroundColor:
                theme.palette.type === Theme.Light
                    ? darken(theme.palette.background.default, 0.1)
                    : lighten(theme.palette.background.default, 0.1),
            color:
                theme.palette.type === Theme.Light
                    ? darken(theme.palette.background.default, 0.5)
                    : lighten(theme.palette.background.default, 1),
        },
    };
});

type CircleIconProps = {
    radius?: number;
    icon: ReactNode;
};

export const CircleIcon: React.FC<CircleIconProps> = ({ radius, icon }) => {
    const classes = useStyles();
    const width = radius ? radius * 2 : 100;
    const height = radius ? radius * 2 : 100;
    return (
        <Box className={classes.root} width={width} height={height}>
            {icon}
        </Box>
    );
};
